import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AnimationOptions } from 'ngx-lottie';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  options: AnimationOptions = {
    path: 'assets/lotties/insurance.json',
  };

  userForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  constructor(
    private dataService: DataService,
    private toastr: ToastrService,
    private router: Router,
    private message: NzMessageService
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.login(this.userForm.value);
  }

  login(user: any) {
    this.dataService.login(user).subscribe((data: any) => {
      if (data?.data?.authenticateUserWithPassword?.sessionToken) {
        if (!data?.data?.authenticateUserWithPassword?.item?.actif) {
          this.message.create('error', "Utilisateur est bloqué. Veuillez svp contacter l'administrateur!");
          // this.toastr.error("Cet utilisateur est bloqué. Veuillez svp contacter l'administrateur!", 'Utilisateur bloqué');
        }
        else {
        
          this.dataService.setToken(data?.data?.authenticateUserWithPassword?.sessionToken);
          this.dataService.setUser(data?.data?.authenticateUserWithPassword?.item);
          this.router.navigate(['/home'])
        }
      }
      else {
        // this.toastr.error("Email ou mot de passe incorect!", 'Erreur authentification');
        this.message.create('error', "Email ou mot de passe incorect!");
      }
    }, 
    err => {
      // this.router.navigate(['/home'])
      console.log(`li la : ${err}`);
    })
  }

}
