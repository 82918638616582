<h5>{{table?.placeholder}}</h5>
<nz-table #editRowTable nzBordered [nzData]="listOfData" [nzPageSize]="9999999999" [nzShowPagination]="false" nzTableLayout="fixed">
    <thead>
      <tr>
        <th
            nzWidth="10%"
            [nzSelections]="listOfSelection"
            [(nzChecked)]="checked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
        ></th>
        <ng-container *ngIf="table?.columns">
            <ng-container *ngFor="let column of table?.columns">
                <th [nzWidth]="column?.size ? column?.size : '30%'" *ngIf="!column?.hide">{{column?.placeholder ? column?.placeholder : column?.name }}</th>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!table?.columns">
            <th>{{table?.placeholder}}</th>
        </ng-container>
        <th 
            nzWidth="15%"
        ></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of editRowTable.data">
        <ng-container *ngIf="!editCache[data.id].edit; else editTemplate">
          <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
          <ng-container *ngFor="let item of data?.items; let i=index">
            <td>{{ item }}</td>
          </ng-container>
          <td><a (click)="startEdit(data.id)">
            <div class="d-flex">
                <a [ngClass]="{'btn': true, 'shadow': true, 'btn-xs': true, 'sharp': true, 'mr-1': true, 'btn-primary': true}">
                    <i [ngClass]="{'fa': true, 'fa-pencil': true}"></i>
                </a>
            </div>
          </a></td>
        </ng-container>
        <ng-template #editTemplate>
          <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
          <ng-container *ngFor="let item of data?.items; let i=index">
            <td>
                <input *ngIf="table?.columns?.[i]?.editable" [type]="getAdequateType(table?.columns?.[i]?.type)" nz-input [(ngModel)]="editCache[data.id].data.items[i]" />
                <span *ngIf="!table?.columns?.[i]?.editable">
                    {{editCache[data.id].data.items[i]}}
                </span>
            </td>
          </ng-container>
          <td>
            <a (click)="saveEdit(data.id)" class="save">
                <button [ngClass]="{'btn': true, 'shadow': true, 'btn-xs': true, 'sharp': true, 'mr-1': true, 'btn-primary': true}">
                    <i [ngClass]="{'fa': true, 'fa-check': true}"></i>
                </button>
            </a>
            <a nz-popconfirm nzPopconfirmTitle="Annuler la modification ?" (nzOnConfirm)="cancelEdit(data.id)">
                <button [ngClass]="{'btn': true, 'shadow': true, 'btn-xs': true, 'sharp': true, 'mr-1': true, 'btn-danger': true}">
                    <i [ngClass]="{'fa': true, 'fa-close': true}"></i>
                </button>
            </a>
          </td>
        </ng-template>
      </tr>
    </tbody>
  </nz-table>