<!--**********************************
    Chat box start
***********************************-->
<div class="chatbox active" id="chatbox">
    <div class="chatbox-close"></div>
    <div class="custom-tab-1">
        <nz-steps class="nav nav-tabs">
            <nz-step nzTitle="Choix risque" nzStatus="{{currentstep < 0 ? 'wait' : (currentstep == 0 ? 'process' : 'finish')}}" nzIcon="{{currentstep == 0 ? 'form' : (currentstep < 0 ? 'loading' : 'check')}}"></nz-step>
            <nz-step nzTitle="Simulation" nzStatus="{{currentstep < 1 ? 'wait' : (currentstep == 1 ? 'process' : 'finish')}}" nzIcon="{{currentstep == 1 ? 'form' : (currentstep < 1 ? 'loading' : 'check')}}"></nz-step>
            <nz-step nzTitle="Garanties" nzStatus="{{currentstep < 2 ? 'wait' : (currentstep == 2 ? 'process' : 'finish')}}" nzIcon="{{currentstep == 2 ? 'form' : (currentstep < 2 ? 'loading' : 'check')}}"></nz-step>
            <!-- <nz-step nzTitle="Police" nzStatus="{{currentstep < 3 ? 'wait' : (currentstep == 3 ? 'process' : 'finish')}}" nzIcon="{{currentstep == 3 ? 'form' : (currentstep < 3 ? 'loading' : 'check')}}"></nz-step>
            <nz-step nzTitle="Prime" nzStatus="{{currentstep < 4 ? 'wait' : (currentstep == 4 ? 'process' : 'finish')}}" nzIcon="{{currentstep == 4 ? 'form' : (currentstep < 4 ? 'loading' : 'check')}}"></nz-step>
            <nz-step nzTitle="Paiement" nzStatus="{{currentstep < 5 ? 'wait' : (currentstep == 5 ? 'process' : 'finish')}}" nzIcon="{{currentstep == 5 ? 'form' : (currentstep < 5 ? 'loading' : 'check')}}"></nz-step> -->
            <ng-template #iconTemplate><span nz-icon nzType="smile"></span></ng-template>
        </nz-steps>
        <div class="tab-content">
            
            <div class="tab-pane fade active show" id="chat" role="tabpanel">
                <div class="card mb-sm-3 mb-md-0 contacts_card dz-chat-user-box">
                    <div class="card-header chat-list-header text-center">
                        <a href="javascript:void(0)"></a>
                        <div>
                            <h6 class="mb-1">{{stepTitles[currentstep].title}}</h6>
                            <p class="mb-0">{{stepTitles[currentstep].sub}}</p>
                        </div>
                        <a href="javascript:void(0)"></a>
                    </div>
                    <div class="card-body contacts_body p-0 dz-scroll  " id="DZ_W_Contacts_Body">
                        <ul class="contacts">
                            <li class="name-first-letter"></li>
                        </ul>
                        <app-create-souscription-risque
                            [hidden]="currentstep != 0"
                            [typeFinancements]="typeFinancements"
                            [selectedTypeFinancement]="selectedTypeFinancement"
                            (typeFinancementChanged)="typeFinancementChanged($event)"
                        >
                        </app-create-souscription-risque>
                        <app-create-souscription-simulation
                            [hidden]="currentstep != 1"
                            [entreprises]="entreprises"
                            [qualites]="qualites"
                            [selectedTypeFinancement]="selectedTypeFinancement"
                            [formulaireSimulation]="formulaireSimulation"
                            (outputEvent)="setOutputs($event)"
                            (planEvent)="setPlanEvent($event)"
                        >
                        </app-create-souscription-simulation>
                       <ng-container *ngIf="currentstep == 2">
                            <app-create-souscription-contrat
                                [hidden]="selectedTypeFinancement?.domaine == 'SANTE'"
                                [selectedTypeFinancement]="selectedTypeFinancement"
                                [formulaire]="formulaire"
                                (formulaireEvent)="setResultFormlulaire($event)"
                            >
                            </app-create-souscription-contrat>
                            <app-create-souscription-garantie
                                [hidden]="selectedTypeFinancement?.domaine != 'SANTE'"
                                [selectedTypeFinancement]="selectedTypeFinancement"
                                [plan]="plan"
                                (planEvent)="setPlanEvent($event)"
                            >
                            </app-create-souscription-garantie>
                       </ng-container>
                       <app-create-souscription-contrat
                           [hidden]="currentstep != 3"
                           [selectedTypeFinancement]="selectedTypeFinancement"
                           [plan]="plan"
                           (planEvent)="setPlanEvent($event)"
                       >
                       </app-create-souscription-contrat>
                        <app-create-souscription-prime
                            [hidden]="currentstep != 4"
                        >
                        </app-create-souscription-prime>
                        <app-payment
                            [hidden]="currentstep != 5"
                        >
                        </app-payment>
                    </div>
                    <div class="card-header chat-list-header text-center">
                        <div class="mr-2 float-right__ d-flex bd-highlight center-block items-center">
                            <button *ngIf="currentstep == 0" (click)="annuler(false)" type="button" class="btn btn-light mr-3">
                                Quittancement libre
                                <span class="btn-icon-left"><i class="fa fa-money"></i></span>
                            </button>
                            <button *ngIf="currentstep != 0" (click)="setPrecedent()" type="button" class="btn btn-light mr-3">
                                Précedent
                                <span class="btn-icon-left"><i class="fa fa-arrow-left"></i></span>
                            </button>
                            <button [disabled]="!handleVisibilityStep()" (click)="setSuivant()" type="button" class="btn btn-primary">
                                Suivant 
                                <span class="btn-icon-right"><i class="fa fa-arrow-right"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--**********************************
    Chat box End
************************************** -->