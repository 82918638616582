import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-accessoires',
  templateUrl: './accessoires.component.html',
  styleUrls: ['./accessoires.component.css']
})
export class AccessoiresComponent implements OnInit {

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  accessoires: any = [];
  compagnies: any = [];
  branches: any = [];
  risques: any = [];

  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'Accessoire';

  searchText = '';

  constructor(
    private dataService: DataService,
    private message: NzMessageService
  ) { }

  formTab = [
    {
      code: 'ONE',
      nom: "Ajout accessoires / commissions",
      titre: 'Accessoires / Commissions',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    },
    // {
    //   code: 'MANY',
    //   nom: 'Chargement en masse',
    //   titre: 'Accessoires / Commissions',
    //   soustitre: 'Chargement en masse des accessories',
    //   type: 'CHARGEMENT',
    //   templateImage: 'assets/images/template-client.png'
    // }
  ]

  formulaires: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  initializeHeaders() {
    this.headers = [
      {
        name: 'Compagnie',
        position: 'left',
        actif: true,
        width: "20%",
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Branche',
        position: 'left',
        actif: true,
        width: "20%",
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Risque',
        position: 'left',
        actif: true,
        width: "20%",
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Taux com acc',
        position: 'left',
        actif: true,
        width: "20%",
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Taux com prime',
        position: 'left',
        actif: true,
        width: "20%",
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Accessoires',
        width: "70%",
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Action',
        type: 'action',
        width: "10%",
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  handleFilter() {
    var _filter = `
    AND: [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    ]
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getAccessoires();
  }

  ngOnInit(): void {
    this.getAccessoires();
    this.getAllCompagnies();
    this.getAllBranches();
  }

  getAllCompagnies() {
    this.dataService.getAllCompagnies().subscribe(
      (data: any) => {
        this.compagnies = data?.data?.compagnies;
        this.initializeHeaders();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllBranches() {
    this.dataService.getAllBranches().subscribe(
      (data: any) => {
        this.branches = data?.data?.branches;
        this.initializeHeaders();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllRisqueOfBranche(id: string) {
    this.dataService.getAllRisqueOfBranche(id).subscribe(
      (data: any) => {
        this.risques = data?.data?.risqueBranches;
        var risqueForm: any = this.formulaires?.find((form: any) => form?.code == 'risque');
        if (risqueForm) {
          risqueForm.options = this.risques?.map((data: any) => {
            return {
              cle: data?.id,
              valeur: data?.nom
            }
          })
        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  handleColumnChanged() {
    this.accessoires = this.getAccessoires();
  }

  formulaireEvent(data: any) {
    if (this.formType == 'ADD') {
      this.dataService.createAccessoire(
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getAccessoires();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    else {
      this.dataService.updateAccessoire(
        this.currentId,
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getAccessoires();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getAccessoires();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getAccessoires();
  }

  getAccessoires() {  
    this.dataService.getAccessoires(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.accessoires = this.handleList(data?.data?.accessoires)
        this.totalCount = data?.data?.accessoiresCount;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getAccessoires()
    })
  }

  handleList(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.compagnie?.raisonSociale,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.risque?.branche?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.risque?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: `${row?.tauxCommissionAccessoire}%`,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: `${row?.tauxCommissionPrime}%`,
        position: 'left',
      },
      {
        type: 'LIST',
        value: row?.accessoires,
        render: (data: any) => {
          return `${data?.min} Fcfa - ${data?.max} Fcfa : Acc = ${data?.accessoire} Fcfa et Taxe ${data?.taxe}%`
        }
      },
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getAccessoireById(action?.value);
      break;
      case 'delete':
        this.getAccessoires();
      break;
    }
  }

  getAccessoireById(id: string) {
    this.dataService.getAccessoireById(id)
    .subscribe(
      (data: any) => {
        this.initForm(data?.data?.accessoire);
        this.getAllRisqueOfBranche(data?.data?.accessoire?.risque?.branche?.id);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.initForm();
  }

  initForm(data: any = {}) {
    if (data?.id) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      {
        code: "compagnie",
        placeholder: "Compagnie",
        typeChamp: "TEXT",
        etape: 1,
        obligatoire: !!(this.formType == 'ADD' || data?.compagnie?.id),
        typeReponse: "OPTION",
        options: this.compagnies?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.raisonSociale
          }
        }),
        defaultValue: data?.compagnie?.id,
        size: 'col-md-12'
      },
      {
        code: "branche",
        placeholder: "Branche",
        typeChamp: "TEXT",
        etape: 1,
        obligatoire: !!(this.formType == 'ADD' || data?.risque?.branche?.id),
        typeReponse: "OPTION",
        options: this.branches?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.nom
          }
        }),
        onChange: (id: string, ) => {
          this.getAllRisqueOfBranche(id);
        },
        defaultValue: data?.risque?.branche?.id,
        size: 'col-md-6'
      },
      {
        code: "risque",
        placeholder: "Risque",
        typeChamp: "TEXT",
        etape: 1,
        obligatoire: !!(this.formType == 'ADD' || data?.risque?.id),
        typeReponse: "OPTION",
        // visilityDependsOn: 'branche',
        options: this.risques?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.nom
          }
        }),
        defaultValue: data?.risque?.id,
        size: 'col-md-6'
      },
      {
        code: "tauxCommissionAccessoire",
        placeholder: "Taux Commission Accessoire",
        typeChamp: "NUMBER",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.tauxCommissionAccessoire),
        defaultValue: data?.tauxCommissionAccessoire,
        size: 'col-md-6'
      },
      {
        code: "tauxCommissionPrime",
        placeholder: "Taux Commission Prime",
        typeChamp: "NUMBER",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.tauxCommissionPrime),
        defaultValue: data?.tauxCommissionPrime,
        size: 'col-md-6'
      },
      {
        code: "accessoires",
        placeholder: "Accessoire",
        inputPlaceholder: 'Accessoires..',
        typeChamp: "TABLE",
        sizeTable: 5,
        etape: 1,
        typeReponse: "TABLE",
        columns: [
          {
            name: 'min',
            placeholder: 'Prime min',
            type: 'NUMBER'
          },
          {
            name: 'max',
            placeholder: 'Prime max',
            type: 'NUMBER'
          },
          {
            name: 'accessoire',
            placeholder: 'Accessoire',
            type: 'NUMBER'
          },
          {
            name: 'taxe',
            placeholder: 'taxe',
            type: 'NUMBER'
          }
        ],
        values: [],
        defaultValue: data?.accessoires,
        size: 'col-md-12'
      },
    ]
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
  }

}
