<!-- row -->
<div class="container-fluid">
    <div class="form-head d-flex mb-sm-4 mb-3 margin-left-right-35">
        <div class="mr-auto">
            <h1 class="text-black font-w600">Quittances</h1>
        </div>
        <div>
            <a (click)="openAddForm()" href="javascript:void(0)" class="btn btn-primary mr-3" data-toggle="modal" data-target="#addOrderModal">Ajouter quittance</a>
            <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzDropdownMenu]="colonne" class="btn btn-outline-primary mr-2"><i class="fa fa-plus scale5 mr-3"></i>{{headersActif()}} {{headersActif() <= 1 ? 'colonne' : 'colonnes'}}</a>
            <!-- <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzDropdownMenu]="bordereau" class="btn btn-outline-primary ml-2"><i class="fa fa-download scale5 mr-3 ml-2"></i>Bordereau</a> -->
            <!-- <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzClickHide]="false" [(nzVisible)]="true" [nzDropdownMenu]="colonne">
                <span style="font-size: 12px; position: relative; top: -3px; " nz-icon nzType="filter" nzTheme="fill"></span>
            </a> -->
        </div>
    </div>
    <!-- Add Order -->
    <app-form 
        class="app-form"
        [isFormLoading]="isFormLoading"
        (onCloseForm)="onCloseForm()" 
        [formTab]="formTab" 
        *ngIf="isFormOpened"
        [formWidth]="'750px'"
        [formulaires]="formulaires"
        (formulaireEvent)="formulaireEvent($event)"
        (tarificationEvent)="tarificationEvent($event)"
        [formType]="formType"
        [modelName]="modelName"
        (onUpload)="onUploadEvent($event)"
        (newFormulaireOpened)="newFormulaireOpened($event)"
        [chargementMutation]="'createChargementQuittance'"
        [formulaireBrancheExist]="formulaireBrancheExist"
        [infoPoliceCorrect]="infoPoliceCorrect"
        (infoPoliceButtonEvent)="infoPoliceButtonEvent($event)"
        >
    </app-form>
    <app-table 
        [headers]="filteredHeader" 
        [listOfData]="quittances"  
        [totalCount]="totalCount"
        [currentPage]="currentPage"
        (onTableUpdate)="onTableUpdate($event)"
        (onSearchTextChanged)="onSearchTextChanged($event)"
        [pageCount]="pageCount"
        [modelName]="modelName"
        title="Liste des quittances"
        (onAction)="onAction($event)"
    >
    </app-table>

    <!-- Formulaire pour les infos client -->
    <nz-drawer
      [nzClosable]="false"
      [nzWidth]="730"
      [nzVisible]="clientDrawer"
      nzPlacement="right"
      nzTitle="Ajout Client"
      (nzOnClose)="closeClientDrawer()"
    >
      <ng-container *nzDrawerContent>
        <app-form 
            class="app-sub-form" 
            [formWidth]="'730px'"
            [opacity]="'0.3'"
            [isFormLoading]="isFormLoading"
            (onCloseForm)="closeClientDrawer()" 
            [formTab]="formClientTab" 
            *ngIf="clientDrawer"
            [formulaires]="clientFormulaires"
            [readonly]="!isNewPolice"
            (formulaireEvent)="formulaireClientEvent($event)"
            [formType]="formType"
            [modelName]="'Client'"
        >
        </app-form>
      </ng-container>
    </nz-drawer>

    <!-- Formulaire pour les infos de la police -->
    <nz-drawer
      [nzClosable]="false"
      [nzWidth]="730"
      [nzVisible]="infoPoliceDrawer"
      nzPlacement="right"
      nzTitle="Info police"
      (nzOnClose)="closeInfoPoliceDrawer()"
    >
      <ng-container *nzDrawerContent>
        <app-form 
            class="app-sub-form" 
            [formWidth]="'730px'"
            [buttons]="infoPoliceButtons"
            [disableButtons]="this.renew ? false : !this.isNewPolice"
            [opacity]="'0.3'"
            [isFormLoading]="isFormLoading"
            (newFormulaireOpened)="newFormulaireOpened($event)"
            (onCloseForm)="closeInfoPoliceDrawer()" 
            [formTab]="formInfoPoliceTab" 
            *ngIf="infoPoliceDrawer"
            [formulaires]="infoPoliceFormulaires"
            (formulaireEvent)="formulaireInfoPoliceEvent($event)"
            [formType]="'EDIT'"
            [modelName]="'Info Police'"
        >
        </app-form>
      </ng-container>
    </nz-drawer>

    <!-- Formulaire pour les commissions -->
    <nz-drawer
      [nzClosable]="false"
      [nzWidth]="730"
      [nzVisible]="comDrawer"
      nzPlacement="right"
      nzTitle="Nous n'avons pas de commissions pour la compagnie par rapport au risque séléctionné"
      (nzOnClose)="closeComDrawer()"
    >
      <ng-container *nzDrawerContent>
        <app-form 
            class="app-sub-form" 
            [formWidth]="'730px'"
            [opacity]="'0.3'"
            [isFormLoading]="isFormLoading"
            (onCloseForm)="closeComDrawer()" 
            [formTab]="formComTab" 
            *ngIf="comDrawer"
            [formulaires]="comFormulaires"
            (formulaireEvent)="formulaireCommissionEvent($event)"
            [formType]="'EDIT'"
            [modelName]="'Commissions'"
        >
        </app-form>
      </ng-container>
    </nz-drawer>

    <!-- Formulaire pour les un nouveau Champs -->
    <nz-drawer
      [nzClosable]="false"
      [nzWidth]="710"
      [nzVisible]="formDrawer"
      nzPlacement="right"
      nzTitle="Créé un nouveau champs"
      (nzOnClose)="closeFormDrawer()"
    >
      <ng-container *nzDrawerContent>
        <app-form 
            class="app-sub-form" 
            [formWidth]="'710px'"
            [opacity]="'0.4'"
            [isFormLoading]="isFormLoading"
            (onCloseForm)="closeFormDrawer()" 
            [formTab]="formFormTab" 
            *ngIf="formDrawer"
            [formulaires]="formFormulaires"
            (formulaireEvent)="formulaireFormEvent($event)"
            [formType]="'EDIT'"
            [modelName]="'Champs'"
        >
        </app-form>
      </ng-container>
    </nz-drawer>

    <!-- Formulaire pour un paiement direct -->
    <nz-drawer
      [nzClosable]="false"
      [nzWidth]="710"
      [nzVisible]="paiementDrawer"
      nzPlacement="right"
      nzTitle="Régler un impayé"
      (nzOnClose)="closePaimentDrawer()"
    >
      <ng-container *nzDrawerContent>
        <app-form 
            class="app-sub-form" 
            [formWidth]="'710px'"
            [opacity]="'0.4'"
            [isFormLoading]="isFormLoading"
            (onCloseForm)="closePaimentDrawer()" 
            [formTab]="formFormTab" 
            *ngIf="paiementDrawer"
            [formulaires]="paymentForm"
            (formulaireEvent)="formulairePaiementEvent($event)"
            [formType]="'EDIT'"
            [modelName]="'Paiement'"
        >
        </app-form>
      </ng-container>
    </nz-drawer>

    <nz-dropdown-menu #colonne="nzDropdownMenu">
        <ul nz-menu>
            <div class="margin-auto width-90 mt-2 mb-3">
                <input (keyup)="searchColumn()" [(ngModel)]="searchColumnText" type="text" class="form-control" nz-input placeholder="Rechercher une colonne" />
            </div>
          <li *ngFor="let header of headers; let i = index" nz-menu-item>
            <ng-container *ngIf="header?.type != 'action'">
                <label [disabled]="headersActif() <= 1 && header?.actif" nz-checkbox (ngModelChange)="handleColumnChanged()" [(ngModel)]="headers[i].actif">{{header?.name}}</label>
            </ng-container>
          </li>
        </ul>
    </nz-dropdown-menu>

</div>

<span id="dismissAll"></span>

<!-- tokenABP: Fe26.2**d3d305ddc1304aa19c49e91c9170c490250042093537b19de3ed5f18e3b304a6*CDdNYrCVieKNu56bKCbG9w*Oui-m9FMFQMZc1rzJaltOeWsu5GMEzSvyT5WMp4Ug6HTz1CfpotBAI7kkabDref3l9fxv5NLgH1kXetZhqgZWw*1696592655924*6182e712fdd646bf2eed2030e8f5c44f6165fc83330dd1988c0879fe052d8c81*K4O69Zq0105jydztUo8yBgcYew4Kz-MsAE74-5zSjnQ -->