import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-edit-select',
  templateUrl: './table-edit-select.component.html',
  styleUrls: ['./table-edit-select.component.css']
})
export class TableEditSelectComponent implements OnInit {

  @Input() table: any = {};
  @Output() onTableEvent: EventEmitter<any> = new EventEmitter<any>();
  // @Output() editSelectEvent: EventEmitter<any> = new EventEmitter<any>();

  editCache: { [key: string]: { edit: boolean; data: any } } = {};
  listOfData: any[] = [];

  listOfSelection = [];
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly any[] = [];
  setOfCheckedId = new Set<number>();

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: string): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: { ...this.listOfData[index] },
      edit: false
    };
  }

  saveEdit(id: string): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    Object.assign(this.listOfData[index], this.editCache[id].data);
    this.editCache[id].edit = false;
    this.onTableEvent.emit({
      type: this.table?.code,
      value: Array.from(this.listOfData),
      // value: Array.from(this.listOfData?.filter((item: any) => Array.from(this.setOfCheckedId)?.includes(item?.id)))
    })
  }

  updateEditCache(): void {
    this.listOfData.forEach(item => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item }
      };
    });
    this.onTableEvent.emit({
      type: this.table?.code,
      value: Array.from(this.listOfData),
      // value: Array.from(this.listOfData?.filter((item: any) => Array.from(this.setOfCheckedId)?.includes(item?.id)))
    })
  }

  getAdequateType(typeChamp: string) {
    switch(typeChamp) {
      case 'TEXT':
        return 'text';
      case 'NUMBER':
        return 'number';
      case 'EMAIL':
        return 'email';
      case 'TELEPHONE':
        return 'phone';
      case 'DATE':
        return 'date';
      default:
        return 'text';
    }
  }

  ngOnInit(): void {
    this.listOfData = this.table?.values;
    this.table?.values?.forEach((item: any) => {
      if (item?.selected) {
        this.setOfCheckedId.add(item?.id)
      }
    })
    this.listOfSelection =  this.table?.listOfSelection?.map((item: any) => (
      item?.id == 'ALL' ? {
        text: item?.text,
        onSelect: () => {
          this.onAllChecked(true);
        }
      }
      : {
        text: item?.text,
        onSelect: () => {
          this.listOfData.forEach((data, index) => this.updateCheckedSet(data.id, data?.selectionId == item?.id));
          this.refreshCheckedStatus();
        }
      }
    ))
    this.updateEditCache();
    console.log(this.table);
  }

  updateCheckedSet(id: number, checked: boolean): void {
    var foundItem = this.listOfData?.find((item: any) => item?.id == id);
    foundItem.checked = checked;
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
    this.onTableEvent.emit({
      type: this.table?.code,
      value: Array.from(this.listOfData),
      // value: Array.from(this.listOfData?.filter((item: any) => Array.from(this.setOfCheckedId)?.includes(item?.id)))
    })
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly any[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked
    console.log(Array.from(this.listOfData))
    this.onTableEvent.emit({
      type: this.table?.code,
      value: Array.from(this.listOfData),
      // value: Array.from(this.listOfData?.filter((item: any) => Array.from(this.setOfCheckedId)?.includes(item?.id)))
    })
  }

}
