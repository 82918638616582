import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StructureDeSanteRoutingModule } from './structure-de-sante-routing.module';


@NgModule({
  declarations:  [],
  imports: [
    CommonModule,
    StructureDeSanteRoutingModule
  ]
})
export class StructureDeSanteModule { }
