<div class="container-fluid">
    <div class="form-head d-flex mb-sm-4 mb-3 margin-left-right-35">
        <div class="mr-auto">
            <h1 class="text-black font-w600">Catégories d'actes médicaux</h1>
        </div>
        <div>
            <a  (click)="openAddForm()" href="javascript:void(0)" class="btn btn-primary mr-3" data-toggle="modal" data-target="#addOrderModal">Ajouter catégorie</a>
            <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzClickHide]="false"  [nzDropdownMenu]="colonne"  class="btn btn-outline-primary"><i class="fa fa-plus scale5 mr-3"></i> {{headersActif()}} {{ headersActif() <= 1 ? 'colonne' : 'colonnes' }}</a>
        
        </div>
    </div>
   
    <app-form 
        [formWidth]="'750px'"
        (onCloseForm)="onCloseForm()" 
        [formTab]="formTab" 
        *ngIf="isFormOpened"
        [formulaires]="formulaires"
        (formulaireEvent)="formulaireEvent($event)"
        [formType]="formType"
        [modelName]="modelName"
        [chargementMutation]="'createChargementCategorieActeMedical'"
        >
    </app-form>
    <!-- (onUpload)="onUploadEvent($event)" -->


    <app-table 
        [headers]="filteredHeader" 
        [listOfData]="categoriesActes"  
        [totalCount]="totalCount"
        [currentPage]="currentPage"
        (onSearchTextChanged)="onSearchTextChanged($event)"
        [pageCount]="pageCount"
        [modelName]="modelName"
        title="Liste des catégories d'actes médicaux"
        (onAction)="onAction($event)"
        (onTableUpdate)="onTableUpdate($event)"
        >
    </app-table> 


    
   
 
    <nz-dropdown-menu #colonne="nzDropdownMenu">
        <ul nz-menu>
            <div class="margin-auto width-90 mt-2 mb-3">
                <input (keyup)="searchColumn()" [(ngModel)]="searchColumnText" type="text" class="form-control" nz-input placeholder="Rechercher une colonne" />
            </div>
          <li *ngFor="let header of headers; let i = index" nz-menu-item>
            <ng-container *ngIf="header?.type != 'action'">
                <label [disabled]="headersActif() <= 1 && header?.actif" [id]="header?.name" nz-checkbox (ngModelChange)="handleColumnChanged()" [(ngModel)]="headers[i].actif">{{ header?.name }}</label>
            </ng-container>
          </li>
        </ul>
    </nz-dropdown-menu>

 
   

   
</div>
