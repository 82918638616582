import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-specialite',
  templateUrl: './specialite.component.html',
  styleUrls: ['./specialite.component.css']
})
export class SpecialiteComponent implements OnInit {

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  specialites: any = [];
  headerInitialed = false;

  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'Specialite';

  searchText : any = '';

  domaineTypes : any = ['MEDECIN','PARAMEDICAL','PHARMACIE']



  constructor(
    private dataService: DataService,
    private message: NzMessageService
  ) { }

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter une spécialité",
      titre: 'Spécialité',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    },
    {
      code: 'MANY',
      nom: 'Chargement en masse',
      titre: 'Spécialité',
      soustitre: 'Chargement en masse des spécialités',
      type: 'CHARGEMENT',
      templateImage: 'assets/images/template-specialite.png'
    }
  ]

  formulaires: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  initializeHeaders() {

    this.headers = [
      {
        name: 'Nom',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Domaine',
        position: 'left',
        filterMultiple: true,
        actif: true,
        dataFilter: [
          { text: 'MEDECIN', value: 'MEDECIN' },
          { text: 'PARAMEDICAL', value: 'PARAMEDICAL' },
          { text: 'PHARMACIE', value: 'PHARMACIE' },
        ],
        filterFn: (list: string[], item: any) => {
          var oldFilter: string = '';
          
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('domaine') == -1) {
            this.filterArray.push({
              key: 'domaine',
              value: ''
            })
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('domaine');
            oldFilter = this.filterArray[index].value;
          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('domaine');

          if (!list.length) {
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'domaine');
            this.handleFilter();
          } else {

            console.log(list);

            this.filterArray[index].value = `
              domaine : {
                in : [
                  ${
                    list?.map((domaine:any) => `${domaine}`)
                  }
                ]
              }
            `
            if (oldFilter != this.filterArray[index].value) {
              this.handleFilter();
            }
          }
        },
      },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  handleFilter() {
    var _filter = `
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getSpecialites();
  }

  ngOnInit(): void {
    this.getSpecialites();
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  handleColumnChanged() {
    this.specialites = this.getSpecialites();
  }

  formulaireEvent(data: any) {
    if (this.formType == 'ADD') {
      this.dataService.createSpecialite(
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getSpecialites();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout ${err}`);
        },
      )
    }
    else {
      this.dataService.updateSpecialite(
        this.currentId,
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getSpecialites();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de la modification`);
        },
      )
    }
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getSpecialites();
  }

  onSearchTextChanged(value: string) {
   
    this.searchText = value;
    this.getSpecialites()
    
  }

  getSpecialites() { 
    this.dataService.getSpecialites(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.specialites = this.handleList(data?.data?.specialites)
        this.totalCount = data?.data?.specialitesCount
        // console.log(this.specialites);
        if (!this.headerInitialed) {
          this.initializeHeaders();
          this.headerInitialed = true;
        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getSpecialites()
    })
  }

  handleList(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.domaine,
        position: 'left',
      },
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  onAction(action: any) {
    
    console.log(action);

    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getSpecialiteById(action?.value);
      break;
      case 'delete':
        this.getSpecialites();
      break;
    }
  }

  getSpecialiteById(id: string) {
    this.dataService.getSpecialiteById(id)
    .subscribe(
      (data: any) => {
        this.initForm(data?.data?.specialite);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.initForm();
  }

  initForm(data: any = {}) {
    if (data?.id) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.nom),
        defaultValue: data?.nom,
        size: 'col-md-12'
      },
      {
        code: "domaine",
        placeholder: "Domaine",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        obligatoire: !!(this.formType == 'ADD' || data?.domaine),
        options: this.domaineTypes?.map((data: string) => {
          return {
            cle: data,
            valeur: data
          }
        }),
        defaultValue:data?.domaine,
        size: 'col-md-12'
      },
     
     
    ]
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
  }

}
