<div class="h-100vh">
    <div _ngcontent-dfj-c57="" class="get-insurance-bg" style="background-image: url(assets/images/get-insurance-bg.png);"></div>
    <div class="authincation h-100">
        <div class="container h-100">
            <div class="row justify-content-center-- h-100 align-items-center">
                <div class="col-md-6">
                    <ng-lottie width="100%" [styles]="{padding: '25px', fill: 'transparent'}" [options]="options"></ng-lottie>
                </div>
                <div class="col-md-6">
                    <div class="authincation-content">
                        <div class="row no-gutters">
                            <div class="col-xl-12">
                                <div class="auth-form">
									<div class="text-center mb-3">
										<a><img class="logo" src="assets/images/logo.jpeg" alt=""></a>
									</div>
                                    <h4 class="text-center mb-4 text-white">Bonjour {{user?.prenom}} {{user?.nom}}, nous allons vous aider à changer votre mot de passe</h4>
                                    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
                                        <div class="form-group">
                                            <label class="mb-1 text-white"><strong>Mot de passe</strong></label>
                                            <input (keyup)="passwordEditing()" required type="password" formControlName="password" placeholder="Entrez votre mot de passe" name="password" class="form-control">
                                            <span class="red-error" *ngIf="messageErrorPass">{{messageErrorPass}}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="mb-1 text-white"><strong>Confirmation du mot de passe</strong></label>
                                            <input required (keyup)="passwordConfirmEditing()" type="password" formControlName="confirmationPassword" placeholder="Confirmez votre mot de passe"
                                            name="password" class="form-control">
                                            <span class="red-error" *ngIf="messageErrorConf">{{messageErrorConf}}</span>
                                        </div>
                                        <!-- <div class="form-row d-flex justify-content-between mt-4 mb-2">
                                            <div class="form-group">
                                               <div class="custom-control custom-checkbox ml-1 text-white">
													<input type="checkbox" class="custom-control-input" id="basic_checkbox_1">
													<label class="custom-control-label" for="basic_checkbox_1">Remember my preference</label>
												</div>
                                            </div>
                                            <div class="form-group">
                                                <a class="text-white" href="page-forgot-password.html">Forgot Password?</a>
                                            </div>
                                        </div> -->
                                        <div class="text-center">
                                            <button [disabled]="!userForm.valid && !isConfirmationValid" type="submit" class="btn bg-white text-primary btn-block">Valider</button>
                                        </div>
                                    </form>
                                    <!-- <div class="new-account mt-3">
                                        <p class="text-white">Don't have an account? <a class="text-white" href="page-register.html">Sign up</a></p>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>