import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-acte-medical',
  templateUrl: './acte-medical.component.html',
  styleUrls: ['./acte-medical.component.css']
})
export class ActeMedicalComponent implements OnInit {

  headers : any = [];
  allHeaders : any = [];
  filteredHeader : any = [];
  formulaires : any = [];
  searchColumnText : string = '';
  actesMedicaux : any = [];
  categoriesActes : any = [];

  totalCount : number = 1;
  currentPage : number = 1;
  searchText : string = '';
  pageCount : number = 5;
  modelName : string = "ActeMedical"
  filter : string = '';
  headerInitialed : boolean = false;
  filterArray : any = [];

  isFormOpened : boolean = false;
  formType: string = 'ADD';
  currentId : string = '';

  actesMedicauxDomaine: any = ["MEDECIN", "PARAMEDICAL"];



  
  constructor(
    private dataService : DataService,
    private message : NzMessageService
  ) {}

  ngOnInit(): void {
    this.getActesMedicaux();
    this.getAllCategoriesActes();
  }

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter un acte médical",
      titre: "Actes médicaux",
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]




  initializeHeaders() {
    this.headers = [
      {
        name: 'Nom',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]

      },
      {
        name: 'Domaine',
        position: 'left',
        actif: true,
        filterMultiple: true,
        sortDirections: ['ascend', 'descend', null],
        dataFilter: this.actesMedicauxDomaine.map((domaine : any) => {
          return {
            text : domaine,
            value : domaine
          }
        }),
        filterFn: (list: string[], item: any) => {
          console.log(item, list);

          var oldFilter: string = '';
      
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('domaineActe') == -1) {

            console.log('domaine');
            this.filterArray.push({
              key: 'domaineActe',
              value: ''
            })
    
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('domaineActe');
            oldFilter = this.filterArray[index].value;
            console.log(oldFilter);

          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('domaineActe');


          if (!list.length) {

            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'domaineActe');
            this.handleFilter();
          } else {
            console.log(list, index)
            this.filterArray[index].value = `
              {
                domaine : {
                  in : [
                    ${
                      list?.map((domaine:any) => `${domaine}`)
                    }
                  ]
                }
              }
            `
            if (oldFilter != this.filterArray[index].value) {
              console.log(oldFilter, this.filterArray[index].value)

              console.log('bien')
              this.handleFilter();
            }
          }
        }
      },
      {
        name: 'Coef',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
     
      {
        name: 'Catégorie acte',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null],
        dataFilter: [
          ...this.categoriesActes.map((categorie: any) => {
            return { text: categorie?.nom, value: categorie?.id }
          }),
        ],
        filterFn: (list: string[], item: any) => {
          var oldFilter: string = '';
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('categorieActe') == -1) {
            this.filterArray.push({
              key: 'categorieActe',
              value: ''
            })
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('categorieActe');
            oldFilter = this.filterArray[index].value;
          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('categorieActe');


          if (!list.length) {
            
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'categorieActe');
            this.handleFilter();
          }
          else {            
            this.filterArray[index].value = `{
              categorieActe:{
                id : {
                  in :[
                    ${
                      list?.map((categorieActe : any) => `"${categorieActe}"`)
                    }
                  ]
                }
              }
            }`;
          if (oldFilter != this.filterArray[index].value) {
            this.handleFilter();
          }
          }
        }

      },
      {
        name: 'Prix',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.allHeaders = this.headers;
    this.filteredHeader = this.headers;
  }

  handleFilter() {
    var _filter = `AND : [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `]
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getActesMedicaux();
  }
  

  headersActif() : number {
    return  this.filteredHeader?.filter((header : any )=> header?.actif)?.length - 1 ;
  }

  searchColumn(){
    this.headers = this.allHeaders?.filter((header : any )=> header?.name.toLowerCase()?.includes(this.searchColumnText.toLowerCase()));
  }

  handleColumnChanged(){
    this.actesMedicaux = this.getActesMedicaux();
  }


  onSearchTextChanged(value : string){
    this.searchText = value;
    this.getActesMedicaux();

  }

  getActesMedicaux(){
    this.dataService.getActesMedicaux(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.actesMedicaux = this.handleList(data?.data?.acteMedicals)
        this.totalCount = data?.data?.acteMedicalsCount;
        if (!this.headerInitialed) {
          this.initializeHeaders();
          this.headerInitialed = true;

        }
      },
      (err: any) => {
        console.log(err);
      },
    )


  }


  getAllCategoriesActes() {  
    this.dataService.getAllCategoriesActes()
    .subscribe(
      (data: any) => {
        this.categoriesActes = data?.data?.categorieActes;
        this.initializeHeaders();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }


  formulaireEvent(data : any){

    if (this.formType == 'ADD') {
      this.dataService.createActeMedical(
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getActesMedicaux();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout ${err}`);
        },
      )
    }
    else {
      this.dataService.updateActeMedical(
        this.currentId,
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getActesMedicaux();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de la modification`);
        },
      )
    }

   }

  handleList(data : any){


    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.domaine,
        position: 'left',

      },
      {
        type: 'TEXT',
        value: row?.coef,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.categorieActe?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.prix,
        position: 'left',
      },
     
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  onAction(action : any){

    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getActeMedicalById(action?.value);
      break;
      case 'delete':
        this.getActesMedicaux();
      break;
    }

  }

  getActeMedicalById(id : string){
    this.dataService.getActeMedicalById(id)
    .subscribe(
      (data: any) => {
        this.initForm(data?.data?.acteMedical);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  // onUploadEvent(upload: string) {
  //   setTimeout(() => {
  //     this.currentPage = 1;
  //     this.getActesMedicaux();
  //   })
  // }


   onTableUpdate(value:any){
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getActesMedicaux();
   }

  openAddForm(){
    this.isFormOpened = true;
    this.initForm();
  }
  initForm(data: any = {}) {

    console.log(data);

    if (data?.id) {
      this.formType = 'EDIT';
    }

    this.formulaires = [
      
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.nom),
        defaultValue: data?.nom,
        size: 'col-md-12'
      },
      {
        code: "domaineActe",
        placeholder: "Domaine",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        obligatoire: !!(this.formType == 'ADD' || data?.domaine),
       
        options: this.actesMedicauxDomaine?.map((data: any) => {
          return {
            cle: data,
            valeur: data
          }
        }),
        defaultValue:data?.domaine,
        size: 'col-md-12'
      },
      {
        code: "coef",
        placeholder: "Coef",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.coef),
        defaultValue: data?.coef,
        size: 'col-md-6'
      },
      {
        code: "prix",
        placeholder: "Prix",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.prix),
        defaultValue: data?.prix,
        size: 'col-md-6'
      },
      {
        code: "categorieActe",
        placeholder: "Categorie Acte",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        options : this.categoriesActes?.map((categorie : any) => {
          return {
            cle : categorie?.id,
            valeur : categorie?.nom
          }
        }),
        obligatoire: !!(this.formType == 'ADD' || data?.categorieActe?.id),
        defaultValue: data?.categorieActe?.id,
        size: 'col-md-12'
      },
     
    ]
  }

  onCloseForm(){
    this.isFormOpened = !this.isFormOpened;
  }

}
