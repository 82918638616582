import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RelanceRoutingModule } from './relance-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RelanceRoutingModule
  ]
})
export class RelanceModule { }
