import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AuthComponent } from './auth/auth.component';
import { AuthModule } from './auth/auth.module';
import { HomeModule } from './home/home.module';
import { LoginModule } from './auth/login/login.module';
import { PasswordForgottenModule } from './auth/password-forgotten/password-forgotten.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UnauthorizedModule } from './unauthorized/unauthorized.module';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';
import { HeaderComponent } from './home/shared/header/header.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { DataService } from './data.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DocumentRendererModule} from "angular-keystone-document-renderer";
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { fr_FR } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { FormsModule } from '@angular/forms';
import { DashboardModule } from './home/dashboard/dashboard.module';
import { DistributeurModule } from './home/distributeur/distributeur.module';
import { SouscriptionModule } from './home/souscription/souscription.module';
import { CreateSouscriptionModule } from './home/souscription/create-souscription/create-souscription.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { QuittancementLibreModule } from './home/souscription/quittancement-libre/quittancement-libre.module';
import { ClientModule } from './home/client/client.module';
import { SocketService } from './socket.service';
import { EntrepriseModule } from './home/entreprise/entreprise.module';
import { CompagnieModule } from './home/configuration/compagnie/compagnie.module';
import { BrancheModule } from './home/configuration/branche/branche.module';
import { RisqueBrancheModule } from './home/configuration/risque-branche/risque-branche.module';
import { AccessoiresModule } from './home/configuration/accessoires/accessoires.module';
import { ApporteurModule } from './home/configuration/apporteur/apporteur.module';
import { QuittanceModule } from './home/nouvelle-police/quittance/quittance.module';
import { AvenantModule } from './home/configuration/avenant/avenant.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { SafePipe } from './safe.pipe';
import { AvenantPoliceModule } from './home/nouvelle-police/avenant-police/avenant-police.module';
import { BordereauModule } from './home/nouvelle-police/bordereau/bordereau.module';
import { UtilisateurModule } from './home/utilisateur/utilisateur.module';
import { ChangePasswordModule } from './auth/change-password/change-password.module';
import { PoliceModule } from './home/nouvelle-police/police/police.module';
import { WavePaymentSuccessfulModule } from './extra/wave-payment-successful/wave-payment-successful.module';
import { RelanceModule } from './home/nouvelle-police/relance/relance.module';
import { PolitiqueConfidentialiteModule } from './extra/politique-confidentialite/politique-confidentialite.module';
import { ParametrageModule } from './home/configuration/parametrage/parametrage.module';
import { SpecialiteModule } from './home/sante/specialite/specialite.module';
import { StructureDeSanteModule } from './home/sante/structure-de-sante/structure-de-sante.module';
import { CategorieActesModule } from './home/sante/categorie-actes/categorie-actes.module';
import { ActeMedicalModule } from './home/sante/acte-medical/acte-medical.module';
import { MedicamentsModule } from './home/sante/medicaments/medicaments.module';
import { PlanSanteModule } from './home/sante/plan-sante/plan-sante.module';
import { QualiteModule } from './home/sante/qualite/qualite.module';
import { EmployeModule } from './home/sante/employe/employe.module';
import { PoliceSanteModule } from './home/sante/police-sante/police-sante.module';

registerLocaleData(fr);

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    PageNotFoundComponent,
    UnauthorizedComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    // HomeModule,
    LoginModule,
    NzIconModule,
    PasswordForgottenModule,
    UnauthorizedModule,
    PageNotFoundModule,
    GraphQLModule,
    HttpClientModule, 
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    DocumentRendererModule, 
    FormsModule, 
    DashboardModule, 
    DistributeurModule, 
    // SouscriptionModule, 
    CreateSouscriptionModule,
    QuittancementLibreModule, 
    ClientModule, 
    EntrepriseModule, 
    CompagnieModule, 
    BrancheModule,
    RisqueBrancheModule, 
    AccessoiresModule, 
    ApporteurModule, 
    QuittanceModule, 
    NzDropDownModule,
    AvenantModule,
    AvenantPoliceModule,
    BordereauModule,
    UtilisateurModule,
    ChangePasswordModule,
    PoliceModule,
    WavePaymentSuccessfulModule,
    RelanceModule,
    PolitiqueConfidentialiteModule,
    ParametrageModule,
    SpecialiteModule,
    StructureDeSanteModule,
    CategorieActesModule,
    ActeMedicalModule,
    MedicamentsModule,
    PlanSanteModule,
    QualiteModule,
    EmployeModule,
    PoliceSanteModule,
    // HighchartsChartModule
  ],
  providers: [
    DataService,
    // SocketService,
    { provide: NZ_I18N, useValue: fr_FR }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
