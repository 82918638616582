import { Component, OnInit } from '@angular/core';
import { error } from 'highcharts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-medicaments',
  templateUrl: './medicaments.component.html',
  styleUrls: ['./medicaments.component.css']
})
export class MedicamentsComponent implements OnInit {

  searchColumnText : string = '';
  headers : any = [];
  filteredHeader : any = [];
  allHeaders : any = [];
  medicaments : any = [];
  
  totalCount : number = 1;
  currentPage : number = 1;
  pageCount : number = 5;
  modelName : string = 'Medicament';
  filter: string = '';
  searchText : string = '';
  headerInitialed : boolean = false;

  isFormOpened : boolean = false;
  formulaires : any = [];
  formType : string = 'ADD';

  currentId : string = '';

  constructor(
    private dataService : DataService,
    private message : NzMessageService
  ) { }

  ngOnInit(): void {
    // this.initializeHeaders();
    this.getMedicaments();


  }

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter médicament",
      titre: "Médicaments",
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
    
    
  ]


  headersActif() : number {
    return this.filteredHeader?.filter((header : any )=> header?.actif)?.length - 1;
  }


  formulaireEvent(data : any){

    if (this.formType == 'ADD') {
      this.dataService.createMedicament(
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getMedicaments();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout ${err}`);
        },
      )
    }
    else {
      this.dataService.updateMedicament(
        this.currentId,
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getMedicaments();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de la modification`);
        },
      )
    }

  }

  searchColumn(){
    this.headers = this.allHeaders?.filter((header : any )=> header.name?.toLowerCase().includes(this.searchColumnText.toLowerCase()));
  }

  initializeHeaders(){
    this.headers = [
      {
        name: 'Nom',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]

      },
      {
        name: 'Denom C Int',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]

      },
      {
        name: 'Indications',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Forme et Dos',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Presentation',
        position: 'left',
        actif: false,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Type',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Obs',
        position: 'left',
        actif: false,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];

    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  handleColumnChanged() {

  }

  getMedicaments(){
    this.dataService.getMedicaments(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.medicaments = this.handleList(data?.data?.medicaments)
        this.totalCount = data?.data?.medicamentsCount;
        if (!this.headerInitialed) {
          this.initializeHeaders();
          this.headerInitialed = true;

        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }


  handleList(data: any) {

    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.denominationCommueInternationale,
        position: 'left',

      },
      {
        type: 'TEXT',
        value: row?.indications,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.formeEtDosage,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.presentation,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.type,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.obs,
        position: 'left',
      },
     
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  

  onSearchTextChanged(value : string) {
    this.searchText = value;
    this.getMedicaments();

  }

  onAction(action :any){

    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getMedicamentById(action?.value);
      break;
      case 'delete':
        this.getMedicaments();
      break;
    }



  }
  

  getMedicamentById(id : string){

    this.dataService.getMedicamentById(id).subscribe(
      (data : any) => {
        this.initForm(data?.data?.medicament);
        this.isFormOpened = true;

      },
      (err : any) => {
        console.log(err);

      }
    )
  }
  onTableUpdate(value: any) {

  }

  openAddForm(){
    this.isFormOpened = true;
    this.initForm();
  }

  initForm(data: any = {}){

    // console.log(data);

    if (data?.id) {
      this.formType = 'EDIT';
    }

    this.formulaires = [
      
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.nom),
        defaultValue: data?.nom,
        size: 'col-md-12'
      },
      {
        code: "denomComInter",
        placeholder: "Denomination Commue Internationale",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.denominationCommueInternationale),
        defaultValue:data?.denominationCommueInternationale,
        size: 'col-md-12'
      },
      {
        code: "indications",
        placeholder: "Indications",
        typeChamp: "TEXTAREA",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.indications),
        defaultValue: data?.indications,
        size: 'col-md-12'
      },
      
      {
        code: "type",
        placeholder: "Type",
        typeChamp: "TEXT",
        etape: 2,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.type),
        defaultValue: data?.type,
        size: 'col-md-12'
      },
      
      {
        code: "formeEtDosage",
        placeholder: "Forme et Dosage",
        typeChamp: "TEXTAREA",
        etape: 2,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.formeEtDosage),
        defaultValue: data?.formeEtDosage,
        size: 'col-md-12'
      },
      
      {
        code: "presentation",
        placeholder: "Presentation",
        typeChamp: "TEXTAREA",
        etape: 2,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.presentation),
        defaultValue: data?.presentation,
        size: 'col-md-12'
      },
     
      {
        code: "obs",
        placeholder: "Obs",
        typeChamp: "TEXTAREA",
        etape: 3,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.obs),
        defaultValue: data?.obs,
        size: 'col-md-12'
      },
     
    ]

  }
  onCloseForm(){
    this.isFormOpened = !this.isFormOpened;
  }
}

