import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpecialiteRoutingModule } from './specialite-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SpecialiteRoutingModule
  ]
})
export class SpecialiteModule { }
