import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
// import extractFiles from 'extract-files/extractFiles.mjs';
import {ApolloClientOptions, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { HttpClientModule } from '@angular/common/http';
// @ts-ignore
import extractFiles from 'extract-files/extractFiles.mjs';
// @ts-ignore
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import API from './api';
const uri = `${API?.baseUrl}${API?.apiUrl}`
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: httpLink.create({
      uri,
      extractFiles: (body) => {
        return extractFiles(body, isExtractableFile)
      },
    }),
    cache: new InMemoryCache({
      addTypename: false,
      resultCaching: false
    })
  };
}

@NgModule({
  exports: [ApolloModule],
  imports: [
    HttpClientModule,
    ApolloModule,
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
