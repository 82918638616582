import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NzMessageModule } from 'ng-zorro-antd/message';


export function playerFactory() {
  return player;
}


@NgModule({
  declarations: [
    LoginComponent,
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }), 
    ReactiveFormsModule,
    AuthRoutingModule,
    NzMessageModule
  ]
})
export class AuthModule { }
