import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { AnimationOptions } from 'ngx-lottie';
import { DataService } from 'src/app/data.service';
import API from '../../../api';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() formTab: any = [];
  @Output() onCloseForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() formulaires : any = [];
  @Input() allQuestions : any = [];
  @Input() inputKeys : any = [];
  @Input() formType: string = 'ADD';
  @Input() modelName: string = '';
  @Input() chargementMutation: string = '';
  @Input() isFormLoading: boolean = false;
  @Input() formWidth: string = '720px';
  @Input() opacity: string = '0.1';
  @Input() formulaireBrancheExist: boolean = false;
  @Input() formulaireBrancheLabel: string = 'Info police';
  @Input() infoPoliceCorrect : boolean = true;
  @Input() readonly: boolean = false;
  @Input() disableButtons: boolean = false;
  @Input() buttons: any = [];

  formulaire: FormGroup = new FormGroup({});
  @Output() formulaireEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() infoPoliceButtonEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output() newFormulaireOpened: EventEmitter<any> = new EventEmitter<any>();
  @Output() tarificationEvent : EventEmitter<any> = new EventEmitter<any>();

  paymentInfo: any = {};

  selectedTab: any = {}
  file: NzUploadFile | undefined;
  uploading = false;

  uploadUrl = `${API.baseUrl}/upload_files`;

  paymentStatus: boolean = false;
  retryPayment: boolean = false;
  isRetrying: boolean = true;

  uploadOptions: AnimationOptions = {
    path: 'assets/lotties/upload.json',
  };

  time: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);

  defaultFileList: NzUploadFile[] = [];

  fileList = [...this.defaultFileList];

  price: number = 1;

  currentStep: number = 0;

  loading: boolean = false;

  spinText: string = '';

  previewImage: string | undefined = '';
  previewVisible = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '200px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
    uploadUrl: 'v1/image',
    // upload: (file: File) => {
    //   console.log(file);
    // },
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
};

  constructor(
    private dataService: DataService,
    private msg: NzMessageService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { 
  }

  ngOnInit(): void {
    this.handleFormControls();
    if (this.formTab?.length) {
      this.selectedTab = this.formTab[0];
    }
    this.isStepValid();
    const user: any = localStorage?.getItem('userABP');
    if (user) {
      this.spinText = `${JSON.parse(user).prenom}, un instant svp...`
    }
    else {
      this.spinText = `Un instant svp...`
    }
  }

  next() {
    this.currentStep = this.currentStep + 1
    this.cdr.detectChanges();
  }

  prev() {
    this.currentStep = this.currentStep - 1
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    let css = document.getElementById('formId');
    if (css) {
      document.head.removeChild(css);
    }
  }

  ngAfterViewInit(): void {
    var css = document.createElement('link');
    css.id ='formId';
    css.href = "assets/css/form-step.css";
    css.rel = "stylesheet";
    document.head.append(css);
  }

  closeTarification(plan: any) {
    this.formulaire.patchValue({
      tarification: plan
    })
    this.tarificationEvent.emit(this.formulaire);
    this.cdr.detectChanges();
    this.next();
  }

  ngOnChanges(changes: SimpleChanges) {
        
    this.handleFormControls();
    console.log(changes)
    this.cdr.detectChanges();
    
  }

  handleStepsCount() {
    var steps: any = [];
    this.formulaires.forEach((form: any) => {
      if (!steps.includes(form?.etape ? form?.etape : 1) && !(this.formType != "ADD" && form?.creationOnly)) {
        steps.push(form?.etape ? form?.etape : 1)
      }
    });
    return steps;
  }

  isStepValid() {
    var valid = true;
    const form = this.formulaires.filter((form: any) => (form?.etape ? form?.etape : 1) == this.currentStep +1);
    form?.forEach((champ: any) => {
      if(this.formulaire?.controls[champ?.code]?.status == 'INVALID' || champ?.errorMessage) {
        valid = false;
      }
    });
    return valid;
  }

  infoPoliceClicked() {
    this.infoPoliceButtonEvent.emit(true);
  }

  handleFormControls() {
    let formControls: any = {};
      this.formulaires?.forEach((champ: any) => {
        if (!(this.formType != "ADD" && champ?.creationOnly)) {
          if (champ?.obligatoire && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && this.formulaire?.value[champ?.visilityDependsOn]))) {
            formControls[champ?.code] = new FormControl(this.getFormControlInitialValue(champ?.defaultValue, champ?.typeReponse, champ?.typeChamp, champ?.multiple, champ?.forceDefaultValue), Validators.required)
          }
          else {
            formControls[champ?.code] = new FormControl(this.getFormControlInitialValue(champ?.defaultValue, champ?.typeReponse, champ?.typeChamp, champ?.multiple, champ?.forceDefaultValue))
          }
        }
      });
      this.formulaire = new FormGroup(formControls);
      this.newFormulaireOpened.emit({
        formulaire: this.formulaire,
        modelName: this.modelName
      });
      this.cdr.detectChanges();
  }

  getTableValues(table: any) {
    let _values = this.formulaire.value;
    _values[table?.type] = table?.value?.map((val: any) => val?.value);
    this.formulaire.patchValue(_values)
  }

  getTableEditValues(table: any) {
    let _values = this.formulaire.value;
    _values[table?.type] = table?.value;
    this.formulaire.patchValue(_values)
  }


  getFormControlInitialValue(defaultValue: any, typeReponse: string, typeChamp: string, multiple: boolean = false, forceDefaultValue: boolean = false) {
    if (typeReponse == 'OPTION') {
      return this.formType == 'ADD' ? (forceDefaultValue ? defaultValue : (multiple ? [] : '')) : defaultValue;
    }
    else {
      switch(typeChamp) {
        case 'CHECKBOX':
          return this.formType == 'ADD'? false : defaultValue;
        case 'TEXT':
          return this.formType == 'ADD' ? '' : defaultValue;
        case 'NUMBER':
          return this.formType == 'ADD' ? null : defaultValue;
        case 'EMAIL':
          return this.formType == 'ADD' ? '' : defaultValue;
        case 'TELEPHONE':
          return this.formType == 'ADD' ? '' : defaultValue;
        case 'TABLE':
          return this.formType == 'ADD' ? [] : defaultValue
        case 'DATE':
          return this.formType == 'ADD' ? new Date()?.toISOString()?.split('T')?.[0]?.split(',') : defaultValue;
        case 'PAIEMENT': 
          return this.formType == 'ADD' ? null : true;
        case 'IMAGE': 
          this.fileList = defaultValue;
          return this.formType == 'ADD' ? null : defaultValue;
        default:
          return this.formType == 'ADD' ? '' : defaultValue;
      } 
    }
  }

  submitFormulaire() {
  }

  submitClicked() {
    this.loading = true;
    this.formulaireEvent.emit({
      ...this.formulaire.value,
      paymentId: this.paymentInfo
    });
  }

  fileUploaded(event: any, question: any) {
    question.reponseUser = event?.fileList;
  }

  cancelFormulaire() {
    this.formulaire.reset();
  }

  getAdequateType(typeChamp: string) {
    switch(typeChamp) {
      case 'TEXT':
        return 'text';
      case 'NUMBER':
        return 'number';
      case 'EMAIL':
        return 'email';
      case 'TELEPHONE':
        return 'phone';
      case 'DATE':
        return 'date';
      default:
        return 'text';
    }
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.file = file;
    return false;
  };

  handleUpload(): void {
    this.uploading = true;
    
    this.dataService.uploadFile(this.file).subscribe(
      (data) => {
        this.uploading = false;
        this.msg.success('Le fichier a bien été chargé.');
        this.closeForm();
        // this.onUpload.emit('success');
      },
      err => {
        console.log(err);
        this.uploading = false;
        this.msg.error('Erreur lors du chargement du fichier.');
      }
    )
  }

  handleChange({ file, fileList }: NzUploadChangeParam): void {
    const status = file.status;
    if (status !== 'uploading') {
      var createMutation: any;
      
      this.handleChargementMutation(file.originFileObj)?.subscribe(
        (data: any) => {
          this.uploading = false;
          this.msg.success('Le fichier a bien été chargé.');
          this.closeForm();
          this.onUpload.emit('success');
        },
        (err: any) => {
          console.log(err?.message);
          const errorMessage = err?.message?.split('afterOperation: ');
          const arrayError = errorMessage && errorMessage[1] ? JSON.parse(errorMessage[1])?.data : [{
            errorMessage: 'Erreur lors du chargement du fichier.'
          }];
          this.uploading = false;
          arrayError?.forEach((error: any) => this.msg.error(error?.errorMessage, {
            nzDuration: 60000,
          }));
        }
      )
      this.uploading = true;
    }
  }

  ras() {
    
  }

  handleChargementMutation(file: any) {
    switch(this.modelName) {
      case 'Compagnie':
        console.log('Compagnie')
        return this.dataService.createChargementCompagnie(file);
      case 'Client':
        console.log('Client')
        return this.dataService.createChargementClient(file);
      case 'Relance':
        console.log('Relance')
        return this.dataService.createChargementRelance(file);
      case 'Specialite':
        console.log('Specialite')
        return this.dataService.createChargementSpecialite(file);
      default: 
        return null
    }
  }

  onPaymentStatus(status: boolean) {
    this.paymentStatus = status;
    if (!status) {
      this.retryPayment = true
    }
  }

  onPayementFinished(paymentInfo: any) {
    this.paymentInfo = paymentInfo;
  }

  handleRetry() {
    this.isRetrying = false;

    setTimeout(() => {
      this.isRetrying = true;
      this.retryPayment = false
    }, 50);
  }

  closeForm() {
    this.loading = false;
    this.onCloseForm.emit(false);
  }

  handlePreview = async (file: any): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

}
