import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-create-souscription-risque',
  templateUrl: './create-souscription-risque.component.html',
  styleUrls: ['./create-souscription-risque.component.css']
})
export class CreateSouscriptionRisqueComponent implements OnInit {

  @Input() typeFinancements : any = [];
  @Input() selectedTypeFinancement : any = [];
  @Output() typeFinancementChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  setTypeFinancementChanged(typeFinancement: any) {
    this.typeFinancementChanged.emit(typeFinancement);
  }
}
