import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';
import API from '../../../api';

@Component({
  selector: 'app-avenant-police',
  templateUrl: './avenant-police.component.html',
  styleUrls: ['./avenant-police.component.css']
})
export class AvenantPoliceComponent implements OnInit {

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  avenants: any = [];
  quittances: any = [];
  categorieAvenants: any = [];
  currentFormulaire: any = [];

  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'Avenant';

  infoPoliceDrawer: boolean = false;

  searchText = '';

  isNewPolice: boolean = true;
  currentPoliceId: string = ''

  compagnies: any = [];
  clients: any = [];
  apporteurs: any = [];
  branches: any = [];
  risques: any = [];
  allRisques: any = [];
  polices: any = [];

  clientDrawer: boolean = false;
  mainFormulaire: any = {};

  isFormLoading: boolean = false;

  infoPolice: any = [];
  infoPoliceCorrect: boolean = false;
  formulaireBrancheExist: boolean = true;

  constructor(
    private dataService: DataService,
    private message: NzMessageService,
    private cdr: ChangeDetectorRef
  ) { }

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter une avenant",
      titre: 'Avenant',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formClientTab = [
    {
      code: 'ONE',
      nom: "Ajouter un client",
      titre: 'Client',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formInfoPoliceTab = [
    {
      code: 'ONE',
      nom: "Information de la police",
      titre: '',
      soustitre: '',
      type: 'AJOUT'
    }
  ]

  formulaires: any = [];
  clientFormulaires: any = [];
  infoPoliceFormulaires: any = [];
  entreprises: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  initializeHeaders() {
    this.cdr.detectChanges();
    this.headers = [
      {
        name: 'Numéro',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Police',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Catégorie',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Quittance',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Date création',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      // {
      //   name: 'Derniére modif',
      //   sortDirections: ['ascend', 'descend', null]
      // },
      {
        name: 'Commentaire',
        actif: false,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Factures',
        actif: true,
        position: 'right',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Action',
        type: 'action',
        position: 'right',
        width: "10%",
        actif: true,
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
    this.cdr.detectChanges();
  }

  formulaireInfoPoliceEvent(infoPolice: any) {
    this.infoPolice = infoPolice;
    // this.infoPoliceCorrect = true;
    this.mainFormulaire.patchValue({
      infoPoliceCorrect: true
    });
    this.closeInfoPoliceDrawer();
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  handleFilter() {
    var _filter = `
    AND: [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    ]
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getAvenants();
  }

  getEntreprises() {  
    this.dataService.getAllEntreprises()
    .subscribe(
      (data: any) => {
        this.entreprises = data?.data?.entreprises;
        this.cdr.detectChanges();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  formulaireClientEvent(client: any) {
    const newContacts = client?.contact?.filter((contact: any) => !contact?.id);
    this.dataService.createClient(
      {
        ...client,
        newContacts
      }
    ).subscribe(
      (data: any) => {
        this.closeClientDrawer();
        this.message.success(`Ajout Client`);
        this.getAllClientBySearch(client?.prenom);
        this.mainFormulaire.patchValue({
          client: data?.data?.createClient?.id
        });
        this.cdr.detectChanges();
      },
      (err: any) => {
        console.log(err);
        this.message.error(`Une erreur est survenue lors de l'ajout`);
      },
    )
  }

  ngOnInit(): void {
    this.getAvenants();
    this.getAllCategorieAvenants();
    this.getAllQuittances();
    this.getEntreprises();
    this.getAllBranches();
    this.getAllApporteurs();
    this.getAllCompagnies();
    this.getAllCategorieAvenants();
    this.getAllRisques();
  }

  getAllBranches() {
    this.dataService.getAllBranches().subscribe(
      (data: any) => {
        this.branches = data?.data?.branches;
        this.initializeHeaders();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllCompagnies() {
    this.dataService.getAllCompagnies().subscribe(
      (data: any) => {
        this.compagnies = data?.data?.compagnies;
        this.initializeHeaders();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllApporteurs() {
    this.dataService.getAllApporteurs().subscribe(
      (data: any) => {
        this.apporteurs = data?.data?.apporteurs;
        this.cdr.detectChanges();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllRisques() {
    this.dataService.getAllRisques().subscribe(
      (data: any) => {
        this.allRisques = data?.data?.risqueBranches;
        this.initializeHeaders();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllQuittances() {
    this.dataService.getAllQuittances().subscribe(
      (data: any) => {
        this.quittances = data?.data?.quittances;
        this.initializeHeaders();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllCategorieAvenants() {
    this.dataService.getAllCategorieAvenants().subscribe(
      (data: any) => {
        this.categorieAvenants = data?.data?.categorieAvenants;
        this.initializeHeaders();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  handleColumnChanged() {
    this.getAvenants();
  }

  infoPoliceButtonEvent(clicked: boolean) {
    this.infoPoliceDrawer = true;
    this.initInfoPoliceForm(this.currentFormulaire?.champs, this.infoPolice);
    this.cdr.detectChanges();
  }

  newFormulaireOpened(formulaireOpened: any) {
    if (this.formType == 'EDIT') {
      formulaireOpened.patchValue({
        infoPoliceCorrect: true
      });
    }
    else {
      formulaireOpened.patchValue({
        infoPoliceCorrect: false
      });
    }
  }

  getFormulaireOfBranche = (id: string, formulaire: any = null) => {
    if (!id) {
      if (formulaire) {
        formulaire.patchValue({
          infoPoliceCorrect: false
        });
      }
      // this.formulaireBrancheExist = true;
    }
    else {
      this.dataService.getFormulaireOfBranche(id).subscribe(
        (data: any) => {
          // this.formulaireBrancheExist = true;
          if (formulaire) {
            formulaire.patchValue({
              infoPoliceCorrect: false
            });
          }
          this.currentFormulaire = data?.data?.branche?.formulaire;
          this.formInfoPoliceTab[0].titre = data?.data?.branche?.formulaire?.nom;
          this.formInfoPoliceTab[0].soustitre = data?.data?.branche?.formulaire?.titre;
          this.cdr.detectChanges();
        },
        (error: any) => {
          console.log(error);
        }
      )
    }
  }

  formulaireEvent(data: any) {
    const originalData = {
      ...data,
      infoPolice: this.infoPolice,
      formulaire: this.currentFormulaire
    };
    if (this.formType == 'ADD') {
      if (this.isNewPolice) {
        this.dataService.createPolice(
          originalData
        ).subscribe(
          (dataPolice: any) => {
            this.dataService.createAvenant(
              {
                ...originalData,
                police: dataPolice?.data?.createPolice?.id
              }
            ).subscribe(
              (data: any) => {
                this.onCloseForm();
                this.currentPage = 1;
                this.getAvenants();
                this.message.success(`Ajout ${this.modelName}`);
              },
              (err: any) => {
                console.log(err);
                this.message.error(`Une erreur est survenue lors de l'ajout`);
              },
            )
          },
          (err: any) => {
            console.log(err);
            this.message.error(`Une erreur est survenue lors de l'ajout`);
          },
        )
      }
      else {
        this.dataService.createAvenant(
          {
            ...originalData,
            police: originalData?.police?.[0]
          }
        ).subscribe(
          (data: any) => {
            this.onCloseForm();
            this.currentPage = 1;
            this.getAvenants();
            this.message.success(`Ajout ${this.modelName}`);
          },
          (err: any) => {
            console.log(err);
            this.message.error(`Une erreur est survenue lors de l'ajout`);
          },
        )
      }
    }
    else {
      if (this.isNewPolice) {
        this.dataService.createPolice(
          originalData
        ).subscribe(
          (dataPolice: any) => {
            this.dataService.updateAvenant(
              this.currentId,
              {
                ...originalData,
                police: dataPolice?.data?.createPolice?.id
              }
            ).subscribe(
              (data: any) => {
                this.onCloseForm();
                this.currentPage = 1;
                this.getAvenants();
                this.message.success(`Modification ${this.modelName}`);
              },
              (err: any) => {
                console.log(err);
                this.message.error(`Une erreur est survenue lors de l'ajout`);
              },
            )
          },
          (err: any) => {
            console.log(err);
            this.message.error(`Une erreur est survenue lors de l'ajout`);
          },
        )
      }
      else {
        this.dataService.updateAvenant(
          this.currentId,
          {
            ...originalData,
            police: originalData?.police?.[0]
          }
        ).subscribe(
          (data: any) => {
            this.onCloseForm();
            this.currentPage = 1;
            this.getAvenants();
            this.message.success(`Modification ${this.modelName}`);
          },
          (err: any) => {
            console.log(err);
            this.message.error(`Une erreur est survenue lors de l'ajout`);
          },
        )
      }
    }
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getAvenants();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getAvenants();
  }

  getAvenants() {  
    this.dataService.getAvenants(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.avenants = this.handleList(data?.data?.avenants)
        this.totalCount = data?.data?.avenantsCount;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getAvenants()
    })
  }

  handleList(data: any) {
    let list = data?.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.numero,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.police?.police,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.categorieAvenant?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.quittance?.numero
      },
      {
        type: 'DATE',
        value: row?.date
      },
      // {
      //   type: 'DATE',
      //   value: row?.derniereDateModif
      // },
      {
        type: 'TEXT',
        value: row?.commentaire
      },
      {
        type: 'HTML',
        position: 'right',
        value: `
          <div class="d-flex">
            <a data-toggle="tooltip" data-placement="top" title="FACTURE POUR PROASSUR" href="${API?.baseUrl + API?.fileUrl + row?.avenantProassur}" download="${row?.avenantProassur}" class="btn shadow btn-xs sharp mr-1 btn-primary" style="background: black;">
              P
            </a>
            <a data-toggle="tooltip" data-placement="top" title="FACTURE POUR LA COMPAGNIE" href="${API?.baseUrl + API?.fileUrl + row?.avenantCompagnie}" download="${row?.avenantCompagnie}" class="btn shadow btn-xs sharp mr-1 btn-primary" style="background: black;">
              <i class="fa fa-building"></i>
            </a>
            <a data-toggle="tooltip" data-placement="top" title="FACTURE POUR LE CLIENT" href="${API?.baseUrl + API?.fileUrl + row?.avenantClient}" download="${row?.avenantClient}" class="btn shadow btn-xs sharp mr-1 btn-primary" style="background: black;">
              <i class="fa fa-user"></i>
            </a>
          </div>
        `,
      },
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    this.cdr.detectChanges();
    return list;

  }

  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getAvenantById(action?.value);
        this.infoPolice = {}
      break;
      case 'delete':
        this.getAvenants();
      break;
    }
  }



  getAllRisqueOfBranche(id: string) {
    this.dataService.getAllRisqueOfBranche(id).subscribe(
      (data: any) => {
        this.risques = data?.data?.risqueBranches;
        var risqueForm: any = this.formulaires?.find((form: any) => form?.code == 'risque');
        if (risqueForm) {
          risqueForm.options = this.risques?.map((data: any) => {
            return {
              cle: data?.id,
              valeur: data?.nom
            }
          })
        }
        this.initializeHeaders();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAvenantById(id: string) {
    this.dataService.getAvenantById(id)
    .subscribe(
      (data: any) => {
        this.initForm(data?.data?.avenant);
        this.isFormOpened = true;
        this.getAllRisqueOfBranche(data?.data?.avenant?.police?.risque?.branche?.id);
        this.getAllClientBySearch(data?.data?.avenant?.police?.client?.prenom);
        this.getAllPolices(data?.data?.avenant?.police?.police);
        this.getFormulaireOfBranche(data?.data?.avenant?.police?.risque?.branche?.id)
        this.infoPolice = data?.data?.avenant?.police?.infoPolice
        this.isFormOpened = true;

        var policeForm: any = this.formulaires?.find((form: any) => form?.code == 'police');
        if (data?.data?.avenant?.police?.id) {
          if (policeForm) {
            policeForm.info = 'Police existante';
            this.isNewPolice = false;
            this.currentPoliceId = data?.data?.avenant?.police?.id;
          }
          this.handleNouvellePolice(null, data?.data?.avenant?.police, true)
        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.infoPolice = {};
    this.initForm();
  }



  getAllPolices(searchText: string) {
    if (searchText && searchText?.length >= 1) {
      this.dataService.getAllQuittanceBySearch(searchText).subscribe(
        (data: any) => {
          this.polices = data?.data?.polices;
          var form: any = this.formulaires?.find((form: any) => form?.code == 'police');
          if (form) {
            form.options = this.polices?.map((data: any) => {
              var valeur = `${data?.police}`;
              valeur += ` - ${data?.client?.prenom} - ${data?.client?.nom}`;
              data?.client?.contacts?.forEach((contact: any) => {
                valeur += ` - ${contact?.contact}`;
              });
              data?.infoPolices?.forEach((info: any) => {
                valeur += ` - ${info?.valeur}`;
              });
              return {
                cle: data?.id,
                valeur
              }
            })
          }
          this.cdr.detectChanges();
        },
        (err: any) => {
          console.log(err);
        },
      )
    }
  }

  getPoliceById(id: string, formulaire: any) {
    this.dataService.getPoliceById(id).subscribe(
      (data: any) => {
        // Handle police
        var policeForm: any = this.formulaires?.find((form: any) => form?.code == 'police');
        if (data?.data?.police?.id) {
          if (policeForm) {
            policeForm.info = 'Police existante';
            this.isNewPolice = false;
            this.currentPoliceId = data?.data?.police?.id;
            this.infoPolice = data?.data?.police?.infoPolice
            this.getFormulaireOfBranche(data?.data?.police?.risque?.branche?.id);
            formulaire.patchValue({
              infoPoliceCorrect: true
            });
            var infoPolice: any = {};
            data?.data?.police?.infoPolices?.forEach((info: any) => {
              infoPolice[info.cle] = info.valeur;
            });
            this.infoPolice = infoPolice; 
          }
          this.handleNouvellePolice(formulaire, data?.data?.police, true)
        }
        else {
          if (policeForm) {
            policeForm.info = 'Nouvelle police';
            this.isNewPolice = true;
          }
          this.handleNouvellePolice(formulaire, data?.data?.police, false)
        }
      },
      (err: any) => {
        var policeForm: any = this.formulaires?.find((form: any) => form?.code == 'police');
        if (policeForm) {
          policeForm.info = 'Nouvelle police'
          this.isNewPolice = true;
        }
        this.handleNouvellePolice(formulaire, {}, false)
      }
    );
  }

  getAllClientBySearch(searchText: string) {
    if (searchText && searchText?.length >= 2) {
      this.dataService.getAllClientBySearch(searchText).subscribe(
        (data: any) => {
          this.clients = data?.data?.clients;
          var form: any = this.formulaires?.find((form: any) => form?.code == 'client');
          if (form) {
            form.options = this.clients?.map((data: any) => {
              var valeur = `${data?.code} - ${data?.prenom} ${data?.nom} ${data?.contact?.[0] ? (' - ' + data?.contact?.[0]) : ''}`;
              data?.contacts?.forEach((contact: any) => {
                valeur += ` - ${contact?.contact}`;
              });
              return {
                cle: data?.id,
                valeur
              }
            })
          }
          this.cdr.detectChanges();
        },
        (err: any) => {
          console.log(err);
        },
      )
    }
  }

  handleNouvellePolice(formulaire: any, police: any, disabled = false) {
    // Récupérer les risques de la branche correspondante
    this.getAllRisqueOfBranche(police?.risque?.branche?.id);
    // Remplir les champs compagnie, client, apporteur, branche et risque
    if (formulaire) {
      formulaire.patchValue({
        compagnie: disabled ? police?.compagnie?.id : undefined,
        client: disabled ? police?.client?.id : undefined,
        apporteur: disabled ? police?.apporteur?.id : undefined,
        branche: disabled ? police?.risque?.branche?.id : undefined,
        risque: disabled ? police?.risque?.id : undefined
      });
    }

    // Disable compagnie
    var compagnieForm: any = this.formulaires?.find((form: any) => form?.code == 'compagnie');
    compagnieForm.readOnly = disabled;
    // Disable client
    var clientForm: any = this.formulaires?.find((form: any) => form?.code == 'client');
    clientForm.readOnly = disabled;
    // Disable apporteur
    var apporteurForm: any = this.formulaires?.find((form: any) => form?.code == 'apporteur');
    apporteurForm.readOnly = disabled;
    // Disable branche
    var brancheForm: any = this.formulaires?.find((form: any) => form?.code == 'branche');
    brancheForm.readOnly = disabled;
    // Disable risque
    var risqueForm: any = this.formulaires?.find((form: any) => form?.code == 'risque');
    risqueForm.readOnly = disabled;
    this.cdr.detectChanges();
    // this.isFormLoading = false;
  }

  initForm(data: any = {}) {
    if (data?.id) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      // {
      //   code: "police",
      //   placeholder: "Numéro de la police",
      //   typeChamp: "TEXT",
      //   etape: 1,
      //   typeReponse: "WRITE",
      //   obligatoire: !!(this.formType == 'ADD' || data?.police),
      //   defaultValue: data?.police,
      //   size: 'col-md-12'
      // },
      {
        code: "infoPoliceCorrect",
        typeChamp: "CHECKBOX",
        typeReponse: "WRITE",
        hidden: true,
      },
      {
        code: "police",
        placeholder: "Police (Minimum 5 caractéres pour la recherche)",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        info: '',
        multiple: true,
        limit: 1,
        onChange: (selected:  any, formulaire: any) => {
          this.mainFormulaire = formulaire;
          document.getElementById('dismissAll')?.click();
          this.cdr.detectChanges();
          if (selected && selected?.length) {
            this.getPoliceById(selected?.[0], formulaire)
          }
          else {
            var policeForm: any = this.formulaires?.find((form: any) => form?.code == 'police');
            if (policeForm) {
              policeForm.info = ''
              this.infoPolice = {}
            }
            this.handleNouvellePolice(formulaire, {}, false)
          }
        },
        options: this.polices?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: `${data?.police}`
          }
        }),
        onSearch: (searchText: string) => {
          this.getAllPolices(searchText);
        },
        obligatoire: !!(this.formType == 'ADD' || data?.police?.id),
        defaultValue: [data?.police?.id],
        size: 'col-md-12'
      },
      {
        code: "compagnie",
        placeholder: "Compagnie",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        options: this.compagnies?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.raisonSociale
          }
        }),
        obligatoire: !!(this.formType == 'ADD' || data?.police?.compagnie?.id),
        defaultValue: data?.police?.compagnie?.id,
        size: 'col-md-12'
      },
      {
        code: "client",
        placeholder: "Client",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        onAfter: (formulaire: any) => {
          this.mainFormulaire = formulaire
          this.clientDrawer = true;
          this.initClientForm();
        },
        options: this.clients?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: `${data?.code} ${data?.prenom} ${data?.nom} ${data?.contact?.[0]}`
          }
        }),
        onSearch: (searchText: string) => {
          this.getAllClientBySearch(searchText);
        },
        obligatoire: !!(this.formType == 'ADD' || data?.police?.client?.id),
        defaultValue: data?.police?.client?.id,
        size: 'col-md-12'
      },
      {
        code: "apporteur",
        placeholder: "Apporteur",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        options: this.apporteurs?.map((data: any) => {
          return {
            cle: data?.id,
            valeur:`${data?.prenom} ${data?.nom}`
          }
        }),
        obligatoire: !!(this.formType == 'ADD' || data?.police?.apporteur?.id),
        defaultValue: data?.police?.apporteur?.id,
        size: 'col-md-12'
      },
      {
        code: "branche",
        placeholder: "Branche",
        typeChamp: "TEXT",
        etape: 2,
        obligatoire: !!(this.formType == 'ADD' || data?.police?.risque?.branche?.id),
        typeReponse: "OPTION",
        options: this.branches?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.nom
          }
        }),
        onChange: (id: string, formulaire: any) => {
          this.getAllRisqueOfBranche(id);
          this.getFormulaireOfBranche(id, formulaire);
        },
        defaultValue: data?.police?.risque?.branche?.id,
        size: 'col-md-6'
      },
      {
        code: "risque",
        placeholder: "Risque",
        typeChamp: "TEXT",
        etape: 2,
        obligatoire: !!(this.formType == 'ADD' || data?.police?.risque?.id),
        typeReponse: "OPTION",
        // visilityDependsOn: 'branche',
        options: this.risques?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.nom
          }
        }),
        defaultValue: data?.police?.risque?.id,
        size: 'col-md-6'
      },
      {
        code: "categorieAvenant",
        placeholder: "Catégorie d'avenant",
        typeChamp: "TEXT",
        etape: 2,
        typeReponse: "OPTION",
        options: this.categorieAvenants?.map((categorie: any) => {
          return {
            cle: categorie?.id,
            valeur: categorie?.nom
          }
        }),
        obligatoire: !!(this.formType == 'ADD' || data?.categorieAvenant?.id),
        defaultValue: data?.categorieAvenant?.id,
        size: 'col-md-12'
      },
      // {
      //   code: "quittance",
      //   placeholder: "Quittance",
      //   typeChamp: "TEXT",
      //   etape: 1,
      //   typeReponse: "OPTION",
      //   options: this.quittances?.map((quittance: any) => {
      //     return {
      //       cle: quittance?.id,
      //       valeur: quittance?.numero
      //     }
      //   }),
      //   obligatoire: !!(this.formType == 'ADD' || data?.quittance?.id),
      //   defaultValue: data?.quittance?.id,
      //   size: 'col-md-6'
      // },
      {
        code: "commentaire",
        placeholder: "Commentaire",
        typeChamp: "TEXTAREA",
        etape: 2,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.commentaire),
        defaultValue: data?.commentaire,
        size: 'col-md-12'
      },
    ]
    this.cdr.detectChanges();
  }



  initClientForm(client: any = {}) {
    this.clientFormulaires = [
      // {
      //   code: "code",
      //   placeholder: "Code client",
      //   typeChamp: "TEXT",
      //   typeReponse: "WRITE",
      //   etape: 1,
      //   obligatoire: true,
      //   size: 'col-md-12'
      // },
      {
        code: "prenom",
        placeholder: "Prénom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: true,
        size: 'col-md-6'
      },
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        etape: 1,
        obligatoire: true,
        size: 'col-md-6'
      },
      {
        code: "email",
        placeholder: "Adresse email",
        typeChamp: "EMAIL",
        typeReponse: "WRITE",
        etape: 1,
        obligatoire: true,
        size: 'col-md-12'
      },
      {
        code: "emplacement",
        placeholder: "Emplacement du dossier",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        etape: 1,
        size: 'col-md-6'
      },
      {
        code: "entreprise",
        placeholder: "Entreprise",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        options: this.entreprises?.map((entrep: any) => {
          return {
            cle: entrep?.id,
            valeur: entrep?.raisonSociale
          }
        }),
        size: 'col-md-6'
      },
      {
        code: "adresse",
        placeholder: "Adresse",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        size: 'col-md-12'
      },
      {
        code: "contact",
        placeholder: "Contact",
        inputPlaceholder: 'Téléphone..',
        typeChamp: "TABLE",
        etape: 2,
        typeReponse: "TABLE",
        values: [],
        columns: [
          {
            name: 'id',
            placeholder: 'Id',
            type: 'TEXT',
            hide: true,
          },
          {
            name: 'contact',
            placeholder: 'contact',
            type: 'TEXT'
          }
        ],
        size: 'col-md-6'
      },
      {
        code: "connexe",
        placeholder: "Connexe",
        inputPlaceholder: 'Connexe..',
        typeChamp: "TABLE",
        etape: 2,
        typeReponse: "TABLE",
        values: [],
        size: 'col-md-6'
      }
    ]
    this.cdr.detectChanges();
  }

  initInfoPoliceForm(champs: any = {}, existingInfo: any = {},) {
    champs = champs?.sort((c1: any, c2: any) => (c1.price < c2.ordre) ? 1 : (c1.price > c2.ordre) ? -1 : 0);
    this.infoPoliceFormulaires = champs?.map((champ: any) => {
      return {
        code: champ?.code,
        placeholder: champ?.placeholder,
        typeChamp: champ?.typeChamp,
        readOnly: !this.isNewPolice,
        etape: 1,
        typeReponse: champ?.typeReponse,
        obligatoire: champ?.obligatoire,
        defaultValue: existingInfo[champ?.code],
        size: champ?.taille?.replace(/_/g, '-')
      }
    });
    this.infoPoliceFormulaires = [
      ...this.infoPoliceFormulaires,
      {
        code: 'readOnly',
        hidden: true,
        defaultValue: !this.isNewPolice
      }
    ]
    this.cdr.detectChanges();
  }

  
  closeClientDrawer() {
    this.clientDrawer = false;
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
    this.infoPolice = {};
  }

  closeInfoPoliceDrawer() {
    this.infoPoliceDrawer = false;
  }

}
