import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RisqueBrancheComponent } from './risque-branche.component';

const routes: Routes = [
  {
    path: '',
    component: RisqueBrancheComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RisqueBrancheRoutingModule { }
