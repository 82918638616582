import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-table-type',
  templateUrl: './table-type.component.html',
  styleUrls: ['./table-type.component.css']
})
export class TableTypeComponent implements OnInit {
  editCache: { [key: string]: { edit: boolean; data: any } } = {};
  @Input() table: any = {};
  @Input() uploadIncluded: boolean = false;
  @Output() onTableEvent: EventEmitter<any> = new EventEmitter<any>();
  listOfData: any[] = [];

  constructor(private msg: NzMessageService) {}

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: string): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: { ...this.listOfData[index] },
      edit: false
    };
  }

  getValueOfSelect(options: any, value: string) {
    return options?.find((option: any) => option?.cle == value)?.valeur
  }

  handleChange({ file, fileList }: NzUploadChangeParam): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    if (status === 'done') {
      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }

  deleteEdit(id: string): void {
    this.listOfData = this.listOfData?.filter((item: any) => item.id != id)
    this.updateEditCache();
    this.onTableEvent.emit({
      type: this.table?.code,
      value: [...this.listOfData]
    })
  }

  saveEdit(id: string): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    console.log("this.editCache[id].data : ", this.editCache[id].data)
    Object.assign(this.listOfData[index], this.handleCorespondedValue(this.editCache[id].data));
    this.editCache[id].edit = false;
    this.onTableEvent.emit({
      type: this.table?.code,
      value: [...this.listOfData]
    })
  }

  handleCorespondedValue(data: any) {
    var values = {...data.value}
    if (this.table?.columns) {
      this.table?.columns?.forEach((column: any) => {
        values[column?.name] = column?.type == 'NUMBER' ? parseInt(values[column?.name]) : values[column?.name]
      })
    }
    else {
      values = this.table?.type == 'NUMBER' ? parseInt(data.value) : data.value
    }
    
    data.value = {...values}
    return data;
  }

  updateEditCache(): void {
    this.listOfData.forEach(item => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  addItem() {
    const index = this.guidGenerator();
    this.listOfData = [
      {
        id: index,
        value: this.table?.columns ? {} : ``
      },
      ...this.listOfData
    ];
    this.updateEditCache();
    this.editCache[index].edit = true;
  }

  guidGenerator() {
    var S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
  }
  
  ngOnInit(): void {
    // console.log(this.table)
    var data: any = [];
    this.listOfData = [...this.table?.defaultValue];
    this.listOfData?.forEach((row: any, index: number) => {
      data.push({
        id: this.guidGenerator(),
        value: {...row}
      });
    });
    this.listOfData = data;

    this.updateEditCache();
    this.onTableEvent.emit({
      type: this.table?.code,
      value: this.listOfData
    })
  }

  getAdequateType(typeChamp: string) {
    switch(typeChamp) {
      case 'TEXT':
        return 'text';
      case 'NUMBER':
        return 'number';
      case 'EMAIL':
        return 'email';
      case 'TELEPHONE':
        return 'phone';
      case 'DATE':
        return 'date';
      default:
        return 'text';
    }
  }

  ras() {}
}