import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';
import API from '../../../api';

@Component({
  selector: 'app-relance',
  templateUrl: './relance.component.html',
  styleUrls: ['./relance.component.css']
})
export class RelanceComponent  implements OnInit, AfterViewInit {

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  quittances: any = [];
  relancesRendered: any = [];
  risques: any = [];
  clients: any = [];
  clientSelected: any = [];
  headerInitialed = false;
  isRelancePanelVisibled: boolean = false;
  segmentType: number = 0;
  relanceType: string = '';

  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'Relance';

  searchText = '';
  
  compagnies: any = [];
  branches: any = [];
  allRisques: any = [];

  tableTypes = ['Liste des relances normales', 'Liste des relances ponctuelles']

  relanceParam: any = { 
    titre: '',
    fileName: '',
    emvoyerEmail: false,
    contenu: '',
    date: new Date(),
    objet: '',
    destinataires: ['proassur.ndao@gmail.com', 'souleymane.ndao@gmail.com'],
    primeNette: [0, 0],
    accessoire: [0, 0],
    taxe: [0, 0],
    cloturer: false,
    paymentId: '',
    compagnies: [],
    branches: [],
    clients: [],
    apporteurs: [],
    risques: [],
    avenants: [],
    dateEffet: [],
    dateEcheance: [],
    dateCreation: [],
    serachText: '',
    derniereDateModif: []
  }

  constructor(
    private dataService: DataService,
    private message: NzMessageService,
    private cdr: ChangeDetectorRef
  ) { }

  formTab = [
    {
      code: 'MANY',
      nom: 'Relance en masse',
      titre: 'Relance',
      soustitre: 'Chargement en masse des relances clients',
      type: 'CHARGEMENT',
      templateImage: 'assets/images/template-relance.png'
    }
  ]

  formulaires: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  getAllClientBySearch(searchText: string) {
    if (searchText && searchText?.length >= 2) {
      this.dataService.getAllClientBySearch(searchText).subscribe(
        (data: any) => {
          this.clients = data?.data?.clients;
        },
        (err: any) => {
          console.log(err);
        },
      )
    }
  }



  getAllRisques() {
    this.dataService.getAllRisques().subscribe(
      (data: any) => {
        this.allRisques = data?.data?.risqueBranches;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllBranches() {
    this.dataService.getAllBranches().subscribe(
      (data: any) => {
        this.branches = data?.data?.branches;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllCompagnies() {
    this.dataService.getAllCompagnies().subscribe(
      (data: any) => {
        this.compagnies = data?.data?.compagnies;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  ngAfterViewInit(): void {
    
  }

  initializeHeaders(segmentType = 0) {
    this.cdr.detectChanges();
    this.headers = segmentType == 0 ? [
      {
        name: 'Quittance',
        code: 'numero',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Police',
        code: 'police',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Création',
        code: 'dateCreation',
        sortDirections: ['ascend', 'descend', null],
        calendarFilter: true,
        calendarFilterFunc: (dataRange: any) => {
          if (dataRange?.length == 2) {
            // this.relanceParam.dateCreation = dataRange;
            dataRange = [
              new Date(dataRange?.[0]),
              this.addDays(dataRange?.[1], 1)
            ]
            var index: number = 0;
            var oldFilter: string = '';
            if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('dateCreation') == -1) {
              this.filterArray.push({
                key: 'dateCreation',
                value: ''
              })
            }
            index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('dateCreation');
            oldFilter = this.filterArray[index].value;
            this.filterArray[index].value = `
            {
              AND: [
                {
                  dateCreation: {
                    gte: "${dataRange?.[0]?.toISOString()?.split('T')?.[0]?.split(',')}"
                  }
                }
                {
                  dateCreation: {
                    lt: "${dataRange?.[1]?.toISOString()?.split('T')?.[0]?.split(',')}"
                  }
                }
              ]
            }
            `;
            if (oldFilter != this.filterArray[index].value) {
              this.handleFilter();
            }

          }
          else if (dataRange?.length == 0) {
            // this.relanceParam.dateCreation = [];
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'dateCreation');
            this.handleFilter();
          }
          else {
            // this.relanceParam.dateCreation = [];
          }

        }
      },
      {
        name: 'Effet',
        code: 'dateEffet',
        sortDirections: ['ascend', 'descend', null],
        calendarFilter: true,
        calendarFilterFunc: (dataRange: any) => {
          if (dataRange?.length == 2) {
            // this.relanceParam.dateEffet = dataRange;
            dataRange = [
              new Date(dataRange?.[0]),
              this.addDays(dataRange?.[1], 1)
            ]
            var index: number = 0;
            var oldFilter: string = '';
            if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('dateEffet') == -1) {
              this.filterArray.push({
                key: 'dateEffet',
                value: ''
              })
            }
            index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('dateEffet');
            oldFilter = this.filterArray[index].value;
            this.filterArray[index].value = `
            {
              AND: [
                {
                  dateEffet: {
                    gte: "${dataRange?.[0]?.toISOString()?.split('T')?.[0]?.split(',')}"
                  }
                }
                {
                  dateEffet: {
                    lt: "${dataRange?.[1]?.toISOString()?.split('T')?.[0]?.split(',')}"
                  }
                }
              ]
            }
            `;
            if (oldFilter != this.filterArray[index].value) {
              this.handleFilter();
            }

          }
          else if (dataRange?.length == 0) {
            // this.relanceParam.dateEffet = [];
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'dateEffet');
            this.handleFilter();
          }
          else {
            // this.relanceParam.dateEffet = [];
          }

        }
      },
      {
        name: 'Echcéance',
        code: 'dateEcheance',
        actif: true,
        sortDirections: ['ascend', 'descend', null],
        calendarFilter: true,
        calendarFilterFunc: (dataRange: any) => {
          if (dataRange?.length == 2) {
            // this.relanceParam.dateEcheance = dataRange;
            dataRange = [
              new Date(dataRange?.[0]),
              this.addDays(dataRange?.[1], 1)
            ]
            var index: number = 0;
            var oldFilter: string = '';
            if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('dateEcheance') == -1) {
              this.filterArray.push({
                key: 'dateEcheance',
                value: ''
              })
            }
            index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('dateEcheance');
            oldFilter = this.filterArray[index].value;
            this.filterArray[index].value = `
            {
              AND: [
                {
                  dateEcheance: {
                    gte: "${dataRange?.[0]?.toISOString()?.split('T')?.[0]?.split(',')}"
                  }
                }
                {
                  dateEcheance: {
                    lt: "${dataRange?.[1]?.toISOString()?.split('T')?.[0]?.split(',')}"
                  }
                }
              ]
            }
            `;
            if (oldFilter != this.filterArray[index].value) {
              this.handleFilter();
            }

          }
          else if (dataRange?.length == 0) {
            // this.relanceParam.dateEcheance = [];
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'dateEcheance');
            this.handleFilter();
          }
          else {
            // this.relanceParam.dateEcheance = [];
          }

        }
      },
      {
        name: 'Prime nette',
        code: 'primeNette',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Accessoire',
        code: 'accessoire',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Taxe',
        code: 'taxe',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Prime totale',
        code: 'primeTotale',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Compagnie',
        code: 'compagnie',
        actif: true,
        sortDirections: ['ascend', 'descend', null],
        dataFilter: [
          ...this.compagnies.map((compagnie: any) => {
            return { text: compagnie?.raisonSociale, value: compagnie?.id }
          })
        ],
        // sortDirections: ['ascend', 'descend', null],
        filterFn: (list: string[], item: any) => {
          // this.relanceParam.compagnies = list?.map((item: any) => {
          //   return this.compagnies?.find((compagnie: any) => compagnie.id === item)
          // });
          var oldFilter: string = '';
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('compagnie') == -1) {
            this.filterArray.push({
              key: 'compagnie',
              value: ''
            })
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('compagnie');
            oldFilter = this.filterArray[index].value;
          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('compagnie');

          if (!list.length) {
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'compagnie');
            this.handleFilter();
          }
          else {
            this.filterArray[index].value = `
            {
              OR: [
                {
                  compagnie: {
                    id: {
                      in: [${
                        list?.map((compagnie: any) => `"${compagnie}"` )
                      }]
                    }
                  }
                }
              ]
            }
          `;
          if (oldFilter != this.filterArray[index].value) {
            this.handleFilter();
          }
          }
        }
      },
      {
        name: 'Client',
        code: 'client',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Apporteur',
        code: 'apporteur',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Branche',
        code: 'branche',
        actif: true,
        sortDirections: ['ascend', 'descend', null],
        dataFilter: [
          ...this.branches.map((branche: any) => {
            return { text: branche?.nom, value: branche?.id }
          })
        ],
        // sortDirections: ['ascend', 'descend', null],
        filterFn: (list: string[], item: any) => {
          // this.relanceParam.branches = list?.map((item: any) => {
          //   return this.branches?.find((branche: any) => branche.id === item)
          // });
          var oldFilter: string = '';
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('branche') == -1) {
            this.filterArray.push({
              key: 'branche',
              value: ''
            })
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('branche');
            oldFilter = this.filterArray[index].value;
          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('branche');


          if (!list.length) {
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'branche');
            this.handleFilter();
          }
          else {
            this.filterArray[index].value = `
            {
              OR: [
                {
                  risque: {
                    branche: {
                     id: {
                       in: [${
                        list?.map((branche: any) => `"${branche}"` )
                      }]
                     }
                   }
                 }
                }
              ]
            }
          `;
          if (oldFilter != this.filterArray[index].value) {
            this.handleFilter();
          }
          }
        }
      },
      {
        name: 'Risque',
        code: 'risque',
        actif: true,
        sortDirections: ['ascend', 'descend', null],
        dataFilter: [
          ...this.allRisques.map((risque: any) => {
            return { text: risque?.nom, value: risque?.id }
          })
        ],
        // sortDirections: ['ascend', 'descend', null],
        filterFn: (list: string[], item: any) => {
          // this.relanceParam.risques = list?.map((item: any) => {
          //   return this.allRisques?.find((risque: any) => risque.id === item)
          // });
          var oldFilter: string = '';
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('risque') == -1) {
            this.filterArray.push({
              key: 'risque',
              value: ''
            })
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('risque');
            oldFilter = this.filterArray[index].value;
          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('risque');

          if (!list.length) {
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'risque');
            this.handleFilter();
          }
          else {
            this.filterArray[index].value = `
            {
              OR: [
                {
                  risque: {
                    id: {
                      in: [${
                      list?.map((risque: any) => `"${risque}"` )
                    }]
                    }
                 }
                }
              ]
            }
          `;
          if (oldFilter != this.filterArray[index].value) {
            this.handleFilter();
          }
          }
        }
      },
      {
        name: 'Avenant',
        code: 'avenant',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Paiement',
        code: 'quittance',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Relances',
        code: 'relance',
        actif: true,
        position: 'right',
        sortDirections: ['ascend', 'descend', null]
      }
    ] 
    :
    [
      {
        name: 'Date',
        code: 'date',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Prenom',
        code: 'prenom',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Nom',
        code: 'nom',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Entreprise',
        code: 'entreprise',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Branche',
        code: 'branche',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Risque',
        code: 'risque',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Echéance',
        code: 'echeance',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Contact',
        code: 'contact',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
    ]
    ;
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
    this.cdr.detectChanges();
  }

  getDate() {
    return new Date();
  }

  addDays = (_date: Date, days: number) => {
    var date = new Date(_date);
    date.setDate(date.getDate() + days);
    return date;
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  brancheChanged(branches: any) {
    this.dataService.getAllRisqueByBranches(branches).subscribe(
      (data: any) => {
        const newInsertedRisques: any = data?.data?.risqueBranches?.filter((r: any) => !(this?.risques?.map((risque: any) => risque.id))?.includes(r?.id))
        this.risques = data?.data?.risqueBranches;
        this.relanceParam.risques = this.relanceParam?.risques?.filter((r: any) => this.risques?.map((risque: any) => risque?.id)?.includes(r));
        this.relanceParam.risques = [
          ...this.relanceParam.risques,
          ...newInsertedRisques?.map((r: any) => r?.id)
        ]
        this.handleFilter();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  reinitFilters(): void {
    this.relanceParam = { 
      titre: '',
      fileName: '',
      emvoyerEmail: false,
      contenu: '',
      date: new Date(),
      objet: '',
      destinataires: ['proassur.ndao@gmail.com', 'souleymane.ndao@gmail.com'],
      primeNette: [0, 0],
      accessoire: [0, 0],
      taxe: [0, 0],
      cloturer: false,
      paymentId: '',
      compagnies: [],
      branches: [],
      clients: [],
      apporteurs: [],
      risques: [],
      avenants: [],
      dateEffet: [],
      dateEcheance: [],
      dateCreation: [],
      derniereDateModif: []
    }
  };

  // handleFilter() {
  //   var _filter = `
  //   AND: [
  //   `;
  //   this.filterArray?.forEach((filter: any) => {
  //     _filter += filter?.value;
  //   });
  //   _filter += `
  //   ]
  //   `;
  //   this.filter = _filter;
  //   this.currentPage = 1;
  //   this.getQuittances();
  // }

  getDateLastTime = (_date: Date): Date => {
    const date: Date = new Date(_date);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    return date;
  };
  
  getDateFirstTime = (_date: Date): Date => {
    const date: Date = new Date(_date);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;  
  };

  showRelancePanel(val: boolean, type: string = ''): void {
    this.isRelancePanelVisibled = val;
    this.relanceType = type;
    this.handleFilter();
    this.getQuittances();
  }

  handleSearchText() {
    this.handleFilter();
    this.getQuittances(1000000000);
    this.cdr.detectChanges();
  }

  handleFilter(modelName: string = '', values: any = null) {
    if (modelName == 'client') {
      const newClient = values?.find((value: any) => !(this.clientSelected?.map((val: any) => val?.id)?.includes(value)));
      const clientInfo: any = this.clients?.find((cli: any) => cli?.id == newClient);
      this.clientSelected?.push(clientInfo);
    }
    this.filter = `
      AND: [
          ${
            this.isRelancePanelVisibled && this.relanceType == 'IMPAYE' ?
            `
            {
              dateEffet: {
                lte: "${this.getDateLastTime(new Date())?.toISOString()?.split('T')?.[0]}"
              }
            }
            {
              paiements: {
                every: {
                  type: {
                    equals: IMPAYE
                  }
                }
              }
            }
            `
            :
            ``
          }
          ${
            this.isRelancePanelVisibled && this.relanceType == 'ECHEANCE' ?
            `
            {
              police: {
                quittances: {
                  none: {
                    dateEcheance: {
                      gt: "${this.getDateLastTime(this.relanceParam?.dateEcheance?.[1])?.toISOString()?.split('T')?.[0]}"
                    }
                  }
                }
              }
            }
            `
            :
            ``
          }
          ${
            !this.isRelancePanelVisibled ?
            `
              {
                OR: [
                  {
                    relanceManuele: {
                      not: {
                        equals: null
                      }
                    }
                  }
                  {
                    relanceJJ: {
                      not: {
                        equals: null
                      }
                    }
                  }
                  {
                    relanceJ3: {
                      not: {
                        equals: null
                      }
                    }
                  }
                  {
                    relanceJ10: {
                      not: {
                        equals: null
                      }
                    }
                  }
                ]
              }
            `
            :
            `
              {
                relanceManuele: {
                  equals: null
                }
              }
            `
          }
          ${
            this.relanceParam?.dateCreation?.length == 2 && this.isRelancePanelVisibled ?
            `{
              AND: [
                {
                  dateCreation: {
                    gte: "${this.getDateFirstTime(this.relanceParam?.dateCreation?.[0])?.toISOString()?.split('T')?.[0]}"
                  }
                },
                {
                  dateCreation: {
                    lte: "${this.getDateLastTime(this.relanceParam?.dateCreation?.[1])?.toISOString()?.split('T')?.[0]}"
                  }
                }
              ]
            }`
            :
            ``
          }
          ${
            this.relanceParam?.primeNette?.length == 2 && this.relanceParam?.primeNette?.[1] != 0 ?
            `{
              AND: [
                {
                  primeNette: {
                    gte: ${this.relanceParam?.primeNette?.[0]}
                  }
                },
                {
                  primeNette: {
                    lte: ${this.relanceParam?.primeNette?.[1]}
                  }
                }
              ]
            }`
            :
            ``
          }
          ${
            this.relanceParam?.accessoire?.length == 2 && this.relanceParam?.accessoire?.[1] != 0 ?
            `{
              AND: [
                {
                  accessoire: {
                    gte: ${this.relanceParam?.accessoire?.[0]}
                  }
                },
                {
                  accessoire: {
                    lte: ${this.relanceParam?.accessoire?.[1]}
                  }
                }
              ]
            }`
            :
            ``
          }
          ${
            this.relanceParam?.taxe?.length == 2 && this.relanceParam?.taxe?.[1] != 0 ?
            `{
              AND: [
                {
                  taxe: {
                    gte: ${this.relanceParam?.taxe?.[0]}
                  }
                },
                {
                  taxe: {
                    lte: ${this.relanceParam?.taxe?.[1]}
                  }
                }
              ]
            }`
            :
            ``
          }
          ${
            this.relanceParam?.dateEffet?.length == 2 && this.isRelancePanelVisibled ?
            `{
              AND: [
                {
                  dateEffet: {
                    gte: "${this.getDateFirstTime(this.relanceParam?.dateEffet?.[0])?.toISOString()?.split('T')?.[0]}"
                  }
                },
                {
                  dateEffet: {
                    lte: "${this.getDateLastTime(this.relanceParam?.dateEffet?.[1])?.toISOString()?.split('T')?.[0]}"
                  }
                }
              ]
            }`
          : 
          ``
          }
          ${
            this.relanceParam?.dateEcheance?.length == 2 && this.isRelancePanelVisibled &&  this.relanceType == 'ECHEANCE' ?
            `{
              AND: [
                {
                  dateEcheance: {
                    gte: "${this.getDateFirstTime(this.relanceParam?.dateEcheance?.[0])?.toISOString()?.split('T')?.[0]}"
                  }
                },
                {
                  dateEcheance: {
                    lte: "${this.getDateLastTime(this.relanceParam?.dateEcheance?.[1])?.toISOString()?.split('T')?.[0]}"
                  }
                }
              ]
            }`
          : 
          ``
          }
          ${
            this.relanceParam?.compagnies?.length ?
            `{
              OR: [
                {
                  police: {
                    compagnie: {
                      id: {
                        in: ${JSON.stringify(this.relanceParam?.compagnies)}
                      }
                    }
                  }
                }
              ]
            }`
            :
            ``
          }
          ${
            this.relanceParam?.branches?.length ?
            `{
              OR: [
                {
                  police: {
                    risque: {
                      branche: {
                      id: {
                        in:  ${JSON.stringify(this.relanceParam?.branches)}
                      }
                    }
                  }
                }
                }
              ]
            }`
            : 
            ``
          }
          ${
            this.relanceParam?.risques?.length ?
            `{
              OR: [
                {
                  police: {
                    risque: {
                      id: {
                        in: ${JSON.stringify(this.relanceParam?.risques)}
                      }
                    }
                  }
                }
              ]
            }`
            : 
            ``
          }
          ${
            this.relanceParam?.clients?.length ? 
            `{
              OR: [
                {
                  police: {
                    client: {
                      id: {
                        in: ${JSON.stringify(this.relanceParam?.clients)}
                      }
                    }
                  }
                }
              ]
            }`
            : 
            ``
          }
          ${
            this.relanceParam?.avenants?.length ? 
            `{
              OR: [
                {
                  avenant: {
                    id: {
                      in: ${JSON.stringify(this.relanceParam?.avenants)}
                    }
                }
                }
              ]
            }`
            : 
            ``
          }
          ${
            this.relanceParam?.apporteurs?.length ? 
            `{
              OR: [
                {
                  apporteur: {
                    id: {
                      in: ${JSON.stringify(this.relanceParam?.apporteurs)}
                    }
                }
                }
              ]
            }`
            : 
            ``
          }
      ]
    `;
  }

  ngOnInit(): void {
    this.relanceParam = {
      ...this.relanceParam,
      dateEcheance: [this.addDays(new Date(), 0)?.toISOString()?.split('T')?.[0]?.split(','), this.addDays(new Date(), 10)?.toISOString()?.split('T')?.[0]?.split(',')]
    }
    this.handleFilter();
    this.getQuittances();
    this.getAllCompagnies();
    this.getAllBranches();
    this.getAllRisques();
    this.cdr.detectChanges();
  }

  relancerPolices() {
    if (this.listOfDataChecked?.length) {
      var str = JSON.stringify(this.listOfDataChecked)
      str = str?.replace(/"/g, `\\"`);
      this.dataService.relance(str, this.relanceType)
      .subscribe((data: any) => {
        this.message.success('Les relances sont envoyées. Les destinataires vont les recevoir dans un instant');
        this.isRelancePanelVisibled = false;
        this.handleFilter();
        this.getQuittances();
  
      }, (err: any) => {
        console.log(err);
        this.message.error("Erreur lors de l'envoi des relances. Contactez l'administrateur");
      });
    }
    else {
      this.message.warning("Désolé! Aucune quittance n'a été sélectionnée")
    }
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  handleColumnChanged() {
    this.quittances = this.getQuittances();
  }

  formulaireEvent(data: any) {
    if (this.formType == 'ADD') {
      this.dataService.createQuittance(
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getQuittances();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    else {
      this.dataService.updateQuittance(
        this.currentId,
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getQuittances();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getQuittances();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getQuittances();
  }

  getQuittances(pageCount=0) {  
    if (this.segmentType == 0) {
      this.dataService.getQuittances(this.currentPage, pageCount!= 0 ? pageCount : this.pageCount, this.filter, this.searchText, this.isRelancePanelVisibled)
      .subscribe(
        (data: any) => {
          if (pageCount != 0) {
            this.listOfData = data?.data?.quittances;
          }
          else {
            this.quittances = this.handleList(data?.data?.quittances)
            this.totalCount = data?.data?.quittancesCount;
            if (!this.headerInitialed) {
              this.initializeHeaders();
              this.headerInitialed = true;
            }
          }
        },
        (err: any) => {
          console.log(err);
        },
      )
    }
    else {
      this.initializeHeaders(this.segmentType);
      this.dataService.getRelancePonctuelles(this.currentPage, pageCount!= 0 ? pageCount : this.pageCount, this.filter, this.searchText)
      .subscribe(
        (data: any) => {
          if (pageCount != 0) {
            this.listOfData = data?.data?.quittances;
          }
          else {
            this.quittances = this.handleListPonctuelle(data?.data?.relancePonctuelles)
            this.totalCount = data?.data?.relancePonctuellesCount;
            if (!this.headerInitialed) {
              this.initializeHeaders();
              this.headerInitialed = true;
            }
          }
        },
        (err: any) => {
          console.log(err);
        },
      )
    }
  }

  getDateFormat(date: string) {
    return date?.split('T')?.[0]?.split(';')?.[0]
  }

  handleListPonctuelle(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: this.getDateFormat(row?.date),
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.prenom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.entreprise ?? '---',
      },
      {
        type: 'TEXT',
        value: row?.branche,
      },
      {
        type: 'TEXT',
        value: row?.risque,
      },
      {
        type: 'TEXT',
        value: row?.echeanche,
      },
      {
        type: 'LIST',
        value: row?.contacts?.map((contact: any) => contact?.contact),
      }
    ]));
    setTimeout(() => {
      this.initRelanceCss();
    }, 3000)
    return list;
  }

  onSegmentEvent(type: number) {
    this.segmentType = type;
    this.getQuittances();
    // switch(type) {
    //   case 0:
        
    //   break;
    //   case 1:
    //     this.relancesRendered = [];
    //   break;
    // }
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getQuittances()
    })
  }

  

  handleList(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.numero,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.police?.police,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.dateCreation,
      },
      {
        type: 'TEXT',
        value: row?.dateEffet,
      },
      {
        type: 'TEXT',
        value: row?.dateEcheance,
      },
      {
        type: 'TEXT',
        value: row?.primeNette,
      },
      {
        type: 'TEXT',
        value: row?.accessoire,
      },
      {
        type: 'TEXT',
        value: row?.taxe,
      },
      {
        type: 'TEXT',
        value: row?.primeTotale,
      },
      {
        type: 'TEXT',
        value: row?.police?.compagnie?.raisonSociale,
      },
      {
        type: 'TEXT',
        value: `${row?.police?.client?.prenom} ${row?.police?.client?.nom} `,
      },
      {
        type: 'TEXT',
        value: `${row?.police?.apporteur?.prenom} ${row?.police?.apporteur?.nom}`,
      },
      {
        type: 'TEXT',
        value: row?.police?.risque?.branche?.nom,
      },
      {
        type: 'TEXT',
        value: row?.police?.risque?.nom,
      },
      {
        type: 'TEXT',
        value: row?.avenant?.nom,
      },
      {
        type: 'TEXT',
        value: row?.paiements?.[0]?.type,
      },
      {
        type: 'HTML',
        position: 'right',
        value: `
          <div class="d-flex">
            ${
              row?.relanceJ10 ? 
              `
                <span class="badge badge-success" style="padding: 4px 4px;">-10</span>
              `
              :
              ``
            }
            ${
              row?.relanceJ3 ? 
              `
                <span class="badge badge-primary light" style="padding: 4px 9px 4px 6px;">-3</span>
              `
              :
              ``
            }
            ${
              row?.relanceJJ ? 
              `
                <span class="badge badge-warning" style="padding: 4px 6px 4px 6px;">JJ</span>
              `
              :
              ``
            }
            ${
              row?.relanceManuele ? 
              `
                <span class="badge badge-danger" style="padding: 4px 6px 4px 6px;">M</span>
              `
              :
              ``
            }
          </div>
        `,
      }
    ]));
    setTimeout(() => {
      this.initRelanceCss();
    }, 3000)
    return list;

  }

  initRelanceCss() {
    var css = document.createElement('link');
    css.id ='bordereauId';
    css.href = "assets/css/bordereau.css";
    css.rel = "stylesheet";
    document.head.append(css);
  }

  handleOk(): void {
    this.isRelancePanelVisibled = false;
  }

  handleCancel(): void {
    this.isRelancePanelVisibled = false;
  }

  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getQuittanceById(action?.value);
      break;
      case 'delete':
        this.getQuittances();
      break;
    }
  }

  getQuittanceById(id: string) {
    this.dataService.getQuittanceById(id)
    .subscribe(
      (data: any) => {
        this.initForm(data?.data?.quittance);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.initForm();
  }

  initForm(data: any = {}) {
    if (data?.id) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      {
        code: "prenom",
        placeholder: "Prénom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.prenom),
        defaultValue: data?.prenom,
        size: 'col-md-6'
      },
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.nom),
        defaultValue: data?.nom,
        size: 'col-md-6'
      },
      {
        code: "designation",
        placeholder: "Désignation",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        defaultValue: data?.designation,
        size: 'col-md-12'
      },
      {
        code: "telephone",
        placeholder: "Téléphone",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        defaultValue: data?.telephone,
        size: 'col-md-6'
      },
      {
        code: "tauxCommission",
        placeholder: "Taux de commission",
        typeChamp: "NUMBER",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.tauxCommission),
        defaultValue: data?.tauxCommission,
        size: 'col-md-6'
      },
    ]
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
  }









  listOfSelection = [
    {
      text: 'Select All Row',
      onSelect: () => {
        this.onAllChecked(true);
      }
    },
    {
      text: 'Select Odd Row',
      onSelect: () => {
        this.listOfCurrentPageData.forEach((data, index) => this.updateCheckedSet(data.id, index % 2 !== 0));
        this.refreshCheckedStatus();
      }
    },
    {
      text: 'Select Even Row',
      onSelect: () => {
        this.listOfCurrentPageData.forEach((data, index) => this.updateCheckedSet(data.id, index % 2 === 0));
        this.refreshCheckedStatus();
      }
    }
  ];
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly any[] = [];
  listOfData: readonly any[] = [];
  setOfCheckedId = new Set<number>();
  listOfDataChecked: any = [];

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
      this.listOfDataChecked = [
        ...this.listOfDataChecked,
        id
      ];
    } else {
      this.setOfCheckedId.delete(id);
      this.listOfDataChecked = this.listOfDataChecked?.filter((checkedId: any) => checkedId != id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly any[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  expandSet = new Set<number>();

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  listOfDataR = [
    {
      id: 1,
      name: 'John Brown',
      age: 32,
      expand: false,
      address: 'New York No. 1 Lake Park',
      description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.'
    },
    {
      id: 2,
      name: 'Jim Green',
      age: 42,
      expand: false,
      address: 'London No. 1 Lake Park',
      description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.'
    },
    {
      id: 3,
      name: 'Joe Black',
      age: 32,
      expand: false,
      address: 'Sidney No. 1 Lake Park',
      description: 'My name is Joe Black, I am 32 years old, living in Sidney No. 1 Lake Park.'
    }
  ];






}