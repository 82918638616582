import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-categorie-actes',
  templateUrl: './categorie-actes.component.html',
  styleUrls: ['./categorie-actes.component.css']
})
export class CategorieActesComponent implements OnInit {

  headers : any = [];
  allHeaders : any = [];
  filteredHeader : any = [];
  formulaires : any = [];
  searchColumnText : string = '';
  categoriesActes : any = [];
  specialites : any = [];

  totalCount : number = 1;
  currentPage : number = 1;
  searchText : string = '';
  pageCount : number = 5;
  modelName : string = "CategorieActe"
  filter : string = '';
  headerInitialed : boolean = false;
  filterArray : any = [];

  isFormOpened : boolean = false;
  formType: string = 'ADD';
  currentId : string = '';



  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter une catégorie d'acte médical",
      titre: "Catégorie d'acte médical",
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  constructor(
    private dataService : DataService,
    private message : NzMessageService
  ) {}

  ngOnInit(): void {
    this.getCategoriesActes();
    this.getAllSpecialites();
  }

  initializeHeaders() {
    this.headers = [
      {
        name: 'Nom',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]

      },
      {
        name: 'Spécialité',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null],
        dataFilter: [
          ...this.specialites.map((specialite: any) => {
            return { text: specialite?.nom, value: specialite?.id }
          }),
        ],
        filterFn: (list: string[], item: any) => {
          var oldFilter: string = '';
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('specialite') == -1) {
            this.filterArray.push({
              key: 'specialite',
              value: ''
            })
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('specialite');
            oldFilter = this.filterArray[index].value;
          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('specialite');


          if (!list.length) {
            
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'specialite');
            this.handleFilter();
          }
          else {            
            this.filterArray[index].value = `
              specialite :{
                id : {
                  in :[
                    ${
                      list?.map((specialite : any) => `"${specialite}"`)
                    }
                  ]
                }
              }
            `;
          if (oldFilter != this.filterArray[index].value) {
            this.handleFilter();
          }
          }
        }
        

      },
      // {
      //   name: 'Actes médicaux',
      //   position: 'left',
      //   actif: true,
      //   sortDirections: ['ascend', 'descend', null]

      // },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.allHeaders = this.headers;
    this.filteredHeader = this.headers;
  }

  handleFilter() {
    var _filter = `
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getCategoriesActes();
  }
  

  headersActif() : number {
    return  this.filteredHeader?.filter((header : any )=> header?.actif)?.length - 1 ;
  }

  searchColumn(){
    this.headers = this.allHeaders?.filter((header : any )=> header?.name.toLowerCase()?.includes(this.searchColumnText.toLowerCase()));
  }

  handleColumnChanged(){
    this.categoriesActes = this.categoriesActes();
  }


  onSearchTextChanged(value : string){
    this.searchText = value;
    this.getCategoriesActes();

  }

  getCategoriesActes(){
    this.dataService.getCategoriesActes(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.categoriesActes = this.handleList(data?.data?.categorieActes)
        this.totalCount = data?.data?.categorieActesCount;
        if (!this.headerInitialed) {
          this.initializeHeaders();
          this.headerInitialed = true;
        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }


  getAllSpecialites() {  

    this.dataService.getAllSpecialites()
    .subscribe(
      (data: any) => {
        this.specialites = data?.data?.specialites;
        this.initializeHeaders();
        // let specialite: any = this.formulaires.find((form: any) => form?.code == 'specialite');
        // entreprise.options = this.specialites?.map((entrep: any) => {
        //   return {
        //     cle: entrep?.id,
        //     valeur: entrep?.raisonSociale
        //   }
        // })
      },
      (err: any) => {
        console.log(err);
      },
    )
  }


  formulaireEvent(data : any){
    if (this.formType == 'ADD') {
      this.dataService.createCategorieActe(
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getCategoriesActes();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout ${err}`);
        },
      )
    }
    else {
      this.dataService.updateCategorieActe(
        this.currentId,
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getCategoriesActes();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de la modification`);
        },
      )
    }

  }

  handleList(data : any){


    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.specialite?.nom,
        position: 'left',

      },
      // {
      //   type: (row?.acteMedicaux.length <= 0) ? 'HTML' : 'LIST',
      //   value:  (row?.acteMedicaux.length <= 0) 
      //     ? `
         
      //     ` 
      //     : row?.acteMedicaux?.map((acte : any) => acte?.nom),
      //   position: 'left',
      // },

      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  onAction(action : any){

    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getCategorieActeById(action?.value);
      break;
      case 'delete':
        this.getCategoriesActes();
      break;
    }

  }

  getCategorieActeById(id : string){
    this.dataService.getCategorieActeById(id)
    .subscribe(
      (data: any) => {
        this.initForm(data?.data?.categorieActe);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )


  }

  // onUploadEvent(upload: string) {
  //   setTimeout(() => {
  //     this.currentPage = 1;
  //     this.getCategoriesActes();
  //   })
  // }


  onTableUpdate(value:any){
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getCategoriesActes();
  }

  openAddForm(){
    this.isFormOpened = true;
    this.initForm();
  }
  initForm(data: any = {}) {

    if (data?.id) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.nom),
        defaultValue: data?.nom,
        size: 'col-md-12'
      },
      {
        code: "specialite",
        placeholder: "Spécialité",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        obligatoire: !!(this.formType == 'ADD' || data?.specialite?.id),
        options: this.specialites?.map((data: any) => {
          return {
            cle: data.id,
            valeur: data.nom
          }
        }),
        defaultValue:data?.specialite?.id,
        size: 'col-md-12'
      },
     
    ]
  }



  onCloseForm(){
    this.isFormOpened = !this.isFormOpened;
  }






}
