<!--**********************************
    Chat box start
***********************************-->
<div class="chatbox active" id="chatbox" [ngStyle]="{'width': formWidth}">
    <div class="chatbox-close" [ngStyle]="{'right': formWidth, 'opacity': opacity}"></div>
    <div class="custom-tab-1">
        <ul class="nav nav-tabs">
            <li [ngStyle]="{'text-align': 'center', 'width': formTab?.length ? (100/formTab?.length + '%') : ('100%')}" class="nav-item" *ngFor="let tab of formTab">
                <a (click)="selectedTab = tab" [ngClass]="{'nav-link': true, 'active': tab?.code == selectedTab?.code}" data-toggle="tab" href="#chat">{{tab?.nom}}</a>
            </li>
        </ul>
        <div class="tab-content">
            
            <div class="tab-pane fade active show" id="chat" role="tabpanel">
                <div class="card mb-sm-3 mb-md-0 contacts_card dz-chat-user-box">
                    <div class="card-header chat-list-header text-center">
                        <a href="javascript:void(0)"></a>
                        <div>
                            <h6 class="mb-1">{{selectedTab?.titre}}</h6>
                            <p class="mb-0">{{selectedTab?.soustitre}}</p>
                        </div>
                        <a href="javascript:void(0)"></a>
                    </div>
                    <!-- <nz-spin [nzTip]="spinText" [nzSpinning]="isFormLoading"> -->
                        <div class="card-body contacts_body p-0 dz-scroll  " id="DZ_W_Contacts_Body">
                            <ul class="contacts">
                                <li class="name-first-letter"></li>
                            </ul>
                            <div *ngIf="selectedTab?.type == 'CHARGEMENT'">
                                <nz-upload
                                    nzType="drag"
                                    [nzAccept]="'xlsx'"
                                    [nzMultiple]="false"
                                    name="files"
                                    (nzChange)="handleChange($event)"
                                    [nzDisabled]="uploading"
                                    [nzAction]="'#'"
                                    >
                                    <h1>Deposez votre fichier</h1>
                                    <p class="ant-upload-drag-icon">
                                        <ng-lottie [styles]="{height: '360px'}" [options]="uploadOptions"></ng-lottie>
                                    </p>
                                    <p class="ant-upload-text">Cliquez ou faites glisser le fichier dans cette zone pour faire le chargement</p>
                                    <p class="ant-upload-hint">
                                        Veuillez respecter le format du template
                                        <img class="template" src="{{selectedTab?.templateImage}}" alt="">
                                    </p>
                                </nz-upload>
                            </div>
    
    
                            <div *ngIf="selectedTab?.type == 'AJOUT'" class="row">
                                <nz-steps *ngIf="handleStepsCount()?.length > 1" [nzCurrent]="currentStep" class="steps-line mt-3">
                                    <nz-step *ngFor="let step of handleStepsCount(); let i=index" [nzTitle]="currentStep > i ? 'Fait' : (currentStep == i ? 'En cours' : 'En attente')"></nz-step>
                                </nz-steps>
    
                                <div class="col-xl-12">
                                    <div class="card details-card scroll-y">
                                        <div class="card-body">
                                            <div class="d-sm-flex mb-3">
                                                <div class="card-info d-flex align-items-start">
                                                    <div class="center-lottie">
                                                        <h3 class="font-w600 mb-2 margin-20 text-black center price-bottom">
                                                            {{formType == 'ADD' ? 'Ajout' : 'Modification'}} {{modelName}}
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div class="font-w600 mb-2 margin-20 text-black center price-bottom">
                                                    <button *ngFor="let button of buttons" [disabled]="disableButtons" (click)="button?.onClick()" href="javascript:void(0)" class="btn btn-primary mr-3"><i class="fa {{button?.icon}}"></i></button>
                                                </div>
                                            </div>
                                            <!-- <h4 class="fs-20 text-black font-w600">Story About Disease</h4> -->
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="basic-form">
                                                        <form [formGroup]="formulaire" (ngSubmit)="submitFormulaire()">
                                                            <div class="form-row">
                                                                <div *ngFor="let champ of formulaires; let i=index"  class="{{(!champ?.etape && currentStep == 0) || champ?.etape -1 == currentStep ? 'form-group' : ''}} {{champ?.size}}">
                                                                    <ng-container *ngIf="((!champ?.etape && currentStep == 0) || champ?.etape -1 == currentStep) && !(formType != 'ADD' && champ?.creationOnly)">
                                                                        
                                                                        <!-- Tarification -->
                                                                        <app-create-souscription (closeEmiter)="closeTarification($event)" *ngIf="champ?.typeReponse == 'WRITE' && champ?.typeChamp == 'TARIFICATION' && !champ?.hidden"></app-create-souscription>
                                                                        <!-- <app-tarification *ngIf="champ?.typeReponse == 'WRITE' && champ?.typeChamp == 'TARIFICATION' && !champ?.hidden"></app-tarification> -->

                                                                        <!-- Champ TEXT -->
                                                                        <label *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'TEXT' || champ?.typeChamp == 'NUMBER' || champ?.typeChamp == 'EMAIL' || champ?.typeChamp == 'TELEPHONE') && !champ?.hidden && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire?.value[champ?.visilityDependsOn]))">{{champ?.placeholder}}{{champ?.obligatoire ? '*' : ''}} {{champ?.step}}</label>
                                                                        <input (keyup)="champ?.keyup ? champ?.keyup(formulaire) : ras()" (change)="champ?.keyup ? champ?.keyup(formulaire) : ras()" [readonly]="champ?.readOnly" *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'TEXT' || champ?.typeChamp == 'NUMBER' || champ?.typeChamp == 'EMAIL' || champ?.typeChamp == 'TELEPHONE') && !champ?.hidden && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire?.value[champ?.visilityDependsOn]))" class="form-control" [formControlName]="champ?.code" [name]="champ?.code" [type]="getAdequateType(champ?.typeChamp)" [placeholder]="champ?.placeholder" [required]="champ?.obligatoire && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire?.value[champ?.visilityDependsOn]))">
                                                                        <span class="errorMessage" *ngIf="(champ?.typeReponse == 'WRITE') && champ?.errorMessage && (champ?.typeChamp == 'TEXT' || champ?.typeChamp == 'NUMBER' || champ?.typeChamp == 'EMAIL' || champ?.typeChamp == 'TELEPHONE') && !champ?.hidden && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire?.value[champ?.visilityDependsOn]))">{{champ?.errorMessage}}</span>
                                                                        <span class="infoMessage" *ngIf="(champ?.typeReponse == 'WRITE') && champ?.info && (champ?.typeChamp == 'TEXT' || champ?.typeChamp == 'NUMBER' || champ?.typeChamp == 'EMAIL' || champ?.typeChamp == 'TELEPHONE') && !champ?.hidden && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire?.value[champ?.visilityDependsOn]))">{{champ?.info}}</span>
    
                                                                        <!-- Champ TEXT AREA -->
                                                                        <label *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'TEXTAREA') && !champ?.hidden">{{champ?.placeholder}} {{champ?.obligatoire ? '*' : ''}}</label>
                                                                        <textarea *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'TEXTAREA') && !champ?.hidden" [rows]="champ?.rows ? champ?.rows : '4'" [readonly]="champ?.readOnly" nz-input [formControlName]="champ?.code" [name]="champ?.code"></textarea>
                                                                        <span class="infoMessage" *ngIf="(champ?.typeReponse == 'WRITE') && champ?.info && (champ?.typeChamp == 'TEXTAREA') && !champ?.hidden">{{champ?.info}}</span>
    
                                                                        <!-- Champ Date -->
                                                                        <label *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'DATE') && !champ?.hidden">{{champ?.placeholder}} {{champ?.obligatoire ? '*' : ''}}</label>
                                                                        <input [readonly]="champ?.readOnly" *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'DATE') && !champ?.hidden" [formControlName]="champ?.code" [name]="champ?.code" [type]="getAdequateType(champ?.typeChamp)" class="form-control" [placeholder]="champ?.placeholder" [required]="champ?.obligatoire">
                                                                        <span class="errorMessage" *ngIf="champ?.errorMessage && (champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'DATE') && !champ?.hidden">{{champ?.errorMessage}}</span>
                                                                        <span class="infoMessage" *ngIf="champ?.info && (champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'DATE') && !champ?.hidden">{{champ?.info}}</span>
                                     
                                                                        <!-- Champ Select -->
                                                                        <label *ngIf="champ?.typeReponse == 'OPTION' && !champ?.multiple && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire?.value[champ?.visilityDependsOn])) && !champ?.hidden">{{champ?.placeholder}} {{champ?.obligatoire ? '*' : ''}}</label>
                                                                        <div nz-row *ngIf="!champ?.hidden">
                                                                            <div nz-col nzFlex="auto">
                                                                                <nz-select [nzDisabled]="champ?.readOnly" nzShowSearch nzAllowClear (nzOnSearch)="champ?.onSearch ? champ?.onSearch($event) : ras()" (ngModelChange)="champ?.onChange ? champ?.onChange(formulaire.value[champ?.code], formulaire) : ras()" *ngIf="champ?.typeReponse == 'OPTION' && !champ?.multiple && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire.value[champ?.visilityDependsOn]))" [nzPlaceHolder]="champ?.placeholder" [formControlName]="champ?.code" class="form-control default-select">
                                                                                    <nz-option *ngFor="let option of champ?.options" [nzLabel]="option?.valeur" [nzValue]="option?.cle"></nz-option>
                                                                                </nz-select>
                                                                            </div>
                                                                            <div *ngIf="champ?.onAfter" nz-col>
                                                                                <button [disabled]="champ?.readOnly && !champ?.activeOnAfter" (click)="champ?.onAfter(formulaire)" [ngClass]="{'btn': true, 'shadow': true, 'btn-primary': true}" style="height: 56px; width: 100%;">
                                                                                    <i [ngClass]="{'fa': true, 'fa-plus': true}"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <span class="errorMessage" *ngIf="champ?.errorMessage && champ?.typeReponse == 'OPTION' && !champ?.multiple  && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire?.value[champ?.visilityDependsOn])) && !champ?.hidden">{{champ?.errorMessage}}</span>
                                                                        <span class="infoMessage" *ngIf="champ?.info && champ?.typeReponse == 'OPTION' && !champ?.multiple  && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire?.value[champ?.visilityDependsOn])) && !champ?.hidden">{{champ?.info}}</span>
    
                                                                        <!-- Champ SelectMultiple -->
                                                                        <label *ngIf="champ?.typeReponse == 'OPTION' && champ?.multiple && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire?.value[champ?.visilityDependsOn])) && !champ?.hidden">{{champ?.placeholder}} {{champ?.obligatoire ? '*' : ''}}</label>
                                                                        <nz-select [nzDisabled]="champ?.readOnly" [nzMaxMultipleCount]="champ?.limit ?? 10000000" nzShowSearch nzAllowClear (nzOnSearch)="champ?.onSearch ? champ.onSearch($event) : ras()" (ngModelChange)="champ?.onChange ? champ?.onChange(formulaire.value[champ?.code], formulaire) : ras()" *ngIf="champ?.typeReponse == 'OPTION' && champ?.multiple && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire.value[champ?.visilityDependsOn])) && !champ?.hidden" [nzPlaceHolder]="champ?.placeholder" [formControlName]="champ?.code" class="form-control default-select multiple-select" nzMode="tags" >
                                                                            <nz-option *ngFor="let option of champ?.options" [nzLabel]="option?.valeur" [nzValue]="option?.cle"></nz-option>
                                                                        </nz-select>
                                                                        <span class="errorMessage" *ngIf="champ?.errorMessage && champ?.typeReponse == 'OPTION' && champ?.multiple  && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire?.value[champ?.visilityDependsOn])) && !champ?.hidden">{{champ?.errorMessage}}</span>
                                                                        <span class="infoMessage" *ngIf="champ?.info && champ?.typeReponse == 'OPTION' && champ?.multiple  && (!champ?.visilityDependsOn || (champ?.visilityDependsOn && formulaire?.value[champ?.visilityDependsOn])) && !champ?.hidden">{{champ?.info}}</span>
    
                                                                        <!-- Champ Table -->
                                                                        <label *ngIf="champ?.typeReponse == 'TABLE' && !champ?.hidden">{{champ?.placeholder}} {{champ?.obligatoire ? '*' : ''}}</label>
                                                                        <app-table-type 
                                                                            *ngIf="champ?.typeReponse == 'TABLE' && !champ?.hidden"
                                                                            [table]="champ" 
                                                                            [uploadIncluded]="champ?.uploadIncluded"
                                                                            (onTableEvent)="getTableValues($event)"
                                                                            >
                                                                        </app-table-type>
                                                                        <span class="errorMessage" *ngIf="champ?.errorMessage && champ?.typeReponse == 'TABLE' && !champ?.hidden">{{champ?.errorMessage}}</span>
                                                                        <span class="infoMessage" *ngIf="champ?.info && champ?.errorMessage && champ?.typeReponse == 'TABLE' && !champ?.hidden">{{champ?.info}}</span>
                                                                        
                                                                        <!-- Champ Table Edit Select -->
                                                                        <label *ngIf="champ?.typeReponse == 'champ?.typeReponse == 'TABLE_EDIT_SELECT' && !champ?.hidden' && !champ?.hidden">{{champ?.placeholder}} {{champ?.obligatoire ? '*' : ''}}</label>
                                                                        <app-table-edit-select 
                                                                            *ngIf="champ?.typeReponse == 'TABLE_EDIT_SELECT' && !champ?.hidden"
                                                                            (onTableEvent)="getTableEditValues($event)"
                                                                            [table]="champ" 
                                                                        ></app-table-edit-select>
                                                                        <span class="errorMessage" *ngIf="champ?.errorMessage && champ?.typeReponse == 'TABLE_EDIT_SELECT' && !champ?.hidden">{{champ?.errorMessage}}</span>
                                                                        <span class="infoMessage" *ngIf="champ?.info && champ?.errorMessage && champ?.typeReponse == 'TABLE_EDIT_SELECT' && !champ?.hidden">{{champ?.info}}</span>

                                                                        <!-- Champ Time Picker -->
                                                                        <!-- <nz-time-picker [nzInputReadOnly]="champ?.readOnly" *ngIf="champ?.typeReponse == 'WRITE' && champ?.typeChamp == 'TIME' && !champ?.hidden" [formControlName]="champ?.code" class="right-datepicker border-datepicker" [nzAutoFocus]="true" [nzDefaultOpenValue]="defaultOpenValue"></nz-time-picker>  -->
    
                                                                        <!-- Champ Checkbox -->
                                                                        <label [nzDisabled]="champ?.disabled ? true : false" (ngModelChange)="champ?.onChange ? champ?.onChange($event, formulaire) : ras()" [formControlName]="champ?.code" *ngIf="champ?.typeChamp == 'CHECKBOX' && !champ?.hidden" nz-checkbox [(ngModel)]="formulaire.value[champ?.code]">{{champ?.placeholder}}</label>
                                                                        
                                                                        <!-- Champ Time Picker -->
                                                                        <nz-time-picker [nzInputReadOnly]="champ?.readOnly" *ngIf="champ?.typeReponse == 'WRITE' && champ?.typeChamp == 'TIME' && !champ?.hidden" [formControlName]="champ?.code" class="right-datepicker border-datepicker" [nzAutoFocus]="true" [nzDefaultOpenValue]="defaultOpenValue"></nz-time-picker> 
                                
                                                                        <!-- champ Slider -->

                                                                        <!-- Payment -->
                                                                        <app-payment (onPayementFinished)="onPayementFinished($event)" (onPaymentStatus)="onPaymentStatus($event)" *ngIf="champ?.typeReponse == 'WRITE' && champ?.typeChamp == 'PAIEMENT' && isRetrying && !champ?.hidden" [totalApayer]="champ?.value" [usefullInfo]="champ?.usefullInfo"></app-payment>

                                                                        <!-- Plan -->
                                                                        <!-- <app-plan></app-plan> -->


                                                                        <label  *ngIf="champ?.typeReponse == 'WRITE' && (champ?.typeChamp == 'TINYMICE') && !champ?.errorAnswer && !champ?.hidden">{{champ?.placeholder}} {{champ?.obligatoire ? '*' : ''}}</label>
                                                                        <angular-editor *ngIf="champ?.typeReponse == 'WRITE' && (champ?.typeChamp == 'TINYMICE') && !champ?.errorAnswer && !champ?.hidden" [formControlName]="champ?.code" [config]="editorConfig" [placeholder]="'Enter text here...'"></angular-editor>
                                                                        
                                                                        <label *ngIf="champ?.typeReponse == 'WRITE' && (champ?.typeChamp == 'IMAGE' || champ?.typeChamp == 'FICHIER') && !champ?.errorAnswer && !champ?.hidden">{{champ?.placeholder}} {{champ?.obligatoire ? '*' : ''}}</label>
                                                                        <div *ngIf="champ?.typeReponse == 'WRITE' && (champ?.typeChamp == 'IMAGE' || champ?.typeChamp == 'FICHIER') && !champ?.errorAnswer && !champ?.hidden" class="clearfix">
                                                                            <!-- <nz-upload
                                                                            nzListType="picture"
                                                                            [formControlName]="champ?.code"
                                                                            (nzChange)="fileUploaded($event, champ)"
                                                                            [(nzFileList)]="fileList"
                                                                            >
                                                                            <button nz-button>
                                                                                <span nz-icon nzType="upload"></span>
                                                                                Upload
                                                                            </button>
                                                                            </nz-upload> -->
                                                                            <nz-upload
                                                                                nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                                                nzListType="picture-card"
                                                                                [(nzFileList)]="fileList"
                                                                                (nzChange)="champ?.change ? champ?.change($event, formulaire) : ras()"
                                                                                [nzShowButton]="fileList.length < champ?.max ?? 1"
                                                                                [nzPreview]="handlePreview"
                                                                            >
                                                                                <div>
                                                                                <span nz-icon nzType="plus"></span>
                                                                                <div style="margin-top: 8px">Upload</div>
                                                                                </div>
                                                                            </nz-upload>
                                                                            <nz-modal
                                                                                [nzVisible]="previewVisible"
                                                                                [nzContent]="modalContent"
                                                                                [nzFooter]="null"
                                                                                (nzOnCancel)="previewVisible = false"
                                                                            >
                                                                                <ng-template #modalContent>
                                                                                <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                                                                                </ng-template>
                                                                            </nz-modal>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                            
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
    
                        </div>
                        <div class="card-header chat-list-header text-center">
                            <div class="margin-auto float-right__ d-flex bd-highlight center-block items-center">
                                <button *ngIf="!formulaire.value['readOnly'] && selectedTab?.type != 'CHARGEMENT'" (click)="closeForm()" type="button" class="btn btn-light mr-3">
                                    Annuler
                                    <span class="btn-icon-left"><i class="fa fa-arrow-left"></i></span>
                                </button>
                                <button *ngIf="formulaire.value['readOnly'] || selectedTab?.type == 'CHARGEMENT'" (click)="closeForm()" type="button" class="btn btn-light mr-3">
                                    Retour
                                    <span class="btn-icon-left"><i class="fa fa-arrow-left"></i></span>
                                </button>
                                <!-- <button type="button" class="btn btn-light mr-3">
                                    Précedent
                                    <span class="btn-icon-left"><i class="fa fa-arrow-left"></i></span>
                                </button> -->
                                <!-- <button *ngIf="selectedTab?.type == 'CHARGEMENT'" (click)="handleUpload()" class="btn btn-primary">
                                    Charger 
                                    <span class="btn-icon-right"><i class="fa fa-arrow-right"></i></span>
                                </button> -->
                                <!-- <button *ngIf="selectedTab?.type != 'CHARGEMENT'" class="btn btn-primary">
                                    Suivant 
                                    <span class="btn-icon-right"><i class="fa fa-arrow-right"></i></span>
                                </button> -->
    
                                <button (click)="prev()" nz-button nzType="default" *ngIf="currentStep > 0" type="button" class="btn btn-light mr-3">
                                    Précedent
                                    <span class="btn-icon-left"><i class="fa fa-arrow-left"></i></span>
                                </button>
    
                                <button (click)="infoPoliceClicked()" *ngIf="formulaireBrancheExist && formulaire.value['branche']" nz-button nzType="default" class="btn btn-primary">
                                    {{formulaireBrancheLabel}}
                                    <span class="btn-icon-right"><i class="fa fa-arrow-right"></i></span>
                                </button>
    
                                <button [disabled]="!isStepValid() || (formulaireBrancheExist  && formulaire.value['branche'] && !formulaire.value['infoPoliceCorrect'])" (click)="next()" *ngIf="selectedTab?.type != 'CHARGEMENT' && currentStep < handleStepsCount()?.length -1" nz-button nzType="default" class="btn btn-primary">
                                    Suivant
                                    <span class="btn-icon-right"><i class="fa fa-arrow-right"></i></span>
                                </button>
    
                                <button nz-button nzType="primary" (click)="handleRetry()" type="submit" *ngIf="selectedTab?.type != 'CHARGEMENT' && currentStep === handleStepsCount()?.length -1 && retryPayment" class="btn btn-primary">
                                    Réessayer 
                                    <span class="btn-icon-right"><i class="fa fa-arrow-right"></i></span>
                                </button>
    
                                <button nz-button nzType="primary" [disabled]="loading || !(formulaire.status == 'VALID' || paymentStatus)" (click)="submitClicked()" type="submit" *ngIf="!formulaire.value['readOnly'] && selectedTab?.type != 'CHARGEMENT' && currentStep === handleStepsCount()?.length -1 && !retryPayment" class="btn btn-primary">
                                    <span *ngIf="loading" style="color: white;" nz-icon nzType="loading"></span>
                                    Valider 
                                    <span class="btn-icon-right"><i class="fa fa-arrow-right"></i></span>
                                </button>
                            </div>
                        </div>
                    <!-- </nz-spin> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!--**********************************
    Chat box End
************************************** -->