import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Apollo, ApolloBase, gql} from 'apollo-angular';
import API from './api';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  // private apollo: ApolloBase;

  constructor(private apolloProvider: Apollo, private httpClient: HttpClient) {
    // this.getRoles();
  }

  emplacements: any = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  setUser(user: any) {
    localStorage.setItem('userABP', JSON.stringify(user));
  }

  getUser() {
    const user: any = localStorage.getItem('userABP') || '{}';
    return JSON.parse(user);
  }

 


  // getEntrepriseId() {
  //   const user: any = localStorage.getItem('userABP') || '{}';
  //   return JSON.parse(user?.entreprise?.id);
  // }

  setToken(token: any) {
    localStorage.setItem('tokenABP', token);
  }

  getToken() {
    return localStorage.getItem('tokenABP');
  }

  enableForRoles(roles: any) {
    return roles?.some((role: any)=> this.getRoles().includes(role))
  }

  logout() {
    localStorage.removeItem('tokenABP');
    localStorage.removeItem('userABP');
  }

  getRoles() {
    const user: any = localStorage.getItem('userABP') || '{}';
    return JSON.parse(user)?.roles;
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append("files", file);
    return this.httpClient.post(`${API.baseUrl}/upload_files`, file, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=MyBoundary"
      }
    })
  }

  webPush() {
    return this.httpClient.post(`${API.baseUrl}/subscribe`, 'https://api.assurtech.org')
  }

  getTypeFinancement() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      {
        typeFinancements (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            plateformes: {
              some: {
                code: {
                  equals: ADMIN
                }
              }
            }
            actif: {
              equals: true
            }
          }
          orderBy: {
            ordre: asc
          }
        ) {
          id
          nom
          icon
          domaine
          imageSize
          formulaire {
            id
            nom
            titre
            infoContrats (
                where: {
                  actif: {
                    equals: true
                  }
                }
                orderBy: {
                  ordre: asc
                }
              )  {
              id
              inputKey
              sheetName
              champFormulaire {
                id
                code
                placeholder
                typeReponse
                obligatoire
                typeChamp
                options {
                  id
                  cle
                  valeur
                }
                taille
                ordre
                config
              }
            }
          }
          image {
            filename
          }
          taxe
          risques (
            where: {
              actif: {
                equals: true
              }
            }
          ) {
            id
            nom
            icon
            image {
              filename
            }
            obligatoire
            garanties {
              id
              nom
            }
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getTotaux() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      {
        policesCount
        quittancesCount
        avenantsCount
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getQuittanceCommule(date: any, compagnies: any = null) {
    var compStr = `[`;
    compagnies?.forEach((value: any) => {
      compStr += ` "${value?.id}"`
    });
    compStr += `]`;
    const request = this.apolloProvider.watchQuery({
      query: gql`
      {
        quittances (
          where: {
            dateCreation: {
              gte: "${date[0]?.toISOString()?.split('T')[0]?.split('Z')[0]}"
              lte: "${date[1]?.toISOString()?.split('T')[0]?.split('Z')[0]}"
            }
          }
        ) {
          primeNette,
          primeTotale
          accessoire
          dateCreation
          taxe
          police {
            id
            compagnie {
              id
            }
            risque {
              id
            }
          }
        }
        accessoires {
          compagnie {
            id
          }
          risque {
            id
          }
          tauxCommissionPrime
          tauxCommissionAccessoire
        }
        compagnies (
           where: {
            polices: {
              some: {
                quittances: {
                  some: {
                    dateCreation: {
                      gte: "${date[0]?.toISOString()?.split('T')[0]?.split('Z')[0]}"
                      lte: "${date[1]?.toISOString()?.split('T')[0]?.split('Z')[0]}"
                    }
                  }
                }
              }
            }
          }
        ) {
          id
          raisonSociale
          polices {
            id
            compagnie {
              id
            }
            risque {
              id
              branche {
                id,
                nom
              }
            }
            quittancesCount
            quittances {
              primeNette,
              primeTotale
              accessoire
              dateCreation
              taxe
            }
          }
        }

        branches (
          where: {
            risques: {
              some: {
                polices: {
                  some: {
                    ${
                      compagnies ?
                      `
                        compagnie: {
                          id: {
                            in: ${compStr}
                          }
                        }
                      `
                      : 
                      ``
                    }
                    quittances: {
                      some: {
                        primeTotale: {
                          gte: 0
                        }
                        dateCreation: {
                          gte: "${date[0]?.toISOString()?.split('T')[0]?.split('Z')[0]}"
                          lte: "${date[1]?.toISOString()?.split('T')[0]?.split('Z')[0]}"
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        ) {
          id
          nom
          risques  (
            where: {
              polices: {
                some: {
                  ${
                    compagnies ?
                    `
                      compagnie: {
                        id: {
                          in: ${compStr}
                        }
                      }
                    `
                    : 
                    ``
                  }
                  quittances: {
                    some: {
                      primeTotale: {
                        gte: 0
                      }
                      dateCreation: {
                        gte: "${date[0]?.toISOString()?.split('T')[0]?.split('Z')[0]}"
                        lte: "${date[1]?.toISOString()?.split('T')[0]?.split('Z')[0]}"
                      }
                    }
                  }
                }
              }
            }
          )  {
            id
            nom
            polices {
              id
              police
              compagnie {
                id
              }
              quittances {
                id
                primeNette
                accessoire
                taxe
                primeTotale
              }
            }
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getFormulaireOfBranche(id: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      {
        branche (
          where: {
            id: "${id}"
          }
        ) {
          id
          nom
          formulaire {
            id
            nom
            titre
            branche {
              id
              nom
            }
            champs {
              id
              code
              placeholder
              typeReponse
              obligatoire
              infoRelance
              typeChamp
              options {
                id
                cle
                valeur
              }
              taille
              ordre
              config
            }
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getPaymenMethod() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        methodePaiements (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            actif: {
              equals: true
            }
          }
          orderBy: {
            ordre: asc
          }
        ) {
          id
          ordre
          nom
          type
          image {
            id
            url
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getWavePayment(montant: number) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
      mutation {
        wavePayment(
          montant: ${montant}
        
        )
      }
      `
    });
    return request
  }

  getOMpayment(telephone: any, OTP: any, montant: number) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
      mutation {
        orangemoney(
          telephone: "${telephone}"
          OTP: "${OTP}"
          montant: ${montant}
        
        )
      }
      `
    });
    return request
  }

  savePaiement(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createPaiement(
            data: {
              intitule: "${data.intitule}"
              date: "${data.date}"
              montant: ${data.montant}
              ${
                data?.ordreCompagnie ? `ordreCompagnie: ${data.ordreCompagnie}` : ``
              }
              ${
                data?.libelle ? `libelle: "${data.libelle}"` : ``
              }
              ${
                data?.banque ? `banque: "${data.banque}"` : ``
              }
              ${
                data?.numero ? `numero: "${data.numero}"` : ``
              }
              ${
                data?.accompte ? `accompte: ${data.accompte}` : ``
              }
              ${
                data?.restantApayer ? `restantApayer: ${data.restantApayer}` : ``
              }
              ${
                data?.codeMarchantOM ? `codeMarchantOM: ${data.codeMarchantOM}` : ``
              }
              type: ${data.type}
              info: ${JSON.stringify(data)?.replace(/"([^"]+)":/g, '$1:')}
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  reglerImpaye(currentId: string, paymentId: string){
    const request = this.apolloProvider.mutate({
      mutation: gql`
      mutation {
        updateQuittance (
          where: {
            id: "${currentId}"
          }
          data: {
            paiements: {
              connect: {
                id: "${paymentId}"
              }
            }
          }
        )
        {
          id
        }
      }
      `
    });
    return request
  }

  getSouscriptions(page: number, filtre: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        souscriptions(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            agent: {
              id: {
                equals: "${this.getUser()?.id}"
              }
            }
            ${filtre}
          }
          skip: ${3 * (page - 1)}
          take: 3
          orderBy: {
            id: desc
          }
        ) {
          id
          date
          price
          status
          methodePaiement {
            id
            nom
          }
          agent {
            id
            prenom
            nom
            email
          }
          partenaire {
            id
            nom
            image {
              id
              url
            }
          }
          output
          resultat
          infoClient
          typeFinancement {
            id
            nom
            icon
            image {
              filename
              url
            }
            risques {
              id
              nom
              icon
               garanties {
                id
                nom
              }
            }
          }
        }
        souscriptionsCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            agent: {
              id: {
                equals: "${this.getUser()?.id}"
              }
            }
            ${filtre}
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getClients(page: number, pageCount: number, filtre: string, searchText: string='') {

    console.log(filtre);


    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        clients(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            
            ${filtre}
            OR: [
              {
                code: {
                  contains: "${searchText}"
                }
              }
              {
                prenom: {
                  contains: "${searchText}"
                }
              }
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                email: {
                  contains: "${searchText}"
                }
              }
              {
                emplacement: {
                  contains: "${searchText}"
                }
              }
              {
                entreprise: {
                  raisonSociale: {
                    contains: "${searchText}"
                  } 
                }
              }
              {
                contacts: {
                  some: {
                    contact: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          code
          prenom
          nom
          emplacement
          contacts {
            id
            contact
          }
          connexe
          email
          adresse
          entreprise {
            id
            raisonSociale
          }
        }
        clientsCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                code: {
                  contains: "${searchText}"
                }
              }
              {
                prenom: {
                  contains: "${searchText}"
                }
              }
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                email: {
                  contains: "${searchText}"
                }
              }
              {
                adresse: {
                  contains: "${searchText}"
                }
              }
              {
                emplacement: {
                  contains: "${searchText}"
                }
              }
              {
                entreprise: {
                  raisonSociale: {
                    contains: "${searchText}"
                  } 
                }
              }
              {
                contacts: {
                  some: {
                    contact: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getUtilisateurs(page: number, pageCount: number, filtre: string, searchText: string='') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        users(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                phoneNumber: {
                  contains: "${searchText}"
                }
              }
              {
                prenom: {
                  contains: "${searchText}"
                }
              }
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                email: {
                  contains: "${searchText}"
                }
              }
              {
                adresse: {
                  contains: "${searchText}"
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          phoneNumber
          prenom
          nom
          adresse
          email
          adresse
          roles
        }
        usersCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                phoneNumber: {
                  contains: "${searchText}"
                }
              }
              {
                prenom: {
                  contains: "${searchText}"
                }
              }
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                email: {
                  contains: "${searchText}"
                }
              }
              {
                adresse: {
                  contains: "${searchText}"
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getCompagnies(page: number, pageCount: number, filtre: string, searchText: string= '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        compagnies(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                code: {
                  contains: "${searchText}"
                }
              }
              {
                raisonSociale: {
                  contains: "${searchText}"
                }
              }
              {
                telephone: {
                  contains: "${searchText}"
                }
              }
              {
                responsable: {
                  contains: "${searchText}"
                }
              }
              {
                BP: {
                  contains: "${searchText}"
                }
              }
              {
                FAX: {
                  contains: "${searchText}"
                }
              }
              {
                codeCourtier: {
                  contains: "${searchText}"
                }
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  tauxAccessoires: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  tauxTaxe: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          raisonSociale
          code
          responsable
          telephone
          BP
          FAX
          codeCourtier
          tauxAccessoires
          tauxTaxe
        }
        compagniesCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                code: {
                  contains: "${searchText}"
                }
              }
              {
                raisonSociale: {
                  contains: "${searchText}"
                }
              }
              {
                telephone: {
                  contains: "${searchText}"
                }
              }
              {
                responsable: {
                  contains: "${searchText}"
                }
              }
              {
                BP: {
                  contains: "${searchText}"
                }
              }
              {
                FAX: {
                  contains: "${searchText}"
                }
              }
              {
                codeCourtier: {
                  contains: "${searchText}"
                }
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ?
                `
                {
                  tauxAccessoires: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ?
                `
                {
                  tauxTaxe: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getCompagnieClotures(page: number, pageCount: number, filtre: string, searchText: string= '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        compagnies(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            clotures: {
              some: {
                reference: {
                  not: {
                    equals: ""
                  }
                }
              }
            }
            ${filtre}
            OR: [
              {
                code: {
                  contains: "${searchText}"
                }
              }
              {
                raisonSociale: {
                  contains: "${searchText}"
                }
              }
              {
                telephone: {
                  contains: "${searchText}"
                }
              }
              {
                responsable: {
                  contains: "${searchText}"
                }
              }
              {
                BP: {
                  contains: "${searchText}"
                }
              }
              {
                FAX: {
                  contains: "${searchText}"
                }
              }
              {
                codeCourtier: {
                  contains: "${searchText}"
                }
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  tauxAccessoires: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  tauxTaxe: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          raisonSociale
          code
          responsable
          telephone
          BP
          FAX
          codeCourtier
          tauxAccessoires
          tauxTaxe
          cloturesCount
          clotures {
            id
            debut
            fin
            date
            reference
            montant
            banqueCheque
            chequeImage {
              id
              url
            }
            numeroCheque
            quittancesCount
          }
        }
        compagniesCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            clotures: {
              some: {
                reference: {
                  not: {
                    equals: ""
                  }
                }
              }
            }
            ${filtre}
            OR: [
              {
                code: {
                  contains: "${searchText}"
                }
              }
              {
                raisonSociale: {
                  contains: "${searchText}"
                }
              }
              {
                telephone: {
                  contains: "${searchText}"
                }
              }
              {
                responsable: {
                  contains: "${searchText}"
                }
              }
              {
                BP: {
                  contains: "${searchText}"
                }
              }
              {
                FAX: {
                  contains: "${searchText}"
                }
              }
              {
                codeCourtier: {
                  contains: "${searchText}"
                }
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ?
                `
                {
                  tauxAccessoires: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ?
                `
                {
                  tauxTaxe: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getBranches(page: number, pageCount: number, filtre: string, searchText: string= '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        branches(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                risques: {
                  some: {
                    nom: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          nom
          aRelancer
          risques {
            id
            nom
          }
        }
        branchesCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                risques: {
                  some: {
                    nom: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getCategorieAvenants(page: number, pageCount: number, filtre: string, searchText: string= '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        categorieAvenants(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                description: {
                  contains: "${searchText}"
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          nom
          description
          neccessitePaiement
        }
        categorieAvenantsCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                description: {
                  contains: "${searchText}"
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getApporteurs(page: number, pageCount: number, filtre: string, searchText: string = '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        apporteurs(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                prenom: {
                  contains: "${searchText}"
                }
              }
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                telephone: {
                  contains: "${searchText}"
                }
              }
              {
                designation: {
                  contains: "${searchText}"
                }
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  tauxCommission: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          prenom
          nom
          telephone
          designation
          tauxCommission
        }
        apporteursCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                prenom: {
                  contains: "${searchText}"
                }
              }
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                telephone: {
                  contains: "${searchText}"
                }
              }
              {
                designation: {
                  contains: "${searchText}"
                }
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  tauxCommission: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getRelancePonctuelles(page: number, pageCount: number, filtre: string, searchText: string = '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      {
        relancePonctuelles (
          where: {
            OR: [
              {
                prenom: {
                  contains: "${searchText}"
                }
              }
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                entreprise: {
                  contains: "${searchText}"
                }
              }
              {
                branche: {
                  contains: "${searchText}"
                }
              }
              {
                risque: {
                  contains: "${searchText}"
                }
              }
              {
                echeanche: {
                  contains: "${searchText}"
                }
              }
              {
                contacts: {
                  some: {
                    contact: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          date
          nom
          prenom
          entreprise
          branche
          risque
          echeanche
          contacts {
            id
            contact
          }
        }
        relancePonctuellesCount (
          where: {
            OR: [
              {
                prenom: {
                  contains: "${searchText}"
                }
              }
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                entreprise: {
                  contains: "${searchText}"
                }
              }
              {
                branche: {
                  contains: "${searchText}"
                }
              }
              {
                risque: {
                  contains: "${searchText}"
                }
              }
              {
                contacts: {
                  some: {
                    contact: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getPoliceSantes(page: number, pageCount: number, filtre: string, searchText: string = '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        entreprises (
          where: {
            polices: {
              some: {
                NOT: {
                  planSante: null
                }
              }
            }
            ${filtre}
            OR: [
              {
                raisonSociale: {
                  contains: "${searchText}"
                }
              }
              {
                polices: {
                  some: {
                    planSante: {
                      nom: {
                        contains: "${searchText}"
                      }
                    }
                  }
                }
              }
              {
                polices: {
                  some: {
                    police: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
              {
                polices: {
                  some: {
                    familles: {
                      some: {
                        qualiteMembres: {
                          some: {
                            OR: [
                              {
                                qualite: {
                                  nom: {
                                    equals: "${searchText}"
                                  }
                                }
                              }
                              {
                                membre: {
                                  OR: [
                                    {
                                      prenom: {
                                        contains: "${searchText}"
                                      }
                                    }
                                    {
                                      nom: {
                                        contains: "${searchText}"
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        }
                      }
                    }
                  }
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          raisonSociale
          polices  (
              where: {
                NOT: {
                  planSante: null
                }
              }
            ) {
            id
            police
            familles (
              where: {
                qualiteMembres: {
                  some: {
                    OR: [
                      {
                        qualite: {
                          nom: {
                            equals: "${searchText}"
                          }
                        }
                      }
                      {
                        membre: {
                          OR: [
                            {
                              prenom: {
                                contains: "${searchText}"
                              }
                            }
                            {
                              nom: {
                                contains: "${searchText}"
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              }
            ) {
              id
              nom
              qualiteMembres {
                id
                membre {
                  id
                  prenom
                  nom
                  dateNaissance
                  telephone
                }
                qualite {
                  id
                  nom
                }
              }
            }
          }
        }
        entreprisesCount (
          where: {
            polices: {
              some: {
                NOT: {
                  planSante: null
                }
              }
            }
            ${filtre}
            OR: [
              {
                raisonSociale: {
                  contains: "${searchText}"
                }
              }
              {
                polices: {
                  some: {
                    planSante: {
                      nom: {
                        contains: "${searchText}"
                      }
                    }
                  }
                }
              }
              {
                polices: {
                  some: {
                    police: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
              {
                polices: {
                  some: {
                    familles: {
                      some: {
                        qualiteMembres: {
                          some: {
                            OR: [
                              {
                                qualite: {
                                  nom: {
                                    equals: "${searchText}"
                                  }
                                }
                              }
                              {
                                membre: {
                                  OR: [
                                    {
                                      prenom: {
                                        contains: "${searchText}"
                                      }
                                    }
                                    {
                                      nom: {
                                        contains: "${searchText}"
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        }
                      }
                    }
                  }
                }
              }
            ]
          }
        )




        polices  (
          where: {
            NOT: {
              planSante: null
            }
            entreprise: null
            OR: [
              {
                planSante: {
                  nom: {
                    contains: "${searchText}"
                  }
                }
              }
              {
                familles: {
                  some: {
                    qualiteMembres: {
                      some: {
                        OR: [
                          {
                            qualite: {
                              nom: {
                                equals: "${searchText}"
                              }
                            }
                          }
                          {
                            membre: {
                              OR: [
                                {
                                  prenom: {
                                    contains: "${searchText}"
                                  }
                                }
                                {
                                  nom: {
                                    contains: "${searchText}"
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  }
                }
              }
            ]
          }
        ) {
        id
        police
        familles (
          where: {
            qualiteMembres: {
              some: {
                OR: [
                  {
                    qualite: {
                      nom: {
                        equals: "${searchText}"
                      }
                    }
                  }
                  {
                    membre: {
                      OR: [
                        {
                          prenom: {
                            contains: "${searchText}"
                          }
                        }
                        {
                          nom: {
                            contains: "${searchText}"
                          }
                        }
                      ]
                    }
                  }
                ]
              }
            }
          }
        ) {
          id
          nom
          qualiteMembres (
            where: {
              OR: [
                  {
                    qualite: {
                      nom: {
                        equals: "${searchText}"
                      }
                    }
                  }
                  {
                    membre: {
                      OR: [
                        {
                          prenom: {
                            contains: "${searchText}"
                          }
                        }
                        {
                          nom: {
                            contains: "${searchText}"
                          }
                        }
                      ]
                    }
                  }
                ]
            }
          ) {
            id
            membre {
              id
              prenom
              nom
              dateNaissance
              telephone
            }
            qualite {
              id
              nom
            }
          }
        }
      }

      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getStructureSantes(page: number, pageCount: number, filtre: string, searchText: string = '') {

    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        structureSantes(
          where : {
            deleted : {
              not : {
                equals : true
              }
            }
            ${filtre}
            OR :[
              {
                nom : {
                  contains : "${searchText}"
                }
              }
              {
                adresse : {
                  contains : "${searchText}"
                }
              }
              {
                responsable : {
                
                  OR :[
                    {
                      nom : {
                        contains : "${searchText}"
                      }
                    }
                    {
                      prenom : {
                        contains : "${searchText}"
                      }
                    }
                    {
                      phoneNumber :{
                        contains :"${searchText}"
                      }  
                    }
                  ]
                  
                }
              }
              {
                contacts : {
                 some : {
                    contact : {
                      contains :"${searchText}"   
                    }
                  }
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ){
          id
          nom
          type
          adresse
          responsable {
            id
            prenom
            nom
            phoneNumber
          }
           contacts {
            id
            contact
          }
          
        }structureSantesCount(
          where :{
            deleted : {
              not : {
                equals : true
              }
            }
            ${filtre}
            OR :[
              {
                nom : {
                  contains : "${searchText}"
                }
              }
              {
                adresse : {
                  contains : "${searchText}"
                }
              }
              {
                responsable : {
                 
                  OR :[

                   {
                    nom : {
                      contains : "${searchText}"
                    }
                   }

                    {
                      prenom : {
                        contains : "${searchText}"
                      }
                    }
                    {
                      phoneNumber :{
                        contains :"${searchText}"
                      }  
                    }
                  ]
                  
                }
              }
              {
                contacts : {
                 some : {
                    contact : {
                      contains :"${searchText}"   
                    }
                  }
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getQualites(page: number, pageCount: number, filtre: string, searchText: string = '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        qualites(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                nom: {
                  contains: "${searchText}"
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          nom
          fichier {
            filename
            url
          }
        }
        qualitesCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                nom: {
                  contains: "${searchText}"
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllQualites () {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        qualites (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
          }
          orderBy: {
            ordre: asc
          }
        ) {
          id
          nom
          ordre
          obligatoire
          type
          value
          fichier {
            filename
            filesize
            url
          }
          qualitePlans {
            id
            min
            max
            prime
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getPlanSantes(page: number, pageCount: number, filtre: string, searchText: string = '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        planSantes(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                description: {
                  contains: "${searchText}"
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          nom
          territorialite
          prime
          forfaitaire
          particulier
          plafond1erAnnee
          plafond2emeAnnee
          tauxCouverture
          qualitePlans {
            min
            max
            prime
            qualite {
              id
              nom
            }
          }
          compagnie {
            id
            raisonSociale
          }
          risque {
            id
            nom
            branche {
              id
              nom
            }
          }
          options {
            id
            nom
            prime
            forfaitaire
          }
          entreprises {
            id
            raisonSociale
          }
          image {
            id
            url
          }
          description
        }
        planSantesCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                description: {
                  contains: "${searchText}"
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getSpecifiquePlanSantes(qualites: any, entrepriseId='') {
    var _qualites = `[`;
    qualites?.filter((qualite: any) => qualite?.quantite > 0).forEach((qualite: any) => {
      _qualites += `{
        qualitePlans: {
          some: {
            min: { lte: ${qualite?.quantite} }
            max: { gte: ${qualite?.quantite} }
            qualite: { id: { equals: "${qualite?.id }" } }
          }
        }
      }`
    });
    _qualites += `]`;

    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        planSantes(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            AND: ${_qualites}
            ${
              entrepriseId ?
              `
              entreprises: {
                some: {
                  id: {
                    equals: "${entrepriseId}"
                  }
                }
              }
              `
              :
              `
              particulier: {
                equals: true
              }
              `
            }
          }
          orderBy: {
            id: desc
          }
        ) {
          id
          nom
          tauxCouverture
          particulier
          prime
          forfaitaire
          image {
            id
            url
          }
          risque {
            id
            nom
            branche {
              id
              nom
            }
          }
          entreprises {
            id
            raisonSociale
            contacts {
              id
              contact
            }
            assures (
              where: {
                estEntreprise: {
                  equals: true
                }
              }
            ) {
              id
              estEntreprise
              code
              prenom
              nom
              contacts {
                id
                contact
              }
            }
          }
          compagnie {
            id
            raisonSociale
          }
          polices {
            id
            police
            options {
              id
              nom
            }
            quittances {
              id
              dateEffet
              dateEcheance
              dateCreation
            }
            infoPolices {
              id
              libelle
              cle
              valeur
            }
            entreprise {
              id
              raisonSociale
              assures (
                where: {
                  estEntreprise: {
                    equals: true
                  }
                }
              ) {
                id
                estEntreprise
                code
                prenom
                nom
                contacts {
                  id
                  contact
                }
              }
            }
          }
          description
          options{
            id
            nom
            forfaitaire
            prime
          }
          qualitePlans {
            min
            max
            prime
            qualite {
              id
              nom
              fichier {
                url
              }
            }
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }


  getSpecialites(page: number, pageCount: number, filtre: string, searchText: string ='') {

    console.log(`Voici l'ajout au filtre : ${filtre}`);
    const request = this.apolloProvider.watchQuery({

      query: gql`
        query {
            specialites(
              where: {
                deleted: {
                  not: {
                    equals: true
                  }
                }
                ${filtre}
                OR: [
                  {
                    nom: {
                      contains: "${searchText}"
                    }
                  }
                ]
              }
              skip: ${pageCount * (page - 1)}
              take: ${pageCount}
              orderBy: {
                id: desc
              }
            )
            {
              id
              nom
              domaine
            }
            specialitesCount(
              where: {
                deleted: {
                  not: {
                    equals: true
                  }
                }
                ${filtre}
                OR: [
                  {
                    nom: {
                      contains: "${searchText}"
                    }
                  }
                ]
              } 
            )
          }
        `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

 

  getCategoriesActes(page: number, pageCount: number, filtre: string, searchText: string ='') {

    console.log(`Voici l'ajout au filtre : ${filtre}`);
    const request = this.apolloProvider.watchQuery({

      query: gql`
        query {
          categorieActes(
            where : {
              deleted :{
                not :{
                  equals :true
                }
              }
             
             ${filtre}
              OR : [
                {
                  nom : {
                    contains : "${searchText}"
                  }
                }
                {
                  specialite : {
                    nom : {
                      contains :"${searchText}"
                    }
                  }
                }
                {
                  acteMedicaux : {
                    some :{
                      nom : {
                        contains :"${searchText}"
                      }
                    }
                    
                  }
                }
              ]   
            }
            skip : ${pageCount * (page -1)}
            take : ${pageCount}
            orderBy: {
              id: desc
            }
          )
          {
            id
            nom
            specialite {
              id
              nom
            }
            acteMedicaux {
              id
              nom
            }
          }categorieActesCount(
            where :{
              deleted :{
                not :{
                  equals :true
                }
              }
             ${filtre}             
              OR : [
                {
                  nom : {
                    contains : "${searchText}"
                  }
                }
                {
                  specialite : {
                    nom : {
                      contains :"${searchText}"
                    }
                  }
                }
                {
                  acteMedicaux : {
                    some :{
                      nom : {
                        contains :"${searchText}"
                      }
                    }
                    
                  }
                }
              ] 
            }
          )
        }
        `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getActesMedicaux(page: number, pageCount: number, filtre: string, searchText: string ='') {

    console.log(`Voici l'ajout au filtre : ${filtre}`);
    const request = this.apolloProvider.watchQuery({

      query: gql`
          query{
            acteMedicals (
              where :{
                deleted :{
                  not : {
                    equals : true
                  }
                }
                ${filtre}
                OR :[
                  {
                    nom :{
                      contains : "${searchText}"
                    }
                  }
                  ${
                    searchText && !isNaN(parseInt(searchText)) ? 
                    `
                    {
                      coef: {
                        equals: ${parseInt(searchText)}
                      }
                    }
                    `: 
                    ``
                  }
                  ${
                    searchText && !isNaN(parseInt(searchText)) ? 
                    `
                    {
                      prix: {
                        equals: ${parseInt(searchText)}
                      }
                    }
                    `: 
                    ``
                  }
                  {
                    categorieActe :{
                      nom :{
                        contains :"${searchText}"
                      }
                    }
                  }
                  
                ]
                
              }
              skip: ${pageCount * (page - 1)}
              take: ${pageCount}
              orderBy: {
                id: desc
              }
            ){
              id
              nom
              domaine
              coef
              prix
              categorieActe {
                id
                nom
              }
              
            }acteMedicalsCount(
              where : {
                deleted :{
                  not : {
                    equals : true
                  }
                }
                ${filtre}
                OR :[
                  {
                    nom :{
                      contains : "${searchText}"
                    }
                  }
                  ${
                    searchText && !isNaN(parseInt(searchText)) ? 
                    `
                    {
                      coef: {
                        equals: ${parseInt(searchText)}
                      }
                    }
                    `: 
                    ``
                  }
                  ${
                    searchText && !isNaN(parseInt(searchText)) ? 
                    `
                    {
                      prix: {
                        equals: ${parseInt(searchText)}
                      }
                    }
                    `: 
                    ``
                  }
                  {
                    categorieActe :{
                      nom :{
                        contains :"${searchText}"
                      }
                    }
                  }
                  
                ]
                
              }
            )
          }
        `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getMedicaments(page: number, pageCount: number, filtre: string, searchText: string ='') {

    console.log(`Voici l'ajout au filtre : ${filtre}`);
    const request = this.apolloProvider.watchQuery({

      query: gql`

        query {
          medicaments(
            where :{
              deleted : {
                not : {
                  equals : true
                }
              }
              
              OR : [
                {
                  nom :{
                    contains : "${searchText}"
                  }
                }
                {
                  denominationCommueInternationale :{
                    contains : "${searchText}"
                  }
                }
                {
                  indications : {
                    contains : "${searchText}"
                  }
                }
                {
                  formeEtDosage : {
                    contains : "${searchText}"
                  }
                }
                {
                  presentation : {
                    contains :"${searchText}"
                  }
                }
                {
                  type :{
                    contains : "${searchText}"
                  }
                }
                {
                  obs : {
                    contains : "${searchText}"
                  }
                }
              ]
              
            }
            skip : ${pageCount * (page - 1)}
            take : ${pageCount}
            orderBy : {
              id : desc
            }
          ){
            id
            nom
            denominationCommueInternationale
            indications
            formeEtDosage
            presentation
            type
            obs
          }medicamentsCount(
            where :{
                    deleted : {
                not : {
                  equals : true
                }
              }
              
              OR : [
                {
                  nom :{
                    contains : "${searchText}"
                  }
                }
                {
                  denominationCommueInternationale :{
                    contains : "${searchText}"
                  }
                }
                {
                  indications : {
                    contains : "${searchText}"
                  }
                }
                {
                  formeEtDosage : {
                    contains : "${searchText}"
                  }
                }
                {
                  presentation : {
                    contains :"${searchText}"
                  }
                }
                {
                  type :{
                    contains : "${searchText}"
                  }
                }
                {
                  obs : {
                    contains : "${searchText}"
                  }
                }
              ]
            }
            
          )
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }



  getParametrages(page: number, pageCount: number, filtre: string, searchText: string= '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        parametrages(
          where: {
            ${filtre}
            OR: [
              {
                valeur: {
                  contains: "${searchText}"
                }
              }
              {
                cle: {
                  contains: "${searchText}"
                }
              }
              {
                libelle: {
                  contains: "${searchText}"
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          cle
          libelle
          valeur
          typeChamp
        }
        parametragesCount(
          where: {
            ${filtre}
            OR: [
              {
                valeur: {
                  contains: "${searchText}"
                }
              }
              {
                cle: {
                  contains: "${searchText}"
                }
              }
              {
                libelle: {
                  contains: "${searchText}"
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllAccessoires() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        accessoires (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
          }
        ) {
          id
          accessoires
          tauxCommissionAccessoire
          tauxCommissionPrime
          compagnie {
            id
            raisonSociale
          }
          risque {
            id
            nom
            branche {
              id
              nom
            }
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAccessoires(page: number, pageCount: number, filtre: string, searchText: string= '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        accessoires(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                compagnie: {
                  raisonSociale: {
                    contains: "${searchText}"
                  }
                }
              }
              {
                risque: {
                  nom: {
                    contains: "${searchText}"
                  }
                }
              }
              {
                risque: {
                  branche: {
                    nom: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          accessoires
          tauxCommissionAccessoire
          tauxCommissionPrime
          compagnie {
            id
            raisonSociale
          }
          risque {
            id
            nom
            branche {
              id
              nom
            }
          }
        }
        accessoiresCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                compagnie: {
                  raisonSociale: {
                    contains: "${searchText}"
                  }
                }
              }
              {
                risque: {
                  nom: {
                    contains: "${searchText}"
                  }
                }
              }
              {
                risque: {
                  branche: {
                    nom: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getRisques(page: number, pageCount: number, filtre: string, searchText: string= '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        risqueBranches(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                branche: {
                  nom: {
                    contains: "${searchText}"
                  }
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          nom
          branche {
            id
            nom
          }
        }
        risqueBranchesCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                branche: {
                  nom: {
                    contains: "${searchText}"
                  }
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAvenants(page: number, pageCount: number, filtre: string, searchText: string= '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        avenants(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                police: {
                  police: {
                    contains: "${searchText}"
                  }
                }
              }
              {
                numero: {
                  contains: "${searchText}"
                }
              }
              {
                commentaire: {
                  contains: "${searchText}"
                }
              }
              {
                quittance: {
                  numero: {
                    contains: "${searchText}"
                  }
                }
              }
              {
                categorieAvenant: {
                  nom: {
                    contains: "${searchText}"
                  }
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          numero
          police {
            id
            police
            compagnie {
              id
              raisonSociale
            }
            client {
              id
              code
              prenom
              nom
            }
            apporteur {
              id
              prenom
              nom
            }
            risque {
              id
              nom
              branche {
                id
                nom
              }
            }
          }
          commentaire
          date
          derniereDateModif
          avenantProassur
          avenantCompagnie
          avenantClient
          quittance {
            id
            numero
          }
          categorieAvenant {
            id
            nom
          }
        }
        avenantsCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                police: {
                  police: {
                    contains: "${searchText}"
                  }
                }
              }
              {
                numero: {
                  contains: "${searchText}"
                }
              }
              {
                commentaire: {
                  contains: "${searchText}"
                }
              }
              {
                quittance: {
                  numero: {
                    contains: "${searchText}"
                  }
                }
              }
              {
                categorieAvenant: {
                  nom: {
                    contains: "${searchText}"
                  }
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAvenantById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          avenant(
            where: {
              id:  "${id}"
            }
          ) {
            id
            numero
            police {
              id
              police
              infoPolice
              compagnie {
                id
                raisonSociale
              }
              client {
                id
                code
                prenom
                nom
              }
              apporteur {
                id
                prenom
                nom
              }
              avenants {
                id
                numero
              }
              risque {
                id
                nom
                branche {
                  id
                  nom
                }
              }
            }
            commentaire
            date
            derniereDateModif
            quittance {
              id
              numero
            }
            categorieAvenant {
              id
              nom
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getPoliceById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          police(
            where: {
              id:  "${id}"
            }
          ) {
            id
            police
            compagnie {
              id
              raisonSociale
            }
            client{
              id
              code
              prenom
              nom
            }
            apporteur {
              id
              prenom
              nom
            }
            risque {
              id
              nom
              branche {
                id
                nom
              }
            }
            infoPolice
            infoPolices {
              id
              cle
              libelle
              valeur
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getCommissions(data: any) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        accessoires (
          where: {
            compagnie: {
              id: {
                equals: "${data?.compagnie}"
              }
            }
            risque: {
              id: {
                equals: "${data?.risque}"
              }
            }
          }
        )
        {
          id
          tauxCommissionPrime
          tauxCommissionAccessoire
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  isPoliceExistInQuittance(searchText: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        quittances(
          where: {
            police: {
              equals: "${searchText}"
            }
          }
        )
        {
          id
          police
        }
        quittancesCount(
          where: {
            police: {
              equals: "${searchText}"
            }
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  checkPoliceExists(policeId: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          policesCount(
            where: {
              police: {
                equals: "${policeId}"
              }
            }
          )
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getQuittances(page: number, pageCount: number, filtre: string='', searchText: string= '', isRelancePanelVisibled = false) {
    // console.log(filtre);
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        quittances(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${
              isRelancePanelVisibled ?
              `
              police: {
                risque: {
                  branche: {
                    aRelancer: {
                      equals: true
                    }
                  }
                }
              }
              `
              : 
              `
              `
            }
            ${filtre}
            OR: [
              {
                numero: {
                  contains: "${searchText}"
                }
              }
              {
                police: {
                  police: {
                    contains: "${searchText}"
                  }
                }
              }
              {
                police: {
                  infoPolices: {
                    some: {
                      valeur: {
                        contains: "${searchText}"
                      }
                    }
                  }
                }
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  primeNette: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  accessoire: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  taxe: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  primeTotale: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
              {
                police: {
                  compagnie: {
                    raisonSociale: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
              {
                police: {
                  client: {
                    OR: [
                      {
                        prenom: {
                          contains: "${searchText}"
                        }
                      }
                      {
                        nom: {
                          contains: "${searchText}"
                        }
                      }
                    ]
                  }
                }
              }
              {
                police: {
                  apporteur: {
                    OR: [
                      {
                        prenom: {
                          contains: "${searchText}"
                        }
                      }
                      {
                        nom: {
                          contains: "${searchText}"
                        }
                      }
                    ]
                  }
                }
              }
              {
                police: {
                  risque: {
                    branche: {
                      nom: {
                        contains: "${searchText}"
                      }
                    }
                  }
                }
              }
              {
                police: {
                  risque: {
                    nom: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          police {
            id
            police
            infoPolices {
              id
              cle
              libelle
              valeur
            }
            compagnie {
              id
              raisonSociale
            }
            client {
              id
              code
              prenom
              nom
            }
            apporteur {
              id
              prenom
              nom
            }
            avenants {
              id
              numero
            }
            risque {
              id
              nom
              branche {
                id
                nom
              }
            }
          }
          numero
          dateCreation
          dateEffet
          dateEcheance
          primeNette
          derniereDateModif
          taxe
          accessoire
          primeTotale
          relanceJ3
          relanceJ10
          relanceJJ
          relanceManuele
          paiements {
            id
            type
            accompte
            montant
          }
          avenant {
            id
            nom
            description
            neccessitePaiement
          }
          factureProassur
          factureClient
          factureCompagnie
          cloture {
            id
          }
        }
        quittancesCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${
              isRelancePanelVisibled ?
              `
              police: {
                risque: {
                  branche: {
                    aRelancer: {
                      equals: true
                    }
                  }
                }
              }
              `
              : 
              `
              `
            }
            ${filtre}
            OR: [
              {
                numero: {
                  contains: "${searchText}"
                }
              }
              {
                police: {
                  police: {
                    contains: "${searchText}"
                  }
                }
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  primeNette: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  accessoire: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  taxe: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
              ${
                searchText && !isNaN(parseInt(searchText)) ? 
                `
                {
                  primeTotale: {
                    equals: ${parseInt(searchText)}
                  }
                }
                `: 
                ``
              }
              {
                police: {
                  compagnie: {
                    raisonSociale: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
              {
                police: {
                  infoPolices: {
                    some: {
                      valeur: {
                        contains: "${searchText}"
                      }
                    }
                  }
                }
              }
              {
                police: {
                  client: {
                    OR: [
                      {
                        prenom: {
                          contains: "${searchText}"
                        }
                      }
                      {
                        nom: {
                          contains: "${searchText}"
                        }
                      }
                    ]
                  }
                }
              }
              {
                police: {
                  apporteur: {
                    OR: [
                      {
                        prenom: {
                          contains: "${searchText}"
                        }
                      }
                      {
                        nom: {
                          contains: "${searchText}"
                        }
                      }
                    ]
                  }
                }
              }
              {
                police: {
                  risque: {
                    branche: {
                      nom: {
                        contains: "${searchText}"
                      }
                    }
                  }
                }
              }
              {
                police: {
                  risque: {
                    nom: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getQuittanceForBordereau(page: number, pageCount: number, filtre: string='') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
          query {
            parametrages(
              where: {
                cle: {
                  equals: "limitesDeGarantie"
                }
              }
            )
            {
              id
              cle
              libelle
              valeur
              typeChamp
            }
            compagnies(
              where: {
                polices: {
                  some: {
                    quittances: {
                      some: ${filtre}
                    }
                  }
                }
              }
            )
            {
              id
              raisonSociale
              polices (
                where: {
                  quittances: {
                    some: ${filtre}
                  }
                }
              ) {
                id
                police
                quittancesCount(
                  where: ${filtre}
                )
                risque {
                  id
                  nom
                  branche {
                    id
                    nom
                  }
                }
              }
            }
            quittances(
              where: ${filtre}
              skip: ${pageCount * (page - 1)}
              take: ${pageCount}
              orderBy: {
                id: asc
              }
            ) {
              id
              numero
              dateCreation
              dateEffet
              dateEcheance
              primeNette
              derniereDateModif
              taxe
              accessoire
              primeTotale
              paiements {
                id
                date
                montant
                type
                accompte
                restantApayer
              }
              avenant {
                id
                nom
                description
                neccessitePaiement
              }
              avenantPolice {
                id
                numero
              }
              police {
                id
                police
                infoPolices {
                  cle
                  libelle
                  valeur
                }
                risque {
                  id
                  nom
                  branche {
                    id
                    nom
                  }
                }
                compagnie {
                  id
                  raisonSociale
                }
                client {
                  id
                  code
                  prenom
                  nom
                }
                apporteur {
                  id
                  prenom
                  nom
                }
              }
            }
            quittancesCount(
              where: ${filtre}
            )
          }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getQuittanceForBordereauByCompagnie(compagnieId: string, brancheId: string, filtre: string='', step=1, PAGE_LENGTH=10) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
          query {
            parametrages(
              where: {
                cle: {
                  equals: "limitesDeGarantie"
                }
              }
            )
            {
              id
              cle
              libelle
              valeur
              typeChamp
            }
            ${
              compagnieId ?
              `
                compagnie(
                  where: {
                    id: "${compagnieId}"
                  }
                ) {
                  id
                  code
                  raisonSociale
                }
              `
              :
              ``
            }
            ${
              brancheId ?
              `
                branche(
                  where: {
                    id: "${brancheId}"
                  }
                ) {
                  id
                  nom
                }
              `
              :
              ``
            }
            quittances(
              where: ${filtre}
              orderBy: {
                id: asc
              }
              skip: ${(step -1) * PAGE_LENGTH}
              take: ${PAGE_LENGTH}
            ) {
              id
              numero
              dateCreation
              dateEffet
              dateEcheance
              primeNette
              derniereDateModif
              taxe
              accessoire
              primeTotale
              paiements {
                id
                date
                montant
                type
                accompte
                restantApayer
              }
              avenant {
                id
                nom
                description
                neccessitePaiement
              }
              avenantPolice {
                id
                numero
              }
              police {
                id
                police
                infoPolices {
                  cle
                  libelle
                  valeur
                }
                risque {
                  id
                  nom
                  branche {
                    id
                    nom
                  }
                }
                compagnie {
                  id
                  raisonSociale
                }
                client {
                  id
                  code
                  prenom
                  nom
                }
                apporteur {
                  id
                  prenom
                  nom
                }
              }
            }
            quittancesCount(
              where: ${filtre}
            )
          }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getClotureById(clotureId: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          cloture (
            where: {
              id: "${clotureId}"
            }
          ) {
            id
            date
            reference
            debut
            fin
            montant
            banqueCheque
            numeroCheque
            chequeImage {
              id
              url
            }
            compagnie {
              id
              code
              raisonSociale
            }
            quittances {
              id
              numero
              dateCreation
              dateEffet
              dateEcheance
              primeNette
              derniereDateModif
              taxe
              accessoire
              primeTotale
              paiements {
                id
                date
                montant
                type
                accompte
                restantApayer
              }
              avenant {
                id
                nom
                description
                neccessitePaiement
              }
              avenantPolice {
                id
                numero
              }
              police {
                id
                police
                infoPolices {
                  cle
                  libelle
                  valeur
                }
                risque {
                  id
                  nom
                  branche {
                    id
                    nom
                  }
                }
                compagnie {
                  id
                  raisonSociale
                }
                client {
                  id
                  code
                  prenom
                  nom
                }
                apporteur {
                  id
                  prenom
                  nom
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getQuittanceTotauxForBordereau(filtre: string='') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        quittances(
          where: ${filtre}
          orderBy: {
            id: asc
          }
        ) {
          primeNette
          taxe
          accessoire
          primeTotale
          police {
            compagnie {
              id
              raisonSociale
            }
          }
          paiements {
            id
            type
            montant
            accompte
            restantApayer
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getQuittanceTotauxForBordereauByCompagnie(filtre: string='') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        quittances(
          where: ${filtre}
          orderBy: {
            id: asc
          }
        ) {
          primeNette
          taxe
          accessoire
          primeTotale
          police {
            compagnie {
              id
              raisonSociale
            }
          }
          paiements {
            id
            type
            montant
            accompte
            restantApayer
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }


  generateBordereau(bordereauParam: any) {
    var str = bordereauParam
    str = str?.replace(/"/g, `\\"`);
    const request = this.apolloProvider.mutate({
      mutation: gql`
      mutation {
        generateBordereau(
          borderauParams: "${str}"
        )
      }
      `,
    });
    return request
  }

  getBordereauRequest(filtre: string, searchText: string= '') {
    return {
      where: JSON.parse(JSON.stringify(`{
        deleted: {
          not: {
            equals: true
          }
        }
        ${filtre}
        OR: [
          {
            police: {
              contains: "${searchText}"
            }
          }
          ${
            searchText && !isNaN(parseInt(searchText)) ? 
            `
            {
              primeNette: {
                equals: ${parseInt(searchText)}
              }
            }
            `: 
            ``
          }
          ${
            searchText && !isNaN(parseInt(searchText)) ? 
            `
            {
              accessoire: {
                equals: ${parseInt(searchText)}
              }
            }
            `: 
            ``
          }
          ${
            searchText && !isNaN(parseInt(searchText)) ? 
            `
            {
              taxe: {
                equals: ${parseInt(searchText)}
              }
            }
            `: 
            ``
          }
          ${
            searchText && !isNaN(parseInt(searchText)) ? 
            `
            {
              primeTotale: {
                equals: ${parseInt(searchText)}
              }
            }
            `: 
            ``
          }
          {
            compagnie: {
              raisonSociale: {
                contains: "${searchText}"
              }
            }
          }
          {
            client: {
              OR: [
                {
                  prenom: {
                    contains: "${searchText}"
                  }
                }
                {
                  nom: {
                    contains: "${searchText}"
                  }
                }
              ]
            }
          }
          {
            apporteur: {
              OR: [
                {
                  prenom: {
                    contains: "${searchText}"
                  }
                }
                {
                  nom: {
                    contains: "${searchText}"
                  }
                }
              ]
            }
          }
          {
            risque: {
              branche: {
                nom: {
                  contains: "${searchText}"
                }
              }
            }
          }
          {
            risque: {
              nom: {
                contains: "${searchText}"
              }
            }
          }
        ]
      }
    } `)),
    query: `
    {
      id
      police
      dateCreation
      dateEffet
      dateEcheance
      primeNette
      derniereDateModif
      taxe
      accessoire
      primeTotale
      compagnie {
        id
        raisonSociale
      }
      client {
        id
        prenom
        nom
      }
      apporteur {
        id
        prenom
        nom
      }
      avenant {
        id
        nom
        description
        neccessitePaiement
      }
      risque {
        id
        nom
        branche {
          id
          nom
        }
      }
    `
    }
  }

  getEntreprises(page: number, pageCount: number, filtre: string, searchText: string='') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        entreprises(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}

            OR: [
              {
                raisonSociale: {
                  contains: "${searchText}"
                }
              }
              {
                adresse: {
                  contains: "${searchText}"
                }
              }
              {
                emplacement: {
                  contains: "${searchText}"
                }
              }
              {
                responsable : {
                
                  OR :[
                    {
                      nom : {
                        contains : "${searchText}"
                      }
                    }
                    {
                      prenom : {
                        contains : "${searchText}"
                      }
                    }
                    {
                      phoneNumber :{
                        contains :"${searchText}"
                      }  
                    }
                  ]
                  
                }
              }
              {
                contacts: {
                  some: {
                    contact: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
          skip: ${pageCount * (page - 1)}
          take: ${pageCount}
          orderBy: {
            id: desc
          }
        ) {
          id
          raisonSociale
          emplacement
          connexe
          adresse
          responsable {
            id
            prenom
            nom
            phoneNumber
          }
          contacts {
            id
            contact
          }
        }
        entreprisesCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${filtre}
            OR: [
              {
                raisonSociale: {
                  contains: "${searchText}"
                }
              }
              {
                adresse: {
                  contains: "${searchText}"
                }
              }
              {
                emplacement: {
                  contains: "${searchText}"
                }
              }
              {
                responsable : {
                
                  OR :[
                    {
                      nom : {
                        contains : "${searchText}"
                      }
                    }
                    {
                      prenom : {
                        contains : "${searchText}"
                      }
                    }
                    {
                      phoneNumber :{
                        contains :"${searchText}"
                      }  
                    }
                  ]
                  
                }
              }
              {
                contacts: {
                  some: {
                    contact: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  
  getClientById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          client(
            where: {
              id:  "${id}"
            }
          ) {
            id
            code
            prenom
            nom
            emplacement
            contacts {
              id
              contact
            }
            connexe
            email
            adresse
            entreprise {
              id
              raisonSociale
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getQualiteById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          qualite(
            where: {
              id:  "${id}"
            }
          ) {
            id
            nom
            fichier {
              filename
            	url
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getPlanSanteById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          planSante(
            where: {
              id:  "${id}"
            }
          ) {
            id
            nom
            territorialite
            prime
            forfaitaire
            particulier
            plafond1erAnnee
            plafond2emeAnnee
            tauxCouverture
            qualitePlans {
              id
              min
              max
              prime
              qualite {
                id
                nom
                obligatoire
                value
              }
            }
            compagnie {
              id
              raisonSociale
            }
            risque {
              id
              nom
              branche {
                id
                nom
              }
            }
            planCategorieActes {
              id
              tauxCouverture
              categorieActe {
                id
                nom
                specialite {
                  id
                  nom
                }
              }
            }
            planSanteDelaisCarences {
              id
              libelleDuree
              duree
              delaisCarence {
                id
                nom
                delaisParDefaut
                libelleDelaisParDefaut
              }
            }
            options {
              id
              nom
              prime
              forfaitaire
            }
            entreprises {
              id
              raisonSociale
            }
            image {
              id
              url
            }
            description
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getCategorieActeById(id: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query { 
          categorieActe(
            where : {
              id :"${id}"
            }
          ){
            id
            nom
            specialite {
              id
              nom
            }
            acteMedicaux {
              id
              nom
            }  
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getActeMedicalById(id: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          acteMedical(
            where : {
              id :"${id}"
            }
          ){
            id
            nom
            domaine 
            coef
            prix
            categorieActe {
              id
              nom
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getMedicamentById(id: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          medicament(
            where :{
              id : "${id}"
            }
          ){
            id
            nom
            denominationCommueInternationale
            indications
            formeEtDosage
            presentation
            type
            obs
            
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getUtilisateurById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          user(
            where: {
              id:  "${id}"
            }
          ) {
            id
            phoneNumber
            prenom
            nom
            roles
            email
            actif
            adresse
            
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getEntrepriseById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          entreprise(
            where: {
              id:  "${id}"
            }
          ) {
            id
            raisonSociale
            emplacement
            responsable {
              id
              nom
              prenom
              phoneNumber
            }
            contacts {
              id
              contact
            }
            connexe
            adresse
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }



  getCompagnieById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          compagnie(
            where: {
              id:  "${id}"
            }
          ) {
            id
            code
            raisonSociale
            responsable
            telephone
            BP
            FAX
            codeCourtier
            tauxAccessoires
            tauxTaxe
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getBrancheById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          branche(
            where: {
              id:  "${id}"
            }
          ) {
            id
            nom
            aRelancer
            risques {
              id
              nom
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getCategorieAvenantById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          categorieAvenant(
            where: {
              id:  "${id}"
            }
          ) {
            id
            nom
            description
            neccessitePaiement
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getApporteurById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          apporteur(
            where: {
              id:  "${id}"
            }
          ) {
            id
            prenom
            nom
            telephone
            designation
            tauxCommission
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getSpecialiteById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          specialite(
            where: {
              id:  "${id}"
            }
          ) {
            id
            nom
            domaine
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getStructureSanteById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          structureSante(
            where: {
              id:  "${id}"
            }
          ) {
            id
            nom
            type
            adresse
            responsable {
              id
              prenom
              nom
              phoneNumber
            }
            contacts {
              id
              contact
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }


  getParametrageById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          parametrage(
            where: {
              id:  "${id}"
            }
          ) {
            id
            cle
            libelle
            valeur
            typeChamp
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAccessoireById(id: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          accessoire(
            where: {
              id:  "${id}"
            }
          ) {
            id
            accessoires
            tauxCommissionAccessoire
            tauxCommissionPrime
            compagnie {
              id
              raisonSociale
            }
            risque {
              id
              nom
              branche {
                id
                nom
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getRisqueById(id: string) {
    
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          risqueBranche(
            where: {
              id:  "${id}"
            }
          ) {
            id
            nom
            branche {
              id
              nom
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getQuittanceById(id: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          quittance(
            where: {
              id:  "${id}"
            }
          ) {
            id
            police {
              id
              police
              infoPolice
              infoPolices {
                id
                cle
                libelle
                valeur
              }
              compagnie {
                id
                raisonSociale
              }
              client {
                id
                code
                prenom
                nom
              }
              apporteur {
                id
                prenom
                nom
              }
              avenants {
                id
                numero
              }
              risque {
                id
                nom
                branche {
                  id
                  nom
                }
              }
            }
            dateCreation
            dateEffet
            dateEcheance
            primeNette
            derniereDateModif
            taxe
            accessoire
            primeTotale
            observation
            typeRelance
            avenant {
              id
              nom
              description
              neccessitePaiement
            }
            avenantPolice {
              id
              numero
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getQuittancePayment(quittanceId: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        quittance(
          where: {
            id: "${quittanceId}"
          }
        )
      {
        id
        primeTotale
        paiements (
          orderBy: {
            id: desc
          }
          take: 1
        ) {
          id
          montant
          accompte
          restantApayer
        }
      }
    }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllEntreprises() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        entreprises (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
          }
        ) {
          id
          raisonSociale
          contacts {
            id
            contact
          }
          assures (
            where: {
              estEntreprise: {
                equals: true
              }
            }
          ) {
            id
            estEntreprise
            code
            prenom
            nom
            contacts {
              id
              contact
            }
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllCategorieActes() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        categorieActes (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
          }
        ) {
          id
          nom
          specialite {
            id
            nom
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllSpecialites() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        specialites(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
          }
        ) {
          id
          nom
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllCategoriesActes() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        categorieActes(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
          }
        ) {
          id
          nom
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllBranches(domaine: string = '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        branches (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            ${
              domaine ?
              `
                domaine: {
                  equals: SANTE
                }
              `
              :
              ``
            }
          }
        ) {
          id
          nom
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllDelaisCarances(domaine: string = '') {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        delaisCarences (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
          }
          orderBy: {
            ordre: asc
          }
        ) {
          id
          nom
          ordre
          delaisParDefaut
          libelleDelaisParDefaut
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllRisques() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        risqueBranches (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
          }
        ) {
          id
          nom
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllRisqueByBranches(branches: any) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        risqueBranches (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            branche: {
              id: {
                in: ${JSON.stringify(branches)}
              }
            }
          }
        ) {
          id
          nom
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllApporteurs() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        apporteurs (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
          }
        ) {
          id
          prenom
          nom
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllClientBySearch(searchText: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        clients(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            OR: [
              {
                code: {
                  contains: "${searchText}"
                }
              }
              {
                prenom: {
                  contains: "${searchText}"
                }
              }
              {
                nom: {
                  contains: "${searchText}"
                }
              }
              {
                email: {
                  contains: "${searchText}"
                }
              }
              {
                emplacement: {
                  contains: "${searchText}"
                }
              }
              {
                entreprise: {
                  raisonSociale: {
                    contains: "${searchText}"
                  } 
                }
              }
              {
                contacts: {
                  some: {
                    contact: {
                      contains: "${searchText}"
                    } 
                  } 
                }
              }
            ]
          }
          orderBy: {
            prenom: desc
          }
        ) {
          id
          code
          prenom
          nom
          contacts {
            id
            contact
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }


  getAllResponsablesBySearch(searchText: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
        query {
          users(
            where: {
              deleted :{
                not : {
                  equals :true
                }
              }
              OR : [
                {
                  nom : {
                    contains :"${searchText}"
                  }
                }
                {
                  prenom :{
                    contains :"${searchText}"
                  }
                }
                {
                  phoneNumber :{
                    contains :"${searchText}"
                  }
                }
              ]
            }
          ){
            id
            nom
            prenom
            phoneNumber
            responsableStructureSantes {
              id
              nom
              type
              adresse
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }


  getResponsableById (id: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        user(
          where: {
            id : "${id}"
          }
        ){
          id
        }
      }      
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
    
    

  }

  getAllQuittanceBySearch(searchText: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        polices(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            OR: [
              {
                police: {
                  contains: "${searchText}"
                }
              }
              {
                client: {
                  OR: [
                    {
                      prenom: {
                        contains: "${searchText}"
                      }
                    }
                    {
                      nom: {
                        contains: "${searchText}"
                      }
                    }
                    {
                      contacts: {
                        some: {
                          contact: {
                            contains: "${searchText}"
                          }
                        }
                      }
                    }
                  ]
                }
              }
              {
                infoPolices: {
                  some: {
                    valeur: {
                      contains: "${searchText}"
                    }
                  }
                }
              }
            ]
          }
          orderBy: {
            id: desc
          }
        ) {
          id
          police
          compagnie {
            id
            raisonSociale
          }
          client {
            id
            code
            prenom
            nom 
            contacts {
              id
              contact
            }
          }
          apporteur {
            id
            prenom
            nom
          }
          risque {
            id
            nom
            branche {
              id
              nom
            }
          }
          infoPolices {
            id
            cle
            valeur
          }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getHighestNettePrime() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        quittances(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }

          }
          orderBy: {
            primeNette: desc
          }
          take: 1
        ) {
          id
          primeNette
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getLowestNettePrime() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        quittances(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }

          }
          orderBy: {
            primeNette: asc
          }
          take: 1
        ) {
          id
          primeNette
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getHighestAccessoire() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        quittances(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }

          }
          orderBy: {
            accessoire: desc
          }
          take: 1
        ) {
          id
          accessoire
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getLowestAccessoire() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        quittances(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }

          }
          orderBy: {
            accessoire: asc
          }
          take: 1
        ) {
          id
          accessoire
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getHighestTaxe() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        quittances(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }

          }
          orderBy: {
            taxe: desc
          }
          take: 1
        ) {
          id
          taxe
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getLowestTaxe() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        quittances(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }

          }
          orderBy: {
            taxe: asc
          }
          take: 1
        ) {
          id
          taxe
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllRisqueOfBranche(id: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        branche (
          where: {
            id: "${id}"
          }
        ) {
          id,
          nom
        }
        risqueBranches (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            branche: {
              id: {
                equals: "${id}"
              }
            }
          }
        ) {
          id
          nom
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllCompagnies() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        compagnies (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
          }
        ) {
          id
          raisonSociale
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllCategorieAvenants() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        categorieAvenants (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
          }
        ) {
          id
          nom
          description
          neccessitePaiement
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getAllQuittances() {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        quittances (
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
          }
        ) {
          id
          numero
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getSouscriptionByStatus(status: string) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        souscriptionsCount(
          where: {
            deleted: {
              not: {
                equals: true
              }
            }
            agent: {
              id: {
                equals: "${this.getUser()?.id}"
              }
            }
            ${
              status ? 
              `status: {
                equals: ${status}
              }`
              : 
              ``
            }
          }
        )
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getQuestionsOfTypeFinancement(typeFinancementId: number) {
    const request = this.apolloProvider.watchQuery({
      query: gql`
      query {
        typeFinancement(
          where: {
            id: "${typeFinancementId}"
          }
        ) {
            id
            nom
              risques (
                  where: {
                    actif: {
                      equals: true
                    }
                  }
                  orderBy: [
                    {
                      ordre: asc
                    }
                  ]
                ) {
                id
                nom
                image {
                  filename
                }
                garanties (
                  where: {
                    deleted: {
                      not: {
                        equals: true
                      }
                    }
                    actif: {
                      equals: true
                    }
                  }
                ) {
                  id
                  nom
                  obligatoire
                  inputKey
                }
                champs(
                  where: {
                    actif: {
                      equals: true
                    }
                  }
                ) {
                    id
                    code
                    premierMessage
                    inputKey
                    typeReponse
                    typeChamp
                    actif
                    config	
                    valeurDefaut
                    placeholder
                    options {
                      id
                      cle
                      valeur
                      suivant {
                        id
                         code 
                      }
                    }
                    messages {
                      id
                      text {
                        document
                      }
                      elementFormMessage {
                        id
                        code
                      }
                      elementFormErreur {
                        id
                        code
                      }
                    }
                    messagesErreurs {
                      id
                      text {
                        document
                      }
                      elementFormMessage {
                        id
                        code
                      }
                      elementFormErreur {
                        id
                        code
                      }
                    }
                    image {
                      filename
                    }
                    suivant {
                      id
                      code
                    }
                    precedants {
                      id
                      code
                    }
                    variables {
                      id
                      variable
                      reference {
                        id
                        code
                      }
                    }
                  }
            }
        }
      }
      `,
      fetchPolicy: 'cache-and-network'
    });
    return request.valueChanges
  }

  getPrice(id: string, inputs: any) {
    var inputString = `[`;
    inputs.forEach((inp: any) => {
      inputString += `{
        cle: "${inp?.cle}",
        valeur: ${(inp?.type == 'NUMBER') ? inp?.valeur : '"' + inp?.valeur + '"'}
      }`;
    });
    inputString += `]`;
    const request = this.apolloProvider.mutate({
      mutation: gql`
      mutation {
        CalculatePrime(
          id: "${id}"
          inputs: ${inputString}
        ) 
      }
      `,
    });
    return request
  }

  login({email, password}: {email: string, password: string}) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
      mutation {
        authenticateUserWithPassword(
          email: "${email}"
          password: "${password}"
        ){
         ... on UserAuthenticationWithPasswordSuccess {
          sessionToken
          item {
            id
            email
            prenom
            nom
            phoneNumber
            actif
            adresse
            entreprise{
              id
            }
            partenaire {
              id
              nom
              image {
                id
                url
              }
            }
            roles
            
          }
        }
          ... on UserAuthenticationWithPasswordFailure {
            message
          }
        }
      }
      `,
    });
    return request
  }

  sendEmailNotification(id: string) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
      mutation {
        sendEmail(
          id: "${id}"
        )
      }
      `,
    });
    return request
  }

  createChargementClient(fichier: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation($upload: Upload!){
          createChargementClient(
            data: {
              fichier: {
                upload: $upload
              }
            }
          ) {
            id
          }
        }
      `,
      variables: {
        upload: fichier
      },
      context: {
         useMultipart: true
      }
    });
    return request
  }

  createChargementRelance(fichier: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation($upload: Upload!){
          createChargementRelance(
            data: {
              fichier: {
                upload: $upload
              }
            }
          ) {
            id
          }
        }
      `,
      variables: {
        upload: fichier
      },
      context: {
         useMultipart: true
      }
    });
    return request
  }

  createChargementSpecialite(fichier: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation($upload: Upload!){
          createChargementSpecialite(
            data: {
              fichier: {
                upload: $upload
              }
            }
          ) {
            id
          }
        }
      `,
      variables: {
        upload: fichier
      },
      context: {
         useMultipart: true
      }
    });
    return request
  }

  createChargementCompagnie(fichier: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation($upload: Upload!){
          createChargementCompagnie(
            data: {
              fichier: {
                upload: $upload
              }
            }
          ) {
            id
          }
        }
      `,
      variables: {
        upload: fichier
      },
      context: {
         useMultipart: true
      }
    });
    return request
  }

  createClient(client: any, isAddEmploye : boolean = false) {

    var newContacts = `[`;
    client.newContacts.forEach((value: any) => {
      newContacts += `{
          contact: "${value.contact}"
        }
      `
    })
    newContacts += `]`;

    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createClient(
            data: {
              prenom: "${client?.prenom}"
              nom: "${client?.nom}"
              email: "${client?.email}"
              emplacement: "${client?.emplacement}"
              contacts: {
                create: ${newContacts}
              }
              connexe: ${client?.connexe?.length > 0 ? JSON.stringify(client?.connexe)?.replace(/"([^"]+)":/g, '$1:') : `[]`}
              adresse: "${client?.adresse ? client?.adresse : ''}"
              ${
                client?.entreprise ?
                `
                  entreprise: {
                    connect: {
                      id: "${this.getUser()?.entreprise?.id}"
                    }
                  }
                `
                : 
                ``
              }
              ${
                isAddEmploye ?
                `
                entreprise: {
                  connect: {
                    id: "${this.getUser()?.entreprise?.id}"
                  }
                }
              `:``
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  

  createUtilisateur(data: any) {

    var rolesString = `[`;
    data?.roles?.forEach((role: string) => {
      rolesString += ` ${role}`;
    })

    rolesString += `]`;
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createUser(
            data: {
              prenom: "${data?.prenom}"
              nom: "${data?.nom}"
              email: "${data?.email}"
              phoneNumber: "${data?.phoneNumber}"
              actif: true
              password: "PROASSUR_TMP_PASSWORD"
              roles: ${rolesString}
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createStructureDeSante(data :any, isConnect : boolean){
   
    
    var newContacts = `[`;
    data.newContacts.forEach((value: any) => {
      newContacts += `{
          contact: "${value.contact}"
        }
      `
    })
    newContacts += `]`;

    console.log(data?.infosNewResponsable)

    
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createStructureSante(
            data : {
              nom : "${data?.nom}"
              type : ${data?.type}
              adresse : "${data?.adresse}"
              contacts : {
                create: ${newContacts}
              }
              responsable : {
                ${
                  (isConnect)
                  ? `
                    connect : {
                      id : "${data?.responsable}"
                    }
                  `
                  : `
                    create : {
                      nom : "${data?.infosNewResponsable?.nom}"
                      prenom : "${data?.infosNewResponsable?.prenom}"
                      phoneNumber : "${data?.infosNewResponsable?.telephone}"
                      email : "${data?.infosNewResponsable?.email}"
                      password : "PROASSUR_TMP_PASSWORD"
                    }                  `
                }
              } 
            }
          ){
            id
          }
        }
      `
    });
    return request
    
  }

  updateUtilisateur(currentId: string, data: any) {
    var rolesString = `[`;
    data?.roles?.forEach((role: string) => {
      rolesString += ` ${role}`;
    })
    rolesString += `]`;
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateUser(
            where: {
              id: "${currentId}"
            }
            data: {
              prenom: "${data?.prenom}"
              nom: "${data?.nom}"
              email: "${data?.email}"
              phoneNumber: "${data?.phoneNumber}"
              actif: ${data?.actif ? true : false}
              roles: ${rolesString}
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  changePassword(currentId: string, password: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateUser(
            where: {
              id: "${currentId}"
            }
            data: {
              password: "${password}"
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  


  createEntreprise(data: any)  {

    var newContacts = `[`;
    data.newContacts.forEach((value: any) => {
      newContacts += `{
          contact: "${value.contact}"
        }
      `
    })
    newContacts += `]`;

    const request = this.apolloProvider.mutate({


      mutation: gql`
        mutation {
          createEntreprise(
            data: {
              raisonSociale: "${data?.raisonSociale}"
              emplacement: "${data?.emplacement}"
              contacts: {
                create: ${newContacts}
              }
              ${
                (data?.infosNewResponsable)
                ? 
                  `responsable : {
                    create : {
                      nom : "${data?.infosNewResponsable?.nom}"
                      prenom : "${data?.infosNewResponsable?.prenom}"
                      phoneNumber : "${data?.infosNewResponsable?.telephone}"
                      email : "${data?.infosNewResponsable?.email}"
                      roles : RESPONSABLE_ENTREPRISE
                      password : "PROASSUR_TMP_PASSWORD"
                    }
                  }`
                : ``                  
              }
              connexe: ${data?.connexe?.length > 0 ? JSON.stringify(data?.connexe)?.replace(/"([^"]+)":/g, '$1:') : `[]`}
              adresse: "${data?.adresse ? data?.adresse : ''}"
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }


  createCompagnie(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createCompagnie(
            data: {
              code: "${data?.code}"
              raisonSociale: "${data?.raisonSociale}"
              responsable: "${data?.responsable}"
              telephone: "${data?.telephone}"
              BP: "${data?.BP}"
              FAX: "${data?.FAX}"
              codeCourtier: "${data?.codeCourtier}"
              tauxAccessoires: ${data?.tauxAccessoires}
              tauxTaxe: ${data?.tauxTaxe}
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createBranche(data: any) {
    var newRisques = `[`;
    data.newRisques.forEach((value: any) => {
      newRisques += `{
          nom: "${value.nom}"
        }
      `
    })
    newRisques += `]`;
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createBranche(
            data: {
              nom: "${data?.nom}"
              aRelancer: ${data?.aRelancer}
              risques: {
                create: ${newRisques}
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createCategorieAvenant(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createCategorieAvenant(
            data: {
              nom: "${data?.nom}"
              description: "${data?.description}"
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  updateCategorieAvenant(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateCategorieAvenant(
            where: {
              id: "${currentId}"
            }
            data: {
              nom: "${data?.nom}"
              description: "${data?.description}"
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createApporteur(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createApporteur(
            data: {
              prenom: "${data?.prenom}"
              nom: "${data?.nom}"
              telephone: "${data?.telephone}"
              designation: "${data?.designation}"
              tauxCommission: ${data?.tauxCommission}
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  // createQualite(data: any) {
  //   const request = this.apolloProvider.mutate({
  //     mutation: gql`
  //       mutation {
  //         createQualite(
  //           data: {
  //             nom: "${data?.nom}"
  //           }
  //         ) {
  //           id
  //         }
  //       }
  //     `
  //   });
  //   return request
  // }

  createQualite(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation($nom: String!, $upload: Upload!){
          createQualite(
            data: {
              nom: $nom
              fichier: {
                upload: $upload
              }
            }
          ) {
            id
          }
        }
      `,
      variables: {
        nom: data?.nom,
        upload: data?.fichier
      },
      context: {
         useMultipart: true
      }
    });
    return request
  }


  createPlanSante(data: any) {
    var qualitePlans = `[`;
    data?.qualites?.forEach((qualite: any) => {
      if (qualite?.checked) {
        qualitePlans += `
        {
          min: ${qualite?.items?.[1] ? parseInt(qualite?.items?.[1]) : 0}
          max: ${qualite?.items?.[2] ? parseInt(qualite?.items?.[2]) : 0}
          prime: ${qualite?.items?.[3] ? parseInt(qualite?.items?.[3]) : 0}
          qualite: {
            connect: {
              id: "${qualite?.id}"
            }
          }
        }
        `
      }
    });
    qualitePlans += `]`;

    var planCategorieActes = `[`;
    data?.acteMedicaux?.forEach((planCategorie: any) => {
     if(planCategorie?.checked) {
        planCategorieActes += `
        {
          tauxCouverture: ${planCategorie?.items?.[1] ? parseInt(planCategorie?.items?.[1]) : data?.tauxCouverture}
          categorieActe: {
            connect: {
              id: "${planCategorie?.id}"
            }
          }
        }
      `
     }
    });
    planCategorieActes += `]`;

    var delaisCarences = `[`;
    data?.delaisCarence?.forEach((delaisCarence: any) => {
      if (delaisCarence?.checked) {
        delaisCarences += `
        {
          libelleDuree: "${delaisCarence?.items?.[2]}"
          duree: ${delaisCarence?.items?.[1] ? parseInt(delaisCarence?.items?.[1]) : 0 }
          delaisCarence: {
            connect: {
              id: "${delaisCarence?.id}"
            }
          }
        }
        `
      }
    });
    delaisCarences += `]`;

    var options = `[`;
    data?.options?.forEach((option: any) => {
      options += `
      {
        nom: "${option?.nom}"
        forfaitaire: ${option?.forfaitaire ? true : false}
        prime: ${option?.prime}
      }
      `
    });
    options += `]`;

    var entreprises = `[`;
    data?.entreprises?.forEach((entreprise: any) => {
      entreprises += `
      {
        id: "${entreprise}"
      }
      `
    });
    entreprises += `]`;
    
    const request = this.apolloProvider.mutate({
      mutation: gql`
      mutation($upload: Upload!){
        createPlanSante(
          data: {
            nom: "${data?.nom}"
            ${
              data?.description ?
              `description: "${data?.description}"`
              : 
              ``
            }
            image: {
              upload: $upload
            }
            prime: ${data?.prime}
            forfaitaire: ${data?.forfaitaire ? true : false}
            particulier: ${data?.particulier ? true : false}
            territorialite: ${data?.territorialite}
            plafond1erAnnee: ${data?.plafond1erAnnee}
            plafond2emeAnnee: ${data?.plafond2emeAnnee}
            tauxCouverture: ${data?.tauxCouverture}
            planSanteDelaisCarences: {
              create: ${delaisCarences}
            }
            entreprises: {
              connect: ${entreprises}
            }
            compagnie: {
              connect: {
                id: "${data?.compagnie}"
              }
            }
            risque: {
              connect: {
                id: "${data?.risque}"
              }
            }
            qualitePlans: {
              create: ${qualitePlans}
            }
            planCategorieActes: {
              create: ${planCategorieActes}
            }
            options: {
              create: ${options}
            }
          }
        ) {
          id
        }
      }
    `,
      variables: {
        upload: data?.image
      },
      context: {
         useMultipart: true
      }
    });
    return request
  }

  createSpecialite(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createSpecialite(
            data: {
              nom: "${data?.nom}"
              domaine: ${data?.domaine}
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createCategorieActe(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createCategorieActe(
            data: {
              nom: "${data?.nom}"
              specialite : {
                connect : {
                  id : "${data?.specialite}"
                }
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createActeMedical(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createActeMedical(
            data : {
              nom : "${data?.nom}"
              domaine : ${data?.domaineActe}
              coef : ${data?.coef}
              prix : ${data?.prix}
              categorieActe : {
                connect : {
                  id :"${data?.categorieActe}"
                }
              }
            }
          ){
            id
          }
        }
      `
    });
    return request
  }

  createMedicament(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`

        mutation {
          createMedicament(
            data : {
              nom :"${data?.nom}"
              denominationCommueInternationale : "${data?.denomComInter}"
              indications :"${data?.indications}"
              formeEtDosage: "${data?.formeEtDosage}"
              presentation : "${data?.presentation}"
              type :"${data?.type}"
              obs : "${data?.obs}"
            }
          ){
            id
          }
        }
      `
    });
    return request
  }

  createStructureSante(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createSpecialite(
            data: {
              nom: "${data?.nom}"
              domaine: ${data?.domaine}
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }


  updateApporteur(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateApporteur(
            where: {
              id: "${currentId}"
            }
            data: {
              prenom: "${data?.prenom}"
              nom: "${data?.nom}"
              telephone: "${data?.telephone}"
              designation: "${data?.designation}"
              tauxCommission: ${data?.tauxCommission}
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }


  updateQualite(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateQualite(
            where: {
              id: "${currentId}"
            }
            data: {
              nom: "${data?.nom}"
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  updatePlanSante(currentId: string, data: any) {

    console.log(data)

    var qualitePlans = `[`;
    data?.qualites?.forEach((qualite: any) => {
      if (!qualite?.selected && qualite?.checked) {
        qualitePlans += `
        {
          min: ${qualite?.items?.[1] ? parseInt(qualite?.items?.[1]) : 0}
          max: ${qualite?.items?.[2] ? parseInt(qualite?.items?.[2]) : 0}
          prime: ${qualite?.items?.[3] ? parseInt(qualite?.items?.[3]) : 0}
          qualite: {
            connect: {
              id: "${qualite?.id}"
            }
          }
        }
        `
      }
    });
    qualitePlans += `]`;


    var qualitePlansUpdate = `[`;
    data?.qualites?.forEach((qualite: any) => {
      if (qualite?.selected && qualite?.saveId) {
        qualitePlansUpdate += `
        {
          where: { id: "${qualite?.saveId}" }
          data: { 
            min: ${qualite?.items?.[1] ? parseInt(qualite?.items?.[1]) : 0}
            max: ${qualite?.items?.[2] ? parseInt(qualite?.items?.[2]) : 0}
            prime: ${qualite?.items?.[3] ? parseInt(qualite?.items?.[3]) : 0}
          }
        }
        `
      }
    });
    qualitePlansUpdate += `]`;

    
    var qualitePlansDelete = `[`;
    data?.qualites?.forEach((qualite: any) => {
      if (qualite?.selected && qualite?.checked == false) {
        qualitePlansDelete += `
        {
          id: "${qualite?.saveId}"
        }
        `
      }
    });
    qualitePlansDelete += `]`;


    var planCategorieActes = `[`;
    data?.acteMedicaux?.forEach((planCategorie: any) => {
      if (!planCategorie?.selected && planCategorie?.checked) {
        planCategorieActes += `
        {
          tauxCouverture: ${planCategorie?.items?.[1] ? parseInt(planCategorie?.items?.[1]) : data?.tauxCouverture}
          categorieActe: {
            connect: {
              id: "${planCategorie?.id}"
            }
          }
        }
        `
      } 
    });
    planCategorieActes += `]`;

    var planCategorieActesUpdate = `[`;
    data?.acteMedicaux?.forEach((planCategorie: any) => {
      if (planCategorie?.selected && planCategorie?.saveId) {
        planCategorieActesUpdate += `
        {
          where: { id: "${planCategorie?.saveId}" }
          data: {
            tauxCouverture: ${planCategorie?.items?.[1] ? parseInt(planCategorie?.items?.[1]) : data?.tauxCouverture}
          }
        }
        `
      } 
    });
    planCategorieActesUpdate += `]`;

    var planCategorieActesDelete = `[`;
    data?.acteMedicaux?.forEach((planCategorie: any) => {
      if (planCategorie?.selected && planCategorie?.checked == false) {
        planCategorieActesDelete += `
        {
          id: "${planCategorie?.saveId}"
        }
        `
      }
    });
    planCategorieActesDelete += `]`;


    var delaisCarences = `[`;
    data?.delaisCarence?.forEach((delaisCarence: any) => {
      if (!delaisCarence?.selected && delaisCarence?.checked) {
        delaisCarences += `
        {
          libelleDuree: "${delaisCarence?.items?.[2]}"
          duree: ${delaisCarence?.items?.[1] ? parseInt(delaisCarence?.items?.[1]) : 0 }
          delaisCarence: {
            connect: {
              id: "${delaisCarence?.id}"
            }
          }
        }
        `
      }
    });
    delaisCarences += `]`;


    var delaisCarencesUpdate = `[`;
    data?.delaisCarence?.forEach((delaisCarence: any) => {
      if (delaisCarence?.selected && delaisCarence?.saveId) {
        delaisCarencesUpdate += `
        {
          where: { id: "${delaisCarence?.saveId}" }
          data: { 
            libelleDuree: "${delaisCarence?.items?.[2]}"
          duree: ${delaisCarence?.items?.[1] ? parseInt(delaisCarence?.items?.[1]) : 0 }
          }
        }
        `
      }
    });
    delaisCarencesUpdate += `]`;
    
    var delaisCarencesDelete = `[`;
    data?.delaisCarence?.forEach((delaisCarence: any) => {
      if (delaisCarence?.selected && delaisCarence?.checked == false) {
        delaisCarencesDelete += `
        {
          id: "${delaisCarence?.saveId}"
        }
        `
      }
    });
    delaisCarencesDelete += `]`;

    var options = `[`;
    data?.options?.forEach((option: any) => {
      if (!option?.id) {
        options += `
        {
          nom: "${option?.nom}"
          forfaitaire: ${option?.forfaitaire ? true : false}
          prime: ${option?.prime}
        }
        `
      }
    });
    options += `]`;


    var optionsUpdate = `[`;
    data?.options?.forEach((option: any) => {
      if (option?.id && !data?.removedOptions?.find((op: any) => op?.id == option?.id)) {
        optionsUpdate += `
        {
          where: { id: "${option?.id}" }
          data: { 
            nom: "${option?.nom}"
            forfaitaire: ${option?.forfaitaire ? true : false}
            prime: ${option?.prime}
          }
        }
        `
      }
    });
    optionsUpdate += `]`;

    var optionsDelete = `[`;
    data?.removedOptions?.forEach((option: any) => {
      optionsDelete += `
        {
          id: "${option?.id}"
        }
        `
    });
    optionsDelete += `]`;

    var entreprises = `[`;
    data?.entreprises?.forEach((entreprise: any) => {
      entreprises += `
      {
        id: "${entreprise}"
      }
      `
    });
    entreprises += `]`;

    var entreprisesDelete = `[`;
    data?.removedEntreprises?.forEach((entreprise: any) => {
      entreprisesDelete += `
      {
        id: "${entreprise}"
      }
      `
    });
    entreprisesDelete += `]`;

    console.log(`updatePlanSanteDelaisCarences(
      data: ${delaisCarencesUpdate}
    ) {
      id
    }`)
    
    const request = this.apolloProvider.mutate({
      mutation: gql`
      ${
        data?.newImage ?
        `mutation($upload: Upload!){`
        :
        `mutation {`
      }
      updateOptionSantes(
          data: ${optionsUpdate}
        ) {
          id
        }
        updatePlanSanteDelaisCarences(
          data: ${delaisCarencesUpdate}
        ) {
          id
        }
        updateQualitePlans(
          data: ${qualitePlansUpdate}
        ) {
          id
        }
        updatePlanCategorieActes(
          data: ${planCategorieActesUpdate}
        ) {
          id
        }
        updatePlanSante(
          where: {
            id: "${currentId}"
          }
          data: {
            nom: "${data?.nom}"
            ${
              data?.description ?
              `description: "${data?.description}"`
              : 
              ``
            }
            ${
              data?.newImage ?
              `
                image: {
                  upload: $upload
                }
              `
              : 
              ``
            }
            prime: ${data?.prime}
            forfaitaire: ${data?.forfaitaire ? true : false}
            particulier: ${data?.particulier ? true : false}
            territorialite: ${data?.territorialite}
            plafond1erAnnee: ${data?.plafond1erAnnee}
            plafond2emeAnnee: ${data?.plafond2emeAnnee}
            tauxCouverture: ${data?.tauxCouverture}
            planSanteDelaisCarences: {
              create: ${delaisCarences}
              disconnect: ${delaisCarencesDelete}
            }
            entreprises: {
              connect: ${entreprises}
              disconnect: ${entreprisesDelete}
            }
            compagnie: {
              connect: {
                id: "${data?.compagnie}"
              }
            }
            risque: {
              connect: {
                id: "${data?.risque}"
              }
            }
            qualitePlans: {
              create: ${qualitePlans}
              disconnect: ${qualitePlansDelete}
            }
            planCategorieActes: {
              create: ${planCategorieActes}
              disconnect: ${planCategorieActesDelete}
            }
            options: {
              create: ${options}
              disconnect: ${optionsDelete}
            }
          }
        ) {
          id
        }
      }
    `,
    variables: {
      upload: data?.image
    },
    context: {
       useMultipart: true
    }
    });
    return request
  }

  updateSpecialite(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateSpecialite(
            where: {
              id: "${currentId}"
            }
            data: {
              nom: "${data?.nom}"
              domaine: ${data?.domaine}  
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  updateCategorieActe(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateCategorieActe(
            where: {
              id: "${currentId}"
            }
            data: {
              nom: "${data?.nom}"
              specialite : {
                connect : {
                  id : "${data?.specialite}"
                }
              } 
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  updateActeMedical(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateActeMedical(
            where: {
              id: "${currentId}"
            }
            data: {
              nom: "${data?.nom}"
              domaine : ${data?.domaineActe}
              coef : ${data?.coef}
              prix : ${data?.prix}
              categorieActe :{
                connect : {
                  id :"${data?.categorieActe}"
                }
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }
  updateMedicament(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateMedicament(
            where :{
              id : "${currentId}",
            }
            data : {
              nom :"${data?.nom}"
              denominationCommueInternationale : "${data?.denomComInter}"
              indications :"${data?.indications}"
              formeEtDosage :"${data?.formeEtDosage}"
              presentation :"${data?.presentation}"
              type :"${data?.type}"
              obs : "${data?.obs}"
            }
          ){
            id
          }
        }
      `
    });
    return request
  }

  updateParametrage(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateParametrage(
            where: {
              id: "${currentId}"
            }
            data: {
              valeur: "${data?.valeur}"
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createAccessoire(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createAccessoire(
            data: {
              accessoires: ${data?.accessoires?.length > 0 ? JSON.stringify(data?.accessoires)?.replace(/"([^"]+)":/g, '$1:') : `[]`}
              tauxCommissionAccessoire: ${data?.tauxCommissionAccessoire}
              tauxCommissionPrime: ${data?.tauxCommissionPrime}
              compagnie: {
                connect: {
                  id: "${data?.compagnie}"
                }
              }
              risque: {
                connect: {
                  id: "${data?.risque}"
                }
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createChamp(data: any) {
    var options = `[`;
    data?.options?.forEach((value: any) => {
      options += `{
          cle: "${value?.cle}",
          valeur: "${value?.valeur}"
        }`;
    });
    options += `]`;
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createChampFormulaire(
            data: {
              code: "${data?.code}"
              placeholder: "${data?.placeholder}"
              typeReponse: ${data?.typeReponse}
              typeChamp: ${data?.typeChamp}
              taille: ${data?.taille}
              ordre: ${data?.ordre}
              actif: true
              obligatoire: ${data?.obligatoire}
              infoRelance: ${data?.infoRelance}
              formulaire: {
                connect: {
                  id: "${data?.formulaireId}"
                }
              }
              options: {
                create: ${options}
              }
            }
          )
          {
            id
            code
            placeholder
            typeReponse
            obligatoire
            infoRelance
            typeChamp
            options {
              id
              cle
              valeur
            }
            taille
            ordre
            config
          }
        }
      `
    });
    return request
  }

  createFormulaireAndChamp(data: any) {
    var options = `[`;
    data?.options?.forEach((value: any) => {
      options += `{
          cle: "${value?.cle}",
          valeur: "${value?.valeur}"
        }`;
    });
    options += `]`;
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createChampFormulaire(
            data: {
              code: "${data?.code}"
              placeholder: "${data?.placeholder}"
              typeReponse: ${data?.typeReponse}
              typeChamp: ${data?.typeChamp}
              taille: ${data?.taille}
              ordre: ${data?.ordre}
              actif: true
              obligatoire: ${data?.obligatoire}
              infoRelance: ${data?.infoRelance}
              formulaire: {
                create: {
                  nom: "${data?.branche?.nom}"
                  titre: "Info ${data?.branche?.nom}"
                  branche: {
                    connect: {
                      id: "${data?.branche?.id}"
                    }
                  }
                }
              }
              options: {
                create: ${options}
              }
            }
          )
          {
            id
          }
        }
      `
    });
    return request
  }

  updateAccessoire(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateAccessoire(
            where: {
              id: "${currentId}"
            }
            data: {
              accessoires: ${data?.accessoires?.length > 0 ? JSON.stringify(data?.accessoires)?.replace(/"([^"]+)":/g, '$1:') : `[]`}
              tauxCommissionAccessoire: ${data?.tauxCommissionAccessoire}
              tauxCommissionPrime: ${data?.tauxCommissionPrime}
              compagnie: {
                connect: {
                  id: "${data?.compagnie}"
                }
              }
              risque: {
                connect: {
                  id: "${data?.risque}"
                }
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createRisque(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createRisqueBranche(
            data: {
              nom: "${data?.nom}"
              branche: {
                connect: {
                  id: "${data?.branche}"
                }
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createAvenant(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createAvenant(
            data: {
              ${
                data?.commentaire ? `commentaire: "${data?.commentaire}"` : ``
              }
              ${
                data?.police ? 
                `police: {
                  connect: {
                    id: "${data?.police}"
                  }
                }`
                : ``
              }
              ${
                data?.quittance ? 
                `quittance: {
                  connect: {
                    id: "${data?.quittance}"
                  }
                }`
                : ``
              }
              categorieAvenant: {
                connect: {
                  id: "${data?.categorieAvenant}"
                }
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  updateAvenant(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateAvenant(
            where: {
              id: "${currentId}"
            }
            data: {
              ${
                data?.commentaire ? `commentaire: "${data?.commentaire}"` : ``
              }
              ${
                data?.police ? 
                `police: {
                  connect: {
                    id: "${data?.police}"
                  }
                }`
                : ``
              }
              ${
                data?.quittance ? 
                `quittance: {
                  connect: {
                    id: "${data?.quittance}"
                  }
                }`
                : ``
              }
              categorieAvenant: {
                connect: {
                  id: "${data?.categorieAvenant}"
                }
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createQuittance(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createQuittance(
            data: {
              dateCreation: "${data?.dateCreation}"
              dateEffet: "${data?.dateEffet}"
              dateEcheance: "${data?.dateEcheance}"
              primeNette: ${data?.primeNette}
              accessoire: ${data?.accessoire}
              taxe: ${data?.taxe}
              primeTotale: ${data?.primeTotale}
              observation: "${data?.observation}"
              typeRelance: ${data?.typeRelance}
              avenant: {
                connect: {
                  id: "${data?.avenant}"
                }
              }
              police: {
                connect: {
                  id: "${data?.police}"
                }
              }
              ${
                data?.quittanceRenouvelle ?
                `
                quittanceRenouvelle:  {
                  connect: {
                    id: "${data?.quittanceRenouvelle}"
                  }
                }
                `
                : 
                ``
              }
              paiements: {
                connect: {
                  id: "${data?.paymentId}"
                }
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createPolice(data: any) {
    console.log(data)
    var infoStr = `[`;
    Object.keys(data?.infoPolice).forEach((info: any) => {
      if (info != 'readOnly' && info != 'paymentId' && data?.infoPolice?.[info]) {
        infoStr += `{
          cle: "${info}"
          libelle: "${data?.formulaire?.champs?.find((f: any) => f.code == info)?.placeholder}"
          valeur: "${data?.infoPolice?.[info]}"
          typeChamp: ${data?.formulaire?.champs?.find((f: any) => f.code == info)?.typeChamp}
        }`;
      }
    });
    infoStr += `]`;
    
    var familleStr = `[`;
    data?.tarification?.familles.forEach((famille: any) => {
      familleStr += `
      {
        nom: "${famille?.[0]?.prenom} ${famille?.[0]?.nom}"
        qualiteMembres: {
          create: [
      `;
      famille?.forEach((membre: any) => {
        familleStr += `
          {
            membre: {
              create: {
                prenom: "${membre?.prenom}"
                nom: "${membre?.nom}"
                dateNaissance: "${membre?.dateNaissance}"
                telephone: "${membre?.telephone}"
              }
            }
            qualite: {
              connect: {
                id: "${membre?.qualite}"
              }
            }
          }
        `
      })
      familleStr += `
          ]
        }
      }
      `
    });
    familleStr += `]`;


    var options = `[`;
    data?.tarification?.selectedOptions?.forEach((option: any) => {
      if (option?.selected) {
        options += `{
          id: "${option?.id}"
        }`;
      }
    });
    options += `]`;

    console.log(`
    mutation {
      createPolice(
        data: {
          police: "${data?.police}"
          compagnie: {
            connect: {
              id: "${data?.compagnie}"
            }
          }
          ${
            data?.tarifIncluded ? 
            `
            planSante: {
              connect: {
                id: "${data?.tarification?.id}"
              }
            }
            options: {
              connect: ${options}
            }
            familles: {
              create: ${familleStr}
            }
            `
            :
            ``
          }
          ${
            data?.tarifIncluded && !data?.tarification?.isParticulierSelected ? 
            `
            entreprise: {
              connect: {
                id: "${data?.tarification?.entrepriseId}"
              }
            }
            `
            :
            ``
          }
          ${
            data?.client != '0' ?
            `
              client: {
                connect: {
                  id: "${data?.client}"
                }
              }
            `
            : 
            `
              client: {
                create: {
                  prenom: "${data?.clientObj?.prenom}"
                  nom: "${data?.clientObj?.nom}"
                  ${
                    data?.entrepriseObj?.contacts?.[0]?.contact ? 
                    `
                    contacts: {
                      create: [
                        {
                          contact: "${data?.entrepriseObj?.contacts?.[0]?.contact}"
                        }
                      ]
                    }
                    `
                    :
                    ``
                  }
                  ${data?.tarification?.isParticulierSelected ? `estEntreprise: false` : `estEntreprise: true`}
                  ${
                    !data?.tarification?.isParticulierSelected ?
                    `
                    entreprise: {
                      connect: {
                        id: "${data?.tarification?.entrepriseId}"
                      }
                    }
                    `
                    :
                    ``
                  }
                }
              }
            `
          }
          apporteur: {
            connect: {
              id: "${data?.apporteur}"
            }
          }
          risque: {
            connect: {
              id: "${data?.risque}"
            }
          }
          infoPolices: {
            create: ${infoStr}
          }
        }
      ) {
        id
      }
    }
  `);

    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createPolice(
            data: {
              police: "${data?.police}"
              compagnie: {
                connect: {
                  id: "${data?.compagnie}"
                }
              }
              ${
                data?.tarifIncluded ? 
                `
                planSante: {
                  connect: {
                    id: "${data?.tarification?.id}"
                  }
                }
                options: {
                  connect: ${options}
                }
                familles: {
                  create: ${familleStr}
                }
                `
                :
                ``
              }
              ${
                data?.tarifIncluded && !data?.tarification?.isParticulierSelected ? 
                `
                entreprise: {
                  connect: {
                    id: "${data?.tarification?.entrepriseId}"
                  }
                }
                `
                :
                ``
              }
              ${
                data?.client != '0' ?
                `
                  client: {
                    connect: {
                      id: "${data?.client}"
                    }
                  }
                `
                : 
                `
                  client: {
                    create: {
                      prenom: "${data?.clientObj?.prenom}"
                      nom: "${data?.clientObj?.nom}"
                      ${
                        data?.entrepriseObj?.contacts?.[0]?.contact ? 
                        `
                        contacts: {
                          create: [
                            {
                              contact: "${data?.entrepriseObj?.contacts?.[0]?.contact}"
                            }
                          ]
                        }
                        `
                        :
                        ``
                      }
                      ${data?.tarification?.isParticulierSelected ? `estEntreprise: false` : `estEntreprise: true`}
                      ${
                        !data?.tarification?.isParticulierSelected ?
                        `
                        entreprise: {
                          connect: {
                            id: "${data?.tarification?.entrepriseId}"
                          }
                        }
                        `
                        :
                        ``
                      }
                    }
                  }
                `
              }
              apporteur: {
                connect: {
                  id: "${data?.apporteur}"
                }
              }
              risque: {
                connect: {
                  id: "${data?.risque}"
                }
              }
              infoPolices: {
                create: ${infoStr}
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  updatePoliceFamilles(policeId: string, data: any) {
    var familleStr = `[`;
    data?.tarification?.familles.forEach((famille: any) => {
      familleStr += `
      {
        nom: "${famille?.[0]?.prenom} ${famille?.[0]?.nom}"
        qualiteMembres: {
          create: [
      `;
      famille?.forEach((membre: any) => {
        familleStr += `
          {
            membre: {
              create: {
                prenom: "${membre?.prenom}"
                nom: "${membre?.nom}"
                dateNaissance: "${membre?.dateNaissance}"
              }
            }
            qualite: {
              connect: {
                id: "${membre?.qualite}"
              }
            }
          }
        `
      })
      familleStr += `
          ]
        }
      }
      `
    });
    familleStr += `]`;
    
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updatePolice(
            where: {
              id: "${policeId}"
            }
            data: {
              familles: {
                create: ${familleStr}
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  createCloture(data: any) {
    var quittances = `[`;
    data?.quittances.forEach((quittance: any) => {
      quittances += `{
        id: "${quittance}"
      }`;
    });
    quittances += `]`;
    const request = this.apolloProvider.mutate({
      mutation: gql`
      mutation($upload: Upload!){
        createCloture(
          data: {
            banqueCheque: "${data?.banque}"
            numeroCheque: "${data?.numeroCheque}"
            debut: "${data?.debut}"
            fin: "${data?.fin}"
            montant: ${data?.montantCheque}
            compagnie: {
              connect: {
                id: "${data?.compagnie}"
              }
            }
            quittances: {
              connect: ${quittances}
            }
            chequeImage: {
              upload: $upload
            }
          }
        ) {
          id
        }
      }
      `,
      variables: {
        upload: data?.chequeImage
      },
      context: {
         useMultipart: true
      }
    });
    return request
  }

  updateQuittance(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateQuittance(
            where: {
              id: "${currentId}"
            }
            data: {
              dateCreation: "${data?.dateCreation}"
              dateEffet: "${data?.dateEffet}"
              dateEcheance: "${data?.dateEcheance}"
              primeNette: ${data?.primeNette}
              accessoire: ${data?.accessoire}
              typeRelance: ${data?.typeRelance}
              taxe: ${data?.taxe}
              primeTotale: ${data?.primeTotale}
              observation: "${data?.observation}"
              avenant: {
                connect: {
                  id: "${data?.avenant}"
                }
              }
              police: {
                connect: {
                  id: "${data?.police}"
                }
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  relance(data: any, relanceType = 'ECHEANCE') {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          relance (
            quittanceIds: "${data}",
            relanceType: "${relanceType}"
          )
        }
      `
    });
    return request
  }



  sendWhatsappMessage(data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
      mutation {
        whatsapp (
          to: "${data?.to}",
          template: "${data?.template}",
          params: "${data?.params}",
          buttonUrl: "${data?.buttonUrl}"
        )
      }
      `
    });
    return request
  }

  updatePolice(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updatePolice(
            where: {
              id: "${currentId}"
            }
            data: {
              police: "${data?.police}"
              compagnie: {
                connect: {
                  id: "${data?.compagnie}"
                }
              }
              client: {
                connect: {
                  id: "${data?.client}"
                }
              }
              apporteur: {
                connect: {
                  id: "${data?.apporteur}"
                }
              }
              risque: {
                connect: {
                  id: "${data?.risque}"
                }
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  updateRisque(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateRisqueBranche(
            where: {
              id: "${currentId}"
            }
            data: {
              nom: "${data?.nom}"
              branche: {
                connect: {
                  id: "${data?.branche}"
                }
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  updateBranche(currentId: string, data: any) {
    var newRisques = `[`;
    data.newRisques.forEach((value: any) => {
      newRisques +=`{
          nom: "${value.nom}"
       }
      `
    })
    newRisques += `]`;

    var removedRisques = `[`;
    data.removedRisques.forEach((value: any) => {
      removedRisques +=`{
          id: "${value.id}"
       }
      `
    })
    removedRisques += `]`;
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateBranche(
            where: {
              id: "${currentId}"
            }
            data: {
              nom: "${data?.nom}"
              aRelancer: ${data?.aRelancer}
              risques: {
                create: ${newRisques}
                disconnect: ${removedRisques}
              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  updateCompagnie(currentId: string, data: any) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateCompagnie(
            where: {
              id: "${currentId}"
            }
            data: {
              code: "${data?.code}"
              raisonSociale: "${data?.raisonSociale}"
              responsable: "${data?.responsable}"
              telephone: "${data?.telephone}"
              BP: "${data?.BP}"
              FAX: "${data?.FAX}"
              codeCourtier: "${data?.codeCourtier}"
              tauxAccessoires: ${data?.tauxAccessoires}
              tauxTaxe: ${data?.tauxTaxe}
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }


  updateEntreprise(currentId: string, data: any) {
    var newContacts = `[`;
    data.newContacts.forEach((value: any) => {
      newContacts +=`{
          contact: "${value.contact}"
       }
      `
    })
    newContacts += `]`;

    var removedContacts = `[`;
    data.removedContacts.forEach((value: any) => {
      removedContacts +=`{
          id: "${value.id}"
       }
      `
    })
    removedContacts += `]`;

    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateEntreprise(
            where: {
              id: "${currentId}"
            }
            data: {
              raisonSociale: "${data?.raisonSociale}"
              emplacement: "${data?.emplacement}"
              ${
                (data?.infosNewResponsable)
                ? 
                  `responsable : {
                    create : {
                      nom : "${data?.infosNewResponsable?.nom}"
                      prenom : "${data?.infosNewResponsable?.prenom}"
                      phoneNumber : "${data?.infosNewResponsable?.telephone}"
                      email : "${data?.infosNewResponsable?.email}"
                      roles : RESPONSABLE_ENTREPRISE
                      password : "PROASSUR_TMP_PASSWORD"
                    }
                  }`
                : ``                  
              }
              contacts: {
                create: ${newContacts}
                disconnect: ${removedContacts}
              }
              connexe: ${data?.connexe?.length > 0 ? JSON.stringify(data?.connexe)?.replace(/"([^"]+)":/g, '$1:') : `[]`}
              adresse: "${data?.adresse ? data?.adresse : ''}"
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  updateClient(currentId: string, client: any, isAddEmploye : boolean = false) {
    var newContacts = `[`;
    client.newContacts.forEach((value: any) => {
      newContacts +=`{
          contact: "${value.contact}"
       }
      `
    })
    newContacts += `]`;

    var removedContacts = `[`;
    client.removedContacts.forEach((value: any) => {
      removedContacts +=`{
          id: "${value.id}"
       }
      `
    })
    removedContacts += `]`;
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateClient(
            where: {
              id: "${currentId}"
            }
            data: {
              prenom: "${client?.prenom}"
              nom: "${client?.nom}"
              email: "${client?.email}"
              emplacement: "${client?.emplacement}"
              contacts: {
                create: ${newContacts}
                disconnect: ${removedContacts}
              }
              connexe: ${client?.connexe?.length > 0 ? JSON.stringify(client?.connexe)?.replace(/"([^"]+)":/g, '$1:') : `[]`}
              adresse: "${client?.adresse ? client?.adresse : ''}"
              ${
                client?.entreprise ?
                `
                  entreprise: {
                    connect: {
                      id: "${client?.entreprise}"
                    }
                  }
                `
                : 
                ``
              }
              ${
                isAddEmploye ?
                `
                entreprise: {
                  connect: {
                    id: "${this.getUser()?.entreprise?.id}"
                  }
                }
              `:``


              }
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  updateStructureSante(currentId: string, structureSante: any, isConnect : boolean) {

    var newContacts = `[`;
    structureSante.newContacts.forEach((value: any) => {
      newContacts +=`{
          contact: "${value.contact}"
       }
      `
    })
    newContacts += `]`;

    var removedContacts = `[`;
    structureSante.removedContacts.forEach((value: any) => {
      removedContacts +=`{
          id: "${value.id}"
       }
      `
    })
    removedContacts += `]`;
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          updateStructureSante(
            where: {
              id: "${currentId}"
            }
            data: {
              nom: "${structureSante?.nom}"
              type: ${structureSante?.type}
              adresse: "${structureSante?.adresse}"
              contacts: {
                create: ${newContacts}
                disconnect: ${removedContacts}
              }
              responsable : {
                ${
                  (isConnect)
                  ? `
                    connect : {
                      id : "${structureSante?.responsable}"
                    }
                  `
                  : `
                    create : {
                      nom : "${structureSante?.infosNewResponsable?.nom}"
                      prenom : "${structureSante?.infosNewResponsable?.prenom}"
                      phoneNumber : "${structureSante?.infosNewResponsable?.telephone}"
                      email : "${structureSante?.infosNewResponsable?.email}"
                      password : "PROASSUR_TMP_PASSWORD"
                    }
                  `
                }
              } 
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }


  deleteMutation(modelName: string, id: string) {
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          update${modelName}(
            where: {
              id: "${id}"
            }
            data: {
              deleted: true
            }
          ) {
            id
          }
        }
      `
    });
    return request
  }

  generateContrat(id: string, souscriptionId: string, inputKeys: any, infoContrats: any) {
    var inputString = `[`;
    inputKeys.forEach((inp: any) => {
      inputString += `{
        cle: "${inp?.cle}",
        valeur: ${(inp?.type == 'NUMBER') ? inp?.valeur : '"' + inp?.valeur + '"'}
      }`;
    });
    inputString += `]`;
    var infoContratString = `[`;
    infoContrats.forEach((inp: any) => {
      infoContratString += `{
        cle: "${inp?.cle}",
        sheetName: "${inp?.sheetName}"
        valeur: ${(inp?.type == 'NUMBER') ? inp?.valeur : '"' + inp?.valeur + '"'}
      }`;
    });
    infoContratString += `]`;
    const request = this.apolloProvider.mutate({
      mutation: gql`
      mutation {
        generateContrat(
          id: "${id}"
          idSouscription: "${souscriptionId}"
          inputs: ${inputString}
          infoContrats: ${infoContratString}
        ) 
      }
      `,
    });
    return request
  }


  createSouscription(souscription: any) {
    var inputResult = `[`;
    souscription?.result.forEach((inp: any) => {
      inputResult += `{
        cle: "${inp.cle}",
        valeur: "${inp.valeur}"
      }`;
    });
    inputResult += `]`;
    var inputOutput = `[`;
    souscription?.output.forEach((inp: any) => {
      inputOutput += `{
        cle: "${inp.cle}",
        valeur: "${inp.valeur}"
      }`;
    });
    inputOutput += `]`;
    var infoClient = `[`;
    souscription?.infoClient.forEach((inp: any) => {
      infoClient += `{
        cle: "${inp[0]}",
        valeur: "${inp[2]}"
      }`;
    });
    infoClient += `]`;
    const request = this.apolloProvider.mutate({
      mutation: gql`
        mutation {
          createSouscription(
            data: {
              date: "${souscription.date}"
              price: ${souscription.price}
              status: EN_COURS
              agent: {
                connect: {
                  id: "${souscription.agentId}"
                }
              }
              partenaire: {
                connect: {
                  id: "${souscription.partenaireId}"
                }
              }
              typeFinancement: {
                connect: {
                  id: "${souscription.typeFinancementId}"
                }
              }
              methodePaiement: {
                connect: {
                  id: "${souscription.paymentMethodId}"
                }
              }
              resultat: ${inputResult}
              output: ${inputOutput}
              infoClient: ${infoClient}
            }
          ) {
            id
          }
        }
      `,
    });
    return request
  }

}
