import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RisqueBrancheRoutingModule } from './risque-branche-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RisqueBrancheRoutingModule
  ]
})
export class RisqueBrancheModule { }
