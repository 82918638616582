import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompagnieRoutingModule } from './compagnie-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CompagnieRoutingModule
  ]
})
export class CompagnieModule { }
