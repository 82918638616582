import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-police-sante',
  templateUrl: './police-sante.component.html',
  styleUrls: ['./police-sante.component.css']
})
export class PoliceSanteComponent implements OnInit {

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  polices: any = [];
  headerInitialed = false;

  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'Police';

  searchText = '';

  constructor(
    private dataService: DataService,
    private message: NzMessageService
  ) { }

  ngOnInit(): void {
    this.getPoliceSantes();
  }
  

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter un police",
      titre: 'Police',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formulaires: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  initializeHeaders() {
    this.headers = [
      {
        name: 'Prenom',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Nom',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Téléphone',
        actif: true,
        position: 'left',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Désignation',
        actif: true,
        position: 'left',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Taux de Commission',
        actif: true,
        position: 'left',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  handleFilter() {
    var _filter = `
    AND: [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    ]
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getPoliceSantes();
  }

  

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  handleColumnChanged() {
    this.polices = this.getPoliceSantes();
   

  }

  formulaireEvent(data: any) {
    if (this.formType == 'ADD') {
      this.dataService.createPolice(
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getPoliceSantes();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    else {
      this.dataService.updatePolice(
        this.currentId,
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getPoliceSantes();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de la modification`);
        },
      )
    }
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getPoliceSantes();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getPoliceSantes();
  }

  getPoliceSantes() {  
    this.dataService.getPoliceSantes(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.polices = this.handleList([...data?.data?.entreprises, {
          raisonSociale: "Clients particuliers",
          polices: data?.data?.polices
        }])
        // console.log(this.polices);
        this.totalCount = data?.data?.entreprisesCount + 1;
        if (!this.headerInitialed) {
          this.initializeHeaders();
          this.headerInitialed = true;
        }
         
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getPoliceSantes()
    })
  }

  handleList(data: any) {
    let list = data.map((row: any) => ({
        name: `${row?.raisonSociale} (${row?.polices?.length} police${row?.polices?.length > 1 ? 's' : ''})`,
        children: row?.polices?.map((police: any) => {
          return {
            name: police?.police,
            children: police.familles?.map((famille: any) => {
              return {
                name: `famille ${famille?.nom}`,
                children: famille.qualiteMembres?.map((qualiteMembre: any) => {
                  return {
                    name: `${qualiteMembre?.membre?.prenom} ${qualiteMembre?.membre?.nom}(${qualiteMembre?.qualite?.nom})`,
                    id: famille?.id
                  }
                })
              }
            })
          }
        })
      }
    ));
    // this.dataSource?.setData(list);
    return list;
  }


  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getPoliceById(action?.value);
      break;
      case 'delete':
        this.getPoliceSantes();
      break;
    }
  }

  getPoliceById(id: string) {
    this.dataService.getPoliceById(id)
    .subscribe(
      (data: any) => {
        this.initForm(data?.data?.police);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.initForm();
  }

  initForm(data: any = {}) {
    if (data?.id) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      {
        code: "prenom",
        placeholder: "Prénom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.prenom),
        defaultValue: data?.prenom,
        size: 'col-md-6'
      },
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.nom),
        defaultValue: data?.nom,
        size: 'col-md-6'
      },
      {
        code: "designation",
        placeholder: "Désignation",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        defaultValue: data?.designation,
        size: 'col-md-12'
      },
      {
        code: "telephone",
        placeholder: "Téléphone",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        defaultValue: data?.telephone,
        size: 'col-md-6'
      },
      {
        code: "tauxCommission",
        placeholder: "Taux de commission",
        typeChamp: "NUMBER",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.tauxCommission),
        defaultValue: data?.tauxCommission,
        size: 'col-md-6'
      },
    ]
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
  }

}
