<!-- row -->
<div class="container-fluid">
    <div class="form-head d-flex mb-sm-4 mb-3 margin-left-right-35">
        <div class="mr-auto">
            <h1 class="text-black font-w600">Avenants</h1>
        </div>
        <div>
            <a (click)="openAddForm()" href="javascript:void(0)" class="btn btn-primary mr-3" data-toggle="modal" data-target="#addOrderModal">Ajouter avenant</a>
            <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzClickHide]="false" [nzDropdownMenu]="colonne" class="btn btn-outline-primary"><i class="fa fa-plus scale5 mr-3"></i>{{headersActif()}} {{headersActif() <= 1 ? 'colonne' : 'colonnes'}}</a>
        </div>
    </div>
    <!-- Add Order -->
    <app-form 
        (onCloseForm)="onCloseForm()" 
        [isFormLoading]="isFormLoading"
        [formTab]="formTab" 
        *ngIf="isFormOpened"
        [formulaires]="formulaires"
        (formulaireEvent)="formulaireEvent($event)"
        [formType]="formType"
        [modelName]="modelName"
        (onUpload)="onUploadEvent($event)"
        [chargementMutation]="'createChargementAvenant'"
        (newFormulaireOpened)="newFormulaireOpened($event)"
        [formulaireBrancheExist]="formulaireBrancheExist"
        [infoPoliceCorrect]="infoPoliceCorrect"
        (infoPoliceButtonEvent)="infoPoliceButtonEvent($event)"
        >
    </app-form>
    <app-table 
        [headers]="filteredHeader" 
        [listOfData]="avenants"  
        [totalCount]="totalCount"
        [currentPage]="currentPage"
        (onTableUpdate)="onTableUpdate($event)"
        (onSearchTextChanged)="onSearchTextChanged($event)"
        [pageCount]="pageCount"
        [modelName]="modelName"
        title="Liste des avenants"
        (onAction)="onAction($event)"
    >
    </app-table>

    <app-form 
        class="app-sub-form" 
        [formWidth]="'630px'"
        [opacity]="'0.3'"
        [isFormLoading]="isFormLoading"
        (onCloseForm)="closeClientDrawer()" 
        [formTab]="formClientTab" 
        *ngIf="clientDrawer"
        [formulaires]="clientFormulaires"
        (formulaireEvent)="formulaireClientEvent($event)"
        [formType]="formType"
        [modelName]="'Client'"
    >
    </app-form>

    <!-- // Formulaire pour l'ajout d'un nouveau Client  -->
    <nz-drawer
      [nzClosable]="false"
      [nzWidth]="700"
      [nzVisible]="clientDrawer"
      nzPlacement="right"
      (nzOnClose)="closeClientDrawer()"
    >
      <ng-container *nzDrawerContent>
        <app-form 
            class="app-sub-form" 
            [formWidth]="'700px'"
            [opacity]="'0.3'"
            [isFormLoading]="isFormLoading"
            (onCloseForm)="closeClientDrawer()" 
            [formTab]="formClientTab" 
            *ngIf="clientDrawer"
            [formulaires]="clientFormulaires"
            (formulaireEvent)="formulaireClientEvent($event)"
            [formType]="formType"
            [modelName]="'Client'"
        >
        </app-form>
      </ng-container>
    </nz-drawer>

    <!-- Formulaire pour les infos de la police -->
    <nz-drawer
      [nzClosable]="false"
      [nzWidth]="700"
      [nzVisible]="infoPoliceDrawer"
      nzPlacement="right"
      nzTitle="Info police"
      (nzOnClose)="closeInfoPoliceDrawer()"
    >
      <ng-container *nzDrawerContent>
        <app-form 
            class="app-sub-form" 
            [formWidth]="'700px'"
            [opacity]="'0.3'"
            [isFormLoading]="isFormLoading"
            (onCloseForm)="closeInfoPoliceDrawer()" 
            [formTab]="formInfoPoliceTab" 
            *ngIf="infoPoliceDrawer"
            [formulaires]="infoPoliceFormulaires"
            (formulaireEvent)="formulaireInfoPoliceEvent($event)"
            [formType]="'EDIT'"
            [modelName]="'Info Police'"
        >
        </app-form>
      </ng-container>
    </nz-drawer>

    <nz-dropdown-menu #colonne="nzDropdownMenu">
        <ul nz-menu>
            <div class="margin-auto width-90 mt-2 mb-3">
                <input (keyup)="searchColumn()" [(ngModel)]="searchColumnText" type="text" class="form-control" nz-input placeholder="Rechercher une colonne" />
            </div>
          <li *ngFor="let header of headers; let i = index" nz-menu-item>
            <ng-container *ngIf="header?.type != 'action'">
                <label [disabled]="headersActif() <= 1 && header?.actif" [id]="header?.name" nz-checkbox (ngModelChange)="handleColumnChanged()" [(ngModel)]="headers[i].actif">{{header?.name}}</label>
            </ng-container>
          </li>
        </ul>
    </nz-dropdown-menu>
</div>