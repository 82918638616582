import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-souscription-prime',
  templateUrl: './create-souscription-prime.component.html',
  styleUrls: ['./create-souscription-prime.component.css']
})
export class CreateSouscriptionPrimeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
