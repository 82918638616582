import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PasswordForgottenRoutingModule } from './password-forgotten-routing.module';
import { PasswordForgottenComponent } from './password-forgotten.component';


@NgModule({
  declarations: [
    PasswordForgottenComponent
  ],
  imports: [
    CommonModule,
    PasswordForgottenRoutingModule
  ]
})
export class PasswordForgottenModule { }
