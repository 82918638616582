import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { log } from 'handsontable/helpers';
import { index } from 'handsontable/helpers/dom';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-structure-de-sante',
  templateUrl: './structure-de-sante.component.html',
  styleUrls: ['./structure-de-sante.component.css']
})
export class StructureDeSanteComponent implements OnInit {



  isFormOpened : boolean = false;
  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];
  formType : string = 'ADD';
  formulaires : any = [];
  modelName : string = 'StructureSante';

  structureSanteTypes : any = ['HOPITAL','CLINIQUE','PHARMACIE','PARTICULIER'];
  searchColumnText: string = '';
  structureSantes : any = [];
  totalCount : number = 1;
  currentPage : number = 1;
  pageCount : number = 5;
  searchText : string = '';
  currentId : string = '';
  headerInitialed : boolean = false;
  filterArray : any = [];
  filter : string = '';

  responsableDrawer : boolean = false;
  mainFormulaire : any;
  responsableFormulaires: any = [];
  infosResponsable : any;
  responsables : any = [];

  initializeResList : boolean = true;
  currentStructureSante : any = {};



  
  constructor(
    private dataService: DataService,
    private message: NzMessageService,
    private cdr: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
    this.getStructureSantes();
    this.getAllResponsablesBySearch('');
    this.initializeResList = false;

  }


  formTab  = [
    {
      code: 'ONE',
      nom: "Ajouter une service de santé",
      titre: 'Structure de santé',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formResTab = [
    {
      code: 'ONE',
      nom: "Ajouter le responsable",
      titre: 'Responsable',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]
  
  initializeHeaders() {
    this.headers = [
      {
        name: 'Nom',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Type',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null],
        dataFilter : this.structureSanteTypes.map((type : any) => {    
          return {
            text : type,
            value : type
          }
        }),
        filterFn: (list: string[], item: any) => {

          var oldFilter: string = '';
      
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('typeStructureSante') == -1) {
            this.filterArray.push({
              key: 'typeStructureSante',
              value: ''
            })
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('typeStructureSante');
            oldFilter = this.filterArray[index].value;
          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('typeStructureSante');


          if (!list.length) {

            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'typeStructureSante');
            this.handleFilter();
          } else {
            this.filterArray[index].value = `
              type : {
                in : [
                  ${
                    list?.map((type:any) => `${type}`)
                  }
                ]
              }
            `
            if (oldFilter != this.filterArray[index].value) {
              this.handleFilter();
            }
          }
        },
      },
      {
        name: 'Adresse',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Responsable',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Contact',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];

    this.allHeaders = this.headers;
    this.filteredHeader = this.headers;
  }


  handleFilter() {
    var _filter = `
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getStructureSantes();
  }

  handleColumnChanged() {
    this.structureSantes = this.getStructureSantes();
  }


  async formulaireEvent(data: any) {

    const newContacts = data?.contact?.filter((contact: any) => !contact?.id);
    const isValid : boolean = await this.isValideResponsableId(data?.responsable)
    const infosNewResponsable =  this.infosResponsable;

    if (this.formType == 'ADD'){

      // console.log(data?.responsable)
      this.dataService.createStructureDeSante(
        {
          ...data,
          newContacts,
          infosNewResponsable
        },
        isValid
       
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getStructureSantes();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout ${err}`);
        },
      )
    }
    else {

      const removedContacts = this.currentStructureSante?.contacts?.filter((contact: any) => !data?.contact?.map((cont: any) => cont?.id).includes(contact?.id));

      this.dataService.updateStructureSante(
        this.currentId,
        {
          ...data,
          newContacts,
          removedContacts,
          infosNewResponsable
        },
        isValid
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getStructureSantes();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de la modification`);
        },
      )
    }
  }

  formulaireResponsableEvent(responsable: any) {

    
    this.infosResponsable = responsable;
    const valeur = `${responsable?.prenom} ${responsable?.nom} - ${responsable?.telephone}`;

    // const newContacts = client?.contact?.filter((contact: any) => !contact?.id);

    var form: any = this.formulaires?.find((form: any) => form?.code == 'responsable');

    if (form){    
      form.options.push(
        {cle : `${responsable?.telephone}` , valeur}
      );
    }
    this.mainFormulaire.patchValue({
      responsable : responsable?.telephone
    });
    this.cdr.detectChanges();
    this.closeResDrawer();
    this.message.success(`Informations enregistrees avec succees`);
    // this.getAllResponsablesBySearch(responsable?.prenom);

  }


  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getStructureSantes();
  }

  getStructureSantes() {  
    this.dataService.getStructureSantes(this.currentPage, this.pageCount, this.filter , this.searchText)
    .subscribe(
      (data: any) => {
        this.structureSantes = this.handleList(data?.data?.structureSantes)
        this.totalCount = data?.data?.structureSantesCount;
        if (!this.headerInitialed) {
          this.initializeHeaders();
          this.headerInitialed = true;
        }
         
      },
      (err: any) => {
        console.log(`Error : ${err}`);
      },
    )
  }

  handleList(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.type,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.adresse,
        position: 'left',
      },
      {
        type: 'HTML',
        value : `
        
        <div class = "shadow-sm p-3  rounded"">
            <span class = "">
              <i class="fa fa-user btn   shadow-lg btn-xs sharp mr-1 btn-primary rounded-circle"  style : "background : #006b88"></i>
              <small>${row?.responsable?.prenom} ${row?.responsable?.nom}</small>
            </span>
        </div>

        `,
        // value: [`${row?.responsable?.prenom} ${row?.responsable?.nom}`, `Tel : ${row?.responsable?.phoneNumber}`],
        position: 'left',
        isPerson : true,
      },
      {
        type: 'LIST',
        value: row?.contacts?.map((contact: any) => contact?.contact)
      },
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }



  headersActif() : number {
    return this.filteredHeader?.filter((header : any) => header?.actif)?.length - 1;
  }

  searchColumn() : void{
    this.headers = this.allHeaders?.filter((header : any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));

  }

  
  openAddForm() : void {
    this.isFormOpened = true;
    this.initForm();
  }


  isValideResponsableId(responsableId: any): Promise<boolean> {

    return new Promise<boolean>((resolve) => {

      this.dataService.getResponsableById(responsableId).subscribe(
        (data: any) => {
          const isValid = !!data?.data?.user?.id;
          resolve(isValid);
        },
        (error) => {
          // console.error('Erreur lors de la recherche de l\'utilisateur par ID :', error);
          resolve(false);  
        }
      );
    });
  }

  getAllResponsablesBySearch(searchText: string) {
    // if (searchText && searchText?.length >= 2 || (this.initializeResList)) {
      this.dataService.getAllResponsablesBySearch(searchText).subscribe(
        (data: any) => {
         this.responsables = data?.data?.users;
          var form :any = this.formulaires?.find((form: any) => form?.code == 'responsable');
          if (form) {

            form.options = this.responsables?.map((data: any) => {
              var valeur = `${data?.prenom} ${data?.nom} - ${data?.phoneNumber}`;
             
              return {
                cle: data?.id,
                valeur
              }
            })
          }
          this.cdr.detectChanges();
        },
        (err: any) => {
          console.log(err);
        },
      )
    // }
  }

  initForm(data: any = {}) {

    // console.log(data?.responsable?.id)


    if (data?.id) {
      this.formType = 'EDIT';
    }

    this.formulaires = [
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.nom),
        defaultValue: data?.nom,
        size: 'col-md-6'
      },
      {
        code: "type",
        placeholder: "Type",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        obligatoire: !!(this.formType == 'ADD' || data?.type),
        defaultValue: data?.type,
        size: 'col-md-6',
       
        options: this.structureSanteTypes?.map((data: string) => {
          return {
            cle: data,
            valeur: data
          }
        }),
      },
      {
        code: "adresse",
        placeholder: "Adresse",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        defaultValue: data?.adresse,
        size: 'col-md-12',
        obligatoire: true
      },
      {
        code : "responsable",
        // placeholder: "Svp, veuillez cliquer sur l'icone  ➕  pour ajouter le responsable",
        placeholder : 'Responsable',
        typeChamp: "TEXT",
        typeReponse : "OPTION",
        etape: 1,
        obligatoire: !!(this.formType == 'ADD' || data?.responsable?.id), 
        // obligatoire : false,
        size: 'col-md-12',
        onAfter: (formulaire: any) => {
          this.mainFormulaire = formulaire
          this.responsableDrawer = true;
          this.initResForm();
        },
        onChange : (selected: any, formulaire : any)=> {
         
          console.log('le selectionne : ' + selected)

        }, 
        defaultValue: data?.responsable?.id,
        options: this.responsables?.map((data: any) => {
          let valeur = `${data?.prenom} ${data?.nom} - ${data?.phoneNumber}`;
        
          return {
            cle: data?.id,
            valeur
          }
        }),

       
        onSearch: (searchText: string) => {
          this.getAllResponsablesBySearch(searchText);
          // console.log(data?.responsable)
        },
      },
      {
        code: "contact",
        placeholder: "Contact",
        inputPlaceholder: 'Téléphone..',
        typeChamp: "TABLE",
        etape: 1,
        typeReponse: "TABLE",
        values: [],
        columns: [
          {
            name: 'id',
            placeholder: 'Id',
            type: 'TEXT',
            hide: true,
          },
          {
            name: 'contact',
            placeholder: 'contact',
            type: 'TEXT'
          }
        ],
        defaultValue: data?.contacts,
        size: 'col-md-12'
      },
    ]
  }

 

  initResForm(responsable: any = {}) {
    this.responsableFormulaires = [
    
      {
        code: "prenom",
        placeholder: "Prénom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: true,
        size: 'col-md-6'
      },
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        etape: 1,
        obligatoire: true,
        size: 'col-md-6'
      },
      {
        code: "email",
        placeholder: "Adresse email",
        typeChamp: "EMAIL",
        typeReponse: "WRITE",
        etape: 1,
        obligatoire: true,
        size: 'col-md-12'
      },
      {
        code: "telephone",
        placeholder: "Numero de telephone",
        typeChamp: "TELEPHONE",
        typeReponse: "WRITE",
        obligatoire : true,
        etape: 1,
        size: 'col-md-12'
      },
     
      {
        code: "adresse",
        placeholder: "Adresse",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        size: 'col-md-12'
      },
    ]
    this.cdr.detectChanges();
  }

  onAction(action : any) {


    switch (action?.type) {
      case 'edit' :
        this.currentId = action?.value;
        this.getStructureSanteById(this.currentId);
      break;
      case 'delete':
        this.getStructureSantes();
      break;

    }

  }

  getStructureSanteById(id: string) {
    this.dataService.getStructureSanteById(id)
    .subscribe(
      (data: any) => {
        this.currentStructureSante = data?.data?.structureSante;
        this.initForm(this.currentStructureSante);
        console.log(this.currentStructureSante);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getStructureSantes();
    })
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getStructureSantes();
  }


  closeResDrawer() {
    this.responsableDrawer = false;
  }

  onCloseForm() : void {
    this.isFormOpened = !this.isFormOpened;
  }

}