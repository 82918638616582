import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WavePaymentSuccessfulRoutingModule } from './wave-payment-successful-routing.module';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { BrowserModule } from '@angular/platform-browser';
import { WavePaymentSuccessfulComponent } from './wave-payment-successful.component';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    WavePaymentSuccessfulComponent
  ],
  imports: [
    CommonModule,
    WavePaymentSuccessfulRoutingModule,
    LottieModule.forRoot({ player: playerFactory }), 
  ]
})
export class WavePaymentSuccessfulModule { }
