import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CategorieActesRoutingModule } from './categorie-actes-routing.module';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CategorieActesRoutingModule
  ]
})
export class CategorieActesModule { }
