<div>
    <nz-card [nzBordered]="false" nzTitle="{{title}}" [nzExtra]="extraTemplate">
        <div *ngIf="tableTypes?.length > 0">
          <nz-segmented (nzValueChange)="segmentChaged($event)" [nzOptions]="tableTypes" [nzBlock]="true"></nz-segmented>
          <br/><br/>
        </div>
        <nz-table 
          *ngIf="!isTree"
          #columnTable 
          [nzData]="listOfData" 
          [nzScroll]="{ x: (getActiveHeader() <= 8 ? '1100px' : (getActiveHeader() <= 9 ? '1300px' : (getActiveHeader() <= 10 ? '1500px' : (getActiveHeader() <= 11 ? '1700px' : (getActiveHeader() <= 12 ? '2100px' : (getActiveHeader() <= 13 ? '2200px' : (getActiveHeader() <= 14 ? '2300px' : (getActiveHeader() <= 15 ? '2400px' : (getActiveHeader() <= 16 ? '2500px' : (getActiveHeader() <= 17 ? '2600px' : '2700px'))))))))))  }"
          [nzShowPagination]="false"
          [nzPageSize]="100"
        >
            <thead>
              <tr>
                <ng-container *ngFor="let header of headers; let i=index">
                  <th 
                  *ngIf="(header?.actif && !header?.actions) || (header?.actions && isActionVisible(header?.actions))" 
                  [nzLeft]="header?.position == 'left'"  
                  [nzRight]="header?.position == 'right'"
                  [nzSortOrder]="header.sortOrder"
                  [nzSortFn]="header.sortFn"
                  [nzSortDirections]="header.sortDirections"
                  [nzFilterMultiple]="header.filterMultiple"
                  [nzFilters]="header.listOfFilter"filterVisible
                  [nzFilterFn]="header.filterFn"
                  [nzWidth]="header?.width"
                >
                  <span *ngIf="!header?.calendarFilter">{{header?.name}}</span>
                  <nz-collapse *ngIf="header?.calendarFilter" [nzBordered]="false">
                    <nz-collapse-panel
                      #p
                      [nzHeader]="!header?.calendarId ? (header?.name + '🕑') : ''"
                      [nzShowArrow]="false"
                    >
                    <nz-range-picker (nzOnCalendarChange)="header?.calendarFilterFunc($event)" [id]="header?.calendarId" *ngIf="header?.calendarFilter" [nzFormat]="datePikerFormat"></nz-range-picker>
                    </nz-collapse-panel>
                  </nz-collapse>
                  <a *ngIf="header?.dataFilter" nz-dropdown (click)="dataFilterChanged(header)" nzTrigger="click" [nzClickHide]="false" [(nzVisible)]="filterVisible" [nzDropdownMenu]="filtre" close="filterVisible">
                    <span style="font-size: 12px; position: relative; top: -3px; " nz-icon nzType="filter" nzTheme="outline"></span>
                  </a>
                </th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of columnTable?.data; let i = index">
                <ng-container *ngFor="let data of row; let j = index">
                  <td 
                    [ngStyle]="{'background': data?.background ? data?.background : null, 'color': data?.color ? data?.color : null}"
                    *ngIf="data?.type=='TEXT' && headers[j]?.actif"
                    [nzLeft]="data?.position == 'left'" 
                    [nzRight]="data?.position == 'right'" 
                  >
                    {{ data.value }}
                  </td>
                  <td 
                  [ngStyle]="{'background': data?.background ? data?.background : null, 'color': data?.color ? data?.color : null}"
                  *ngIf="data?.type=='LOTTIE' && headers[j]?.actif"
                  [nzLeft]="data?.position == 'left'" 
                  [nzRight]="data?.position == 'right'" 
                  >
                    <ng-lottie [styles]="{height: '20px', borderRadius: '25px', background: '#f3f5f9'}" [options]="data.value"></ng-lottie>
                  </td>
                  <td [ngStyle]="{'background': data?.background ? data?.background : null, 'color': data?.color ? data?.color : null}"
                    *ngIf="data?.type=='DATE' && headers[j]?.actif"
                    [nzLeft]="data?.position == 'left'" 
                    [nzRight]="data?.position == 'right'" 
                  >
                    {{ data.value | date: 'dd/MM/yyyy, à HH:mm'}}
                  </td>
                  <!-- <td 
                    *ngIf="data?.type=='TREE' && headers[j]?.actif"
                    [nzLeft]="data?.position == 'left'" 
                    [nzRight]="data?.position == 'right'" 
                  >
                    
                  </td> -->
                  <td [ngStyle]="{'background': data?.background ? data?.background : null, 'color': data?.color ? data?.color : null}"
                    *ngIf="data?.type=='BOX' && headers[j]?.actif"
                    [nzLeft]="data?.position == 'left'" 
                    [nzRight]="data?.position == 'right'" 
                  >
                    <a [ngClass]="{'btn': true, 'shadow': true, 'btn-xs': true, 'sharp': true, 'mr-1': true}">
                      {{data?.value}}
                    </a>
                  </td>
                  <td [ngStyle]="{'background': data?.background ? data?.background : null, 'color': data?.color ? data?.color : null}"
                    *ngIf="data?.type=='LIST' && headers[j]?.actif"
                    [nzLeft]="data?.position == 'left'" 
                    [nzRight]="data?.position == 'right'" 
                  >
                    <div *ngFor="let val of data?.value" class="d-flex align-items-center"><i *ngIf="!data?.isPerson" class="fa fa-circle text-success mr-1"></i> <i *ngIf="data.isPerson" class="fa fa-circle text-solid fa-user mr-1"></i> 
                      {{
                        data?.render ? data?.render(val) : val
                      }}
                    </div>
                  </td>
                  <td [ngStyle]="{'background': data?.background ? data?.background : null, 'color': data?.color ? data?.color : null}"
                    *ngIf="data?.type=='HTML' && headers[j]?.actif"
                    [nzLeft]="data?.position == 'left'" 
                    [nzRight]="data?.position == 'right'" 
                  >
                    <div [innerHTML]="data?.value | nzSanitizer: 'html'"></div>
                  </td>
                  <td [ngStyle]="{'background': data?.background ? data?.background : null, 'color': data?.color ? data?.color : null}"
                    *ngIf="data?.type=='ACTION' && headers[j]?.actif && isActionVisible(data?.value)"
                    [nzRight]="data?.position == 'right'"   
                  >
                    <div class="d-flex">
                      <ng-container *ngFor="let action of data?.value">
                        <a [ngStyle]="{'cursor': action.type == 'close' ? 'not-allowed' : 'pointer'}" *ngIf="isVisible(action)" nz-popconfirm [nzPopconfirmTitle]="action.type == 'delete' ? 'Êtes vous sûr?' : ''" (click)="handleAction(action)" (nzOnConfirm)="handleDelete(action?.value, i)" [ngClass]="{'btn': true, 'shadow': true, 'btn-xs': true, 'sharp': true, 'mr-1': true, 'btn-primary': action.type == 'edit', 'btn-danger': action.type == 'delete', 'btn-outline-primary': action.type == 'details' || action.type == 'renew' || action.type == 'paiement'}">
                          <i [ngStyle]="{'color': action.type == 'close' ? 'red' : null}" [ngClass]="{'fa': true, 'fa-money':  action.type == 'paiement', 'fa-pencil': action.type == 'edit', 'fa-refresh': action.type == 'renew', 'fa-trash': action.type == 'delete', 'fa-eye': action.type == 'details', 'fa-lock': action.type == 'close'}"></i>
                        </a>
                      </ng-container>
                    </div>
                  </td>
              </ng-container>
              </tr>
            </tbody>
        </nz-table>

        <!-- <nz-input-group [nzSuffix]="suffixIcon">
          <input type="text" class="form-control" (keyup)="onTreeSearch()" nz-input placeholder="Recherche..." [(ngModel)]="searchValueTree" />
        </nz-input-group>
        <ng-template #suffixIcon>
          <span nz-icon nzType="search"></span>
        </ng-template> -->

        <nz-tree-view *ngIf="isTree" [nzTreeControl]="treeControl" [nzDataSource]="dataSource" [nzDirectoryTree]="true">
          <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodePadding>
            <nz-tree-node-toggle nzTreeNodeNoopToggle></nz-tree-node-toggle>
            <nz-tree-node-option
              [nzDisabled]="node.disabled"
              [nzSelected]="selectListSelection.isSelected(node)"
              (nzClick)="selectListSelection.toggle(node)"
            >
              <span nz-icon nzType="file" nzTheme="outline"></span>
              <span class="node-name">{{ node.name }}</span>
              <a (click)="handleNodeClicked(node?.id)" style="float: right;" class="btn shadow btn-xs sharp mr-1 btn-primary">
                <i class="fa fa-eye"></i>
              </a>
              <!-- <span [innerHTML]="node.name | nzHighlight: searchValueTree:'i':'highlight'"></span> -->
            </nz-tree-node-option>
          </nz-tree-node>
    
          <nz-tree-node *nzTreeNodeDef="let node; when: hasChild" nzTreeNodePadding>
            <nz-tree-node-toggle>
              <span nz-icon nzType="caret-down" nzTreeNodeToggleRotateIcon></span>
            </nz-tree-node-toggle>
            <nz-tree-node-option
              [nzDisabled]="node.disabled"
              [nzSelected]="selectListSelection.isSelected(node)"
              (nzClick)="selectListSelection.toggle(node)"
            >
              <span nz-icon [nzType]="treeControl.isExpanded(node) ? 'folder-open' : 'folder'" nzTheme="outline"></span>
              <span class="node-name">{{ node.name }}</span>
              <!-- <span [innerHTML]="node.name | nzHighlight: searchValueTree:'i':'highlight'"></span> -->
            </nz-tree-node-option>
          </nz-tree-node>
        </nz-tree-view>

        <div class="ant-pagination ant-table-pagination ant-table-pagination-right ng-star-inserted">
          <nz-pagination class="mr-2" 
            [nzPageIndex]="currentPage" 
            [nzPageSize]="pageCount"
            [nzTotal]="totalCount"
            (nzPageIndexChange)="setTableUpdate('currentPage',$event)"
            >
          </nz-pagination> 
          <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            {{pageCount}} / page
            <span nz-icon nzType="down"></span>
          </button>
        </div>
    </nz-card>
</div>

<nz-dropdown-menu nz-menu #filtre="nzDropdownMenu" class="box-filter" style="border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
">
  <div nz-menu>
    <div class="margin-auto width-90 mt-2 mb-3">
      <input (keyup)="searchFilter()" [(ngModel)]="searchFilterText" type="text" class="form-control" nz-input placeholder="Rechercher une colonne" />
    </div>
    <ul style="position: relative;">
      <li *ngFor="let filter of currentDataFilter" nz-menu-item>
        <label [id]="filter?.value" nz-checkbox [(ngModel)]="filter.checked">{{filter?.text}}</label>
      </li>
      <li nz-menu-divider></li>
      <!-- <li nz-menu-item style="position: fixed;">
        
      </li> -->
    </ul>
    <div class="ant-table-filter-dropdown-btns padding-20">
      <button (click)="reinitFilter()" nz-button nztype="link" nzsize="small" class="ant-btn ant-btn-link ant-btn-sm" ng-reflect-nz-type="link" ng-reflect-nz-size="small" ng-reflect-disabled="true" tabindex="-1" >
        <span class="ng-star-inserted"> Réinitialiser </span>
      </button>
      <button (click)="validateFilter()" nz-button nztype="primary" nzsize="small" class="ant-btn ant-btn-primary ant-btn-sm" ng-reflect-nz-type="primary" ng-reflect-nz-size="small">
        <span class="ng-star-inserted ant-dropdown-trigger ant-dropdown-link ng-star-inserted">OK</span>
      </button>
    </div>
  </div>
</nz-dropdown-menu>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li (click)="setTableUpdate('pageCount',5)" nz-menu-item>5 / page</li>
    <li nz-menu-divider></li>
    <li (click)="setTableUpdate('pageCount',10)" nz-menu-item>10 / page</li>
    <li nz-menu-divider></li>
    <li (click)="setTableUpdate('pageCount',20)" nz-menu-item>20 / page</li>
    <li nz-menu-divider></li>
    <li (click)="setTableUpdate('pageCount',30)" nz-menu-item>30 / page</li>
    <li nz-menu-divider></li>
    <li (click)="setTableUpdate('pageCount',40)" nz-menu-item>40 / page</li>
    <li  nz-menu-divider></li>
    <li (click)="setTableUpdate('pageCount',50)" nz-menu-item>50 / page</li>
    <li nz-menu-divider></li>
    <li (click)="setTableUpdate('pageCount',75)" nz-menu-item>75 / page</li>
    <li nz-menu-divider></li>
    <li (click)="setTableUpdate('pageCount',100)" nz-menu-item>100 / page</li>
  </ul>
</nz-dropdown-menu>

<ng-template #extraTemplate>
    <div class="input-group search-area d-lg-inline-flex d-none">
        <input [(ngModel)]="searchText" (keyup)="searchTextChanged()" type="text" class="form-control" placeholder="Rechercher...">
        <div class="input-group-append">
            <span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
        </div>
    </div>
</ng-template>

