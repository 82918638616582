import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import API from '../../../api';
import { NzMessageService } from 'ng-zorro-antd/message';

declare let paypal: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, AfterViewInit {

  @Output() onPaymentStatus: EventEmitter<any> = new EventEmitter<any>()
  @Output() onPayementFinished: EventEmitter<any> = new EventEmitter<any>()

  optionsSuccess: AnimationOptions = {
    path: 'assets/lotties/payment-success.json',
  };
  
  optionsFailed: AnimationOptions = {
    path: 'assets/lotties/payment-failed.json',
  };

  paymentMethods: any = [];
  selectedMethod: any = {}

  @Input() totalApayer: number = 0;
  @Input() usefullInfo: any = {};

  wavePayementWhitoutApp: boolean = false;

  waveNumber: string = '';
  wavePaymentInProgress: boolean = false;
  wavePaymentUrl: string = '';
  wavePaymentUrlFame: string = '';
  omPaymentInProgress: boolean = false;

  successPayment: boolean = false;
  failedPayment: boolean = false;

  chequeForm = new FormGroup({
    numero: new FormControl('', [Validators.required]),
    banque: new FormControl('', [Validators.required]),
    ordreCompagnie: new FormControl(false),
  });

  impayerForm = new FormGroup({
    accompte: new FormControl(0, [Validators.required]),
    restantApayer: new FormControl(this.totalApayer, [Validators.required])
  });

  omForm = new FormGroup({
    telephone: new FormControl('', [Validators.required]),
    OTP: new FormControl('', [Validators.required]),
    codeMarchantOM: new FormControl(false),
  });

  virementForm = new FormGroup({
    banque: new FormControl('', [Validators.required])
  });

  compesationForm = new FormGroup({
    libelle: new FormControl('', [Validators.required])
  });

  constructor(
    private dataService: DataService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private message: NzMessageService,
  ) { }

  ngOnInit(): void {
    this.getPaymenMethod();
  }

  ngAfterViewInit(): void {
    this.impayerForm.setValue({
      accompte: 0,
      restantApayer: this.totalApayer
    })
  }

  getPaymenMethod() {
    this.dataService.getPaymenMethod().subscribe((data: any) => {
      this.paymentMethods = data?.data?.methodePaiements.map((methode: any) => {
        return {
          ...methode,
          imageurl: methode?.image?.url
        }
      });
      this.cdr.detectChanges();
    });
  }

  paymentMethodSelected(payment: any) {
    this.selectedMethod = payment;
    document?.getElementById('paiement-panel')?.scrollIntoView({behavior: 'smooth'});
    if(document.getElementById('paypal-button-container')) {
      var element: any = document.getElementById('paypal-button-container');
      element.innerHTML = '';
    }
    setTimeout(() => {
      this.renderPaymentPanel(payment);
    })
  }

  initPayPalButton() {
    paypal.Buttons({
      style: {
        shape: 'rect',
        color: 'gold',
        layout: 'vertical',
        label: 'checkout',
      },

      createOrder: (data: any, actions: any) => {
        return actions.order.create({
          purchase_units: [{
            "amount":{
              "currency_code":"USD",
              "value": this.totalApayer,
              "breakdown": {
                "item_total":{
                  "currency_code":"USD",
                  "value": this.totalApayer
                },
                "shipping":{
                  "currency_code":"USD",
                  "value": 0
                },
                "tax_total":{
                  "currency_code":"USD",
                  "value":0
                }
              }
            }
          }]
        });
      },

      onApprove: (data: any, actions: any) => {
        return actions.order.capture().then((orderData: any) => {
          
          // Full available details

          // Show a success message within this page, e.g.
          const element: any = document.getElementById('paypal-button-container');
          element.innerHTML = '';
          element.innerHTML = '<h3>Thank you for your payment!</h3>';
          

          // Or go to another URL:  actions.redirect('thank_you.html');
          
        });
      },

      onError: (err: any) => {
        console.log(err);
        // Swal.fire(
        //   'Error!',
        //   'Error while paying !',
        //   'error'
        // )
      }
    }).render('#paypal-button-container');
  }

  handleAccompte() {
    const accompte: number = this.impayerForm?.value?.accompte ? this.impayerForm?.value?.accompte : 0;
    this.impayerForm.setValue({
      accompte: accompte < this.totalApayer ? accompte : this.totalApayer - 1,
      restantApayer: this.totalApayer - (accompte < this.totalApayer ? accompte : this.totalApayer - 1)
    })
  }

  handleWavePayment() {
    this.dataService.getWavePayment(this.totalApayer).subscribe((data: any) => {
      this.wavePaymentUrl = data?.data?.wavePayment;
      this.wavePaymentUrlFame = data?.data?.wavePayment;
      this.wavePaymentInProgress = true;
      document?.getElementById('wavePaymentId')?.scrollIntoView({behavior: 'smooth'});
      this.cdr.detectChanges();
    });
  }

  handleOMPayment() {
    this.omPaymentInProgress = true;
    if (this.omForm.value?.codeMarchantOM) {
      this.omPaymentInProgress = false;
      this.savePaiement('ORANGE_MONEY');
      this.cdr.detectChanges();
    }
    else {
      this.dataService.getOMpayment(this.omForm.value?.telephone, this.omForm.value?.OTP, this.totalApayer).subscribe((data: any) => {
        this.omPaymentInProgress = false;
        this.savePaiement('ORANGE_MONEY');
        this.cdr.detectChanges();
      },
      error => {
        console.log(error, error.message);
        this.omPaymentInProgress = false;
        this.message.error("Erreur lors du paiement. Vérifiez bien que le CODE OTP est correct et n'est pas expiré")
      });
    }
  }

  renderPaymentPanel(payment: any) {
    switch(payment?.type) {
      case 'PAYPAL_CARTE_CREDIT':
        this.initPayPalButton();
      break;
    }
  }

  sendPaymentUrlToWhatsapp() {
    const array = [
      this.usefullInfo?.client,
      this.usefullInfo?.branche,
      this.usefullInfo?.risque,
      this.usefullInfo?.infoPolice?.[0],
      this.usefullInfo?.infoPolice?.[1],
      this.usefullInfo?.infoPolice?.[2],
      `${this.totalApayer}`
    ]
    var str = JSON.stringify(array)
    str = str?.replace(/"/g, `\\"`);
    this.dataService.sendWhatsappMessage({
      to: `221${this.waveNumber}`,
      template: "paiementwave",
      params: str,
      buttonUrl: this.wavePaymentUrl?.substring(21)
    })
    // .subscribe((data: any) => {
    // }, (err: any) => {
    //   console.log(err);
    // });
    this.waveNumber = '';
  }

  paiementValid(paymentStatus: boolean) {
    if (paymentStatus) {
      this.successPayment = true;
      this.failedPayment = false;
    } else {
      this.successPayment = false;
      this.failedPayment = true;
    }
    this.onPaymentStatus.emit(paymentStatus);
  }

  handleChequePayment() {
    this.savePaiement('CHEQUE');
  }

  handleVirementPayment() {
    this.savePaiement('VIREMENT');
  }

  handleCompensationPayment() {
    this.savePaiement('COMPENSATION');
  }

  savePaiement(type: string) {
    const _date = new Date();
    let data: any = {}
    switch(type) {
      case 'PAYPAL_CARTE_CREDIT':
        // data.numeroPaiement = '';
      break;
      case 'WAVE':
        data.numeroPaiement = '';
      break;
      case 'COMPENSATION':
        data = this.compesationForm.value;
      break;
      case "VIREMENT":
        data = this.virementForm.value
      break
      case "CHEQUE":
        data = this.chequeForm.value
      break
      case "ORANGE_MONEY":
        data = this.omForm.value
      break
      case "IMPAYE":
        data = this.impayerForm.value
      break
    }
    data = {
      ...data,
      type,
      montant: this.totalApayer,
      date: _date?.toISOString()?.split('T')?.[0],
      intitule: "Paiement quittance",
    }
    this.dataService.savePaiement(data).subscribe((data: any) => {
      this.paiementValid(true);
      this.onPayementFinished.emit(data?.data?.createPaiement?.id);
    });
  }

  safeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
