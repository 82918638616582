import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MedicamentsRoutingModule } from './medicaments-routing.module';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    MedicamentsRoutingModule
  ]
})
export class MedicamentsModule {
  
 }
