<!-- row -->
<div class="container-fluid">
    <div class="form-head d-flex mb-sm-4 mb-3 margin-left-right-35">
        <div class="mr-auto">
            <h1 class="text-black font-w600">Qualites</h1>
        </div>
        <div>
            <a (click)="openAddForm()" href="javascript:void(0)" class="btn btn-primary mr-3" data-toggle="modal" data-target="#addOrderModal">Ajouter qualite</a>
            <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzClickHide]="false" [nzDropdownMenu]="colonne" class="btn btn-outline-primary"><i class="fa fa-plus scale5 mr-3"></i>{{headersActif()}} {{headersActif() <= 1 ? 'colonne' : 'colonnes'}}</a>
            <!-- <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzClickHide]="false" [(nzVisible)]="true" [nzDropdownMenu]="colonne">
                <span style="font-size: 12px; position: relative; top: -3px; " nz-icon nzType="filter" nzTheme="fill"></span>
            </a> -->
        </div>
    </div>
    <!-- Add Order -->
    <app-form 
        (onCloseForm)="onCloseForm()" 
        [formTab]="formTab" 
        *ngIf="isFormOpened"
        [formulaires]="formulaires"
        (formulaireEvent)="formulaireEvent($event)"
        [formType]="formType"
        [modelName]="modelName"
        (onUpload)="onUploadEvent($event)"
        [chargementMutation]="'createChargementQualite'"
        >
    </app-form>
    <app-table 
        [headers]="filteredHeader" 
        [listOfData]="qualites"  
        [totalCount]="totalCount"
        [currentPage]="currentPage"
        (onTableUpdate)="onTableUpdate($event)"
        (onSearchTextChanged)="onSearchTextChanged($event)"
        [pageCount]="pageCount"
        [modelName]="modelName"
        title="Liste des qualites"
        (onAction)="onAction($event)"
        >
    </app-table>

    <nz-dropdown-menu #colonne="nzDropdownMenu">
        <ul nz-menu>
            <div class="margin-auto width-90 mt-2 mb-3">
                <input (keyup)="searchColumn()" [(ngModel)]="searchColumnText" type="text" class="form-control" nz-input placeholder="Rechercher une colonne" />
            </div>
          <li *ngFor="let header of headers; let i = index" nz-menu-item>
            <ng-container *ngIf="header?.type != 'action'">
                <label [disabled]="headersActif() <= 1 && header?.actif" [id]="header?.name" nz-checkbox (ngModelChange)="handleColumnChanged()" [(ngModel)]="headers[i].actif">{{header?.name}}</label>
            </ng-container>
          </li>
        </ul>
    </nz-dropdown-menu>
</div>