import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'payment/:status',
    loadChildren: () => import('./extra/wave-payment-successful/wave-payment-successful.module').then((m) => m.WavePaymentSuccessfulModule)
  },
  {
    path: 'politique-confidentialite',
    loadChildren: () => import('./extra/politique-confidentialite/politique-confidentialite.module').then((m) => m.PolitiqueConfidentialiteModule)
  },
  {
    path: 'page-not-found',
    loadChildren: () => import('./page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule)
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./unauthorized/unauthorized.module').then((m) => m.UnauthorizedModule)
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
