import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/data.service';
import API from 'src/app/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-souscription',
  templateUrl: './create-souscription.component.html',
  styleUrls: ['./create-souscription.component.css']
})
export class CreateSouscriptionComponent implements OnInit, AfterViewInit, OnDestroy{

  typeFinancements: any = [];
  entreprises: any = [];
  qualites: any = [];
  selectedTypeFinancement: any;
  user: any;
  currentstep: number = 0;
  paymentMethods: any = [];
  allQuestions: any = [];

  resultOutputs: any = [];
  contrat: any;
  currentRisque: any = {};
  plan: any = {};
  resultsTab: any = [];
  outputs: any = [];
  resultFormulaire: any = [];
  selectedPaymentMethod: string = '';

  @Output() closeEmiter = new EventEmitter();


  inputKeys: any = [];
  
  // currentRisqueQuestions: any = {};
  formulaire: FormGroup = new FormGroup({});
  formulaireSimulation: FormGroup = new FormGroup({});
  stepTitles: any = [
    {
      title: 'Choix risque',
      sub: 'Veuillez choisir le risque'
    },
    {
      title: 'Simulation de prix',
      sub: 'Remplissez le formulaire et obtenez un prix'
    },
    {
      title: 'Choix risque',
      sub: 'Veuillez choisir le risque'
    },
    {
      title: 'Choix risque',
      sub: 'Veuillez choisir le risque'
    }
  ]

  constructor(
    private dataService: DataService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getPaymenMethod();
    this.getAllEntreprises();
    this.getAllQualites();
    this.user = this.dataService.getUser();
    this.dataService.getTypeFinancement().subscribe((data: any) => {
      console.log(data);
      this.typeFinancements = data?.data?.typeFinancements.map((tf: any) => {
        return {
          ...tf,
          icon: `icon-${tf?.icon.replace(new RegExp('_', 'g'), '-')}`,
          image: {
            path: API.baseUrl + API.fileUrl + tf?.image?.filename
          },
          formulaire: {
            ...tf?.formulaire,
            champFormulaires: tf?.formulaire?.infoContrats.map((inf: any) => {
              return {
                ...inf,
                ...inf?.champFormulaire
              }
            })
          }
        }
      });
      if (this.typeFinancements && this.typeFinancements[0]) {
        this.selectedTypeFinancement = this.typeFinancements[0];
        this.handleFormControls();
      }
      this.cdr.detectChanges();
    }, 
    err => {
      console.log(err);
    })
  }


  getAllEntreprises() {
    this.dataService.getAllEntreprises().subscribe((data: any) => {
      this.entreprises = data?.data?.entreprises;
    });
  }

  getAllQualites() {
    this.dataService.getAllQualites().subscribe((data: any) => {
      this.qualites = data?.data?.qualites?.map((quality: any) => {
        return {
          ...quality,
          quantite: quality?.obligatoire ? quality.value : 0,
          image: {
            path: quality?.fichier?.url
          },
        }
      });
    });
  }

  ngAfterViewInit(): void {
    this.removeStyle('formId');
    this.addStyle('tarificationId', "assets/css/tarification.css");
  }

  ngOnDestroy(): void {
    this.removeStyle('tarificationId');
    this.addStyle('formId', "assets/css/form-step.css");
  }

  addStyle(id: string, path: string) {
    var css = document.createElement('link');
    css.id ='tarificationId';
    css.href = "assets/css/tarification.css";
    css.rel = "stylesheet";
    document.head.append(css);
  }

  removeStyle(id: string) {
    let css = document.getElementById(id);
    if (css) {
      document.head.removeChild(css);
    }
  }

  annuler(tarifIncluded = true) {
    // document.getElementById('chatbox')?.setAttribute('class', 'chatbox'); 
    this.closeEmiter.emit({
      ...this.plan,
      tarifIncluded
    });
  }

  setOutputs(outputs: any) {
    this.outputs = outputs;
  }

  setPlanEvent(plan: any) {
    this.plan = plan;
  }

  setResultFormlulaire(resultFormulaire: any) {
    this.resultFormulaire = resultFormulaire;
  }

  handleFormControls() {
    let formControls: any = {};
      this.selectedTypeFinancement?.formulaire?.champFormulaires?.forEach((champ: any) => {
        if (champ?.obligatoire) {
          formControls[champ?.code] = new FormControl(this.getFormControlInitialValue(champ?.typeChamp), Validators.required)
        }
        else {
          formControls[champ?.code] = new FormControl(this.getFormControlInitialValue(champ?.typeChamp))
        }
      });
      this.formulaire = new FormGroup(formControls);
  }

  handleSimulationFormControls() {
    let formControls: any = {};
    this.selectedTypeFinancement?.risques && this.selectedTypeFinancement?.risques[0] && this.selectedTypeFinancement?.risques[0]?.champs?.forEach((champ: any) => {
        if (champ?.obligatoire) {
          formControls[champ?.code] = new FormControl(this.getFormControlInitialValue(champ?.typeChamp, champ?.valeurDefaut), Validators.required)
        }
        else {
          formControls[champ?.code] = new FormControl(this.getFormControlInitialValue(champ?.typeChamp, champ?.valeurDefaut))
        }
      });
      this.formulaireSimulation = new FormGroup(formControls);
  }

  getFormControlInitialValue(typeChamp: string, valeurDefaut: string = '') {
    switch(typeChamp) {
      case 'TEXT':
        return valeurDefaut ? valeurDefaut : '';
      case 'NUMBER':
        return valeurDefaut ? Number(valeurDefaut) : 0;
      case 'EMAIL':
        return valeurDefaut ? valeurDefaut : '';
      case 'TELEPHONE':
        return valeurDefaut ? valeurDefaut : '';
      case 'DATE':
        return valeurDefaut ? valeurDefaut : null;
      default:
        return valeurDefaut ? valeurDefaut : '';
    } 
  }

  cancelFormulaire() {
    this.formulaire.reset();
  }

  getQuestionOfTypeFincancement(typeFinancement: any) {
    this.dataService.getQuestionsOfTypeFinancement(typeFinancement.id).subscribe((data: any) => {
      this.allQuestions = data?.data?.typeFinancement;
      this.selectedTypeFinancement = {
        ...this.selectedTypeFinancement,
        ...this.allQuestions
      };
      this.handleSimulationFormControls();
      this.handleFormControls();
      // console.log(data?.data?.typeFinancement);
      // this.currentRisqueQuestions = this.allQuestions?.risques.find((risque: any) => risque.id == this.currentRisque.id);
      // this.questions = {
      //   ...this.allQuestions,
      //   risques: []
      // };
      // // this.pushQuestion('prenom');
      // // this.handleIncludedQuestions();
      // this.selectedQuestion();
    },
    err => {
      console.log(err);
    })
  }

  saveSouscription(values: any, infoContrats: any) {
    const day = new Date();
    var data = {
      date: `${day.getFullYear()}-${('0' + (day.getMonth() + 1)).slice(-2)}-${('0' + (day.getDate())).slice(-2)}`,
      price: this.currentRisque.price,
      agentId: this.user?.id,
      contrat: this.contrat,
      partenaireId: this.user?.partenaire?.id,
      typeFinancementId: this.selectedTypeFinancement?.id,
      paymentMethodId: this.selectedPaymentMethod,
      result: this.resultsTab,
      output: this.resultOutputs,
      infoClient: values
    }
    this.dataService?.createSouscription(data).subscribe((data: any) => {
      this.generateContrat(data?.data?.createSouscription?.id, infoContrats);
    },
    err => {
      console.log(err);
    }
    )
  }

  getPaymenMethod() {
    this.dataService.getPaymenMethod().subscribe((data: any) => {
      this.paymentMethods = data?.data?.methodePaiements.map((methode: any) => {
        return {
          ...methode,
          imageurl: methode?.image?.url
        }
      });
    });
  }

  generateContrat(souscriptionId: string, infoContrats: any) {
    this.dataService.generateContrat(this.selectedTypeFinancement?.id, souscriptionId, this.inputKeys, infoContrats).subscribe(
      (data: any) => {
        console.log(data);
        // this.dataService.sendEmailNotification(souscriptionId).subscribe((data: any) => {
          this.router.navigate([`/home/espace-personnel`]);
        // },
        // err => {
        //   console.log(err);
        // });
      },
      (err: any) => {
        console.log(err);
      });
  }

  typeFinancementChanged(typeFincancement: any) {
    this.selectedTypeFinancement = typeFincancement;
    this.getQuestionOfTypeFincancement(typeFincancement);
  }

  setSuivant() {
    if (this.currentstep < 2) {
      this.currentstep = this.currentstep + 1;
    }
    else {
      this.annuler();
    }
  }

  setPrecedent() {
    if (this.currentstep > 0) {
      this.currentstep = this.currentstep - 1;
    }
  }

  handleVisibilityStep() {
    switch (this.currentstep) {
      case 1: return !!this.plan?.id
      default: return true;
    }
  }

}
