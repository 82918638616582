import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  options: AnimationOptions = {
    path: 'assets/lotties/reset-password.json',
  };

  userForm = new FormGroup({
    password: new FormControl('', Validators.required),
    confirmationPassword: new FormControl('', Validators.required),
  });

  user: any = {};
  currentId: string = ''

  messageErrorPass = '';
  messageErrorConf = ''
  isConfirmationValid: boolean = false

  constructor(
    private dataService: DataService,
    private toastr: ToastrService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {  
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(params => {
      this.currentId = params['id'];
      this.getUtilisateurById(params['id'])
    });
  }

  onSubmit() {
    this.changePassword();
  }

  passwordEditing() {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/g;
    const found = this.userForm.value?.password?.match(regex);
    this.messageErrorPass = !found ? 'Minimum 8 caractéres (Lettres et Chiffres)' :  ''
  }
  
  passwordConfirmEditing() {
    const areEqual = this.userForm.value?.password != this.userForm.value?.confirmationPassword;
    this.messageErrorConf = (areEqual) ? 'Les deux mot de passes ne correspondent pas' :  '';
    if (areEqual && !this.messageErrorPass && !this.messageErrorConf) {
      this.isConfirmationValid = true
    }
    else {
      this.isConfirmationValid = false
    }
  }

  getUtilisateurById(id: string) {
    this.dataService.getUtilisateurById(id)
    .subscribe(
      (data: any) => {
        this.user = data?.data?.user
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  changePassword() {
    this.dataService.changePassword(this.currentId, this.userForm?.value?.password).subscribe((data: any) => {
      this.router.navigate(['/auth/login']);
    }, 
    (err: any) => {
      console.log(err);
    })
  }

}
