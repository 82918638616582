import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnDestroy, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NzTreeFlatDataSource, NzTreeFlattener } from 'ng-zorro-antd/tree-view';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() title: string = 'Liste';
  @Input() listOfData: any = [];
  @Input() headers: any = [];
  @Input() totalCount: number = 1;
  @Input() currentPage: number = 1;
  @Input() pageCount: number = 10;
  @Input() modelName: string = '';
  @Input() isTree: boolean = false;
  @Input() tableTypes: any = [];

  @Output() onTableUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchTextChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSegmentEvent: EventEmitter<any> = new EventEmitter<any>();

  currentheader: any = {};
  currentDataFilter: any = [];
  currentFilterValueSelected: any = [];
  filterVisible: boolean = false;
  searchText: string = '';
  searchFilterText: string = '';

  dateFormat = 'dd/MM/yyyy';
  datePikerFormat = 'dd/MM';

  private transformer = (node: any, level: number): any => ({
    expandable: !!node.children && node.children.length > 0,
    name: node.name,
    id: node.id,
    level,
    disabled: !!node.disabled
  });
  selectListSelection = new SelectionModel<any>();

  treeControl = new FlatTreeControl<any>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new NzTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  searchValueTree: string = '';

  dataSource = new NzTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: any): boolean => node.expandable;

  getNode(name: string): any | null {
    return this.treeControl.dataNodes.find(n => n.name === name) || null;
  }

  onTreeSearch() {
    this.dataSource.setData(this.listOfData?.filter((item: any, index: number) => item?.name?.includes(this.searchValueTree)));
  }

  isVisible(action: any) {
    if (!action?.roles) {
      return true;
    }
    else {
      return this.dataService.enableForRoles(action?.roles)
    }
  }

  segmentChaged(event: any) {
    this.onSegmentEvent.emit(event);
  }

  handleNodeClicked(nodeId: string) {
    this.onAction.emit({
      type: 'tree',
      id: nodeId
    });
  }

  isActionVisible(actions: any) {
    var visible = false;
    actions?.forEach((action: any) => {
      if (!action?.roles) {
        visible = true;
      }
      else {
        if (this.dataService.enableForRoles(action?.roles)) {
          visible = true
        }
      }
    });
    return visible;
  }

  constructor(
    private dataService: DataService,
    private message: NzMessageService
  ) {
   }

  ngOnInit(): void {

    this.headers = this.headers?.map((header: any, index: number) => {
      return {
        ...header,
        filtreHeader: header?.name + index
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource.setData(this.listOfData);
  }

  getTreeSource(value: any) {
    const dataSource = new NzTreeFlatDataSource(this.treeControl, this.treeFlattener);
    dataSource.setData(value);
    return dataSource;
  }

  ngAfterViewInit(): void {
    var css = document.createElement('link');
    css.id = 'tableId'
    css.href = "assets/css/table.css";
    css.rel = "stylesheet";
    document.head.append(css);

    var js = document.createElement('script');
    js.id = 'tooltipId'
    js.src = "assets/js/tooltip.js";
    document.head.append(js);
    // setTimeout(() => {
    //   this.treeControl.expand(this.getNode('FINAFRICA')!);
    // }, 300);
  }

  ngOnDestroy(): void {
    let css = document.getElementById('tableId');
    let js = document.getElementById('tooltipId');
    if (css) {
      document.head.removeChild(css);
    }
    if (js) {
      document.head.removeChild(js);
    }
  }

  getHeaderWidth() {
    let headersLength = this.headers.filter((header: any) => header.actif)?.length;
    return `${ (1100 + (headersLength - 8) * 200)}px`
  }

  getActiveHeader() {
    let headersLength = this.headers.filter((header: any) => header.actif)?.length;
    return headersLength 
  }


  setTableUpdate(type: string, value: number) {
    switch(type) {
      case 'pageCount' : 
        this.pageCount = value;
        this.currentPage = 1;
      break;
      case 'currentPage' : 
        this.currentPage = value;
      break;
    }

    this.onTableUpdate.emit({
      currentPage: this.currentPage,
      pageCount: this.pageCount
    })

  }

  searchFilter() {
    this.currentDataFilter = this.currentheader?.dataFilter?.filter((item: any) => item?.text?.toLowerCase()?.includes(this.searchFilterText?.toLowerCase()));
  }

  validateFilter() {
    this.filterVisible = false;
    const itemChecked = this.currentDataFilter?.filter((item: any) => item?.checked)?.map((item: any) => item?.value);
    this.currentheader?.filterFn(itemChecked, []);
    this.currentDataFilter = [];
  }

  dataFilterChanged(header: any) {
    this.currentDataFilter = header?.dataFilter;
    this.currentheader = header;
  }

  reinitFilter() {
    this.currentDataFilter?.filter((item: any) => item?.checked)?.forEach((filter: any) => {
      document.getElementById(filter?.value)?.click();
    });
    this.filterVisible = false;
    this.currentheader?.filterFn([], []);
  }

  searchTextChanged() {
    this.onSearchTextChanged.emit(this.searchText);
  }

  handleAction(data: any) {
    if(data?.type != 'delete') {
      this.onAction.emit(data);
    }
  }

  handleDelete(id: string, index: number) {
    this.dataService.deleteMutation(this.modelName, id).subscribe(
      (data: any) => {
        this.message.success(`Le ${this.modelName} a bien été supprimé`);
        this.removeItem(id, index);
        this.onAction.emit({
          id,
          type: 'delete'
        });
      },
      (err: any) => {
        console.log(err);
        this.message.error(`Erreur lors de la suppression du ${this.modelName}`);
      },
    )
  }

  removeItem(id: string, _index: number) {
    this.listOfData =  this.listOfData?.filter((row: any, index: number) => _index != index)
  }

  openCalendar(calendarId: string) {
    document?.getElementById(calendarId)?.click();
  }

  // isThereAnyFilterChecked() {
  //   constitemChecked = this.currentDataFilter?.filter((item: any) => item?.checked)?.map((item: any) => item?.value);
  //   return !!itemChecked;
  // }

}
