import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { AnimationOptions } from 'ngx-lottie';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/data.service';
import * as XLSX from 'xlsx';

type AOA = any[][];

const template = [
  'NOM',
  'PRENOM',
  'DDN',
  'TELEPHONE',
  'QUALITE'
]

@Component({
  selector: 'app-create-souscription-simulation',
  templateUrl: './create-souscription-simulation.component.html',
  styleUrls: ['./create-souscription-simulation.component.css']
})
export class CreateSouscriptionSimulationComponent implements OnInit {

  @Input() selectedTypeFinancement : any = {};
  @Input() formulaireSimulation : any = [];
  @Input() allQuestions : any = [];
  @Input() inputKeys : any = [];
  @Input() entreprises : any = [];
  @Input() qualites : any = [];
  @Input() planSantes : any = [];
  @Output() outputEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() planEvent: EventEmitter<any> = new EventEmitter<any>();

  tableTypes=['Particulier', 'Entreprise']

  time: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);

  defaultFileList: NzUploadFile[] = [];

  fileList = [...this.defaultFileList];
  isUploading = false;

  outputs: any = [];
  garanties: any = [];
  selectedPlanSante: any = [];

  price: number = 0;
  selectedCompagnie: any = null;

  isParticulier: boolean = true;
  chargementEnMasse: boolean = false;
  entrepriseId: string = '';

  optionsEmpty: AnimationOptions = {
    path: 'assets/lotties/empty.json',
  };

  constructor(
    private dataService: DataService,
    private toastr: ToastrService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private msg: NzMessageService
  ) { }

  submitFormulaire() {
    console.log(this.formulaireSimulation.value);
    this.getPrice(this.formulaireSimulation.value);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.handleTypeClient(true);
    }, 500)
  }

  handleUploadChange({ file, fileList }: NzUploadChangeParam, evt: any): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    // if (status === 'done') {
    //   this.msg.success(`${file.name} file uploaded successfully.`);
    // } else if (status === 'error') {
    //   this.msg.error(`${file.name} file upload failed.`);
    // }

    if (status === 'done' || status === 'error') {
      console.log(file)
      this.onFileChange(evt)
      // var workbook = XLSX.readFile(file, {});
    }
  }

  segmentChaged(type: number) {
    this.isParticulier = type == 0 ? true : false;
    this.handleTypeClient(type == 0 ? true : false)
  }

  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };

  beginUpload() {
    document.getElementById('file')?.click();
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

      this.isUploading = true;
      var valid = true;
      console.log(target)
      if (this.data?.[0]?.length == 5) {
        this.data?.[0]?.forEach((item: any, index: number) => {
          if (item != template[index]) {
            this.msg.error(`Désolé! La colonne ${item} doit être ${template[index]}`);
            setTimeout(() => {
              this.isUploading = false;
            }, 1000)
            valid = false;
          }
        });
      }
      else {
        this.msg.error(`Désolé! Le doit contenir 5 colonnes et non ${this.data?.[0]?.length}`);
        setTimeout(() => {
          this.isUploading = false;
        }, 1000)
      }

      if (valid) {

        this.qualites = this.qualites?.map((qualite: any) => {
          return {
            ...qualite,
            quantite: (this.data?.filter((item, index) => item?.[4] == qualite?.type)?.length ?? 0)
          }
        })

        this.handleTypeClient(this.isParticulier, true);

        var familles: any = [];
        const beneficiairesCharges = this.data?.filter((item, index) => index != 0)?.map((item: any, index: number) => {
          let membre = {
            nom: item?.[0],
            prenom: item?.[1],
            dateNaissance: item?.[2],
            telephone: item?.[3],
            qualite: this.qualites?.find((qualite: any) => qualite?.type == item?.[4])?.id // item?.[4]
          }
          if (item?.[4] == 'ASSURE_PRINCIPAL' || familles?.length == 0) {
            familles.push(
              [
                membre
              ]
            )
          }
          else {
            familles[familles?.length - 1].push(membre)
          }
          return membre
        })

        console.log(familles)

        this.selectedPlanSante = {
          ...this.selectedPlanSante, 
          familles,
          beneficiairesCharges,
          selectedTypeFinancement: this.selectedTypeFinancement,
        };
        this.planEvent.emit(this.selectedPlanSante);
        this.msg.success(`Les bénéficiaires ont bien été récupérés.`);
        setTimeout(() => {
          this.isUploading = false;
        }, 1000)
      }
    };
    reader.readAsBinaryString(target.files[0]);
    setTimeout(() => {
      this.isUploading = false;
    }, 1000)
  }

  fileUploaded(event: any, question: any) {
    console.log(event);
    question.reponseUser = event?.fileList;
  }

  cancelFormulaire() {
    this.formulaireSimulation.reset();
  }

  getPrice(formData: any) {
    this.inputKeys = [];
    this.selectedTypeFinancement.risques[0].champs?.forEach((question: any) => {
      if (question?.inputKey) {
        this.inputKeys.push({
          cle: question?.inputKey,
          valeur: question?.typeChamp == 'NUMBER' && formData[question?.code] ? parseInt(formData[question?.code]) : formData[question?.code],
          type: question?.typeChamp == 'NUMBER' ? 'NUMBER' : 'STRING'
        });
      }
    })
    this.selectedTypeFinancement?.risques?.[0]?.garanties?.forEach((garantie: any) => {
      this.inputKeys.push({
        cle: garantie?.inputKey,
        valeur: garantie?.obligatoire || garantie?.checked ? 1 : 0,
        type: 'NUMBER'
      });
    })
    console.log(this.inputKeys)
    this.dataService.getPrice(this.selectedTypeFinancement?.id, this.inputKeys).subscribe(
      ((data: any) => {
        console.log(data);
        this.price = data?.data?.CalculatePrime.price;
        this.outputs = data?.data?.CalculatePrime.outputs?.sort((a: any, b: any) => a?.valeur - b?.valeur);
        if (this.outputs.length > 0) {
          this.price = this.outputs?.[0]?.valeur;
          this.selectedCompagnie = this.outputs?.[0];
        }
        this.outputEvent.emit(data?.data?.CalculatePrime);
      }),
      err => {
        console.log(err);
      }
    )
  }

  handleTypeClient(particulier: boolean, upload: boolean = false) {
    if (!upload){
      this.selectedPlanSante = null;
    }
    if (particulier) {
      this.dataService.getSpecifiquePlanSantes(upload ? [] : this.qualites).subscribe((data: any) => {
        this.planSantes = data?.data?.planSantes?.map((planSante: any) => {
          return {
            ...planSante,
            primeNette: this.calculatePrime(planSante, false)?.prime
          }
        });
        if (upload) {
          this.selectPlanSante(this.planSantes?.find((planSante: any) => planSante?.id == this.selectedPlanSante?.id));
        }
        else {
          this.generateBeneficiaireTable();
        }
        this.cdr.detectChanges();
      });
    }
    else {
      if (this.entrepriseId) {
        this.dataService.getSpecifiquePlanSantes(upload ? [] : this.qualites, this.entrepriseId).subscribe((data: any) => {
          this.planSantes = data?.data?.planSantes?.map((planSante: any) => {
            console.log(planSante, this.entrepriseId)
            const primeCalculated = this.calculatePrime(planSante, true)
            return {
              ...planSante,
              primeNette: primeCalculated?.prime,
              prorata: primeCalculated?.prorata,
              prorataDays: primeCalculated?.prorataDays
            }
          });
          if (upload) {
            this.selectPlanSante(this.planSantes?.find((planSante: any) => planSante?.id == this.selectedPlanSante?.id));
          }
          else {
            this.generateBeneficiaireTable();
          }
          this.cdr.detectChanges();
        });
      }
      else {
        this.planSantes = []
        this.cdr.detectChanges();
      }
    }
  }

  generateBeneficiaireTable() {
    var familles: any = [];
    var beneficiairesCharges: any = [];
    this.qualites?.filter((item: any, index: number) => item?.quantite > 0)?.map((item: any, index: number) => {
      Array.from(Array(item?.quantite).keys())?.forEach((val: number) => {
        let membre = {
          nom: '',
          prenom: '',
          dateNaissance: '',
          telephone: '',
          qualite: item?.id // item?.[4]
        }
        beneficiairesCharges?.push(membre)
        if (familles?.length == 0) {
          familles.push(
            [
              membre
            ]
          )
        }
        else {
          familles[familles?.length - 1].push(membre)
        }
      })
    })

    console.log(familles)

    this.selectedPlanSante = {
      ...this.selectedPlanSante, 
      familles,
      beneficiairesCharges,
      selectedTypeFinancement: this.selectedTypeFinancement,
    };
    this.planEvent.emit(this.selectedPlanSante);
  }

  selectPlanSante(planSante: any) {
    this.selectedPlanSante = {
      ...this.selectedPlanSante,
      ...planSante, 
      allQualites: this.qualites,
      selectedQualites: this.qualites?.filter((qualite: any) => qualite?.quantite > 0),
      isParticulierSelected: this.isParticulier,
      selectedTypeFinancement: this.selectedTypeFinancement,
      entrepriseId: this.entrepriseId
    };
    this.planEvent.emit(this.selectedPlanSante);
  }

  calculatePrime(planSante: any, esEntreprise = false) {
    let prime  = planSante.prime;
    if(!prime || !planSante?.forfaitaire){
      prime = planSante?.qualitePlans?.reduce((acc: number,planQualite: any)=> acc + planQualite?.prime * (this.qualites?.find((qualite: any)=>qualite?.id == planQualite?.qualite?.id)?.quantite ?? 0) ,0) 
    }
    let foundPolice = planSante?.polices?.find((police: any) => police?.entreprise?.id == this.entrepriseId);
    if (this.entrepriseId && esEntreprise && foundPolice) {
      let Difference_In_Time = new Date(foundPolice?.quittances?.[0]?.dateEcheance).getTime() - (new Date()).getTime();
      let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24)) + 1;
      return {
        prime: Math.floor((prime * Difference_In_Days) / 365),
        prorata: true,
        prorataDays: Difference_In_Days
      }
    }
    else {
      return {
        prime,
        prorata: false,
        prorataDays: 365
      }
    }
  }

  getAdequateType(typeChamp: string) {
    switch(typeChamp) {
      case 'TEXT':
        return 'text';
      case 'NUMBER':
        return 'number';
      case 'EMAIL':
        return 'email';
      case 'TELEPHONE':
        return 'phone';
      case 'DATE':
        return 'date';
      default:
        return 'text';
    }
  }

  handleQuantite(qualite:any, valeur: string) {
    if (valeur == '-') {
      qualite.quantite = (qualite?.quantite > 0) ? (qualite?.quantite - 1) : 0
    }
    else {
      qualite.quantite = qualite.quantite + 1;
    }
    this.handleTypeClient(this.isParticulier);
  }

}
