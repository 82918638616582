import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-parametrage',
  templateUrl: './parametrage.component.html',
  styleUrls: ['./parametrage.component.css']
})
export class ParametrageComponent  implements OnInit {

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  parametrages: any = [];
  headerInitialed = false;

  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'Parametrage';

  searchText = '';

  constructor(
    private dataService: DataService,
    private message: NzMessageService
  ) { }

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter une parametrage",
      titre: 'Entreprise',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formulaires: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  initializeHeaders() {
    this.headers = [
      {
        name: 'Nom',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Valeur',
        actif: true,
        position: 'left',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit'
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  handleFilter() {
    var _filter = `
    AND: [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    ]
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getParametrages();
  }

  ngOnInit(): void {
    this.getParametrages();
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  handleColumnChanged() {
    this.parametrages = this.getParametrages();
  }

  formulaireEvent(data: any) {
    if (this.formType != 'ADD') {
      this.dataService.updateParametrage(
        this.currentId,
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getParametrages();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getParametrages();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getParametrages();
  }

  getParametrages() {  
    this.dataService.getParametrages(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.parametrages = this.handleList(data?.data?.parametrages)
        this.totalCount = data?.data?.parametragesCount;
        if (!this.headerInitialed) {
          this.initializeHeaders();
          this.headerInitialed = true;
        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getParametrages()
    })
  }

  handleList(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.libelle,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.valeur,
        position: 'left',
      },
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getParametrageById(action?.value);
      break;
      case 'delete':
        this.getParametrages();
      break;
    }
  }

  getParametrageById(id: string) {
    this.dataService.getParametrageById(id)
    .subscribe(
      (data: any) => {
        this.initForm(data?.data?.parametrage);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.initForm();
  }

  initForm(data: any = {}) {
    if (data?.id) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      {
        code: "libelle",
        placeholder: "Libellé",
        typeChamp: "TEXT",
        etape: 1,
        readOnly: true,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.libelle),
        defaultValue: data?.libelle,
        size: 'col-md-12'
      },
      {
        code: "valeur",
        placeholder: "Valeur",
        typeChamp: data?.typeChamp == "TEXT" ? 'TEXTAREA' : "TEXTAREA",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.valeur),
        defaultValue: data?.valeur,
        size: 'col-md-12'
      }
    ]
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
  }

}