<div class="row">
    <div class="col-xl-12">
        <div class="card details-card">
            <div class="card-body">
                <div class="d-sm-flex mb-3">
                    <div class="card-info d-flex align-items-start">
                        <div class="center-lottie">
                            <ng-lottie [styles]="{width: '180px', marginRight: '20px', background: 'white'}" [options]="selectedTypeFinancement?.image"></ng-lottie>
                            <h2 class="font-w600 mb-2 text-black center price-bottom">
                                <!-- Prix
                                <br/> -->
                                Finalisez votre souscription
                            </h2>
                        </div>
                    </div>
                </div>
                <!-- <h4 class="fs-20 text-black font-w600">Story About Disease</h4> -->
                <div class="card">
                    <div class="card-body">
                        <div class="basic-form">
                            <form [formGroup]="formulaire" (ngSubmit)="submitFormulaire()">
                                <div class="form-row">
                                    <div *ngFor="let champ of selectedTypeFinancement?.formulaire?.champFormulaires; let i=index"  class="form-group col-md-6">
                                        <!-- Champ TEXT -->
                                        <label *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'TEXT' || champ?.typeChamp == 'NUMBER' || champ?.typeChamp == 'EMAIL' || champ?.typeChamp == 'TELEPHONE')">{{champ?.placeholder}}{{champ?.obligatoire ? '*' : ''}}</label>
                                        <input *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'TEXT' || champ?.typeChamp == 'NUMBER' || champ?.typeChamp == 'EMAIL' || champ?.typeChamp == 'TELEPHONE')" class="form-control" [formControlName]="champ?.code" [name]="champ?.code" [type]="getAdequateType(champ?.typeChamp)" [placeholder]="champ?.placeholder" [required]="champ?.obligatoire">
                                        
                                        <!-- Champ Date -->
                                        <label *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'DATE')">{{champ?.placeholder}} {{champ?.obligatoire ? '*' : ''}}</label>
                                        <input *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'DATE')" [formControlName]="champ?.code" [name]="champ?.code" [type]="getAdequateType(champ?.typeChamp)" class="form-control" [placeholder]="champ?.placeholder" [required]="champ?.obligatoire">
    
                                        <!-- Champ Select -->
                                        <label *ngIf="champ?.typeReponse == 'OPTION'">{{champ?.placeholder}} {{champ?.obligatoire ? '*' : ''}}</label>
                                        <select *ngIf="champ?.typeReponse == 'OPTION'" [formControlName]="champ?.code" [name]="champ?.code" class="form-control default-select">
                                            <option selected>Choisissez...</option>
                                            <option *ngFor="let option of champ?.options" [value]="option?.cle">{{option?.valeur}}</option>
                                        </select> 
                                        
                                        <!-- Champ Time Picker -->
                                        <nz-time-picker *ngIf="champ?.typeReponse == 'WRITE' && champ?.typeChamp == 'TIME'" [formControlName]="champ?.code" class="right-datepicker border-datepicker" [nzAutoFocus]="true" [nzDefaultOpenValue]="defaultOpenValue"></nz-time-picker> 

                                        <!-- champ Slider -->
                                        <nz-slider *ngIf="champ?.typeReponse == 'WRITE' && champ?.typeChamp == 'RANGE' && !champ?.errorAnswer && !champ?.answered" [formControlName]="champ?.code" class="slider-rigth" nzRange ></nz-slider>

                                        <div *ngIf="champ?.typeReponse == 'WRITE' && (champ?.typeChamp == 'IMAGE' || champ?.typeChamp == 'FICHIER') && !champ?.errorAnswer" class="clearfix">
                                            <nz-upload
                                            nzListType="picture"
                                            [formControlName]="champ?.code"
                                            (nzChange)="fileUploaded($event, champ)"
                                            [(nzFileList)]="fileList"
                                            >
                                            <button nz-button>
                                                <span nz-icon nzType="upload"></span>
                                                Upload
                                            </button>
                                            </nz-upload>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>