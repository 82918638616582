<!-- row -->
<div class="container-fluid" *ngIf="!isRelancePanelVisibled">
    <div class="form-head d-flex mb-sm-4 mb-3 margin-left-right-35">
        <div class="mr-auto">
            <h1 class="text-black font-w600">Relances</h1>
        </div>
        <div>
            <a (click)="showRelancePanel(true, 'IMPAYE')" href="javascript:void(0)" class="btn btn-primary mr-3" data-toggle="modal" data-target="#addOrderModal">Relancer impayé</a>
            <a (click)="showRelancePanel(true, 'ECHEANCE')" href="javascript:void(0)" class="btn btn-primary mr-3" data-toggle="modal" data-target="#addOrderModal">Relancer echéance</a>
            <a (click)="openAddForm()" href="javascript:void(0)" class="btn btn-outline-primary mr-3" data-toggle="modal" data-target="#addOrderModal">Relance ponctuelle</a>
            <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzClickHide]="false" [nzDropdownMenu]="colonne" class="btn btn-outline-primary"><i class="fa fa-plus scale5 mr-3"></i>{{headersActif()}} {{headersActif() <= 1 ? 'colonne' : 'colonnes'}}</a>
            <!-- <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzClickHide]="false" [(nzVisible)]="true" [nzDropdownMenu]="colonne">
                <span style="font-size: 12px; position: relative; top: -3px; " nz-icon nzType="filter" nzTheme="fill"></span>
            </a> -->
        </div>
    </div>
    <!-- Add Order -->
    <app-form 
        (onCloseForm)="onCloseForm()" 
        [formTab]="formTab" 
        *ngIf="isFormOpened"
        [formulaires]="formulaires"
        (formulaireEvent)="formulaireEvent($event)"
        [formType]="formType"
        [modelName]="modelName"
        (onUpload)="onUploadEvent($event)"
        [chargementMutation]="'createChargementQuittance'"
        >
    </app-form>
    <app-table 
        [headers]="filteredHeader" 
        [listOfData]="quittances"  
        [tableTypes]="tableTypes"
        (onSegmentEvent)="onSegmentEvent($event)"
        [totalCount]="totalCount"
        [currentPage]="currentPage"
        (onTableUpdate)="onTableUpdate($event)"
        (onSearchTextChanged)="onSearchTextChanged($event)"
        [pageCount]="pageCount"
        [modelName]="modelName"
        title="Liste des clients relancés"
        (onAction)="onAction($event)"
    >
    </app-table>

    <nz-dropdown-menu #colonne="nzDropdownMenu">
        <ul nz-menu>
            <div class="margin-auto width-90 mt-2 mb-3">
                <input (keyup)="searchColumn()" [(ngModel)]="searchColumnText" type="text" class="form-control" nz-input placeholder="Rechercher une colonne" />
            </div>
          <li *ngFor="let header of headers; let i = index" nz-menu-item>
            <ng-container *ngIf="header?.type != 'action'">
                <label [disabled]="headersActif() <= 1 && header?.actif" [id]="header?.name" nz-checkbox (ngModelChange)="handleColumnChanged()" [(ngModel)]="headers[i].actif">{{header?.name}}</label>
            </ng-container>
          </li>
        </ul>
    </nz-dropdown-menu>


</div>

<!-- row -->
<div class="container-fluid" *ngIf="isRelancePanelVisibled">
    <div class="form-head d-flex mb-sm-4 mb-3 margin-left-right-35">
        <div class="mr-auto">
            <h1 class="text-black font-w600">Relances</h1>
        </div>
        <div>
            <a (click)="showRelancePanel(false)" href="javascript:void(0)" class="btn btn-primary mr-3" data-toggle="modal" data-target="#addOrderModal">Liste des relances</a>
            <!-- <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzClickHide]="false" [nzDropdownMenu]="colonne" class="btn btn-outline-primary"><i class="fa fa-plus scale5 mr-3"></i>{{headersActif()}} {{headersActif() <= 1 ? 'colonne' : 'colonnes'}}</a> -->
            <!-- <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzClickHide]="false" [(nzVisible)]="true" [nzDropdownMenu]="colonne">
                <span style="font-size: 12px; position: relative; top: -3px; " nz-icon nzType="filter" nzTheme="fill"></span>
            </a> -->
        </div>
    </div>
    
    <div class="filter-container">
        <nz-card style="margin-left: 1.875; margin-right: 1.875;" nzTitle="Filtres" [nzExtra]="extraTemplateFilter">
            <div class="row">
                <div class="col-md-6" *ngIf="relanceType == 'IMPAYE'">
                    <label>Recherche</label>
                    <br>
                    <input (keyup)="handleSearchText()" placeholder="Recherche" class="form-control input-daterange-datepicker" [(ngModel)]="searchText" type="text" name="searchText">
                    <!-- <nz-range-picker (nzOnCalendarChange)="handleFilter()" [(ngModel)]="relanceParam.dateEcheance" [nzFormat]="'dd-MM-yyyy'" [nzSize]="'large'"></nz-range-picker> -->
                </div>
                <div class="col-md-6" *ngIf="relanceType == 'ECHEANCE'">
                    <label>Intervale echéance</label>
                    <br>
                    <div class="row">
                        <div class="col-md-6">
                            <input (change)="handleFilter()" class="form-control input-daterange-datepicker" [(ngModel)]="relanceParam.dateEcheance[0]" type="date" name="daterange1">
                        </div>
                        <div class="col-md-6">
                            <input (change)="handleFilter()" class="form-control input-daterange-datepicker" [(ngModel)]="relanceParam.dateEcheance[1]" type="date" name="daterange2">
                        </div>
                    </div>
                    <!-- <nz-range-picker (nzOnCalendarChange)="handleFilter()" [(ngModel)]="relanceParam.dateEcheance" [nzFormat]="'dd-MM-yyyy'" [nzSize]="'large'"></nz-range-picker> -->
                </div>
                <div class="col-md-6">
                    <label>Compagnies</label>
                    <nz-select (ngModelChange)="handleFilter()" [nzAllowClear]="true" class="form-control select-multiple" nzMode="tags" nzPlaceHolder="Par défaut c'est toutes les compagnies" [(ngModel)]="relanceParam.compagnies">
                        <!-- <nz-option [nzLabel]="'Toutes les compagnies'" [nzValue]="'*'"></nz-option> -->
                        <nz-option *ngFor="let option of compagnies" [nzDisabled]="relanceParam?.compagnies?.includes('*')" [nzLabel]="option.raisonSociale" [nzValue]="option.id"></nz-option>
                    </nz-select>
                </div>
                <div class="col-md-6">
                    <label>Branches</label>
                    <nz-select (ngModelChange)="brancheChanged($event)" [nzAllowClear]="true" class="form-control select-multiple" nzMode="tags" nzPlaceHolder="Par défaut c'est toutes les branches" [(ngModel)]="relanceParam.branches">
                        <!-- <nz-option [nzLabel]="'Toutes les branches'" [nzValue]="'*'"></nz-option> -->
                        <nz-option *ngFor="let option of branches" [nzDisabled]="relanceParam?.branches?.includes('*')" [nzLabel]="option.nom" [nzValue]="option.id"></nz-option>
                    </nz-select>
                </div>
                <!-- <div class="col-md-4">
                    <label>Risques</label>
                    <nz-select (ngModelChange)="handleFilter()" [nzAllowClear]="true" class="form-control select-multiple" nzMode="tags" nzPlaceHolder="Par défaut c'est tous les risques" [(ngModel)]="relanceParam.risques">
                        <nz-option *ngFor="let option of risques" [nzDisabled]="relanceParam?.risques?.includes('*')" [nzLabel]="option.nom" [nzValue]="option.id"></nz-option>
                    </nz-select>
                </div> -->
                <div class="col-md-6">
                    <label>Clients</label>
                    <nz-select (ngModelChange)="handleFilter('client', $event)" [nzAllowClear]="true" [nzLoading]="true" (nzOnSearch)="getAllClientBySearch($event)" class="form-control select-multiple" nzMode="tags" nzPlaceHolder="Par défaut c'est tous les clients" [(ngModel)]="relanceParam.clients">
                        <!-- <nz-option [nzLabel]="'Toutes les clients'" [nzValue]="'*'"></nz-option> -->
                        <nz-option *ngFor="let option of clients" [nzDisabled]="relanceParam?.clients?.includes('*')" [nzLabel]="option.code + ' - ' + option.prenom + ' ' + option.nom + ' - ' + (option.contact?.[0] ? option.contact?.[0] : '')" [nzValue]="option.id"></nz-option>
                    </nz-select>
                </div>
            </div>
        </nz-card>
    </div>

    <ng-template #extraTemplateFilter>
        <a nz-dropdown (click)="getQuittances(1000000000)" class="btn btn-primary mr-3"><i class="fa fa-check scale5"></i> Valider le filtre</a>
        <a (click)="reinitFilters()" style="color: #016b88;">Tout Réinitialiser</a>
    </ng-template>

    <ng-template #extraTemplateRelance>
        <a (click)="relancerPolices();" href="javascript:void(0)" class="btn btn-outline-primary mr-3" data-toggle="modal" data-target="#addOrderModal">Relancer la sélection</a>
    </ng-template>
    <!-- Add Order -->
    <nz-card style="margin-left: 1.875; margin-right: 1.875;" nzTitle="Resultat du filtre des relances" [nzExtra]="extraTemplateRelance">
        
    <nz-table
    #rowSelectionTable
    [nzData]="listOfData"
    [nzFrontPagination]="false"
    (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
  >
    <thead>
      <tr>
        <th
          [nzSelections]="listOfSelection"
          [(nzChecked)]="checked"
          [nzIndeterminate]="indeterminate"
          (nzCheckedChange)="onAllChecked($event)"
        ></th>
        <th>Quittance</th>
        <th>Police</th>
        <th>Echcéance</th>
        <th>Client</th>
        <!-- <th>Compagnie</th> -->
        <th>Branche</th>
        <th>Risque</th>
        <th>Prime totale</th>
        <th>Relances</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let data of rowSelectionTable.data">
        <tr>
            <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)" [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)"></td>
            <td>{{ data?.numero }}</td>
            <td>{{ data?.police?.police }}</td>
            <td>{{ data?.dateEcheance }}</td>
            <td>{{ data?.police?.client?.code }} - {{ data?.police?.client?.prenom }} {{ data?.police?.client?.nom }}</td>
            <!-- <td>{{ data?.police?.compagnie?.raisonSociale }}</td> -->
            <td>{{ data?.police?.risque?.branche?.nom }}</td>
            <td>{{ data?.police?.risque?.nom }}</td>
            <td>{{ data?.primeTotale }} Fcfa</td>
            <td>
                <div class="d-flex">
                    <span *ngIf="data?.relanceJ10" class="badge badge-success" style="padding: 4px 4px;">-10</span>
                    <span *ngIf="data?.relanceJ3" class="badge badge-primary light" style="padding: 4px 9px 4px 6px;">-3</span>
                    <span *ngIf="data?.relanceJJ" class="badge badge-warning" style="padding: 4px 6px 4px 6px;">JJ</span>
                    <span *ngIf="data?.relanceManuele" class="badge badge-danger" style="padding: 4px 6px 4px 6px;">M</span>
                </div>
                <span *ngIf="!data?.relanceJ10 && !data?.relanceJ3 && !data?.relanceJJ && !data?.relanceManuele">----</span>
            </td>
          </tr>
          <tr [nzExpand]="expandSet.has(data.id)">
            <div class="row">
                <div *ngFor="let info of data?.police?.infoPolices" class="col-md-3">
                    <i class="fa fa-circle text-success mr-1"></i>  <span >{{info?.libelle}} : {{ info?.valeur }}, <br></span>
                </div>
            </div>
          </tr>
      </ng-container>
    </tbody>
  </nz-table>
    </nz-card>

    <nz-dropdown-menu #colonne="nzDropdownMenu">
        <ul nz-menu>
            <div class="margin-auto width-90 mt-2 mb-3">
                <input (keyup)="searchColumn()" [(ngModel)]="searchColumnText" type="text" class="form-control" nz-input placeholder="Rechercher une colonne" />
            </div>
          <li *ngFor="let header of headers; let i = index" nz-menu-item>
            <ng-container *ngIf="header?.type != 'action'">
                <label [disabled]="headersActif() <= 1 && header?.actif" [id]="header?.name" nz-checkbox (ngModelChange)="handleColumnChanged()" [(ngModel)]="headers[i].actif">{{header?.name}}</label>
            </ng-container>
          </li>
        </ul>
    </nz-dropdown-menu>


</div>
