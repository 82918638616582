import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import * as Highcharts from 'highcharts/highcharts';
import highcharts3D from 'highcharts/highcharts-3d';
import treegraph from 'highcharts/modules/treegraph'
import treemap from 'highcharts/modules/treemap'
import exportModule from 'highcharts/modules/exporting'
import accessibility from 'highcharts/modules/accessibility'
import treegrid from 'highcharts/modules/treegrid'

treemap(Highcharts);
treegraph(Highcharts);
highcharts3D(Highcharts);
exportModule(Highcharts);
accessibility(Highcharts);
treegrid(Highcharts);
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  totaux: any = {};
  statistics: any = {};
  quittanceFilters: any = {};
  dateRange: any = [];
  compagnies: any = [];
  compagnieTrees: any = [];
  accessoires: any = [];
  branches: any = [];

  dateFormat = 'dd/MMM/yyyy';
  monthFormat = 'MM/yyyy';
  currentTabCompagnie: string = 'commissionTotale';
  currentTabCompagnieLibelle: any = {
    production: 'Production', 
    commissionTotale: 'Commission Totale', 
    commissionPrime: 'Commission sur la prime', 
    commissionAccessoire: 'Commission sur les accessoires'
  };

  colors = ['#A4E57D', '#519EBD', '#C5F8FF', '#F2952F', '#5C71AA', '#8671AE', '#FFEACE', '#84F9BD', '#00A4E9', '#98AFBA', '#7CC7E7'];

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    var _date = new Date();
    _date.setDate(1);
    this.dateRange = [
      _date,
      new Date()
    ]
    this.getTotaux(this.dateRange);
  }

  ngAfterViewInit(): void {
    // this.addScript('assets/vendor/apexchart/apexchart.js');
    // this.addScript('assets/js/dashboard/dashboard-1.js');
    // this.addScript('assets/js/somescripts.js');
    
    var css = document.createElement('link');
    css.id ='zorroId';
    css.href = "assets/css/zorro-dashbord.css";
    css.rel = "stylesheet";
    document.head.append(css);
  }

  addScript(src: string) {
    var element = document.createElement('script');
    element.src = src;
    document.body.append(element);
  }

  getTotaux(range: any, compagnies: any = null, initCompagnie = true) {

    this.dataService.getQuittanceCommule(range, compagnies)
    .subscribe((data: any) => {
      this.statistics = {
        quittanceSome: data?.data?.quittances?.length ? data?.data?.quittances?.map((quittance: any) => quittance?.primeTotale) : [0],
        quittanceCommission: data?.data?.quittances?.length ? data?.data?.quittances?.map((quittance: any) => {
          var coms = data?.data?.accessoires?.find((accessoire: any) => accessoire?.compagnie?.id == quittance?.police?.compagnie?.id && accessoire?.risque?.id == quittance?.police?.risque?.id);
          return Math.floor((quittance?.primeNette * (coms?.tauxCommissionPrime ? coms?.tauxCommissionPrime : 0) / 100) + (quittance?.accessoire * (coms?.tauxCommissionAccessoire ? coms?.tauxCommissionAccessoire : 0) / 100))
        })
        : [0],
        commissionPrime: data?.data?.quittances?.length ? data?.data?.quittances?.map((quittance: any) => {
          var coms = data?.data?.accessoires?.find((accessoire: any) => accessoire?.compagnie?.id == quittance?.police?.compagnie?.id && accessoire?.risque?.id == quittance?.police?.risque?.id)
          return Math.floor((quittance?.primeNette * (coms?.tauxCommissionPrime ? coms?.tauxCommissionPrime : 0) / 100))
        })
        : [0],
        commissionAccessoire: data?.data?.quittances?.length ? data?.data?.quittances?.map((quittance: any) => {
          var coms = data?.data?.accessoires?.find((accessoire: any) => accessoire?.compagnie?.id == quittance?.police?.compagnie?.id && accessoire?.risque?.id == quittance?.police?.risque?.id)
          return Math.floor((quittance?.accessoire * (coms?.tauxCommissionAccessoire ? coms?.tauxCommissionAccessoire : 0) / 100))
        })
        : [0],
      }

      if (initCompagnie) {
        this.compagnies = data?.data?.compagnies?.map((compagnie: any) =>{
          return {
            ...compagnie,
            actif: true,
            actifTree: true
          }
        });
        this.compagnieTrees = this.compagnies;
      }
      this.accessoires = data?.data?.accessoires;

      this.getQuittanceFilters(data?.data?.compagnies, data?.data?.accessoires)
      this.getQuittanceFilterForBranche(data?.data?.branches, data?.data?.accessoires, data?.data?.compagnies);
      this.getTreeQuittance(data?.data?.compagnies, data?.data?.branches, data?.data?.accessoires)
    })
  }

  compagnieFilterChanged() {
    setTimeout(() => {
      this.getQuittanceFilterForBranche(this.branches, this.accessoires, this.compagnies?.filter((compagnie: any) => compagnie?.actif));
      // this.getTotaux(this.dateRange, this.compagnies?.filter((compagnie: any) => compagnie?.actif), false)
    }, 200)
  }

  getQuittanceFilterForBranche(branches: any, accessoires: any, compagnies: any) {
    var series: any = [{
          name: 'Production',
          type: 'column',
          data: []

      }, {
          name: 'Com.',
          type: 'column',
          data: []

      }, {
          name: 'Com. Prime',
          type: 'column',
          data: []

      }, {
          name: 'Com Acce',
          type: 'column',
          data: []

      }];

      this.branches = branches?.map((branche: any, index: number) => {
        series[0].data?.push(branche?.risques?.reduce((partial: number, risque: any) => partial + risque?.polices?.reduce((partial: number, police: any) => partial +              compagnies?.map((compagnie: any) => compagnie?.id)?.includes(police?.compagnie?.id) ? police?.quittances?.reduce((partial: number, quittance: any) => partial + quittance?.primeTotale ,0) : 0                       ,0) ,0));
        series[1].data?.push(Math.floor(branche?.risques?.reduce((partial: number, risque: any) => partial + risque?.polices?.reduce((partial: number, police: any) => partial + compagnies?.map((compagnie: any) => compagnie?.id)?.includes(police?.compagnie?.id) ? police?.quittances?.reduce((partial: number, quittance: any) => partial + ( (quittance?.primeNette * ( accessoires?.find((accessoire: any) => accessoire?.compagnie?.id == police?.compagnie?.id && accessoire?.risque?.id == risque?.id)?.tauxCommissionPrime / 100)) + (quittance?.accessoire * ( accessoires?.find((accessoire: any) => accessoire?.compagnie?.id == police?.compagnie?.id && accessoire?.risque?.id == risque?.id)?.tauxCommissionAccessoire / 100))) ,0) : 0 ,0) ,0)));
        series[2].data?.push(Math.floor(branche?.risques?.reduce((partial: number, risque: any) => partial + risque?.polices?.reduce((partial: number, police: any) => partial + compagnies?.map((compagnie: any) => compagnie?.id)?.includes(police?.compagnie?.id) ? police?.quittances?.reduce((partial: number, quittance: any) => partial + (quittance?.primeNette * ( accessoires?.find((accessoire: any) => accessoire?.compagnie?.id == police?.compagnie?.id && accessoire?.risque?.id == risque?.id)?.tauxCommissionPrime / 100)) ,0) : 0 ,0) ,0)));
        series[3].data?.push(Math.floor(branche?.risques?.reduce((partial: number, risque: any) => partial + risque?.polices?.reduce((partial: number, police: any) => partial + compagnies?.map((compagnie: any) => compagnie?.id)?.includes(police?.compagnie?.id) ? police?.quittances?.reduce((partial: number, quittance: any) => partial + (quittance?.accessoire * ( accessoires?.find((accessoire: any) => accessoire?.compagnie?.id == police?.compagnie?.id && accessoire?.risque?.id == risque?.id)?.tauxCommissionAccessoire / 100)) ,0) : 0 ,0) ,0)));
        
        return {
          ...branche,
          production: series[0].data?.[index],
          commission: series[1].data?.[index],
          comPrime: series[2].data?.[index],
          comAccessoire: series[3].data?.[index]
        }
      });
    
    Highcharts.chart('branchequittance', {
      chart: {
          type: 'column'
      },
      title: {
          text: 'Commissions par branche',
      },
      subtitle: {
          text: 'Cliquer sur le button à droite pour filtrer par compagnie'
      },
      xAxis: {
          categories: branches?.map((branche: any) => branche?.nom),
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Fcfa'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.0f} Fcfa</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series
    });
  }

  compagnieTabChanged(type: string) {
    this.currentTabCompagnie = type;
    this.getQuittanceFilters(this.compagnies, this.accessoires)
  }

  getTreeQuittance(compagnies: any, branches: any, accessoires: any) {
    var treeGraph = [{
      name: 'PROASSUR',
      parent: '',
      id: 'root'
    }];
    compagnies?.forEach((compagnie: any) => {
      treeGraph?.push({
        name: compagnie?.raisonSociale,
        parent: 'root',
        // @ts-ignore
        parentName: 'PROASSUR',
        id: compagnie?.id,
      })
      branches?.forEach((branche: any) => {
        if(branche?.risques?.find((risque: any) => risque?.polices?.find((police: any) => police?.compagnie?.id == compagnie?.id))) {
          treeGraph?.push({
            id: `${compagnie?.id}-${branche?.id}`,
            name: branche?.nom,
            // @ts-ignore
            parentName: compagnie?.raisonSociale,
            parent: compagnie?.id
          });
          branche?.risques?.forEach((risque: any) => {
            if(risque?.polices?.find((police: any) => police?.compagnie?.id == compagnie?.id)) {
              treeGraph?.push({
                id: `${compagnie?.id}-${branche?.id}-${risque?.id}`,
                name: risque?.nom,
                // @ts-ignore
                parentName: branche?.nom,
                parent: `${compagnie?.id}-${branche?.id}`
              });
              treeGraph?.push({
                id: `${compagnie?.id}-${branche?.id}-${risque?.id}-production`,
                name: "Prime totale",
                // @ts-ignore
                parentName: risque?.nom,
                parent: `${compagnie?.id}-${branche?.id}-${risque?.id}`
              });
              treeGraph?.push({
                id: `${compagnie?.id}-${branche?.id}-${risque?.id}-commission`,
                name: "Commissions",
                // @ts-ignore
                parentName: risque?.nom,
                parent: `${compagnie?.id}-${branche?.id}-${risque?.id}`
              });
              treeGraph?.push({
                id: `${compagnie?.id}-${branche?.id}-${risque?.id}-production-somme`,
                name: risque?.polices?.reduce((partialSum: number, police: any) => partialSum + (police?.compagnie?.id == compagnie?.id ? police?.quittances?.reduce((partialSum: number, quittance: any) => partialSum + quittance?.primeTotale, 0) : 0), 0) + ' Fcfa',
                // @ts-ignore
                parentName: "Prime totale",
                parent: `${compagnie?.id}-${branche?.id}-${risque?.id}-production`
              });
              treeGraph?.push({
                id: `${compagnie?.id}-${branche?.id}-${risque?.id}-commission-somme`,
                name: Math.floor(risque?.polices?.reduce((partialSum: number, police: any) => partialSum + (police?.compagnie?.id == compagnie?.id ? police?.quittances?.reduce((partialSum: number, quittance: any) => partialSum + (quittance?.accessoire * ( accessoires?.find((accessoire: any) => accessoire?.compagnie?.id == police?.compagnie?.id && accessoire?.risque?.id == risque?.id)?.tauxCommissionAccessoire / 100)) + (quittance?.primeNette * ( accessoires?.find((accessoire: any) => accessoire?.compagnie?.id == police?.compagnie?.id && accessoire?.risque?.id == risque?.id)?.tauxCommissionPrime / 100)), 0) : 0), 0)) + ' Fcfa', 
                // @ts-ignore
                parentName: "Commissions",
                parent: `${compagnie?.id}-${branche?.id}-${risque?.id}-commission`
              });
            }
          });
        }
      });
    });

    var container = document.getElementById('arbrequittance');
    if (container) {
      var width = 600;
      if (compagnies?.length > 2) {
        width += (compagnies?.length - 2) * 120;
      }
      container.style.height = `${width}px`;
    }

    // @ts-ignore
    Highcharts.chart('arbrequittance', {
      chart: {
        spacingBottom: 30,
        marginRight: 120
      },
      title: {
        text: 'Arbre de productions & de commissions'
      },
      series: [
        {
          type: 'treegraph',
          link: {
              type: 'curved',
              lineWidth: 3
          },
          keys: ['parent', 'parentName', 'id', 'name', 'level'],
          clip: false,
          data: treeGraph,
          tooltip: {
            pointFormat: '{point.name}',
            linkFormat: '{point.parentName}-{point.name}',
          },
          marker: {
            symbol: 'circle',
            radius: 6,
            fillColor: '#ffffff',
            lineWidth: 3
          },
          dataLabels: {
            align: 'left',
            pointFormat: '{point.name}',
            style: {
              color: '#000000',
              textOutline: '3px #ffffff',
              whiteSpace: 'nowrap'
            },
            x: 24,
            crop: false,
            overflow: 'none'
          },
          levels: [
            {
              level: 1,
              levelIsConstant: false
            },
            {
              level: 2,
              colorByPoint: true
            },
            {
              level: 3,
              colorVariation: {
                key: 'brightness',
                to: -0.5
              }
            },
            {
              level: 4,
              colorVariation: {
                key: 'brightness',
                to: 0.5
              }
            },
            {
              level: 6,
              dataLabels: {
                x: 10
              },
              marker: {
                radius: 4
              }
            }
          ]
        }
      ]
    });
  }

  arbreFilterChanged() {
    setTimeout(() => { 
      this.getTreeQuittance(this.compagnieTrees?.filter((compagnie: any) => compagnie?.actifTree), this.branches, this.accessoires)
    }, 200);
  }

  getQuittanceFilters(compagnies: any, accessoires: any) {
    // const totale = compagnies?.map((compagnie: any) => compagnie?.polices?.reduce((partialSum: number, police: any) => partialSum + police?.quittances.reduce((partialSum: number, quittance: any) => partialSum + quittance?.primeNette, 0), 0)).reduce((partialSum: number, a: any) => partialSum + a, 0);
    this.quittanceFilters = {
      // totale,
      compagnies: compagnies?.map((compagnie: any) => {
        var commissionAccessoire = Math.floor(compagnie?.polices?.reduce((partialSum: number, police: any) => partialSum + police?.quittances.reduce((partialSum: number, quittance: any) => partialSum + (quittance?.accessoire * accessoires?.find((accessoire: any) => accessoire?.compagnie?.id == police?.compagnie?.id && accessoire?.risque?.id == police?.risque?.id)?.tauxCommissionAccessoire / 100 ), 0), 0));
        var commissionPrime = Math.floor(compagnie?.polices?.reduce((partialSum: number, police: any) => partialSum + police?.quittances.reduce((partialSum: number, quittance: any) => partialSum + (quittance?.primeNette * accessoires?.find((accessoire: any) => accessoire?.compagnie?.id == police?.compagnie?.id && accessoire?.risque?.id == police?.risque?.id)?.tauxCommissionPrime / 100 ), 0), 0))
        return {
          nom: compagnie?.raisonSociale,
          production: Math.floor(compagnie?.polices?.reduce((partialSum: number, police: any) => partialSum + police?.quittances.reduce((partialSum: number, quittance: any) => partialSum + quittance?.primeTotale, 0), 0)),
          commissionAccessoire,
          commissionPrime,
          commissionTotale: commissionPrime + commissionAccessoire
        }
      }),
      month: 130
    }
    this.quittanceFilters = {
      totale: this.quittanceFilters?.compagnies?.map((compagnie: any) => compagnie[this.currentTabCompagnie])?.reduce((partialSum: number, val: any) => partialSum + val, 0),
      compagnies: this.quittanceFilters?.compagnies?.map((compagnie: any) => {
        return {
          nom: compagnie?.nom,
          valeur: compagnie[this.currentTabCompagnie],
          actif: true
        }
      })
    }

    // this.quittanceFilters.compagnies = [
    //   ...this.quittanceFilters?.compagnies,
    //   ...this.quittanceFilters?.compagnies,
    //   ...this.quittanceFilters?.compagnies,
    //   ...this.quittanceFilters?.compagnies,
    // ]

    Highcharts.chart('compagniequittance', {
      chart: {
          type: 'pie',
          options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
          }
      },
      title: {
          text: 'Commissions par compagnie',
          align: 'left'
      },
      // subtitle: {
      //     text: 'Source: ' +
      //         '<a href="https://www.counterpointresearch.com/global-smartphone-share/"' +
      //         'target="_blank">Counterpoint Research</a>',
      //     align: 'left'
      // },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                  enabled: true,
                  format: '{point.name}'
              }
          }
      },
      series: [{
          type: 'pie',
          colors: this.colors,
          name: 'Quittance',
          data: this.quittanceFilters?.compagnies?.map((compagnie: any) => {
            return [
              compagnie?.nom,
              compagnie?.valeur
            ]
          })
      }]
    });

  }

  calendarChanged() {
      setTimeout(() => {
        this.getTotaux(this.dateRange);
      }, 200)
  }

  getDateOfFilterType(type: string) {
    var date = new Date();
    switch(type) {
      case 'day':
        return date?.toISOString()?.split('T')[0]?.split('Z')[0];
      case 'week':
        date.setDate(date.getDate() - date.getDay() + 1)
        return date?.toISOString()?.split('T')[0]?.split('Z')[0];
      case 'month':
        date.setDate(1);
        return date?.toISOString()?.split('T')[0]?.split('Z')[0];
      default:
        date.setDate(1);
        return date?.toISOString()?.split('T')[0]?.split('Z')[0];
    }
  }

}
