<!-- row -->
<iframe 
    id="wavePaymentId && !successPayment && !failedPayment" 
    *ngIf="wavePaymentInProgress" 
    [src]="safeUrl(wavePaymentUrlFame)" 
    style="border:0px #ffffff none;" 
    name="myiFrame" 
    scrolling="no" 
    frameborder="1" 
    marginheight="0px" 
    marginwidth="0px" 
    height="570px" 
    width="650px" 
    allowfullscreen>
</iframe>
<nz-input-group *ngIf="wavePaymentInProgress" nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
    <input type="text" style="height: 56px;" [(ngModel)]="waveNumber" class="form-control" nz-input placeholder="Numéro WAVE" />
</nz-input-group>
<ng-template #suffixButton>
<button (click)="sendPaymentUrlToWhatsapp()" nz-button nzType="primary" [disabled]="!waveNumber" type="submit" class="btn btn-primary">
    Envoyer le lien par whatsapp 
    <span class="btn-icon-right"><i class="fa fa-arrow-right"></i></span>
</button>
</ng-template>
<div *ngIf="successPayment" class="payment-done">
    <ng-lottie width="100%" height="500px" [styles]="{padding: '25px', fill: 'transparent'}" [options]="optionsSuccess"></ng-lottie>
</div>
<div *ngIf="failedPayment" class="payment-done">
    <ng-lottie width="100%" height="500px" [styles]="{padding: '25px', fill: 'transparent'}" [options]="optionsFailed"></ng-lottie>
</div>
<div class="row" *ngIf="!wavePaymentInProgress && !successPayment && !failedPayment">
    <div *ngFor="let paymentMethod of paymentMethods" class="col-xl-6 col-xxl-6 col-lg-6 col-sm-6">
        <div class="widget-stat card">
            <div (click)="paymentMethodSelected(paymentMethod)" [ngClass]="{'card-body': true, 'card-hghlght': true, 'p-4': true, 'payment-selected': selectedMethod?.id == paymentMethod?.id }">
                <div class="media ai-icon">
                    <span class="mr-3 text-primary">
                        <!-- <i class="ti-user"></i> -->
                        <img class="image-paiement" [src]="paymentMethod?.imageurl" alt="">
                    </span>
                    <div class="media-body">
                        <p class="mb-1">paiement</p>
                        <h4 class="mb-0 paiement-description">{{paymentMethod?.nom}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <nz-card *ngIf="selectedMethod?.id" class="width-100 selected-panel" [nzCover]="coverTemplate" >
        <nz-card-meta
            [nzDescription]="bodyTemplate"
        ></nz-card-meta>
    </nz-card>
    <ng-template #bodyTemplate>
        <div *ngIf="selectedMethod?.type == 'PAYPAL_CARTE_CREDIT'" id="paypal-button-container"></div>

        <div class="payment-form" *ngIf="selectedMethod?.type == 'WAVE'">
            <nz-switch [(ngModel)]="wavePayementWhitoutApp" class="mb-30"></nz-switch> <span for="wavePayementWhitoutApp"> Paiement sans internet</span>
            <div *ngIf="wavePayementWhitoutApp">
                Vous pouvez payer autrement selon votre opérateur
                <div class="operateur-container">
                    <img class="operateur-img" src="assets/images/operateur/orange2.jpeg" alt=""> Orange : #2171#⁣1*numéro*montant*code_sécret#
                </div>
                <div class="operateur-container">
                    <img class="operateur-img" src="assets/images/operateur/free.webp" alt=""> Free : #171#⁣
                </div>
                <div class="operateur-container">
                    <img class="operateur-img" src="assets/images/operateur/expresso.jpeg" alt=""> Expresso : Activez juste les données mobile⁣
                </div>
            </div>
            <br>
            <button (click)="handleWavePayment()" *ngIf="!wavePayementWhitoutApp" nz-button nzType="default" type="button" class="btn btn-light width-100 wave-btn">
                <span class="btn-icon-left"><i class="fa fa-check color-primary"></i></span>
                Payer avec l'application
            </button>
            <button (click)="savePaiement('WAVE')" *ngIf="wavePayementWhitoutApp" nz-button nzType="default" type="button" class="btn btn-light width-100 wave-btn">
                <span class="btn-icon-left"><i class="fa fa-check color-primary"></i></span>
                Je confirme avoir reçu le paiement
            </button>
        </div>

        <div class="payment-form" *ngIf="selectedMethod?.type == 'CHEQUE'">
            <div class="basic-form">
                <form [formGroup]="chequeForm">
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <label>Numéro du chéque</label>
                            <input class="form-control" formControlName="numero" name="numero" type="text" placeholder="Numéro du chéque" [required]="true">
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Nom de la banque</label>
                            <input class="form-control" formControlName="banque" name="banque" type="text" placeholder="Nom de la banque" [required]="true">
                        </div>
                        <div class="form-group col-lg-12">
                            <br>
                            <nz-switch formControlName="ordreCompagnie" name="ordreCompagnie" class="mb-30"></nz-switch> <span> Paiement à l'ordre de la compagnie</span>
                        </div>
                    </div>
                    
                </form>
            </div>
            <br>
            <button (click)="handleChequePayment()" [disabled]="!(chequeForm.status == 'VALID')" nz-button nzType="default" type="button" class="btn btn-light width-100 black-btn">
                <span class="btn-icon-left"><i class="fa fa-check color-primary"></i></span>
                Confirmer le paiement par chéque
            </button>
        </div>

        <div class="payment-form" *ngIf="selectedMethod?.type == 'ORANGE_MONEY'">
            <div class="basic-form">
                <form [formGroup]="omForm">
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <label>Numéro de téléphone</label>
                            <input [readOnly]="omForm?.value?.codeMarchantOM" class="form-control" formControlName="telephone" name="telephone" type="text" placeholder="Numéro du payeur" [required]="true">
                        </div>
                        <div class="form-group col-lg-6">
                            <label>CODE OTP (#144#391#)</label>
                            <input [readOnly]="omForm?.value?.codeMarchantOM" class="form-control" formControlName="OTP" name="OTP" type="text" placeholder="#144#391# pour obtenir le code OTP" [required]="true">
                        </div>
                    </div>
                    <div class="form-group col-lg-12">
                        <br>
                        <nz-switch [nzDisabled]="omForm.status == 'VALID'" formControlName="codeMarchantOM" name="codeMarchantOM" class="mb-30"></nz-switch> <span> Paiement par scan ou code marchant</span>
                    </div>
                    
                </form>
            </div>
            <br>
            <button (click)="handleOMPayment()" [disabled]="!(omForm.status == 'VALID' || omForm?.value?.codeMarchantOM) || omPaymentInProgress" nz-button nzType="default" type="button" class="btn btn-light width-100 black-btn">
                <span class="btn-icon-left"><i class="fa fa-check color-primary"></i></span>
                Valider
            </button>
        </div>

        <div class="payment-form" *ngIf="selectedMethod?.type == 'VIREMENT'">
            <div class="basic-form">
                <form [formGroup]="virementForm">
                    <div class="form-row">
                        <div class="form-group col-lg-12">
                            <label>Nom de la banque</label>
                            <input class="form-control" formControlName="banque" name="banque" type="text" placeholder="Nom de la banque" [required]="true">
                        </div>
                    </div>
                    
                </form>
            </div>
            <br>
            <button (click)="handleVirementPayment()" [disabled]="!(virementForm.status == 'VALID')" nz-button nzType="default" type="button" class="btn btn-light width-100 black-btn">
                <span class="btn-icon-left"><i class="fa fa-check color-primary"></i></span>
                Confirmer le virement
            </button>
        </div>

        <div class="payment-form" *ngIf="selectedMethod?.type == 'COMPENSATION'">
            <div class="basic-form">
                <form [formGroup]="compesationForm">
                    <div class="form-row">
                        <div class="form-group col-lg-12">
                            <label>Libellé de la compensation</label>
                            <input class="form-control" formControlName="libelle" name="libelle" type="text" placeholder="Libellé de la compensation" [required]="true">
                        </div>
                    </div>
                    
                </form>
            </div>
            <br>
            <button (click)="handleCompensationPayment()" [disabled]="!(compesationForm.status == 'VALID')" nz-button nzType="default" type="button" class="btn btn-light width-100 black-btn">
                <span class="btn-icon-left"><i class="fa fa-check color-primary"></i></span>
                Confirmer la compensation
            </button>
        </div>

        <div class="payment-form" *ngIf="selectedMethod?.type == 'ESPECE'">
            <br>
            <button (click)="savePaiement('ESPECE')" nz-button nzType="default" type="button" class="btn btn-light width-100 black-btn">
                <span class="btn-icon-left"><i class="fa fa-check color-primary"></i></span>
                Confirmer le paiement en espéce
            </button>
        </div>

        <div class="payment-form" *ngIf="selectedMethod?.type == 'ANNULATION'">
            <br>
            <button (click)="savePaiement('ANNULATION')" nz-button nzType="default" type="button" class="btn btn-light width-100 black-btn">
                <span class="btn-icon-left"><i class="fa fa-check color-primary"></i></span>
                Confirmer la quittance d'annulation
            </button>
        </div>

        <div class="payment-form" *ngIf="selectedMethod?.type == 'IMPAYE'">
            <div class="basic-form">
                <form [formGroup]="impayerForm">
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <label>Accompte</label>
                            <input class="form-control" (keyup)="handleAccompte()" (change)="handleAccompte()" formControlName="accompte" name="accompte" type="number" placeholder="Accompte" [required]="true">
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Restant à payer</label>
                            <input class="form-control" formControlName="restantApayer" readonly name="restantApayer" type="number" placeholder="Restant à payer" [required]="true">
                        </div>
                    </div>
                    
                </form>
            </div>
            <br>
            <button [disabled]="impayerForm && impayerForm.invalid" (click)="savePaiement('IMPAYE')" nz-button nzType="default" type="button" class="btn btn-light width-100 black-btn">
                <span class="btn-icon-left"><i class="fa fa-check color-primary"></i></span>
                Confirmer l'accompte et le restant
            </button>
        </div>


    </ng-template>
    <ng-template #coverTemplate>
        <div [ngClass]="{'card-body': true, 'p-4': true}">
            <div class="media ai-icon">
                <span class="mr-3 text-primary">
                    <!-- <i class="ti-user"></i> -->
                    <img style="width: 180px; border-radius: 10px;" class="image-paiement" [src]="selectedMethod?.imageurl" alt="">
                </span>
                <div class="media-body">
                    <p class="mb-1 fz-30">{{selectedMethod?.nom}}</p>
                    <h1 class="mb-0 paiement-description">{{totalApayer}} Fcfa</h1>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #actionSetting>
    <span nz-icon nzType="setting"></span>
    </ng-template>
    <ng-template #actionEdit>
    <span nz-icon nzType="edit"></span>
    </ng-template>
    <ng-template #actionEllipsis>
    <span nz-icon nzType="ellipsis"></span>
    </ng-template>

    
</div>


<div id="paiement-panel"></div>