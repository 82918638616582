<nz-upload
      *ngIf="uploadIncluded"
      class="table-type"
      nzType="drag"
      [nzMultiple]="true"
      nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      (nzChange)="handleChange($event)"
    >
      <p class="ant-upload-drag-icon">
        <span nz-icon nzType="inbox"></span>
      </p>
      <p class="ant-upload-text">Chargement en masse</p>
</nz-upload>


<nz-table #editRowTable nzBordered [nzPageSize]="table?.sizeTable ? table?.sizeTable : 2" [nzData]="listOfData" nzTableLayout="fixed">
    <thead>
        <tr>
        <ng-container *ngIf="table?.columns">
            <ng-container *ngFor="let column of table?.columns">
                <th *ngIf="!column?.hide">{{column?.placeholder ? column?.placeholder : column?.name }}</th>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!table?.columns">
            <th>{{table?.placeholder}}</th>
        </ng-container>
        <th [nzWidth]="!table?.columns ? '20%' : '12px'">
            <a *ngIf="!table?.hideAdd" (click)="addItem()" [ngClass]="{'btn': true, 'shadow': true, 'btn-sm': true, 'sharp': true, 'mr-1': true, 'btn-primary': true}" style="float: right;">
                <i [ngClass]="{'fa': true, 'fa-plus': true}"></i>
            </a>
        </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of editRowTable.data">
        <ng-container *ngIf="!editCache[data.id].edit; else editTemplate">
            <ng-container *ngIf="table?.columns">
                <ng-container *ngFor="let column of table?.columns"> 
                    <td *ngIf="!column?.hide && column?.type != 'HTML'">{{ column?.type == 'SELECT' ? getValueOfSelect(column?.options, data.value[column?.name]) : data.value[column?.name] }}</td>
                    <td *ngIf="!column?.hide && column?.type == 'HTML'">
                        <div [innerHTML]="data.value[column?.name] | nzSanitizer: 'html'"></div>
                    </td>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!table?.columns">
                <td>{{ data.value }}</td>
            </ng-container>
            <td>
                <div class="d-flex" style="float: right;">
                    <a (click)="startEdit(data.id)" [ngClass]="{'btn': true, 'shadow': true, 'btn-xs': true, 'sharp': true, 'mr-1': true, 'btn-primary': true}">
                      <i [ngClass]="{'fa': true, 'fa-pencil': true}"></i>
                    </a>
                    <a *ngIf="!table?.hideDelete" nz-popconfirm nzPopconfirmTitle="Êtes vous sûr?" (nzOnConfirm)="deleteEdit(data.id)" [ngClass]="{'btn': true, 'shadow': true, 'btn-xs': true, 'sharp': true, 'mr-1': true, 'btn-danger': true}">
                        <i [ngClass]="{'fa': true, 'fa-trash': true}"></i>
                    </a>
                </div>
            </td>
        </ng-container>
        <ng-template #editTemplate>
            <ng-container *ngIf="!table?.columns">
                <td>
                    <input [placeholder]="table?.inputPlaceholder" class="form-control" [type]="getAdequateType(table?.type ? table?.type : 'TEXT')" nz-input [(ngModel)]="editCache[data.id].data.value" />
                </td>
            </ng-container>
            <ng-container *ngIf="table?.columns">
                <ng-container *ngFor="let column of table?.columns">
                    <td *ngIf="!column?.hide" class="table-type">
                        <input *ngIf="column?.type != 'SELECT' && column?.type != 'CHECKBOX'" [placeholder]="column?.placeholder" class="form-control" [type]="getAdequateType(column?.type)" nz-input [(ngModel)]="editCache[data.id].data.value[column?.name]" />
                        <label *ngIf="column?.type == 'CHECKBOX'" nz-checkbox [(ngModel)]="editCache[data.id].data.value[column?.name]">{{column?.name}}</label>
                        <nz-select *ngIf="column?.type == 'SELECT'" [nzDisabled]="column?.readOnly" nzShowSearch nzAllowClear (nzOnSearch)="column?.onSearch ? column?.onSearch($event) : ras()" [(ngModel)]="editCache[data.id].data.value[column?.name]" [nzPlaceHolder]="column?.placeholder" class="form-control default-select">
                            <nz-option *ngFor="let option of column?.options" [nzLabel]="option?.valeur" [nzValue]="option?.cle"></nz-option>
                        </nz-select>
                    </td>
                </ng-container>
            </ng-container>
            <td>
                <div class="d-flex" style="float: right;">
                    <a (click)="saveEdit(data.id)" [ngClass]="{'btn': true, 'shadow': true, 'btn-xs': true, 'sharp': true, 'mr-1': true, 'btn-primary': true}">
                        <i [ngClass]="{'fa': true, 'fa-check': true}"></i>
                    </a>
                    <a (click)="cancelEdit(data.id)" [ngClass]="{'btn': true, 'shadow': true, 'btn-xs': true, 'sharp': true, 'mr-1': true, 'btn-danger': true}">
                        <i [ngClass]="{'fa-solid': true, 'fa-xmark': true}">X</i>
                    </a>
                </div>
            </td>
        </ng-template>
        </tr>
    </tbody>
</nz-table>