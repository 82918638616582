import { Component, OnInit, NgZone, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';
import API from '../../../api';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-quittance',
  templateUrl: './quittance.component.html',
  styleUrls: ['./quittance.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuittanceComponent implements OnInit {


  isToClose: boolean = false;
  isBordereauSettingOpened: boolean = false;
  isNewPolice: boolean = true;
  currentPoliceId: string = ''

  isFormOpened = false;
  isFormLoading: boolean = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  quittances: any = [];
  compagnies: any = [];
  clients: any = [];
  apporteurs: any = [];
  branches: any = [];
  risques: any = [];
  allRisques: any = [];
  avenants: any = [];
  polices: any = [];
  entreprise: any = {};
  client: any = {};
  police: any = {};
  currenBranche: any = {};
  infoPoliceButtons: any = [{
    name: "Créer champ",
    icon: 'fa-plus',
    actif: true,
    onClick: () => {
      this.formDrawer = true;
      this.initChampForm();
    }
  }];

  sendInfo = {
    active: false,
    objet: '',
    contenu: ''
  }

  tarifIncluded: boolean = false;

  searchColumnText: string = '';

  currentId: string = '';
  currentAvenantPoliceId: string = '';

  formType: string = 'ADD';

  modelName: string = 'Quittance';

  searchText = '';
  currenBrancheName: string = '';

  indexBordereau: number = 0;
  clientDrawer: boolean = false;
  infoPoliceDrawer: boolean = false;
  formDrawer: boolean = false;
  paiementDrawer: boolean = false;
  comDrawer: boolean = false;
  mainFormulaire: any;
  formulaireBrancheExist: boolean = true;
  renew : boolean = false;

  commissions = {
    comPrime: 0,
    comAccessoire: 0
  }

  constructor(
    private dataService: DataService,
    private message: NzMessageService,
    private cdr: ChangeDetectorRef
  ) { }

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter une quittance",
      titre: 'Quittance',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formClientTab = [
    {
      code: 'ONE',
      nom: "Ajouter un client",
      titre: 'Client',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formInfoPoliceTab = [
    {
      code: 'ONE',
      nom: "Information de la police",
      titre: '',
      soustitre: '',
      type: 'AJOUT'
    }
  ]

  formComTab = [
    {
      code: 'ONE',
      nom: "Ajouter une accessoire",
      titre: 'Accessoires',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formFormTab = [
    {
      code: 'ONE',
      nom: "Ajouter un champ",
      titre: 'Champ formulaire',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formulaires: any = [];
  clientFormulaires: any = [];
  infoPoliceFormulaires: any = [];
  formFormulaires: any = [];
  comFormulaires: any = [];
  paymentForm: any = [];
  entreprises: any = [];

  // bordereauParam: any = { 
  //   titre: '',
  //   fileName: '',
  //   emvoyerEmail: false,
  //   contenu: '',
  //   date: new Date(),
  //   objet: '',
  //   destinataires: ['proassur.ndao@gmail.com', 'souleymane.ndao@gmail.com'],
  //   cloturer: false,
  //   paymentId: '',
  //   compagnies: [],
  //   branches: [],
  //   clients: [],
  //   apporteurs: [],
  //   risques: [],
  //   avenants: [],
  //   dateEffet: [],
  //   dateEcheance: [],
  //   dateCreation: [],
  //   derniereDateModif: []
  // }

  typeReponses =  ["WRITE", "OPTION"];
  typeChamps =  ["TEXT", "NUMBER", "DATE"];

  destinataires: any = {
    inputPlaceholder: 'Adresse email',
    placeholder: 'Liste des destinataires',
    values: [],
    defaultValue: ['proassur.ndao@gmail.com', 'souleymane.ndao@gmail.com']
  }

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];
  currentFormulaire: any = {};
  currentInfoPoliceFormulaire: any = {};
  infoPolice: any = [];
  infoPoliceCorrect: boolean = false;

  getDate() {
    return new Date();
  }

  addDays = (_date: Date, days: number) => {
    var date = new Date(_date);
    date.setDate(date.getDate() + days);
    return date;
  }

  openBordereauPanel() {
    this.isBordereauSettingOpened = true
  }

  initializeHeaders() {
    this.cdr.detectChanges();
    this.headers = [
      {
        name: 'Quittance',
        code: 'numero',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Police',
        code: 'police',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Création',
        code: 'dateCreation',
        sortDirections: ['ascend', 'descend', null],
        calendarFilter: true,
        calendarFilterFunc: (dataRange: any) => {
          if (dataRange?.length == 2) {
            // this.bordereauParam.dateCreation = dataRange;
            dataRange = [
              new Date(dataRange?.[0]),
              this.addDays(dataRange?.[1], 1)
            ]
            var index: number = 0;
            var oldFilter: string = '';
            if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('dateCreation') == -1) {
              this.filterArray.push({
                key: 'dateCreation',
                value: ''
              })
            }
            index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('dateCreation');
            oldFilter = this.filterArray[index].value;
            this.filterArray[index].value = `
            {
              AND: [
                {
                  dateCreation: {
                    gte: "${dataRange?.[0]?.toISOString()?.split('T')?.[0]?.split(',')}"
                  }
                }
                {
                  dateCreation: {
                    lt: "${dataRange?.[1]?.toISOString()?.split('T')?.[0]?.split(',')}"
                  }
                }
              ]
            }
            `;
            if (oldFilter != this.filterArray[index].value) {
              this.handleFilter();
            }

          }
          else if (dataRange?.length == 0) {
            // this.bordereauParam.dateCreation = [];
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'dateCreation');
            this.handleFilter();
          }
          else {
            // this.bordereauParam.dateCreation = [];
          }

        }
      },
      {
        name: 'Effet',
        code: 'dateEffet',
        sortDirections: ['ascend', 'descend', null],
        calendarFilter: true,
        calendarFilterFunc: (dataRange: any) => {
          if (dataRange?.length == 2) {
            // this.bordereauParam.dateEffet = dataRange;
            dataRange = [
              new Date(dataRange?.[0]),
              this.addDays(dataRange?.[1], 1)
            ]
            var index: number = 0;
            var oldFilter: string = '';
            if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('dateEffet') == -1) {
              this.filterArray.push({
                key: 'dateEffet',
                value: ''
              })
            }
            index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('dateEffet');
            oldFilter = this.filterArray[index].value;
            this.filterArray[index].value = `
            {
              AND: [
                {
                  dateEffet: {
                    gte: "${dataRange?.[0]?.toISOString()?.split('T')?.[0]?.split(',')}"
                  }
                }
                {
                  dateEffet: {
                    lt: "${dataRange?.[1]?.toISOString()?.split('T')?.[0]?.split(',')}"
                  }
                }
              ]
            }
            `;
            if (oldFilter != this.filterArray[index].value) {
              this.handleFilter();
            }

          }
          else if (dataRange?.length == 0) {
            // this.bordereauParam.dateEffet = [];
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'dateEffet');
            this.handleFilter();
          }
          else {
            // this.bordereauParam.dateEffet = [];
          }

        }
      },
      {
        name: 'Echcéance',
        code: 'dateEcheance',
        actif: true,
        sortDirections: ['ascend', 'descend', null],
        calendarFilter: true,
        calendarFilterFunc: (dataRange: any) => {
          if (dataRange?.length == 2) {
            // this.bordereauParam.dateEcheance = dataRange;
            dataRange = [
              new Date(dataRange?.[0]),
              this.addDays(dataRange?.[1], 1)
            ]
            var index: number = 0;
            var oldFilter: string = '';
            if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('dateEcheance') == -1) {
              this.filterArray.push({
                key: 'dateEcheance',
                value: ''
              })
            }
            index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('dateEcheance');
            oldFilter = this.filterArray[index].value;
            this.filterArray[index].value = `
            {
              AND: [
                {
                  dateEcheance: {
                    gte: "${dataRange?.[0]?.toISOString()?.split('T')?.[0]?.split(',')}"
                  }
                }
                {
                  dateEcheance: {
                    lt: "${dataRange?.[1]?.toISOString()?.split('T')?.[0]?.split(',')}"
                  }
                }
              ]
            }
            `;
            if (oldFilter != this.filterArray[index].value) {
              this.handleFilter();
            }

          }
          else if (dataRange?.length == 0) {
            // this.bordereauParam.dateEcheance = [];
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'dateEcheance');
            this.handleFilter();
          }
          else {
            // this.bordereauParam.dateEcheance = [];
          }

        }
      },
      {
        name: 'Prime nette',
        code: 'primeNette',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Accessoire',
        code: 'accessoire',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Taxe',
        code: 'taxe',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Prime totale',
        code: 'primeTotale',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Compagnie',
        code: 'compagnie',
        actif: true,
        sortDirections: ['ascend', 'descend', null],
        dataFilter: [
          ...this.compagnies.map((compagnie: any) => {
            return { text: compagnie?.raisonSociale, value: compagnie?.id }
          })
        ],
        // sortDirections: ['ascend', 'descend', null],
        filterFn: (list: string[], item: any) => {
          // this.bordereauParam.compagnies = list?.map((item: any) => {
          //   return this.compagnies?.find((compagnie: any) => compagnie.id === item)
          // });
          var oldFilter: string = '';
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('compagnie') == -1) {
            this.filterArray.push({
              key: 'compagnie',
              value: ''
            })
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('compagnie');
            oldFilter = this.filterArray[index].value;
          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('compagnie');


          if (!list.length) {
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'compagnie');
            this.handleFilter();
          }
          else {
            this.filterArray[index].value = `
            {
              OR: [
                {
                  compagnie: {
                    id: {
                      in: [${
                        list?.map((compagnie: any) => `"${compagnie}"` )
                      }]
                    }
                  }
                }
              ]
            }
          `;
          if (oldFilter != this.filterArray[index].value) {
            this.handleFilter();
          }
          }
        }
      },
      {
        name: 'Client',
        code: 'client',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Apporteur',
        code: 'apporteur',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Branche',
        code: 'branche',
        actif: true,
        sortDirections: ['ascend', 'descend', null],
        dataFilter: [
          ...this.branches.map((branche: any) => {
            return { text: branche?.nom, value: branche?.id }
          })
        ],
        // sortDirections: ['ascend', 'descend', null],
        filterFn: (list: string[], item: any) => {
          // this.bordereauParam.branches = list?.map((item: any) => {
          //   return this.branches?.find((branche: any) => branche.id === item)
          // });
          var oldFilter: string = '';
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('branche') == -1) {
            this.filterArray.push({
              key: 'branche',
              value: ''
            })
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('branche');
            oldFilter = this.filterArray[index].value;
          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('branche');


          if (!list.length) {
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'branche');
            this.handleFilter();
          }
          else {
            this.filterArray[index].value = `
            {
              OR: [
                {
                  risque: {
                    branche: {
                     id: {
                       in: [${
                        list?.map((branche: any) => `"${branche}"` )
                      }]
                     }
                   }
                 }
                }
              ]
            }
          `;
          if (oldFilter != this.filterArray[index].value) {
            this.handleFilter();
          }
          }
        }
      },
      {
        name: 'Risque',
        code: 'risque',
        actif: true,
        sortDirections: ['ascend', 'descend', null],
        dataFilter: [
          ...this.allRisques.map((risque: any) => {
            return { text: risque?.nom, value: risque?.id }
          })
        ],
        // sortDirections: ['ascend', 'descend', null],
        filterFn: (list: string[], item: any) => {
          // this.bordereauParam.risques = list?.map((item: any) => {
          //   return this.allRisques?.find((risque: any) => risque.id === item)
          // });
          var oldFilter: string = '';
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('risque') == -1) {
            this.filterArray.push({
              key: 'risque',
              value: ''
            })
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('risque');
            oldFilter = this.filterArray[index].value;
          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('risque');


          if (!list.length) {
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'risque');
            this.handleFilter();
          }
          else {
            this.filterArray[index].value = `
            {
              OR: [
                {
                  risque: {
                    id: {
                      in: [${
                      list?.map((risque: any) => `"${risque}"` )
                    }]
                    }
                 }
                }
              ]
            }
          `;
          if (oldFilter != this.filterArray[index].value) {
            this.handleFilter();
          }
          }
        }
      },
      {
        name: 'Avenant',
        code: 'avenant',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Paiement',
        code: 'quittance',
        actif: true,
        dataFilter: [
          ...["PAYPAL_CARTE_CREDIT", "WAVE", "ORANGE_MONEY", "CHEQUE", "COMPENSATION", "ESPECE", "VIREMENT", "IMPAYE", 'ANNULATION'].map((paiement: any) => {
            return { text: paiement, value: paiement }
          })
        ],
        // sortDirections: ['ascend', 'descend', null],
        filterFn: (list: string[], item: any) => {
          // this.bordereauParam.compagnies = list?.map((item: any) => {
          //   return this.compagnies?.find((compagnie: any) => compagnie.id === item)
          // });
          var oldFilter: string = '';
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('paiement') == -1) {
            this.filterArray.push({
              key: 'paiement',
              value: ''
            })
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('paiement');
            oldFilter = this.filterArray[index].value;
          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('paiement');


          if (!list.length) {
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'paiement');
            this.handleFilter();
          }
          else {
            this.filterArray[index].value = `
            {
              paiements: {
                some: {
                  type: {
                    in: [
                      ${
                        list?.map((paiement: any) => `${paiement}` )
                      }
                    ]
                  }
                }
              }
            }
          `;
          if (oldFilter != this.filterArray[index].value) {
            this.handleFilter();
          }
          }
        },
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Quittances',
        code: 'quittance',
        actif: true,
        position: 'right',
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Action',
        code: 'action',
        type: 'action',
        width: '10%',
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit',
            roles: ['SUPER_ADMIN', 'ADMIN']
          },
          {
            type: 'renew',
            roles: ['SUPER_ADMIN', 'ADMIN', 'PRODUCTEUR']
          },
          {
            type: 'delete',
            roles: ['SUPER_ADMIN', 'ADMIN']
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
    this.cdr.detectChanges();
  }

  getFormulaireOfBranche = (id: string, formulaire: any = null, initForm: boolean = false) => {
    if (!id) {
      if (formulaire) {
        formulaire.patchValue({
          infoPoliceCorrect: false
        });
      }
      // this.formulaireBrancheExist = true;
    }
    else {
      this.dataService.getFormulaireOfBranche(id).subscribe(
        (data: any) => {
          // this.formulaireBrancheExist = true;
          if (formulaire) {
            formulaire.patchValue({
              infoPoliceCorrect: false
            });
          }
          this.currentFormulaire = data?.data?.branche?.formulaire ? data?.data?.branche?.formulaire : {champs: []};
          this.formInfoPoliceTab[0].titre = data?.data?.branche?.formulaire?.nom ? data?.data?.branche?.formulaire?.nom : this.currenBrancheName;
          this.formInfoPoliceTab[0].soustitre = data?.data?.branche?.formulaire?.titre ? data?.data?.branche?.formulaire?.titre : "Profitez-en pour créer le formulaire";
          if (initForm) {
            this.initInfoPoliceForm(this.currentFormulaire?.champs);
          }          
          this.cdr.detectChanges();
        },
        (error: any) => {
          this.currentFormulaire = {
            champs: []
          };
          this.formInfoPoliceTab[0].titre = this.currenBrancheName;
          this.formInfoPoliceTab[0].soustitre = "Profitez-en pour créer le formulaire";
          if (initForm) {
            this.initInfoPoliceForm(this.currentFormulaire?.champs);
          }
          this.cdr.detectChanges();
        }
      )
    }
  }

  infoPoliceButtonEvent(clicked: boolean) {
    this.infoPoliceDrawer = true;
    this.initInfoPoliceForm(this.currentFormulaire?.champs, this.infoPolice);
    this.cdr.detectChanges();
  }

  generateBordereau(){
    // this.getBordereauRequest()
  }


  getTableValues(table: any) {
    // this.bordereauParam.destinataires = table?.value?.map((value: any) => value.value);
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }


  handleFilter(getOnlyRequest=false) {
    var _filter = `
    AND: [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    ]
    `;
    this.filter = _filter;
    this.currentPage = 1;

    // if(getOnlyRequest){
    //   this.getBordereauRequest();
    // }
    // else {
      this.getQuittances();
    // }
  }

  getBordereauRequest() {
    this.dataService.generateBordereau(
      JSON.stringify(
        {
          // ...this.bordereauParam,
          headers: this.headers,
          searchText: this.searchText
        }
      )
    ).subscribe(
      (data: any) => {
        this.downloadFile(data?.data?.generateBordereau);
      },
      (error: any) => {
        console.log(error);
      }
    )
  }

  downloadFile(fileName: string) {
    var element = document.createElement('a');
    element.setAttribute('href', fileName);
    element.setAttribute('download', fileName);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
  }

  ngOnInit(): void {
    this.getQuittances();
    this.getAllBranches();
    this.getAllApporteurs();
    this.getAllCompagnies();
    this.getAllAvenants();
    this.getAllRisques();
    this.getEntreprises();
  } 

  getEntreprises() {  
    this.dataService.getAllEntreprises()
    .subscribe(
      (data: any) => {
        this.entreprises = data?.data?.entreprises;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllRisques() {
    this.dataService.getAllRisques().subscribe(
      (data: any) => {
        this.allRisques = data?.data?.risqueBranches;
        this.initializeHeaders();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  handleColumnChanged() {
    this.quittances = this.getQuittances();
  }

  formulaireEvent(data: any) {
    var originalData = {
      ...data,
      infoPolice: this.infoPolice,
      formulaire: this.currentFormulaire,
      tarifIncluded: this.tarifIncluded,
      clientObj: this.client,
      entrepriseObj: this.entreprise,
      policeObj: this.police,
      // entreprise: data?.tarification?.entreprises?.find((entreprise: any) => data?.tarification?.entrepriseId && entreprise?.id == data?.tarification?.entrepriseId)
    };

    console.log(originalData)

    if (originalData?.policeObj?.id) {
      this.dataService.updatePoliceFamilles(
        originalData?.policeObj?.id,
        originalData
      ).subscribe(
        (dataQuittance: any) => {
        },
        (err: any)=> {

        }
      )
      this.isNewPolice = false;
    }

    if (this.renew) {
      originalData = {
        ...originalData,
        quittanceRenouvelle: this.currentId,
        police: data?.nouvellePolice ? data?.nouvellePolice : data?.police
      }
      this.dataService.createPolice(
        originalData
      ).subscribe(
        (dataPolice: any) => {
          this.dataService.createQuittance(
            {
              ...originalData,
              police: dataPolice?.data?.createPolice?.id
            }
          ).subscribe(
            (dataQuittance: any) => {
              this.onCloseForm();
              this.currentPage = 1;
              this.getQuittances();
              this.message.success(`Ajout ${this.modelName}`);
              this.dataService.createAvenant(
                {
                  categorieAvenant: originalData?.avenant,
                  police: dataPolice?.data?.createPolice?.id,
                  quittance: dataQuittance?.data?.createQuittance?.id
                }
              ).subscribe(
                (data: any) => {
                  this.currentPage = 1;
                  this.getQuittances();
                },
                (err: any) => {
                  console.log(err);
                  this.message.error(`Une erreur est survenue lors de l'ajout`);
                },
              )
            },
            (err: any) => {
              console.log(err);
              this.message.error(`Une erreur est survenue lors de l'ajout`);
            },
          )
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    else if (this.formType == 'ADD') {
      if (this.isNewPolice) {
        this.dataService.createPolice(
          originalData
        ).subscribe(
          (dataPolice: any) => {
            this.dataService.createQuittance(
              {
                ...originalData,
                police: dataPolice?.data?.createPolice?.id
              }
            ).subscribe(
              (dataQuittance: any) => {
                this.onCloseForm();
                this.currentPage = 1;
                this.getQuittances();
                this.message.success(`Ajout ${this.modelName}`);
                this.dataService.createAvenant(
                  {
                    categorieAvenant: originalData?.avenant,
                    police: dataPolice?.data?.createPolice?.id,
                    quittance: dataQuittance?.data?.createQuittance?.id
                  }
                ).subscribe(
                  (data: any) => {
                    this.currentPage = 1;
                    this.getQuittances();
                  },
                  (err: any) => {
                    console.log(err);
                    this.message.error(`Une erreur est survenue lors de l'ajout`);
                  },
                )
              },
              (err: any) => {
                console.log(err);
                this.message.error(`Une erreur est survenue lors de l'ajout`);
              },
            )
          },
          (err: any) => {
            console.log(err);
            this.message.error(`Une erreur est survenue lors de l'ajout`);
          },
        )
      }
      else {
        this.dataService.createQuittance(
          {
            ...originalData,
            police: this.currentPoliceId
          }
        ).subscribe(
          (dataQuittance: any) => {
            this.onCloseForm();
            this.currentPage = 1;
            this.getQuittances();
            this.message.success(`Ajout ${this.modelName}`);
            this.dataService.createAvenant(
              {
                categorieAvenant: originalData?.avenant,
                quittance: dataQuittance?.data?.createQuittance?.id,
                police: this.currentPoliceId
              }
            ).subscribe(
              (data: any) => {
                this.currentPage = 1;
                this.getQuittances();
              },
              (err: any) => {
                console.log(err);
                this.message.error(`Une erreur est survenue lors de l'ajout`);
              },
            )
          },
          (err: any) => {
            console.log(err);
            this.message.error(`Une erreur est survenue lors de l'ajout`);
          },
        )
      }
    }
    else {
      if (this.isNewPolice) {
        this.dataService.createPolice(
          originalData
        ).subscribe(
          (dataPolice: any) => {
            this.dataService.updateQuittance(
              this.currentId,
              {
                ...originalData,
                police: dataPolice?.data?.createPolice?.id
              }
            ).subscribe(
              (dataQuittance: any) => {
                this.onCloseForm();
                this.currentPage = 1;
                this.getQuittances();
                this.message.success(`Modification ${this.modelName}`);
                this.dataService.updateAvenant(
                  this.currentAvenantPoliceId,
                  {
                    categorieAvenant: originalData?.avenant,
                    police: dataPolice?.data?.createPolice?.id,
                    quittance: dataQuittance?.data?.updateQuittance?.id
                  }
                ).subscribe(
                  (data: any) => {
                    this.currentPage = 1;
                    this.getQuittances();
                  },
                  (err: any) => {
                    console.log(err);
                    this.message.error(`Une erreur est survenue lors de l'ajout`);
                  },
                )
                
              },
              (err: any) => {
                console.log(err);
                this.message.error(`Une erreur est survenue lors de l'ajout`);
              },
            )
          },
          (err: any) => {
            console.log(err);
            this.message.error(`Une erreur est survenue lors de l'ajout`);
          },
        )
      }
      else {
        this.dataService.updateQuittance(
          this.currentId,
          {
            ...originalData,
            police: this.currentPoliceId
          }
        ).subscribe(
          (dataQuittance: any) => {
            this.onCloseForm();
            this.currentPage = 1;
            this.getQuittances();
            this.message.success(`Modification ${this.modelName}`);
            this.dataService.updateAvenant(
              this.currentAvenantPoliceId,
              {
                categorieAvenant: originalData?.avenant,
                quittance: dataQuittance?.data?.updateQuittance?.id,
                police: this.currentPoliceId
              }
            ).subscribe(
              (data: any) => {
                this.currentPage = 1;
                this.getQuittances();
              },
              (err: any) => {
                console.log(err);
                this.message.error(`Une erreur est survenue lors de l'ajout`);
              },
            )
            
          },
          (err: any) => {
            console.log(err);
            this.message.error(`Une erreur est survenue lors de l'ajout`);
          },
        )
      }
    }
  }


  formulaireClientEvent(client: any) {
    const newContacts = client?.contact?.filter((contact: any) => !contact?.id);
    this.dataService.createClient(
      {
        ...client,
        newContacts
      }
    ).subscribe(
      (data: any) => {
        this.closeClientDrawer();
        this.message.success(`Ajout Client`);
        this.getAllClientBySearch(client?.prenom);
        this.mainFormulaire.patchValue({
          client: data?.data?.createClient?.id
        });
        this.cdr.detectChanges();
      },
      (err: any) => {
        console.log(err);
        this.message.error(`Une erreur est survenue lors de l'ajout`);
      },
    )
  }

  formulaireInfoPoliceEvent(infoPolice: any) {
    this.infoPolice = infoPolice;
    // this.infoPoliceCorrect = true;
    if (this.mainFormulaire) {
      this.mainFormulaire.patchValue({
        infoPoliceCorrect: true
      });
    }
    this.closeInfoPoliceDrawer();
  }

  camelCase(str: string) {
    return str
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .trim()
      .replace(/'/g, '')
      .replace(/`/g, '')
      .replace(/\$/g, '')
      .replace(/!/g, '')
      .replace(/-/g, '')
      .replace(/\./g, '')
      .replace(/\//g, '')
      .replace(/\s(.)/g, function (a) {
          return a.toUpperCase();
      })
      .replace(/\s/g, '')
      .replace(/^(.)/, function (b) {
          return b.toLowerCase();
      });
  }

  formulaireFormEvent(champ: any) {
    champ = {
      ...champ,
      actif: true,
      ordre: this.currentFormulaire?.champs?.length + 1,
      taille: "col_xl_6",
      obligatoire: !!champ?.obligatoire,
      infoRelance: !!champ?.infoRelance,
      code: this.camelCase(champ?.placeholder?.toLowerCase()),
      options: champ?.options ? champ?.options/*?.map((value: any) => {
        return {
            cle: this.camelCase(value?.cle),
            valeur: value?.valeur
        }
      })*/
      : []
    }
    if (this.currentFormulaire?.id) {
      this.dataService.createChamp({
        ...champ,
        formulaireId: this.currentFormulaire?.id,
        brancheId: this.currentFormulaire?.branche?.id
      }).subscribe(
        (data: any) => {
          this.infoPoliceFormulaires.push({
            ...data?.data?.createChampFormulaire,
            size: data?.data?.createChampFormulaire?.taille?.replace(/_/g, '-')?.replace(/xl/g, 'md')
          });
          this.currentFormulaire?.champs.push({
            ...data?.data?.createChampFormulaire,
            size: data?.data?.createChampFormulaire?.taille?.replace(/_/g, '-')?.replace(/xl/g, 'md')
          });
          var control = new FormControl();
          this.currentInfoPoliceFormulaire.addControl(champ?.code, control);
          this.getFormulaireOfBranche(this.currenBranche?.id, this.mainFormulaire?.id, false);
          this.cdr.detectChanges();
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    else {
      this.dataService.createFormulaireAndChamp({
        ...champ,
        branche: this.currenBranche
      }).subscribe(
        (data: any) => {
          this.getFormulaireOfBranche(this.currenBranche?.id, this.mainFormulaire?.id, true)
          var control = new FormControl();
          this.currentInfoPoliceFormulaire.addControl(champ?.code, control);
          this.cdr.detectChanges();
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    this.closeFormDrawer();
  }

  formulaireCommissionEvent(commission: any) {
    this.commissions = {
      comPrime: commission?.tauxCommissionPrime,
      comAccessoire: commission?.tauxCommissionAccessoire
    };
    this.dataService.createAccessoire(
      commission
    ).subscribe(
      (data: any) => {
        this.message.success(`Ajout commisions`);
      },
      (err: any) => {
        this.message.error(`Une erreur est survenue lors de l'ajout`);
      },
    )
    // this.mainFormulaire.patchValue({
    //   comPrime: commission?.tauxCommissionPrime,
    //   comAccessoire: commission?.tauxCommissionAccessoire
    // });
    this.closeComDrawer();
  }

  formulairePaiementEvent(paiement: any) {
    console.log(paiement)
    this.closeComDrawer();
    this.dataService.reglerImpaye(this.currentId, paiement?.paymentId)
    .subscribe(
      (data: any) => {
      },
      (err: any) => {
        
      }
    )
    this.paiementDrawer = false;
    this.cdr.detectChanges();
    this.closeComDrawer();
    this.handleFilter();
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getQuittances();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getQuittances();
  }

  getQuittances() {  
    this.dataService.getQuittances(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.quittances = this.handleList(data?.data?.quittances)
        this.totalCount = data?.data?.quittancesCount;
        this.cdr.detectChanges();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getQuittances()
    })
  }

  handleList(data: any) {
    let list = data.map((row: any) => ([
      // {
      //   type: 'BACKGROUND',
      //   value: "red"
      // },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.numero,
        position: 'left',
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.police?.police,
        position: 'left',
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.dateCreation,
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.dateEffet,
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.dateEcheance,
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.primeNette,
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.accessoire,
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.taxe,
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.primeTotale,
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.police?.compagnie?.raisonSociale,
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: `${row?.police?.client?.prenom} ${row?.police?.client?.nom} `,
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: `${row?.police?.apporteur?.prenom} ${row?.police?.apporteur?.nom}`,
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.police?.risque?.branche?.nom,
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.police?.risque?.nom,
      },
      {
        type: 'TEXT',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.avenant?.nom,
      },
      {
        type: 'LIST',
        background: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? '#fffaeb' : (row?.paiements?.[row?.paiements?.length - 1]?.type == 'ANNULATION' ? '#ffebe7' : null),
        value: row?.paiements?.map((paiement: any, index: number) => `${index + 1} : ${paiement?.type} (${paiement?.type == 'IMPAYE' ? paiement?.accompte ?? 0 : paiement?.montant ?? 0}f)`),
      },
      {
        type: 'HTML',
        position: 'right',
        value: `
          <div class="d-flex">
            <a data-toggle="tooltip" data-placement="top" title="FACTURE POUR PROASSUR" href="${API?.baseUrl + API?.fileUrl + row?.factureProassur}" download="${row?.factureProassur}" class="btn shadow btn-xs sharp mr-1 btn-primary" style="background: black;">
              P
            </a>
            <a data-toggle="tooltip" data-placement="top" title="FACTURE POUR LA COMPAGNIE" href="${API?.baseUrl + API?.fileUrl + row?.factureCompagnie}" download="${row?.factureCompagnie}" class="btn shadow btn-xs sharp mr-1 btn-primary" style="background: black;">
              <i class="fa fa-building"></i>
            </a><span class="border border-primary"></span>
            <a data-toggle="tooltip" data-placement="top" title="FACTURE POUR LE CLIENT" href="${API?.baseUrl + API?.fileUrl + row?.factureClient}" download="${row?.factureClient}" class="btn shadow btn-xs sharp mr-1 btn-primary" style="background: black;">
              <i class="fa fa-user"></i>
            </a>
          </div>
        `,
      },
      {
        type: 'ACTION',
        position: 'right',
        value: row?.cloture?.id ? 
        [{
          type: 'close',
          value: row?.id
        }]
        :[
          {
            type: 'renew',
            value: row?.id,
            roles: ['SUPER_ADMIN', 'ADMIN', 'PRODUCTEUR']
          },
          {
            type: 'edit',
            value: row?.id,
            roles: ['SUPER_ADMIN', 'ADMIN']
          },
          {
            type: 'paiement',
            value: row?.id,
            roles: row?.paiements?.[row?.paiements?.length - 1]?.type == 'IMPAYE' ? ['SUPER_ADMIN', 'ADMIN', 'PRODUCTEUR'] : []
          },
          {
            type: 'delete',
            value: row?.id,
            roles: ['SUPER_ADMIN', 'ADMIN']
          }
        ]
      }
    ]));
    return list;

  }

  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.renew = false;
        this.currentId = action?.value;
        this.getQuittanceById(action?.value);
        this.infoPolice = {}
      break;
      case 'renew':
        this.currentId = action?.value;
        this.renew = true;
        this.getQuittanceById(action?.value, true);
        this.infoPolice = {}
      break;
      case 'paiement':
        this.currentId = action?.value;
        this.handlePaymentFormImpayer(action?.value)
        this.infoPolice = {}
      break;
      case 'delete':
        this.getQuittances();
      break;
    }
  }

  handlePaymentFormImpayer(quittanceId:  string) {
    this.dataService.getQuittancePayment(quittanceId)
    .subscribe((data: any) => {
      this.initPayment(data?.data?.quittance?.paiements?.[0]?.restantApayer ?? (data?.data?.quittance?.primeTotale ?? 0));
      this.paiementDrawer = true;
      this.cdr.detectChanges();
    }, 
    (err: any) => {

    });
  }

  newFormulaireOpened(formulaireEvent: any) {
    var formulaireOpened = formulaireEvent?.formulaire;
    if (formulaireEvent?.modelName == 'Quittance') {
      if (this.formType == 'EDIT') {
        formulaireOpened.patchValue({
          infoPoliceCorrect: true
        });
        if (formulaireOpened?.value?.compagnie && formulaireOpened?.value?.risque) {
          this.handleCommissions(formulaireOpened, formulaireOpened?.value?.compagnie, formulaireOpened?.value?.risque)
        }
      }
      else {
        formulaireOpened.patchValue({
          infoPoliceCorrect: false
        });
      }
    }
    if (formulaireEvent?.modelName == 'Info Police') {
      this.currentInfoPoliceFormulaire = formulaireOpened;
    }
  }

  getQuittanceById(id: string, renew: boolean = false) {
    this.dataService.getQuittanceById(id)
    .subscribe(
      (data: any) => {
        this.currentAvenantPoliceId = data?.data?.quittance?.avenantPolice?.id
        this.initForm(data?.data?.quittance);
        this.getAllRisqueOfBranche(data?.data?.quittance?.police?.risque?.branche?.id);
        this.getAllClientBySearch(data?.data?.quittance?.police?.client?.prenom);
        this.getAllPolices(data?.data?.quittance?.police?.police);
        this.currenBrancheName = data?.data?.quittance?.police?.risque?.branche?.nom;
        this.currenBranche = data?.data?.quittance?.police?.risque?.branche;
        this.getFormulaireOfBranche(data?.data?.quittance?.police?.risque?.branche?.id)
        var infoPolice: any = {};
        data?.data?.quittance?.police?.infoPolices?.forEach((info: any) => {
          infoPolice[info.cle] = info.valeur;
        });
        this.infoPolice = infoPolice
        this.isFormOpened = true;

        var policeForm: any = this.formulaires?.find((form: any) => form?.code == 'police');
        if (data?.data?.quittance?.police?.id) {
          if (policeForm) {
            policeForm.info = 'Police existante';
            this.isNewPolice = false;
            this.currentPoliceId = data?.data?.quittance?.police?.id;
          }
          this.handleNouvellePolice(this.mainFormulaire, data?.data?.quittance?.police, renew ? false : true, renew);
          var infoPolice: any = {};
          data?.data?.quittance?.police?.infoPolices?.forEach((info: any) => {
              infoPolice[info.cle] = info.valeur;
            });
          this.infoPolice = infoPolice; 

          if (this.renew) {
            let paiemement: any = this.formulaires?.find((formulaire: any) => formulaire?.code == 'paiemement');
            if (paiemement) {
              paiemement.value = data?.data?.quittance?.primeTotale;
            }
            this.cdr.detectChanges();
          }

        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllBranches() {
    this.dataService.getAllBranches().subscribe(
      (data: any) => {
        this.branches = data?.data?.branches;
        this.initializeHeaders();
        this.cdr.detectChanges();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllCompagnies() {
    this.dataService.getAllCompagnies().subscribe(
      (data: any) => {
        this.compagnies = data?.data?.compagnies;
        this.initializeHeaders();
        this.cdr.detectChanges();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllAvenants() {
    this.dataService.getAllCategorieAvenants().subscribe(
      (data: any) => {
        this.avenants = data?.data?.categorieAvenants;
        this.cdr.detectChanges();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllApporteurs() {
    this.dataService.getAllApporteurs().subscribe(
      (data: any) => {
        this.apporteurs = data?.data?.apporteurs;
        this.cdr.detectChanges();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllClientBySearch(searchText: string, clientId: string = '') {
    if (searchText && searchText?.length >= 2) {
      this.dataService.getAllClientBySearch(searchText).subscribe(
        (data: any) => {
          this.clients = data?.data?.clients;
          // Handle Client info for payment
          if (clientId) {
            let paiemement: any = this.formulaires?.find((formulaire: any) => formulaire?.code == 'paiemement');
            const client = this.clients?.find((client: any) => client?.id == clientId);
            paiemement.usefullInfo.client = `${client?.prenom} ${client?.nom}`;
          }
          var form: any = this.formulaires?.find((form: any) => form?.code == 'client');
          if (form) {
            form.options = this.clients?.map((data: any) => {
              var valeur = `${data?.code} - ${data?.prenom} ${data?.nom} ${data?.contact?.[0] ? (' - ' + data?.contact?.[0]) : ''}`;
              data?.contacts?.forEach((contact: any) => {
                valeur += ` - ${contact?.contact}`;
              });
              return {
                cle: data?.id,
                valeur
              }
            })
          }
          this.cdr.detectChanges();
        },
        (err: any) => {
          console.log(err);
        },
      )
    }
  }

  getAllPolices(searchText: string) {
    if (searchText && searchText?.length >= 1) {
      this.dataService.getAllQuittanceBySearch(searchText).subscribe(
        (data: any) => {
          this.polices = data?.data?.polices;
          var form: any = this.formulaires?.find((form: any) => form?.code == 'police');
          if (form) {
            form.options = this.polices?.map((data: any) => {
              var valeur = `${data?.police}`;
              valeur += ` - ${data?.client?.prenom} - ${data?.client?.nom}`;
              data?.client?.contacts?.forEach((contact: any) => {
                valeur += ` - ${contact?.contact}`;
              });
              data?.infoPolices?.forEach((info: any) => {
                valeur += ` - ${info?.valeur}`;
              });
              return {
                cle: data?.id,
                valeur
              }
            })
          }
          this.cdr.detectChanges();
        },
        (err: any) => {
          console.log(err);
        },
      )
    }
    else {
      this.polices = [];
      var form: any = this.formulaires?.find((form: any) => form?.code == 'police');
      if (form) {
        form.options = []
      }
    }
  }

  getAllRisqueOfBranche(id: string, risqueId: string = '') {
    this.dataService.getAllRisqueOfBranche(id).subscribe(
      (data: any) => {
        this.risques = data?.data?.risqueBranches;
        var risqueForm: any = this.formulaires?.find((form: any) => form?.code == 'risque');
        if (risqueForm) {
          risqueForm.options = this.risques?.map((data: any) => {
            return {
              cle: data?.id,
              valeur: data?.nom
            }
          })
        }
        // Handle Client info for payment
        if (risqueId) {
          let paiemement: any = this.formulaires?.find((formulaire: any) => formulaire?.code == 'paiemement');
          const risque = this.risques?.find((risque: any) => risque?.id == risqueId);
          paiemement.usefullInfo.branche = data?.data?.branche?.nom;
          paiemement.usefullInfo.risque = risque?.nom;
        }

        this.initializeHeaders();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getPoliceById(id: string, formulaire: any, renew = false) {
    this.infoPolice = {};
    this.dataService.getPoliceById(id).subscribe(
      (data: any) => {
        // Handle police
        var policeForm: any = this.formulaires?.find((form: any) => form?.code == 'police');
        if (data?.data?.police?.id) {
          if (policeForm) {
            policeForm.info = 'Police existante';
            this.isNewPolice = false;
            this.currentPoliceId = data?.data?.police?.id;
            this.currenBrancheName = data?.data?.police?.risque?.branche?.nom;
            this.currenBranche = data?.data?.police?.risque?.branche
            this.getFormulaireOfBranche(data?.data?.police?.risque?.branche?.id);
            if(!renew) {
              formulaire.patchValue({
                infoPoliceCorrect: true
              });
            }
            var infoPolice: any = {};
            data?.data?.police?.infoPolices?.forEach((info: any) => {
              infoPolice[info.cle] = info.valeur;
            });
            this.infoPolice = infoPolice; 
          }
          if (!this.tarifIncluded) {
            this.handleNouvellePolice(formulaire, data?.data?.police, true);
          }

          // this.initInfoForPayment(data?.data?.police?.infoPolices);
        }
        else {
          if (policeForm) {
            policeForm.info = 'Nouvelle police';
            this.isNewPolice = true;
          }
          if (!this.tarifIncluded) {
            this.handleNouvellePolice(formulaire, data?.data?.police, false)
          }
        }
        this.cdr.detectChanges();
      },
      (err: any) => {
        var policeForm: any = this.formulaires?.find((form: any) => form?.code == 'police');
        if (policeForm) {
          policeForm.info = 'Nouvelle police'
          this.isNewPolice = true;
        }
        if (!this.tarifIncluded) {
          this.handleNouvellePolice(formulaire, {}, false)
        }
      }
    );
  }

  initInfoForPayment(champs: any, infoPolice: any) {
    var paiementForm: any = this.formulaires?.find((form: any) => form?.code == 'paiemement');
    paiementForm.usefullInfo.infoPolice = [];
    champs?.forEach((champ: any) => {
      if (champ?.obligatoire && paiementForm.usefullInfo.infoPolice?.length < 3) {
        paiementForm.usefullInfo.infoPolice.push(infoPolice?.[champ?.code]);
      }
    });
    if (paiementForm.usefullInfo.infoPolice?.length < 3) {
      champs?.forEach((champ: any) => {
        if (champ?.obligatoire && paiementForm.usefullInfo.infoPolice?.length < 3) {
          paiementForm.usefullInfo.infoPolice.push(infoPolice?.[champ?.code]);
        }
      });
    }
  }

  handleNouvellePolice(formulaire: any, police: any, disabled = false, renew = false) {
    // Récupérer les risques de la branche correspondante
    this.getAllRisqueOfBranche(police?.risque?.branche?.id, police?.risque?.id);
    this.getAllClientBySearch( police?.client?.prenom, police?.client?.id)
    // Remplir les champs compagnie, client, apporteur, branche et risque
    if (formulaire) {
      formulaire.patchValue({
        compagnie: disabled ? police?.compagnie?.id : undefined,  
        client: disabled ? police?.client?.id : undefined,
        apporteur: disabled ? police?.apporteur?.id : undefined,
        branche: disabled ? police?.risque?.branche?.id : undefined,
        risque: disabled ? police?.risque?.id : undefined,
      });
    }

    if (police?.compagnie?.id && police?.risque?.id) {
      this.handleCommissions(formulaire, police?.compagnie?.id, police?.risque?.id);
    }

    // Disable compagnie
    var compagnieForm: any = this.formulaires?.find((form: any) => form?.code == 'compagnie');
    compagnieForm.readOnly = disabled;
    // Disable client
    var clientForm: any = this.formulaires?.find((form: any) => form?.code == 'client');
    clientForm.readOnly = disabled;
    // Disable apporteur
    var apporteurForm: any = this.formulaires?.find((form: any) => form?.code == 'apporteur');
    apporteurForm.readOnly = disabled;
    // Disable branche
    var brancheForm: any = this.formulaires?.find((form: any) => form?.code == 'branche');
    brancheForm.readOnly = disabled;
    // Disable risque
    var risqueForm: any = this.formulaires?.find((form: any) => form?.code == 'risque');
    risqueForm.readOnly = disabled;
    // Disable police pour le renouvellement
    if (renew) {
      var policeForm: any = this.formulaires?.find((form: any) => form?.code == 'police');
      policeForm.readOnly = true;
      var nouvellePoliceForm: any = this.formulaires?.find((form: any) => form?.code == 'nouvellePolice');
      nouvellePoliceForm.hidden = false;
      nouvellePoliceForm.obligatoire = true;
    }
    else {
      var policeForm: any = this.formulaires?.find((form: any) => form?.code == 'police');
      policeForm.readOnly = false;
      var nouvellePoliceForm: any = this.formulaires?.find((form: any) => form?.code == 'nouvellePolice');
      nouvellePoliceForm.hidden = true;
      nouvellePoliceForm.obligatoire = false;
    }
    // this.isFormLoading = false;
    this.cdr.detectChanges();
  }

  handleCommissions(formulaire: any, compagnie: string, risque: string) {
    this.dataService.getCommissions({
      compagnie,
      risque
    }).subscribe(
      (data: any) => {
        if (data?.data?.accessoires?.length) {
          this.commissions = {
            comPrime: data?.data?.accessoires?.[0]?.tauxCommissionPrime,  
            comAccessoire: data?.data?.accessoires?.[0]?.tauxCommissionAccessoire
          };
          if (formulaire?.value?.primeNette) {
            formulaire.patchValue({
              comPrime: Math.floor(this.commissions?.comPrime * formulaire?.value?.primeNette / 100)
            })
          }
          if (formulaire?.value?.accessoire) {
            formulaire.patchValue({
              comAccessoire: Math.floor(this.commissions?.comAccessoire * formulaire?.value?.accessoire / 100)
            })
          }
        }
        else {
          this.comDrawer = true;
          this.initCommissionForm(formulaire?.value);
        }
      },
      (err: any) => {
        this.comDrawer = true;
        this.initCommissionForm(formulaire?.value);
      })
  }

  openAddForm() {
    this.isFormOpened = true;
    this.renew = false;
    this.infoPolice = {}
    this.initForm();
  }

  tarificationEvent(formulaire: any) {
    this.mainFormulaire = formulaire;
    const tarification = formulaire?.value?.tarification;
    this.tarifIncluded = tarification?.tarifIncluded;
    switch(formulaire?.value?.tarification?.selectedTypeFinancement?.domaine) {
      case 'SANTE':
        const police = tarification?.polices?.find((police: any) => tarification?.entrepriseId && police?.entreprise?.id == tarification?.entrepriseId);
        const entreprise = tarification?.entreprises?.find((entreprise: any) => tarification?.entrepriseId && entreprise?.id == tarification?.entrepriseId);
        const client = police?.assures?.[0] ? police?.assures?.[0] : (entreprise?.assures?.[0] ? entreprise?.assures?.[0] : {
          id: "0",
          code: '',
          prenom: entreprise?.raisonSociale,
          nom: '',
          contacts: entreprise?.contacts
        })

        this.entreprise = entreprise;
        this.client = client;
        this.police = police;

        if (police?.police) {
          this.getAllPolices(police?.police);

          var infoPolice: any = {};
          police?.infoPolices?.forEach((info: any) => {
              infoPolice[info.cle] = info.valeur;
            });
          this.infoPolice = infoPolice;

          this.currentPoliceId = police?.id;

          // setTimeout(() => {
          //   this.isNewPolice = false;
          // }, 1000)
        }
        else {
          this.isNewPolice = true;
        }

        if (tarification?.risque?.branche?.id) {
          this.getAllRisqueOfBranche(tarification?.risque?.branche?.id)
          this.getFormulaireOfBranche(tarification?.risque?.branche?.id, formulaire);
        }
        
        var valeur = `${client?.code} ${client?.prenom} ${client?.nom}`;
        client?.contacts?.forEach((contact: any) => {
          valeur += ` - ${contact?.contact}`
        }); 
        this.clients = [
          {
              cle: client?.id,
              valeur
          }
        ]

        const clientForm = this.formulaires?.find((form: any) => form?.code == 'client');
        if (clientForm && !tarification?.isParticulierSelected) {
          clientForm.options = this.clients;
          clientForm.readOnly = true;
        }

        // this.formulaireBrancheExist = false;
        // this.infoPoliceCorrect = true;

        const foundPolice = tarification?.polices?.find((police: any) => police?.entreprise?.id == tarification?.entrepriseId)

        const now = new Date();

        now.setDate(now.getDate() + 365);

        formulaire.patchValue({
          police: (!tarification?.isParticulierSelected && police) ? [police?.id] : [],
          compagnie: tarification?.compagnie?.id,
          risque: tarification?.risque?.id,
          branche: tarification?.risque?.branche?.id,
          infoPoliceCorrect: true,
          primeNette: tarification?.sumPrime,
          dateEcheance: foundPolice?.quittances?.[0]?.dateEcheance ? foundPolice?.quittances?.[0]?.dateEcheance : now?.toISOString()?.split('T')?.[0],
          client: !tarification?.isParticulierSelected ? client?.id : ''
        });

        // gerer les commissions et initialise le paiement
        let values = formulaire.value;
        let total = 0;
        total += tarification?.sumPrime ? parseInt(tarification?.sumPrime) : 0;
        formulaire.patchValue({
          primeTotale: total,
          comPrime: Math.floor(total * this.commissions?.comPrime / 100)
        });
        let paiemement: any = this.formulaires?.find((formulaire: any) => formulaire?.code == 'paiemement');
        if (paiemement) {
          paiemement.value = total;
        }
        this.initInfoForPayment(this.currentFormulaire?.champs, this.infoPolice);
        this.cdr.detectChanges();

        // Gerer les taux de commission
        if (tarification?.compagnie?.id && tarification?.risque?.id) {
          this.handleCommissions(formulaire, tarification?.compagnie?.id, tarification?.risque?.id)
        }
        
        // Disable compagnie
        var compagnieForm: any = this.formulaires?.find((form: any) => form?.code == 'compagnie');
        compagnieForm.readOnly = true;
        // Disable police
        if (police) {
          var policeForm: any = this.formulaires?.find((form: any) => form?.code == 'police');
          policeForm.readOnly = true;
          policeForm.info = 'Police existante';
        }
        // Disable apporteur
        // var apporteurForm: any = this.formulaires?.find((form: any) => form?.code == 'apporteur');
        // apporteurForm.readOnly = true;
        // Disable branche
        var brancheForm: any = this.formulaires?.find((form: any) => form?.code == 'branche');
        brancheForm.readOnly = true;
        // Disable risque
        var risqueForm: any = this.formulaires?.find((form: any) => form?.code == 'risque');
        risqueForm.readOnly = true;
        // Disable police pour le renouvellement

        break;
    }
    this.cdr.detectChanges();
    console.log(formulaire?.value)
  }

  checkPoliceExists(policeId: string) {
    this.dataService.checkPoliceExists(policeId).subscribe(
      (data: any) => {
        console.log(data?.data?.policesCount)
        if (data?.data?.policesCount > 0) {
          var nouvellePoliceForm: any = this.formulaires?.find((form: any) => form?.code == 'nouvellePolice');
          nouvellePoliceForm.errorMessage = "Désolé la police existe déjà";
          this.cdr.detectChanges();
        }
        else {
          var nouvellePoliceForm: any = this.formulaires?.find((form: any) => form?.code == 'nouvellePolice');
          nouvellePoliceForm.errorMessage = "";
          this.cdr.detectChanges();
        }
      },
      (err: any) => {
        console.log(err)
      })
  }

  initForm(data: any = {}) {

    // console.log(data?.police?.client?.id);
    // console.log(data);
    let initialEtape = 1;
    if (data?.id) {
      this.formType = 'EDIT';
      if (data?.compagnie?.id && data?.risque?.id) {
        this.handleCommissions(null, data?.compagnie?.id, data?.risque?.id)
      }
      initialEtape = 0;
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      {
        code: "tarification",
        placeholder: "Tarification",
        typeChamp: "TARIFICATION",
        usefullInfo: {},
        etape:  1,
        creationOnly: true,
        obligatoire: true,
        value: 0,
        typeReponse: "WRITE",
        size: 'col-md-12'
      },
      {
        code: "infoPoliceCorrect",
        typeChamp: "CHECKBOX",
        etape: initialEtape + 1,
        typeReponse: "WRITE",
        hidden: true,
      },
      {
        code: "police",
        placeholder: "Police (Tout type de recherche)",
        typeChamp: "TEXT",
        etape: initialEtape + 1,
        typeReponse: "OPTION",
        info: '',
        multiple: true,
        limit: 1,
        onChange: (selected:  any, formulaire: any) => {
          this.mainFormulaire = formulaire;
          document.getElementById('dismissAll')?.click();
          this.cdr.detectChanges();
          if (selected && selected?.length) {
            this.getPoliceById(selected?.[0], formulaire);
          }
          else {
            var policeForm: any = this.formulaires?.find((form: any) => form?.code == 'police');
            if (policeForm) {
              policeForm.info = ''
              this.infoPolice = {}
            }
            if (!this.tarifIncluded) {
              this.handleNouvellePolice(formulaire, {}, false)
            }
          }
        },
        options: this.polices?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: `${data?.police}`
          }
        }),
        onSearch: (searchText: string) => {
          this.getAllPolices(searchText);
        },
        obligatoire: !!(this.formType == 'ADD' || data?.police?.id),
        defaultValue: [data?.police?.id],
        size: 'col-md-12'
      },
      {
        code: "nouvellePolice",
        placeholder: "Nouvelle police",
        typeChamp: "TEXT",
        etape: initialEtape + 1,
        typeReponse: "WRITE",
        keyup: (formulaire: any) => {
          let values = formulaire.value;
          this.checkPoliceExists(values?.nouvellePolice);
        },
        hidden: true,
        obligatoire: false,
        defaultValue: '',
        size: 'col-md-12'
      },
      {
        code: "compagnie",
        placeholder: "Compagnie",
        typeChamp: "TEXT",
        etape: initialEtape + 1,
        typeReponse: "OPTION",
        options: this.compagnies?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.raisonSociale
          }
        }),
        onChange: (selected:  any, formulaire: any) => {
          if (formulaire?.value?.compagnie && formulaire?.value?.risque) {
            this.handleCommissions(formulaire, formulaire?.value?.compagnie, formulaire?.value?.risque)
          }
        },
        obligatoire: !!(this.formType == 'ADD' || data?.police?.compagnie?.id),
        defaultValue: data?.police?.compagnie?.id,
        size: 'col-md-12'
      },
      {
        code: "client",
        placeholder: "Client",
        typeChamp: "TEXT",
        etape: initialEtape + 1,
        typeReponse: "OPTION",
        onChange: (selected:  any, formulaire: any) => {
          let paiemement: any = this.formulaires?.find((formulaire: any) => formulaire?.code == 'paiemement');
          if (paiemement && !!selected) {
            const client = this.clients?.find((client: any) => client?.id == formulaire?.value?.client);
            paiemement.usefullInfo.client = `${client?.prenom} ${client?.nom}`
          }
        },
        onAfter: (formulaire: any) => {
          this.mainFormulaire = formulaire
          this.clientDrawer = true;
          this.initClientForm();
        },
        options: this.clients?.map((data: any) => {
          var valeur = `${data?.code} ${data?.prenom} ${data?.nom}`;
          data?.contacts?.forEach((contact: any) => {
            valeur += ` - ${contact?.contact}`
          }); 
          return {
            cle: data?.id,
            valeur
          }
        }),
        onSearch: (searchText: string) => {
          this.getAllClientBySearch(searchText);
        },
        obligatoire: !!(this.formType == 'ADD' || data?.police?.client?.id),
        defaultValue: data?.police?.client?.id,
        size: 'col-md-12'
      },
      {
        code: "apporteur",
        placeholder: "Apporteur",
        typeChamp: "TEXT",
        etape: initialEtape + 1,
        typeReponse: "OPTION",
        options: this.apporteurs?.map((data: any) => {
          return {
            cle: data?.id,
            valeur:`${data?.prenom} ${data?.nom}`
          }
        }),
        obligatoire: !!(this.formType == 'ADD' || data?.police?.apporteur?.id),
        defaultValue: data?.police?.apporteur?.id ? data?.police?.apporteur?.id : this.apporteurs?.[0]?.id,
        forceDefaultValue: true,
        size: 'col-md-12'
      },
      {
        code: "branche",
        placeholder: "Branche",
        typeChamp: "TEXT",
        etape: initialEtape + 2,
        obligatoire: !!(this.formType == 'ADD' || data?.police?.risque?.branche?.id),
        typeReponse: "OPTION",
        options: this.branches?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.nom
          }
        }),
        onChange: (id: string, formulaire: any) => {
          this.mainFormulaire = formulaire;
          this.currenBrancheName = this.branches?.find((branche: any) => branche?.id == id)?.nom;
          this.currenBranche = this.branches?.find((branche: any) => branche?.id == id);
          this.getAllRisqueOfBranche(id);
          this.getFormulaireOfBranche(id, formulaire);

          let paiemement: any = this.formulaires?.find((formulaire: any) => formulaire?.code == 'paiemement');
          if (paiemement && !!id) {
            const branche = this.branches?.find((branche: any) => branche?.id == id);
            paiemement.usefullInfo.branche = branche?.nom
          }

        },
        defaultValue: data?.police?.risque?.branche?.id,
        size: 'col-md-6'
      },
      {
        code: "risque",
        placeholder: "Risque",
        typeChamp: "TEXT",
        etape: initialEtape + 2,
        obligatoire: !!(this.formType == 'ADD' || data?.police?.risque?.id),
        typeReponse: "OPTION",
        // visilityDependsOn: 'branche',
        options: this.risques?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.nom
          }
        }),
        onChange: (selected:  any, formulaire: any) => {
          if (formulaire?.value?.compagnie && formulaire?.value?.risque) {
            this.handleCommissions(formulaire, formulaire?.value?.compagnie, formulaire?.value?.risque)
          }
          let paiemement: any = this.formulaires?.find((formulaire: any) => formulaire?.code == 'paiemement');
          if (paiemement && !!selected) {
            const risque = this.risques?.find((risque: any) => risque?.id == selected);
            paiemement.usefullInfo.risque = risque?.nom
          }
        },
        defaultValue: data?.police?.risque?.id,
        size: 'col-md-6'
      },
      {
        code: "typeRelance",
        placeholder: "Type de relance",
        typeChamp: "TEXT",
        etape: initialEtape + 2,
        obligatoire: !!(this.formType == 'ADD' || data?.typeRelance),
        typeReponse: "OPTION",
        options: [{
            valeur: 'Durée ferme', 
            cle: 'DureeFerme',
            default: true
          },
          {
            valeur: 'Tacite reconduction', 
            cle: 'taciteReconduction'
          }
        ],
        defaultValue: data?.typeRelance ? data?.typeRelance : 'DureeFerme',
        forceDefaultValue: true,
        size: 'col-md-6'
      },
      {
        code: "dateCreation",
        placeholder: "Date création",
        typeChamp: "DATE",
        etape: initialEtape + 2,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.dateCreation),
        defaultValue: data?.dateCreation,
        size: 'col-md-6'
      },
      {
        code: "dateEffet",
        placeholder: "Date effet",
        typeChamp: "DATE",
        etape: initialEtape + 2,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.dateEffet),
        defaultValue: data?.dateEffet,
        size: 'col-md-6'
      },
      {
        code: "dateEcheance",
        placeholder: "Date echéance",
        typeChamp: "DATE",
        etape: initialEtape + 2,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.dateEcheance),
        defaultValue: data?.dateEcheance,
        size: 'col-md-6'
      },
      {
        code: "primeNette",
        placeholder: "Prime nette",
        typeChamp: "NUMBER",
        etape: initialEtape + 3,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.primeNette),
        keyup: (formulaire: any) => {
          let values = formulaire.value;
          let total = 0;
          total += values?.primeNette ? parseInt(values?.primeNette) : 0;
          total += values?.accessoire ? parseInt(values?.accessoire) : 0;
          total += values?.taxe ? parseInt(values?.taxe) : 0;
          formulaire.patchValue({
            primeTotale: total,
            comPrime: Math.floor(formulaire.value?.primeNette * this.commissions?.comPrime / 100)
          });
          let paiemement: any = this.formulaires?.find((formulaire: any) => formulaire?.code == 'paiemement');
          if (paiemement) {
            paiemement.value = total;
          }
          this.initInfoForPayment(this.currentFormulaire?.champs, this.infoPolice);
          this.cdr.detectChanges();
        },
        defaultValue: data?.primeNette,
        size: 'col-md-6'
      },
      {
        code: "accessoire",
        placeholder: "Accessoires",
        typeChamp: "NUMBER",
        etape: initialEtape + 3,
        typeReponse: "WRITE",
        keyup: (formulaire: any) => {
          let values = formulaire.value;
          let total = 0;
          total += values?.primeNette ? parseInt(values?.primeNette) : 0;
          total += values?.accessoire ? parseInt(values?.accessoire) : 0;
          total += values?.taxe ? parseInt(values?.taxe) : 0;
          formulaire.patchValue({
            primeTotale: total,
            comAccessoire: Math.floor(formulaire.value?.accessoire * this.commissions?.comAccessoire / 100)
          });
          let paiemement: any = this.formulaires?.find((formulaire: any) => formulaire?.code == 'paiemement');
          if (paiemement) {
            paiemement.value = total;
          }
          this.cdr.detectChanges();
        },
        obligatoire: !!(this.formType == 'ADD' || data?.accessoire),
        defaultValue: data?.accessoire,
        size: 'col-md-6'
      },
      {
        code: "taxe",
        placeholder: "Taxe",
        typeChamp: "NUMBER",
        etape: initialEtape + 3,
        typeReponse: "WRITE",
        keyup: (formulaire: any) => {
          let values = formulaire.value;
          let total = 0;
          total += values?.primeNette ? parseInt(values?.primeNette) : 0;
          total += values?.accessoire ? parseInt(values?.accessoire) : 0;
          total += values?.taxe ? parseInt(values?.taxe) : 0;
          formulaire.patchValue({
            primeTotale: total
          });
          let paiemement: any = this.formulaires?.find((formulaire: any) => formulaire?.code == 'paiemement');
          if (paiemement) {
            paiemement.value = total;
          }
          this.cdr.detectChanges();
        },
        obligatoire: !!(this.formType == 'ADD' || data?.taxe),
        defaultValue: data?.taxe,
        size: 'col-md-6'
      },
      {
        code: "primeTotale",
        placeholder: "Prime totale",
        typeChamp: "NUMBER",
        etape: initialEtape + 3,
        readOnly: true,
        dependsOn: [
          'primeNette',
          'accessoire',
          'taxe'
        ],
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.primeTotale),
        defaultValue: data?.primeTotale,
        size: 'col-md-6'
      },
      {
        code: "comPrime",
        placeholder: "Commission sur la prime",
        typeChamp: "NUMBER",
        etape: initialEtape + 3,
        readOnly: true,
        dependsOn: [
          'primeNette',
          'accessoire',
          'taxe'
        ],
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.comPrime),
        defaultValue: data?.comPrime,
        size: 'col-md-6'
      },
      {
        code: "comAccessoire",
        placeholder: "Commission sur les accessoires",
        typeChamp: "NUMBER",
        etape: initialEtape + 3,
        readOnly: true,
        dependsOn: [
          'primeNette',
          'accessoire',
          'taxe'
        ],
        typeReponse: "WRITE",
        // onAfter: (formulaire: any) => {
        //   this.mainFormulaire = formulaire
        //   this.comDrawer = true;
        //   this.initCommissionForm({
        //     compagnie: formulaire?.value?.compagnie,
        //     branche: formulaire?.value?.branche,
        //     risque: formulaire?.value?.risque
        //   });
        // },
        defaultValue: data?.comAccessoire,
        size: 'col-md-6'
      },
      {
        code: "avenant",
        placeholder: "Avenant",
        typeChamp: "TEXT",
        etape: initialEtape + 3,
        typeReponse: "OPTION",
        options: this.avenants?.map((data: any) => {
          return {
            cle: data?.id,
            valeur:`${data?.nom}`
          }
        }),
        obligatoire: !!(this.formType == 'ADD' || data?.avenant?.id),
        defaultValue: data?.avenant?.id,
        size: 'col-md-12'
      },
      {
        code: "observation",
        placeholder: "Observations",
        typeChamp: "TEXTAREA",
        etape: initialEtape + 3,
        typeReponse: "WRITE",
        defaultValue: data?.observation,
        size: 'col-md-12'
      },
      {
        code: "paiemement",
        placeholder: "Paiement",
        typeChamp: "PAIEMENT",
        usefullInfo: {},
        etape: initialEtape + 4,
        creationOnly: this.renew ? false : true,
        obligatoire: true,
        value: 0,
        typeReponse: "WRITE",
        size: 'col-md-12'
      }
    ]
    this.cdr.detectChanges();
  }

  initPayment(montant: number) {
    this.paymentForm = [
      {
        code: "paiemement",
        placeholder: "Paiement",
        typeChamp: "PAIEMENT",
        usefullInfo: {},
        etape: 1,
        obligatoire: true,
        value: montant,
        typeReponse: "WRITE",
        size: 'col-md-12'
      }
    ]
  }

  initClientForm(client: any = {}) {
    this.clientFormulaires = [
      // {
      //   code: "code",
      //   placeholder: "Code client",
      //   typeChamp: "TEXT",
      //   typeReponse: "WRITE",
      //   etape: 1,
      //   obligatoire: true,
      //   size: 'col-md-12'
      // },
      {
        code: "prenom",
        placeholder: "Prénom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: true,
        size: 'col-md-6'
      },
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        etape: 1,
        obligatoire: true,
        size: 'col-md-6'
      },
      {
        code: "email",
        placeholder: "Adresse email",
        typeChamp: "EMAIL",
        typeReponse: "WRITE",
        etape: 1,
        obligatoire: true,
        size: 'col-md-12'
      },
      {
        code: "emplacement",
        placeholder: "Emplacement du dossier",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        etape: 1,
        size: 'col-md-6'
      },
      {
        code: "entreprise",
        placeholder: "Entreprise",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        options: this.entreprises?.map((entrep: any) => {
          return {
            cle: entrep?.id,
            valeur: entrep?.raisonSociale
          }
        }),
        size: 'col-md-6'
      },
      {
        code: "adresse",
        placeholder: "Adresse",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        size: 'col-md-12'
      },
      {
        code: "contact",
        placeholder: "Contact",
        inputPlaceholder: 'Téléphone..',
        typeChamp: "TABLE",
        etape: 2,
        typeReponse: "TABLE",
        columns: [
          {
            name: 'id',
            placeholder: 'Id',
            type: 'TEXT',
            hide: true,
          },
          {
            name: 'contact',
            placeholder: 'contact',
            type: 'TEXT'
          }
        ],
        values: [],
        size: 'col-md-6'
      },
      {
        code: "connexe",
        placeholder: "Connexe",
        inputPlaceholder: 'Connexe..',
        typeChamp: "TABLE",
        etape: 2,
        typeReponse: "TABLE",
        values: [],
        size: 'col-md-6'
      }
    ]
    this.cdr.detectChanges();
  }

  initCommissionForm(data: any = {}) {
    this.comFormulaires = [
      {
        code: "compagnie",
        placeholder: "Compagnie",
        typeChamp: "TEXT",
        etape: 1,
        obligatoire: !!(this.formType == 'ADD' || data?.compagnie),
        typeReponse: "OPTION",
        readOnly: true,
        options: this.compagnies?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.raisonSociale
          }
        }),
        defaultValue: data?.compagnie,
        size: 'col-md-12'
      },
      {
        code: "branche",
        placeholder: "Branche",
        typeChamp: "TEXT",
        etape: 1,
        readOnly: true,
        obligatoire: !!(this.formType == 'ADD' || data?.branche),
        typeReponse: "OPTION",
        options: this.branches?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.nom
          }
        }),
        defaultValue: data?.branche,
        size: 'col-md-6'
      },
      {
        code: "risque",
        placeholder: "Risque",
        typeChamp: "TEXT",
        etape: 1,
        obligatoire: !!(this.formType == 'ADD' || data?.risque),
        typeReponse: "OPTION",
        readOnly: true,
        // visilityDependsOn: 'branche',
        options: this.risques?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.nom
          }
        }),
        defaultValue: data?.risque,
        size: 'col-md-6'
      },
      {
        code: "tauxCommissionAccessoire",
        placeholder: "Taux Commission Accessoire",
        typeChamp: "NUMBER",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.tauxCommissionAccessoire),
        defaultValue: data?.tauxCommissionAccessoire,
        size: 'col-md-6'
      },
      {
        code: "tauxCommissionPrime",
        placeholder: "Taux Commission Prime",
        typeChamp: "NUMBER",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.tauxCommissionPrime),
        defaultValue: data?.tauxCommissionPrime,
        size: 'col-md-6'
      },
      {
        code: "accessoires",
        placeholder: "Accessoire",
        inputPlaceholder: 'Accessoires..',
        typeChamp: "TABLE",
        sizeTable: 5,
        etape: 1,
        typeReponse: "TABLE",
        columns: [
          {
            name: 'min',
            placeholder: 'Prime min',
            type: 'NUMBER'
          },
          {
            name: 'max',
            placeholder: 'Prime max',
            type: 'NUMBER'
          },
          {
            name: 'accessoire',
            placeholder: 'Accessoire',
            type: 'NUMBER'
          },
          {
            name: 'taxe',
            placeholder: 'taxe',
            type: 'NUMBER'
          }
        ],
        values: [],
        defaultValue: data?.accessoires,
        size: 'col-md-12'
      },
    ]
    this.cdr.detectChanges();
  }

  initChampForm(data: any = {}) {
    this.formFormulaires = [
      {
        code: "placeholder",
        placeholder: "Libellé",
        typeChamp: "TEXT",
        etape: 1,
        obligatoire: true,
        typeReponse: "WRITE",
        size: 'col-md-12'
      },
      {
        code: "typeReponse",
        placeholder: "Type de réponse",
        typeChamp: "TEXT",
        etape: 1,
        obligatoire: true,
        typeReponse: "OPTION",
        options: this.typeReponses?.map((typeReponse: string) => {
          return {
            cle: typeReponse,
            valeur: typeReponse
          }
        }),
        onChange: (selected:  any, formulaire: any) => {
          var typeChampForm: any = this.formFormulaires?.find((form: any) => form?.code == 'typeChamp');
          var optionsForm: any = this.formFormulaires?.find((form: any) => form?.code == 'options');
          // typeChampForm.hidden = selected == 'OPTION';
          optionsForm.hidden = selected == 'WRITE';
        },
        size: 'col-md-6'
      },
      {
        code: "typeChamp",
        placeholder: "Type de réponse",
        typeChamp: "TEXT",
        etape: 1,
        // hidden: true,
        obligatoire: true,
        typeReponse: "OPTION",
        options: this.typeChamps?.map((typeChamp: string) => {
          return {
            cle: typeChamp,
            valeur: typeChamp
          }
        }),
        onChange: (selected:  any, formulaire: any) => {
          var optionsForm: any = this.formFormulaires?.find((form: any) => form?.code == 'options');
          optionsForm.columns = optionsForm.columns?.map((column: any) => {
            return {
              ...column,
              type: selected
            }
          });
        },
        size: 'col-md-6'
      },
      {
        code: "options",
        placeholder: "Options",
        inputPlaceholder: 'Options',
        typeChamp: "TABLE",
        // obligatoire: true,
        columns: [
          {
            name: 'cle',
            placeholder: 'Valeur réele',
            type: 'TEXT',
          },
          {
            name: 'valeur',
            placeholder: 'Valeur à afficher',
            type: 'TEXT'
          }
        ],
        etape: 1,
        hidden: true,
        typeReponse: "TABLE",
        size: 'col-md-12'
      },
      {
        code: "obligatoire",
        placeholder: "Obligatoire",
        typeChamp: "CHECKBOX",
        etape: 1,
        typeReponse: "WRITE",
        size: 'col-md-6'
      },
      {
        code: "infoRelance",
        placeholder: "Ajouter cette info sur la relance",
        typeChamp: "CHECKBOX",
        etape: 1,
        typeReponse: "WRITE",
        size: 'col-md-6'
      }
    ]
    this.cdr.detectChanges();
  }

  initInfoPoliceForm(_champs: any = [], existingInfo: any = {}) {
    // var champs = _champs ? _champs.sort((c1: any, c2: any) => (c1.price < c2.ordre) ? 1 : (c1.price > c2.ordre) ? -1 : 0) : [];
    this.infoPoliceFormulaires = _champs?.map((champ: any) => {
      return {
        code: champ?.code,
        placeholder: champ?.placeholder,
        typeChamp: champ?.typeChamp,
        readOnly: this.renew ? false : !this.isNewPolice,
        etape: 1,
        typeReponse: champ?.typeReponse,
        options: champ?.options,
        obligatoire: champ?.obligatoire,
        defaultValue: existingInfo[champ?.code],
        size: champ?.taille?.replace(/_/g, '-')
      }
    });
    this.infoPoliceFormulaires = [
      ...this.infoPoliceFormulaires,
      {
        code: 'readOnly',
        hidden: true,
        defaultValue: this.renew ? false : !this.isNewPolice
      }
    ]
    this.cdr.detectChanges();
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
    this.infoPolice = {};
  }

  closeClientDrawer() {
    this.clientDrawer = false;
  }

  closeInfoPoliceDrawer() {
    this.infoPoliceDrawer = false;
  }

  closeComDrawer() {
    this.comDrawer = false;
  }

  closeFormDrawer() {
    this.formDrawer = false;
  }

  closePaimentDrawer() {
    this.paiementDrawer = false;
  }

}

