<div class="h-100">
    <div class="authincation h-100">
        <div class="container h-100">
            <div class="row justify-content-center h-100 align-items-center">
                <div class="col-md-5">
                    <div class="form-input-content text-center error-page">
                        <ng-lottie width="100%" height="500px" [styles]="{padding: '25px', fill: 'transparent'}" [options]="option"></ng-lottie>
                        <!-- <h1 class="error-text font-weight-bold">400</h1> -->
                        <h4 [ngClass]="{'fa': true, 'fa-thumbs-up': status == 'success', 'fa-thumbs-down': status != 'success', 'text-primary': status == 'success', 'text-danger': status != 'success'}"><i ></i> {{status == 'success' ? 'Paiement réussi' : 'Echec paiement'}}</h4>
                        <p>{{status == 'success' ? 'Votre paiement a bien été prise en compte.' : 'Echec lors de votre paiement avec wave.'}} <br> {{status == 'success' ? 'Proassur vous remercie pour votre confiance' : 'Veuillez réessayer plus tard.'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>