import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';

const routes: Routes = [{
  path: '',
  component: AuthComponent,
  children: [
    {
      path: 'login',
      loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)
    },
    {
      path: 'user/:id',
      loadChildren: () => import('./change-password/change-password.module').then((m) => m.ChangePasswordModule)
    },
    {
      path: 'password-forgotten',
      loadChildren: () => import('./password-forgotten/password-forgotten.module').then((m) => m.PasswordForgottenModule)
    },
    {
      path: '**',
      redirectTo: 'login'
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
