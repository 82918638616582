import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-compagnie',
  templateUrl: './compagnie.component.html',
  styleUrls: ['./compagnie.component.css']
})
export class CompagnieComponent implements OnInit {

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  compagnies: any = [];

  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'Compagnie';

  searchText = '';
  headerInitialed: boolean = false;

  constructor(
    private dataService: DataService,
    private message: NzMessageService
  ) { }

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter une entreprise",
      titre: 'Entreprise',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    },
    {
      code: 'MANY',
      nom: 'Chargement en masse',
      titre: 'Client',
      soustitre: 'Chargement en masse des clients',
      type: 'CHARGEMENT',
      templateImage: 'assets/images/template-compagnie.png'
    }
  ]

  formulaires: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  initializeHeaders() {
    this.headers = [
      {
        name: 'Code',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Raison sociale',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Responsable',
        filterMultiple: true,
        // filterFn: (list: string[], item: any) => list.some(name => item.name.indexOf(name) !== -1)
      },
      {
        name: 'Telephone',
        actif: true,
      },
      {
        name: 'BP',
      },
      {
        name: 'FAX',
      },
      {
        name: 'Code Courtier',
        actif: true,
      },
      {
        name: 'Accessoirs',
      },
      {
        name: 'Taxe',
      },
      {
        name: 'Action',
        type: 'action',
        width: "10%",
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  handleFilter() {
    var _filter = `
    AND: [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    ]
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getCompagnies();
  }

  ngOnInit(): void {
    this.getCompagnies();
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  handleColumnChanged() {
    this.compagnies = this.getCompagnies();
  }

  formulaireEvent(data: any) {
    if (this.formType == 'ADD') {
      this.dataService.createCompagnie(
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getCompagnies();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    else {
      this.dataService.updateCompagnie(
        this.currentId,
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getCompagnies();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getCompagnies();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getCompagnies();
  }

  getCompagnies() {  
    this.dataService.getCompagnies(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.compagnies = this.handleList(data?.data?.compagnies)
        this.totalCount = data?.data?.compagniesCount;
        if (!this.headerInitialed) {
          this.initializeHeaders();
          this.headerInitialed = true;
        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getCompagnies()
    })
  }

  handleList(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.code,
        position: 'left',
      },{
        type: 'TEXT',
        value: row?.raisonSociale,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.responsable,
      },
      {
        type: 'TEXT',
        value: row?.telephone
      },
      {
        type: 'TEXT',
        value: row?.BP
      },
      {
        type: 'TEXT',
        value: row?.FAX
      },
      {
        type: 'BOX',
        value: row?.codeCourtier
      },
      {
        type: 'TEXT',
        value: row?.tauxAccessoires
      },
      {
        type: 'TEXT',
        value: row?.tauxTaxe
      },
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getCompagnieById(action?.value);
      break;
      case 'delete':
        this.getCompagnies();
      break;
    }
  }

  getCompagnieById(id: string) {
    this.dataService.getCompagnieById(id)
    .subscribe(
      (data: any) => {
        this.initForm(data?.data?.compagnie);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.initForm();
  }

  initForm(data: any = {}) {
    if (data?.code) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      {
        code: "code",
        placeholder: "code Compagnie",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.code),
        defaultValue: data?.code,
        size: 'col-md-6'
      },
      {
        code: "raisonSociale",
        placeholder: "Raison sociale",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.raisonSociale),
        defaultValue: data?.raisonSociale,
        size: 'col-md-6'
      },
      {
        code: "responsable",
        placeholder: "Responsable",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        defaultValue: data?.responsable,
        size: 'col-md-6'
      },
      {
        code: "telephone",
        placeholder: "Télephone",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        defaultValue: data?.telephone,
        size: 'col-md-6'
      },
      {
        code: "BP",
        placeholder: "BP",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        defaultValue: data?.BP,
        size: 'col-md-6'
      },
      {
        code: "FAX",
        placeholder: "FAX",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        defaultValue: data?.FAX,
        size: 'col-md-6'
      },
      {
        code: "codeCourtier",
        placeholder: "Code courtier",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        defaultValue: data?.codeCourtier,
        size: 'col-md-4'
      },
      {
        code: "tauxAccessoires",
        placeholder: "Accessoires",
        typeChamp: "NUMBER",
        typeReponse: "WRITE",
        defaultValue: data?.tauxAccessoires,
        size: 'col-md-4'
      },
      {
        code: "tauxTaxe",
        placeholder: "Taux Taxe",
        typeChamp: "NUMBER",
        typeReponse: "WRITE",
        defaultValue: data?.tauxTaxe,
        size: 'col-md-4'
      }
    ]
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
  }

}
