import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-create-souscription-contrat',
  templateUrl: './create-souscription-contrat.component.html',
  styleUrls: ['./create-souscription-contrat.component.css']
})
export class CreateSouscriptionContratComponent implements OnInit {

  @Input() selectedTypeFinancement : any = {};
  @Input() formulaire : any = [];
  @Input() plan : any = {};
  @Input() allQuestions : any = [];
  @Input() inputKeys : any = [];
  @Output() formulaireEvent: EventEmitter<any> = new EventEmitter<any>();

  time: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);

  defaultFileList: NzUploadFile[] = [];

  fileList = [...this.defaultFileList];

  price: number = 0;

  constructor(
    private dataService: DataService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  submitFormulaire() {
    console.log(this.formulaire.value);
  }

  ngOnInit(): void {
  }

  fileUploaded(event: any, question: any) {
    console.log(event);
    question.reponseUser = event?.fileList;
  }

  cancelFormulaire() {
    this.formulaire.reset();
  }

  getAdequateType(typeChamp: string) {
    switch(typeChamp) {
      case 'TEXT':
        return 'text';
      case 'NUMBER':
        return 'number';
      case 'EMAIL':
        return 'email';
      case 'TELEPHONE':
        return 'phone';
      case 'DATE':
        return 'date';
      default:
        return 'text';
    }
  }

}
