import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-entreprise',
  templateUrl: './entreprise.component.html',
  styleUrls: ['./entreprise.component.css']
})
export class EntrepriseComponent implements OnInit {

  emplacements: any =  ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  entreprises: any = [];


  currentEntreprise: any = {}
  initializeResList : boolean = true;
  mainFormulaire : any;
  responsableDrawer : boolean = false;
  responsables : any = [];
  responsableFormulaire: any = [];
  infosResponsable : any;



  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'Entreprise';

  searchText = '';
  headerInitialed: boolean = false; 

  constructor(
    private dataService: DataService,
    private message: NzMessageService,
    private cdr: ChangeDetectorRef

  ) { }

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter une entreprise",
      titre: 'Entreprise',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formResTab = [
    {
      code: 'ONE',
      nom: "Ajouter le responsable",
      titre: 'Responsable',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formulaires: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  initializeHeaders() {
    this.headers = [
      {
        name: 'Raison sociale',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Emplacement',
        filterMultiple: true,
        actif: true,
        // filterFn: (list: string[], item: any) => list.some(name => item.name.indexOf(name) !== -1)
      },
      {
        name: 'Contact',
        actif: true,
      },
      {
        name: 'Responsable',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Connexe',
        actif: true,
      },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  handleFilter() {
    var _filter = `
    AND: [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    ]
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.entreprises();
  }

  ngOnInit(): void {
    this.getEntreprises();
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  handleColumnChanged() {
    this.entreprises = this.getEntreprises();
  }

 formulaireEvent(data: any) {

    const newContacts = data?.contact?.filter((contact: any) => !contact?.id);
    const infosNewResponsable =  this.infosResponsable;
    

    if (this.formType == 'ADD') {
      this.dataService.createEntreprise(
        {
          ...data,
          newContacts,
          infosNewResponsable
        }
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getEntreprises();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    else {
      const removedContacts = this.currentEntreprise?.contacts?.filter((contact: any) => !data?.contact?.map((cont: any) => cont?.id).includes(contact?.id));

      this.dataService.updateEntreprise(
        this.currentId,
        {
          ...data,
          newContacts,
          removedContacts,
          infosNewResponsable
        }
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getEntreprises();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getEntreprises();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getEntreprises();
  }


  getAllResponsablesBySearch(searchText: string) {
    // if (searchText && searchText?.length >= 2 || (this.initializeResList)) {
      this.dataService.getAllResponsablesBySearch(searchText).subscribe(
        (data: any) => {
         this.responsables = data?.data?.users;
          var form :any = this.formulaires?.find((form: any) => form?.code == 'responsable');
          if (form) {

            form.options = this.responsables?.map((data: any) => {
              var valeur = `${data?.prenom} ${data?.nom} - ${data?.phoneNumber}`;
             
              return {
                cle: data?.id,
                valeur
              }
            })
          }
          this.cdr.detectChanges();
        },
        (err: any) => {
          console.log(err);
        },
      )
    // }
  }

  getEntreprises() {  

    this.dataService.getEntreprises(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.entreprises = this.handleList(data?.data?.entreprises)
        this.totalCount = data?.data?.entreprisesCount;
        if (!this.headerInitialed) {
          this.initializeHeaders();
          this.headerInitialed = true;
        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }


  formulaireResponsableEvent(responsable: any) {

    this.infosResponsable = responsable;
    console.log(this.infosResponsable);
    const valeur = `${responsable?.prenom} ${responsable?.nom} - ${responsable?.telephone}`;
    // console.log(valeur);
    var form: any = this.formulaires?.find((form: any) => form?.code == 'responsable');

    if(form){    
      form.options.push(
        {cle : `${responsable?.telephone}` , valeur}
      );
    }
    this.mainFormulaire.patchValue({
      responsable : responsable?.telephone
    });
    this.cdr.detectChanges();
    this.closeResDrawer();
    this.message.success(`Informations enregistrees avec succees`);
    // this.getAllResponsablesBySearch(responsable?.prenom);

  }


  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getEntreprises()
    })
  }

  handleList(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.raisonSociale,
        position: 'left',
      },
      {
        type: 'BOX',
        value: row?.emplacement,
      },
      {
        type: 'LIST',
        value: row?.contacts?.map((contact: any) => contact.contact)
      },
      {
        type: 'HTML',
        value : (row?.responsable?.prenom) ? `
          <div class = "shadow-sm p-3  rounded"">
              <span class = "">
                <i class="fa fa-user btn   shadow-lg btn-xs sharp mr-1 btn-primary rounded-circle"  style : "background : #006b88"></i>
                <small>${row?.responsable?.prenom} ${row?.responsable?.nom}</small>
              </span>
          </div>` : ``,
        // value: [`${row?.responsable?.prenom} ${row?.responsable?.nom}`, `Tel : ${row?.responsable?.phoneNumber}`],
        position: 'left',
        // isPerson : true,
      },
      {
        type: 'LIST',
        value: row?.connexe
      },
     
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getEntrepriseById(action?.value);
      break;
      case 'delete':
        this.getEntreprises();
      break;
    }
  }

  getEntrepriseById(id: string) {
    this.dataService.getEntrepriseById(id)
    .subscribe(
      (data: any) => {
        this.currentEntreprise = data?.data?.entreprise;
        this.initForm(data?.data?.entreprise);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.initForm();
  }

  

  initForm(data: any = {}) {

    if (data?.raisonSociale) {
      this.formType = 'EDIT';
    }
   

    this.formulaires = [

      {
        code: "raisonSociale",
        placeholder: "Raison sociale",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.raisonSociale),
        defaultValue: data?.raisonSociale,
        size: 'col-md-8'
      },
      {
        code: "emplacement",
        etape: 1,
        placeholder: "Emplacement",
        typeChamp: "TEXT",
        typeReponse: "OPTION",
        options: this.emplacements?.map((emplacement: any) => {
          return {
            cle: emplacement,
            valeur: emplacement
          }
        }),
        defaultValue: data?.emplacement,
        size: 'col-md-4'
      },
      {
        code: "adresse",
        etape: 1,
        placeholder: "Adresse",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        defaultValue: data?.adresse,
        size: 'col-md-12'
      },
      {
        code : "responsable",
        placeholder : 'Responsable',
        typeChamp: "TEXT",
        typeReponse : "OPTION",
        etape: 1,
        // obligatoire: !!(this.formType == 'ADD' || data?.responsable?.id), 
        // obligatoire : false,
        size: 'col-md-12',
        onAfter: (formulaire: any) => {
          this.mainFormulaire = formulaire
          this.responsableDrawer = true;
          this.initResForm();
        },
        onChange : (selected: any, formulaire : any)=> {
          console.log('le selectionne : ' + selected)
        }, 
        defaultValue: data?.responsable?.id,
        options: this.responsables?.map((data: any) => {
          let valeur = `${data?.prenom} ${data?.nom} - ${data?.phoneNumber}`;
        
          return {
            cle: data?.id,
            valeur
          }
        }),
        onSearch: (searchText: string) => {
          this.getAllResponsablesBySearch(searchText);
          // console.log(data?.responsable)
        },
      },
      {
        code: "contact",
        etape: 1,
        placeholder: "Contact",
        inputPlaceholder: 'Téléphone..',
        typeChamp: "TABLE",
        typeReponse: "TABLE",
        values: [],
        columns: [
          {
            name: 'id',
            placeholder: 'Id',
            type: 'TEXT',
            hide: true,
          },
          {
            name: 'contact',
            placeholder: 'contact',
            type: 'TEXT'
          }
        ],
        defaultValue: data?.contacts,
        size: 'col-md-6'
      },
      {
        etape: 1,
        code: "connexe",
        placeholder: "Connexe",
        inputPlaceholder: 'Connexe..',
        typeChamp: "TABLE",
        typeReponse: "TABLE",
        values: [],
        defaultValue: data?.connexe,
        size: 'col-md-6'
      }
    ]
  }

  initResForm(responsable: any = {}) {
    this.responsableFormulaire = [
    
      {
        code: "prenom",
        placeholder: "Prénom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: true,
        size: 'col-md-6'
      },
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        etape: 1,
        obligatoire: true,
        size: 'col-md-6'
      },
      {
        code: "email",
        placeholder: "Adresse email",
        typeChamp: "EMAIL",
        typeReponse: "WRITE",
        etape: 1,
        obligatoire: true,
        size: 'col-md-12'
      },
      {
        code: "telephone",
        placeholder: "Numero de telephone",
        typeChamp: "TELEPHONE",
        typeReponse: "WRITE",
        obligatoire : true,
        etape: 1,
        size: 'col-md-12'
      },
     
      {
        code: "adresse",
        placeholder: "Adresse",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        size: 'col-md-12'
      },
    ]
    this.cdr.detectChanges();
  }


  // SCI ABI ROSA / 781600010

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
  }

  closeResDrawer() {
    this.responsableDrawer = false;
  }

}
