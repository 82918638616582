import { Component, OnInit } from '@angular/core';

import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.css']
})
export class UtilisateurComponent implements OnInit {

  roles =  ["SUPER_ADMIN", "ADMIN", "PRODUCTEUR", "REDACTEUR_SINISTRE", "COMPTABLE", "OPERATEUR_SAISIE", "SUPERVISEUR"];

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  utilisateurs: any = [];
  entreprises: any = [];

  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'Utilisateur';

  searchText = '';

  constructor(
    private dataService: DataService,
    private message: NzMessageService
  ) { }

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter un utilisateur",
      titre: 'Utilisateur',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formulaires: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  initializeHeaders() {
    this.headers = [
      {
        name: 'Prénom',
        actif: true,
        position: 'left'
      },
      {
        name: 'Nom',
        actif: true,
        position: 'left',
      },
      {
        name: 'Email',
        actif: true,
        position: 'left'
      },
      {
        name: 'Téléphone',
        actif: true,
        // filterFn: (list: string[], item: any) => list.some(name => item.name.indexOf(name) !== -1)
      },
      {
        name: 'Profile',
        actif: true,
        // dataFilter: [
        //   ...this.roles.map((role: any) => {
        //     return { text: role?.replace('_', ' '), value: role }
        //   })
        // ],
        // filterFn: (list: string[], item: any) => {
        //   var oldFilter: string = '';
        //   if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('entreprise') == -1) {
        //     this.filterArray.push({
        //       key: 'entreprise',
        //       value: ''
        //     })
        //   }
        //   else {
        //     const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('entreprise');
        //     oldFilter = this.filterArray[index].value;
        //   }

        //   const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('entreprise');

        //   if (!list.length) {
        //     this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'entreprise');
        //     this.handleFilter();
        //   }
        //   else {
        //     this.filterArray[index].value = `
        //     {
        //       OR: [
        //         {
        //           entreprise: {
        //             id: {
        //               in: [${
        //                 list.filter((temp: any) => temp != "Particulier" )?.map((entreprise: any) => `"${entreprise}"` )
        //               }]
        //             }
        //           }
        //         }
        //         ${
        //           list?.includes('Particulier') ? 
        //           `
        //             {
        //               entreprise: {
        //                 NOT: {
        //                   id: {
        //                     in: [${
        //                       this.entreprises.map((entreprise: any) => `"${entreprise?.id}"` )
        //                     }]
        //                   }
        //                 }
        //               }
        //             }
        //           `
        //           : 
        //           ``
        //         }
        //       ]
        //     }
        //   `;
        //   if (oldFilter != this.filterArray[index].value) {
        //     this.handleFilter();
        //   }
        //   }
        // }
      },
      // {
      //   name: 'adresse'
      // },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        actif: true,
        position: 'right',
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  handleColumnChanged() {
    this.utilisateurs = this.getUtilisateurs();
  }

  handleFilter() {
    var _filter = `
    AND: [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    ]
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getUtilisateurs();
  }

  ngOnInit(): void {
    this.getUtilisateurs();
    this.getEntreprises();
  }

  formulaireEvent(utilisateur: any) {
    if (this.formType == 'ADD') {
      this.dataService.createUtilisateur(
        utilisateur
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getUtilisateurs();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    else {
      this.dataService.updateUtilisateur(
        this.currentId,
        utilisateur
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getUtilisateurs();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getUtilisateurs();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getUtilisateurs();
  }

  getUtilisateurs() {  
    this.dataService.getUtilisateurs(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.utilisateurs = this.handleList(data?.data?.users)
        this.totalCount = data?.data?.usersCount;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getUtilisateurs()
    })
  }

  handleList(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.prenom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.email,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.phoneNumber,
      },
      {
        type: 'LIST',
        value: row?.roles
      },
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  getEntreprises() {  
    this.dataService.getAllEntreprises()
    .subscribe(
      (data: any) => {
        this.entreprises = data?.data?.entreprises;
        this.initializeHeaders();
        // let entreprise: any = this.formulaires.find((form: any) => form?.code == 'entreprise');
        // entreprise.options = this.entreprises?.map((entrep: any) => {
        //   return {
        //     cle: entrep?.id,
        //     valeur: entrep?.raisonSociale
        //   }
        // })
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getUtilisateurById(action?.value);
      break;
      case 'delete':
        this.getUtilisateurs();
      break;
    }
  }

  getUtilisateurById(id: string) {
    this.dataService.getUtilisateurById(id)
    .subscribe(
      (data: any) => {
        this.initForm(data?.data?.user);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.initForm();
  }

  initForm(utilisateur: any = {}) {
    if (utilisateur?.id) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      {
        code: "prenom",
        placeholder: "Prénom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        defaultValue: utilisateur?.prenom,
        obligatoire: !!(this.formType == 'ADD' || utilisateur?.prenom),
        size: 'col-md-6'
      },
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        etape: 1,
        defaultValue: utilisateur?.nom,
        obligatoire: !!(this.formType == 'ADD' || utilisateur?.nom),
        size: 'col-md-6'
      },
      {
        code: "email",
        placeholder: "Adresse email",
        typeChamp: "EMAIL",
        typeReponse: "WRITE",
        etape: 1,
        defaultValue: utilisateur?.email,
        obligatoire: !!(this.formType == 'ADD' || utilisateur?.email),
        size: 'col-md-12'
      },
      {
        code: "phoneNumber",
        placeholder: "Téléphone",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        etape: 1,
        obligatoire: !!(this.formType == 'ADD' || utilisateur?.phoneNumber),
        defaultValue: utilisateur?.phoneNumber,
        size: 'col-md-12'
      },
      {
        code: "roles",
        placeholder: "Profiles",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        multiple: true,
        options: this.roles?.map((roles: any) => {
          return {
            cle: roles,
            valeur: roles
          }
        }),
        defaultValue: utilisateur?.roles,
        size: 'col-md-12'
      },
      {
        code: "actif",
        placeholder: "Activé",
        typeChamp: "CHECKBOX",
        typeReponse: "WRITE",
        etape: 1,
        disabled: this.formType == 'ADD' ? true : false,
        obligatoire: !!(this.formType == 'ADD' || utilisateur?.actif),
        defaultValue: this.formType == 'ADD' ? true : utilisateur?.actif,
        size: 'col-md-12'
      }
    ]
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
  }

}