import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-create-souscription-garantie',
  templateUrl: './create-souscription-garantie.component.html',
  styleUrls: ['./create-souscription-garantie.component.css']
})
export class CreateSouscriptionGarantieComponent implements OnInit {
  
  @Input() selectedTypeFinancement : any = {};
  @Input() plan : any = {};
  @Output() planEvent: EventEmitter<any> = new EventEmitter<any>();

  options: any = [];
  primeSouplementaire: number = 0;

  listBeneficiaire: any = {
    code: "familles",
    placeholder: "Familles",
    inputPlaceholder: 'Familles..',
    typeChamp: "TABLE",
    sizeTable: 5,
    etape: 1,
    typeReponse: "TABLE",
    hideAdd: true,
    hideDelete: true,
    columns: [
      // {
      //   name: 'qualite',
      //   placeholder: '#',
      //   type: 'TEXT'
      // },
      {
        name: 'prenom',
        placeholder: 'Prénom',
        type: 'TEXT'
      },
      {
        name: 'nom',
        placeholder: 'Nom',
        type: 'TEXT'
      },
      {
        name: 'dateNaissance',
        placeholder: 'Naissance',
        type: 'DATE'
      },
      {
        name: 'telephone',
        placeholder: 'Téléphone',
        type: 'TEXT',
      //   options: [

      //   ]
      },
    ],
    values: this.plan.beneficiairesCharges ? [...this.plan.beneficiairesCharges] : [],
    defaultValue: this.plan.beneficiairesCharges ? [...this.plan.beneficiairesCharges] : [],
    size: 'col-md-12'
  }

  constructor() { }

  ngOnInit(): void {
    console.log(this.plan);
    this.listBeneficiaire = {
      ...this.listBeneficiaire,
      defaultValue: this.plan.beneficiairesCharges,
      columns: [
        ...this.listBeneficiaire.columns,
        {
          name: 'qualite',
          placeholder: 'Qualité',
          readOnly: true,
          type: 'SELECT',
          options: this.plan.allQualites?.map((qualite: any) => ({
            valeur: qualite?.nom,
            cle: qualite?.id
          }))
        },
      ]
    }
    // console.log(this.plan.beneficiairesCharges)
    this.options = [
      ...this.plan?.options?.map((option: any) => ({
        ...option,
        selected: false
      }))
    ];
    this.planEvent.emit({
      ...this.plan,
      additionnalPrice: this.primeSouplementaire,
      selectedOptions: this.options?.filter((option: any) => option?.selected),
      sumPrime: this.plan?.primeNette + this.primeSouplementaire
    })
  }

  handleOptionPolice(option: any) {
    const foundPolice = this.plan?.polices?.find((police: any) => police?.entreprise?.id == this.plan?.entrepriseId);
    if (foundPolice) {
      option.selected = foundPolice?.options?.find((opt: any) => option?.id == opt?.id)
    }
    return !!foundPolice
  }

  getTableValues(table: any) {
    var familles: any = [];
    this.plan.beneficiairesCharges = table?.value?.map((item: any, index: number) => {
      let membre = {
        nom: item?.value?.nom,
        prenom: item?.value?.prenom,
        dateNaissance: item?.value?.dateNaissance,
        telephone: item?.value?.telephone,
        qualite: item?.value?.qualite
      }
      if (this.plan?.allQualites?.find((qualite: any) => qualite?.id == item?.value?.qualite)?.type == 'ASSURE_PRINCIPAL' || familles?.length == 0) {
        familles.push(
          [
            membre
          ]
        )
      }
      else {
        familles[familles?.length - 1].push(membre)
      }
      return membre
    })

    this.plan = {
      ...this.plan,
      familles
    }

    this.planEvent.emit({
      ...this.plan,
      additionnalPrice: this.primeSouplementaire,
      selectedOptions: this.options?.filter((option: any) => option?.selected),
      sumPrime: this.plan?.primeNette + this.primeSouplementaire
    })
  }

  handlePrimeSouplementaire(option: any) {
    option.selected = option?.selected? false : true;
    let nbPersonnes = this.plan?.selectedQualites?.reduce((acc: number, option: any) => acc + (option?.quantite ?? 0), 0);
    let additionnalPrice = this.options?.reduce((acc: number,option: any)=> {
      return acc + (option?.selected ? (option?.forfaitaire ? (option?.prime ?? 0) : (option?.prime * nbPersonnes)) : 0)
    },0 );

    this.primeSouplementaire = additionnalPrice;
    this.planEvent.emit({
      ...this.plan,
      additionnalPrice,
      selectedOptions: this.options?.filter((option: any) => option?.selected),
      sumPrime: this.plan?.primeNette + additionnalPrice
    })
  }

}
