import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-wave-payment-successful',
  templateUrl: './wave-payment-successful.component.html',
  styleUrls: ['./wave-payment-successful.component.css']
})
export class WavePaymentSuccessfulComponent implements OnInit {

  status: any = 'success';

  optionsSuccess: AnimationOptions = {
    path: 'assets/lotties/payment-success.json',
  };

  optionsFailed: AnimationOptions = {
    path: 'assets/lotties/failed.json',
  };

  option: AnimationOptions = {
    path: 'assets/lotties/payment-success.json',
  }

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.status = this.route?.snapshot?.paramMap?.get('status');
    if (this.status == 'success') {
      this.option = this.optionsSuccess;
    }
    else {
      this.option = this.optionsFailed;
    }
  }

}
