import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private router: Router
  ) { }

  ngOnInit(): void {
    let token = this.dataService.getToken();
    if (token && this.router.url.includes('auth')) {
      this.router.navigate(['/home']);
    }
  }

}
