import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-avenant',
  templateUrl: './avenant.component.html',
  styleUrls: ['./avenant.component.css']
})
export class AvenantComponent implements OnInit {

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  avenants: any = [];

  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'CategorieAvenant';

  searchText = '';
  headerInitialed: boolean = false;

  constructor(
    private dataService: DataService,
    private message: NzMessageService
  ) { }

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter une avenant",
      titre: 'Catégorie Avenant',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formulaires: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  initializeHeaders() {
    this.headers = [
      {
        name: 'Nom',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Description',
        actif: false,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  handleFilter() {
    var _filter = `
    AND: [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    ]
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getAvenants();
  }

  ngOnInit(): void {
    this.getAvenants();
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  handleColumnChanged() {
    this.avenants = this.getAvenants();
  }

  formulaireEvent(data: any) {
    if (this.formType == 'ADD') {
      this.dataService.createCategorieAvenant(
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getAvenants();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    else {
      this.dataService.updateCategorieAvenant(
        this.currentId,
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getAvenants();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getAvenants();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getAvenants();
  }

  getAvenants() {  
    this.dataService.getCategorieAvenants(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.avenants = this.handleList(data?.data?.categorieAvenants)
        this.totalCount = data?.data?.categorieAvenantsCount;
        if (!this.headerInitialed) {
          this.initializeHeaders();
          this.headerInitialed = true;
        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getAvenants()
    })
  }

  handleList(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.description,
      },
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getAvenantById(action?.value);
      break;
      case 'delete':
        this.getAvenants();
      break;
    }
  }

  getAvenantById(id: string) {
    this.dataService.getCategorieAvenantById(id)
    .subscribe(
      (data: any) => {
        this.initForm(data?.data?.categorieAvenant);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.initForm();
  }

  initForm(data: any = {}) {
    if (data?.id) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      {
        code: "nom",
        placeholder: "Catégorie d'avenant",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.nom),
        defaultValue: data?.nom,
        size: 'col-md-12'
      },
      {
        code: "description",
        placeholder: "Description",
        typeChamp: "TEXTAREA",
        etape: 1,
        typeReponse: "WRITE",
        defaultValue: data?.description,
        size: 'col-md-12'
      },
      // {
      //   code: "neccessitePaiement",
      //   placeholder: "Neccessite un paiement",
      //   typeChamp: "CHECKBOX",
      //   etape: 1,
      //   rows: 3,
      //   typeReponse: "WRITE",
      //   defaultValue: data?.neccessitePaiement,
      //   size: 'col-md-12'
      // }
    ]
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
  }

}
