import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Handsontable from 'handsontable';
import { NzMarks } from 'ng-zorro-antd/slider';
import { DataService } from 'src/app/data.service';
import { getPlugin } from 'handsontable/plugins'; 
import * as XLSX from 'xlsx';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NzTreeFlatDataSource, NzTreeFlattener } from 'ng-zorro-antd/tree-view';
import { NzMessageService } from 'ng-zorro-antd/message';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { NgxCaptureService } from 'ngx-capture';
// import { tap } from 'rxjs/operators';

// import { saveAs } from 'file-saver';

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const PAGE_LENGTH = 30;

@Component({
  selector: 'app-bordereau',
  templateUrl: './bordereau.component.html',
  styleUrls: ['./bordereau.component.css']
})
export class BordereauComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('screen', { static: true }) screen: any;

  // options = ['Production', 'Encaissement', 'Annulation', 'Reversement', 'Arriéré', 'Sinistre', 'Recours', 'Client'];
  options = ['Production', 'Encaissement', 'Annulation', 'Reversement', 'Arriéré', 'Client'];

  hotSettings: Handsontable.GridSettings = {
    colHeaders: true,
    rowHeaders: true,
    height: "auto",
    // width: '100%',
    stretchH: "all",
    licenseKey: 'non-commercial-and-evaluation'
  };

  currentPage: number = 1;
  totalCount: number = 1;
  totalBordereauCount: number = 1;
  pageCount: number = 10;
  compagnieBorderauxTotalPagination: number = 0;
  
  @ViewChild('invoice') invoiceElement!: ElementRef;

  isPreviewVisibled = false;
  isClotureVisibled = false;
  isClotureViewed = false;

  totalCountTable: number = 1;
  currentPageTable: number = 1;
  pageCountTable: number = 5;

  searchColumnText: string = '';
  filterIndex: number = 0;
  modelName: string = 'Bordereau';

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];
  bordereauHeader: any = [];
  clientSelected: any = [];

  currentCompagnie: any = {};
  currentBranche: any = {};

  quittances: any = [];
  allRisques: any = [];
  branches: any = [];
  compagnies: any = [];
  compagnieBordereaux: any = [];
  compagnieClotures: any = [];
  avenants: any = [];
  apporteurs: any = [];
  clients: any = [];
  risques: any = [];
  accessoires: any = [];
  compagnieBorderaux: any = [];
  limiteDeGarantie:  any = {};
  isTableLoading: boolean = false;
  primeTotal: number = 0;
  primeTotalBordereau: number = 0;
  currentPageBordereaux: number = 0;
  isListBordereauxActived: boolean = false;
  bordereaux: any = [{
      titre: 'Bordereau de production'
    },
    {
      titre: "Bordereau d'encaissement"
    },
    {
      titre: "Bordereau d'annulation"
    }
  ];

  exportPlugin: any;
  currentBordereau: number = 0;
  filter: string = `{}`;
  filterTable: string = ``;

  searchText: string = '';
  compagnieBordereauIndex: number = 1;

  bordereauParam: any = { 
    titre: '',
    fileName: '',
    emvoyerEmail: false,
    contenu: '',
    date: new Date(),
    objet: '',
    destinataires: ['proassur.ndao@gmail.com', 'souleymane.ndao@gmail.com'],
    primeNette: [0, 0],
    accessoire: [0, 0],
    taxe: [0, 0],
    cloturer: false,
    paymentId: '',
    compagnies: [],
    branches: [],
    clients: [],
    apporteurs: [],
    risques: [],
    avenants: [],
    dateEffet: [],
    dateEcheance: [],
    dateCreation: [(new Date().setDate(1)), (new Date())],
    derniereDateModif: []
  }

  private transformer = (node: any, level: number): any => ({
    expandable: !!node.children && node.children.length > 0,
    name: node.name,
    level,
    disabled: !!node.disabled
  });
  selectListSelection = new SelectionModel<any>();

  treeControl = new FlatTreeControl<any>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new NzTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new NzTreeFlatDataSource(this.treeControl, this.treeFlattener);

  listBordereaux: any = [];

  indexBordereau: number = 0;
  indexTypeBordereau: number = 0;

  paymentStatus: boolean = false;
  retryPayment: boolean = false;
  paymentInfo: any = {};

  destinataires: any = {
    inputPlaceholder: 'Adresse email',
    placeholder: 'Liste des destinataires',
    values: [],
    defaultValue: ['proassur.ndao@gmail.com', 'souleymane.ndao@gmail.com']
  }

  high: any = {};
  low: any = {};

  statistics: any = [];
  statisticsBorderaux: any = [];

  marksNettePrime: NzMarks = {};

  marksAccessoire: NzMarks = {};

  marksTaxe: NzMarks = {};

  previewImage: string | undefined = '';
  previewVisible = false;

  chequeForm = new FormGroup({
    numeroCheque: new FormControl('', [Validators.required]),
    banque: new FormControl('', [Validators.required]),
    montantCheque: new FormControl(null, [Validators.required]),
  });
  chequeImage: any;
  currentCloture: any = {};

  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly any[] = [];
  listOfData: readonly any[] = [];
  setOfCheckedId = new Set<number>();
  listOfDataChecked: any = [];
  isAttestation: boolean = false;

  handlePreview = async (_file: any): Promise<void> => {
    var file = _file?.file;
    this.chequeImage = file;
    // if (!file.url && !file.preview) {
    //   file.preview = await getBase64(file.originFileObj!);
    // }
    this.previewImage = file.url || await getBase64(file.originFileObj!);
    this.previewVisible = true;
  };
  

  changeMarks(): void {
    this.marksNettePrime = {
      20: '20%',
      99: '99%'
    };
  }

  onAction(action: any) {
    switch(action?.type) {
      case 'tree':
        this.showCloture(action?.id);
      break;
      default:
      break;

    }
  } 

  showCloture(clotureId: string) {
    this.dataService.getClotureById(clotureId)
    .subscribe(
      (data: any) => {
        this.isClotureVisibled = true;
        this.isClotureViewed = true;
        this.currentCloture = data?.data?.cloture;
        this.chequeForm.setValue({
          banque: data?.data?.cloture?.banqueCheque,
          numeroCheque: data?.data?.cloture?.numeroCheque,
          montantCheque: data?.data?.cloture?.montant,
        })
      },
      (err: any) => {
        console.log(err)
      }
    )
  }

  showModal(): void {
    this.isClotureViewed = false;
    if (this.bordereauParam?.dateCreation?.length == 2) {
      this.isPreviewVisibled = true;
      this.compagnieBordereauIndexChanged(1);
    }
    else {
      this.message?.error("Vous n'avez pas sélectionné une période")
    }
  }

  handleOk(): void {
    this.isClotureVisibled = true;
    this.getQuittances(true)
  }

  imprimer(): void {
    this.generatePDF();
  }

  handleCancel(type: string): void {
    switch(type) {
      case 'preview':
        this.isPreviewVisibled = false;
      break;
      case 'cloture':
        this.isClotureVisibled = false;
      break;
      default: 
        this.isClotureVisibled = false;
        this.isPreviewVisibled = false;
      break;
    }
  }

  generatePDF(): void {
    // this.captureService.getImage(this.screen.nativeElement, true)
    // .pipe(
    //   tap(img => {
    //   })
    // ).subscribe();
    // return;
    html2canvas(this.invoiceElement.nativeElement, { scale: 3 }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/png');
      const fileWidth = 200;
      const generatedImageHeight = (canvas.height * fileWidth) / canvas.width;
      // let PDF = new jsPDF('p', 'mm', 'a4',);
      let PDF = new jsPDF({
        orientation: "landscape"
      })

      
      // var a = document.createElement("a"); //Create <a>
      // a.href = imageGeneratedFromTemplate; //Image Base64 Goes here
      // a.download = "Image.png"; //File name Here
      // a.click(); //Downloaded file
      // PDF.addImage(imageGeneratedFromTemplate, 'PNG', 0, 5, fileWidth, generatedImageHeight,);
      // const cols = Array.from(document.getElementsByClassName('htDimmed') as HTMLCollectionOf<HTMLElement>)
      // for(let i = 0; i < cols.length; i++) {
      //   console.log(cols[i])
      //   cols[i].style.color = 'black';
      //   // cols[i].style.fontSize = '15px';
      // }
      // StyleSheet.insertCssRule(".htDimmed { color: black;}", 0);
      PDF.addImage(imageGeneratedFromTemplate, 'PNG', 10, 10, 275,0);
      PDF.html(this.invoiceElement.nativeElement.innerHTML)
      PDF.save(`${this.bordereauParam?.titre}-${this.currentCompagnie?.raisonSociale}-${this.getDateFormated(this.bordereauParam?.dateCreation?.[0], false)} au ${this.getDateFormated(this.bordereauParam?.dateCreation?.[1], false)}`);
    });
  }

  reinitFilters(): void {
    this.bordereauParam = { 
      titre: '',
      fileName: '',
      emvoyerEmail: false,
      contenu: '',
      date: new Date(),
      objet: '',
      destinataires: ['proassur.ndao@gmail.com', 'souleymane.ndao@gmail.com'],
      primeNette: [0, 0],
      accessoire: [0, 0],
      taxe: [0, 0],
      cloturer: false,
      paymentId: '',
      compagnies: [],
      branches: [],
      clients: [],
      apporteurs: [],
      risques: [],
      avenants: [],
      dateEffet: [],
      dateEcheance: [],
      dateCreation: [],
      derniereDateModif: []
    }
  };

  generateBordereau(){
    this.getBordereauRequest()
  }

  onPaymentStatus(status: boolean) {
    this.paymentStatus = status;
    if (!status) {
      this.retryPayment = true
    }
  }

  compagnieBordereauIndexChanged(index: number) {
    this.currentPageBordereaux = index; 
    var compagnieIndex: number = 0;
    var brancheIndex: number = 0;
    var currentIndex: number = 0;
    var step: number = 0;
    console.log(this.compagnieBorderaux)
    while (currentIndex < index) {
      step++;
      if (this.compagnieBorderaux?.[compagnieIndex]?.branches?.[brancheIndex]) {
        if (step > this.compagnieBorderaux?.[compagnieIndex]?.branches?.[brancheIndex]?.steps) {     
          step = 1
          brancheIndex++;
        }
      }
      else {
        compagnieIndex++;
        brancheIndex = 0;
        step = 1;
      }
      currentIndex++;
    }
    this.getQuittanceOfCompagnie(compagnieIndex, brancheIndex, step)
  }

  onPayementFinished(paymentInfo: any) {
    this.getBordereauRequest(paymentInfo);
    this.paymentInfo = paymentInfo;
  }

  getBordereauRequest(paymentInfo: string = '') {
    this.dataService.generateBordereau(
      JSON.stringify(
        {
          ...this.bordereauParam,
          headers: this.headers,
          searchText: this.searchText,
          paymentInfo
        }
      )
    ).subscribe(
      (data: any) => {
        this.downloadFile(data?.data?.generateBordereau);
      },
      (error: any) => {
        console.log(error);
      }
    )
  }

  downloadFile(fileName: string) {
    var element = document.createElement('a');
    element.setAttribute('href', fileName);
    element.setAttribute('download', fileName);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
  }

  getTableValues(table: any) {
    this.bordereauParam.destinataires = table?.value?.map((value: any) => value.value);
  }

  getDate() {
    return new Date();
  }

  handleIndexChange(index: any): void {
    this.initializeHeaders(this.getBordereauFields(this.options?.[index]));
    this.indexTypeBordereau = index;
    this.handleFilter();
    this.getQuittances();
  }

  // addBordereauFilter(bordereau: string) {
  //   switch(bordereau) {
  //     case 'production':
  //   }
  //   this.getQuittances();
  // }

  dataset: any[] = [];
  datasetBordereau: any[] = [];

  constructor(
    public dataService: DataService,
    private message: NzMessageService,
    // private captureService: NgxCaptureService
  ) { }

  ngOnInit(): void {
    this.getAccessoires();
    this.initializeHeaders(this.getBordereauFields('Production'));
    this.getAllBranches();
    this.getAllApporteurs();
    this.getAllCompagnies();
    this.getCompagnieClotures();
    this.getAllAvenants();
    this.getAllRisques();
    this.getHighestNettePrime();
    this.getHighestAccessoire();
    this.getHighestTaxe();
    this.initializeBordereauHeaders();
    // this.getCompagnies();
  }

  getCompagnies() {  
    this.dataService.getCompagnies(this.currentPageTable, this.pageCountTable, this.filterTable, this.searchText)
    .subscribe(
      (data: any) => {
        this.compagnieBordereaux = this.handleListBordereau(data?.data?.compagnies)
        this.totalCountTable = data?.data?.compagniesCount;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getCompagnieClotures() {  
    this.dataService.getCompagnieClotures(this.currentPageTable, this.pageCountTable, this.filterTable, this.searchText)
    .subscribe(
      (data: any) => {
        this.compagnieClotures = this.handleListBordereau(data?.data?.compagnies)
        this.totalCountTable = data?.data?.compagniesCount;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  initializeBordereauHeaders() {
    this.bordereauHeader = [
      {
        name: 'Compagnie',
        code: 'compagnie',
        actif: true
      },
    ]
  }

  ngAfterViewInit(): void {
    var css = document.createElement('link');
    css.id ='bordereauId';
    css.href = "assets/css/bordereau.css";
    css.rel = "stylesheet";
    document.head.append(css);
  }

  ngOnDestroy(): void {
    let css = document.getElementById('bordereauId');
    if (css) {
      document.head.removeChild(css);
    }
  }

  onTableUpdate(value: any) {
    this.currentPageTable = value?.currentPage;
    this.pageCountTable = value?.pageCount;
    this.getCompagnieClotures();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getCompagnieClotures();
  }

  download() {
    /**passing table id**/
    let data = document.getElementById('dataExport');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(data);

    /**Generate workbook and add the worksheet**/
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /*save to file*/
    XLSX.writeFile(wb, `Bordereau de ${this.options[this.currentBordereau]} ${(new Date()).getTime()}.xlsx`);
  }

  handleSelectChange(listName: string): void {
    if (this.bordereauParam?.compagnies?.includes('*')) {
      this.bordereauParam.compagnies = [];
    }
  }

  brancheChanged(branches: any) {
    this.dataService.getAllRisqueByBranches(branches).subscribe(
      (data: any) => {
        const newInsertedRisques: any = data?.data?.risqueBranches?.filter((r: any) => !(this?.risques?.map((risque: any) => risque.id))?.includes(r?.id))
        this.risques = data?.data?.risqueBranches;
        this.bordereauParam.risques = this.bordereauParam?.risques?.filter((r: any) => this.risques?.map((risque: any) => risque?.id)?.includes(r));
        this.bordereauParam.risques = [
          ...this.bordereauParam.risques,
          ...newInsertedRisques?.map((r: any) => r?.id)
        ]
        this.handleFilter();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getDateLastTime = (_date: Date): Date => {
    const date: Date = new Date(_date);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    return date;
  };
  
  getDateFirstTime = (_date: Date): Date => {
    const date: Date = new Date(_date);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;  
  };

  getLabelByIds(modelName: any, arrayOfIds: any) {
    return arrayOfIds?.map((id: string) => {
      return modelName?.find((item: any) => item.id == id);
    })
  }

  handleFilter(modelName: string = '', values: any = null, compagnieId: string = '', brancheId: string = '') {
    if (modelName == 'client') {
      const newClient = values?.find((value: any) => !(this.clientSelected?.map((val: any) => val?.id)?.includes(value)));
      const clientInfo: any = this.clients?.find((cli: any) => cli?.id == newClient);
      this.clientSelected?.push(clientInfo);
    }

    var bordereauTypeFilter = '';
    switch(this.indexTypeBordereau) {
      case 1:
        bordereauTypeFilter =  `
        {
          OR: [
            {
              paiements: {
                some: {
                  type: {
                    not: {
                      equals: IMPAYE
                    }
                  }
                }
              }
            }
            {
              paiements: {
                some: {
                  accompte: {
                    gt: 0
                  }
                }
              }
            }
          ]
        }
        `;
      break;
      case 2:
        bordereauTypeFilter = `
        {
          AND: [
            {
              paiements: {
                some: {
                  type: {
                    equals: ANNULATION
                  }
                }
              }
            }
          ]
        }
        `
      break;
      case 4:
        bordereauTypeFilter =  `
        {
          AND: [
            {
              paiements: {
                every: {
                  restantApayer: {
                    gt: 0
                  }
                }
              }
            }
            {
              paiements: {
                some: {
                  restantApayer: {
                    not: {
                      equals: null
                    }
                  }
                }
              }
            }
          ]
        }
        `;
      break;
      default: 
        bordereauTypeFilter =  ``;
      break;
    }

    this.filter = `{
      deleted: {
        not: {
          equals: true
        }
      },
    	cloture: null
      AND: [
        ${
          compagnieId ?
          `
          {
            police: {
              compagnie: {
                  id: {
                    equals: "${compagnieId}"
                  }
              }
            }
          }
          `
          : 
          ``
        }
        ${
          brancheId ?
          `
          {
            police: {
              risque: {
                branche: {
                    id: {
                      equals: "${brancheId}"
                    }
                }
              }
            }
          }
          `
          : 
          ``
        }
          ${
            bordereauTypeFilter
          }
          ${
            this.bordereauParam?.dateCreation?.length == 2 ?
            `{
              AND: [
                {
                  dateCreation: {
                    gte: "${this.getDateFirstTime(this.bordereauParam?.dateCreation?.[0])?.toISOString()?.split('T')?.[0]}"
                  }
                },
                {
                  dateCreation: {
                    lte: "${this.getDateLastTime(this.bordereauParam?.dateCreation?.[1])?.toISOString()?.split('T')?.[0]}"
                  }
                }
              ]
            }`
            :
            ``
          }
          ${
            this.bordereauParam?.primeNette?.length == 2 && this.bordereauParam?.primeNette?.[1] != 0 ?
            `{
              AND: [
                {
                  primeNette: {
                    gte: ${this.bordereauParam?.primeNette?.[0]}
                  }
                },
                {
                  primeNette: {
                    lte: ${this.bordereauParam?.primeNette?.[1]}
                  }
                }
              ]
            }`
            :
            ``
          }
          ${
            this.bordereauParam?.accessoire?.length == 2 && this.bordereauParam?.accessoire?.[1] != 0 ?
            `{
              AND: [
                {
                  accessoire: {
                    gte: ${this.bordereauParam?.accessoire?.[0]}
                  }
                },
                {
                  accessoire: {
                    lte: ${this.bordereauParam?.accessoire?.[1]}
                  }
                }
              ]
            }`
            :
            ``
          }
          ${
            this.bordereauParam?.taxe?.length == 2 && this.bordereauParam?.taxe?.[1] != 0 ?
            `{
              AND: [
                {
                  taxe: {
                    gte: ${this.bordereauParam?.taxe?.[0]}
                  }
                },
                {
                  taxe: {
                    lte: ${this.bordereauParam?.taxe?.[1]}
                  }
                }
              ]
            }`
            :
            ``
          }
          ${
            this.bordereauParam?.dateEffet?.length == 2 ?
            `{
              AND: [
                {
                  dateEffet: {
                    gte: "${this.getDateFirstTime(this.bordereauParam?.dateEffet?.[0])?.toISOString()?.split('T')?.[0]}"
                  }
                },
                {
                  dateEffet: {
                    lte: "${this.getDateLastTime(this.bordereauParam?.dateEffet?.[1])?.toISOString()?.split('T')?.[0]}"
                  }
                }
              ]
            }`
          : 
          ``
          }
          ${
            this.bordereauParam?.dateEcheance?.length == 2 ?
            `{
              AND: [
                {
                  dateEcheance: {
                    gte: "${this.getDateFirstTime(this.bordereauParam?.dateEcheance?.[0])?.toISOString()?.split('T')?.[0]}"
                  }
                },
                {
                  dateEcheance: {
                    lte: "${this.getDateLastTime(this.bordereauParam?.dateEcheance?.[1])?.toISOString()?.split('T')?.[0]}"
                  }
                }
              ]
            }`
          : 
          ``
          }
          ${
            this.bordereauParam?.derniereDateModif?.length == 2 ?
            `{
              AND: [
                {
                  derniereDateModif: {
                    gte: "${this.getDateFirstTime(this.bordereauParam?.derniereDateModif?.[0])?.toISOString()}"
                  }
                },
                {
                  derniereDateModif: {
                    lte: "${this.getDateLastTime(this.bordereauParam?.derniereDateModif?.[1])?.toISOString()}"
                  }
                }
              ]
            }`
          : 
          ``
          }
          ${
            this.bordereauParam?.compagnies?.length ?
            `{
              OR: [
                {
                  police: {
                    compagnie: {
                      id: {
                        in: ${JSON.stringify(this.bordereauParam?.compagnies)}
                      }
                    }
                  }
                }
              ]
            }`
            :
            ``
          }
          ${
            this.bordereauParam?.branches?.length ?
            `{
              OR: [
                {
                  police: {
                    risque: {
                      branche: {
                        id: {
                          in:  ${JSON.stringify(this.bordereauParam?.branches)}
                        }
                      }
                    }
                  }
                }
              ]
            }`
            : 
            ``
          }
          ${
            this.bordereauParam?.risques?.length ?
            `{
              OR: [
                {
                  police: {
                    risque: {
                      id: {
                        in: ${JSON.stringify(this.bordereauParam?.risques)}
                      }
                    }
                  }
                }
              ]
            }`
            : 
            ``
          }
          ${
            this.bordereauParam?.clients?.length ? 
            `{
              OR: [
                {
                  police: {
                    client: {
                      id: {
                        in: ${JSON.stringify(this.bordereauParam?.clients)}
                      }
                    }
                  }
                }
              ]
            }`
            : 
            ``
          }
          ${
            this.bordereauParam?.avenants?.length ? 
            `{
              OR: [
                {
                  avenant: {
                    id: {
                      in: ${JSON.stringify(this.bordereauParam?.avenants)}
                    }
                  }
                }
              ]
            }`
            : 
            ``
          }
          ${
            this.bordereauParam?.apporteurs?.length ? 
            `{
              OR: [
                {
                  police: {
                    apporteur: {
                      id: {
                        in: ${JSON.stringify(this.bordereauParam?.apporteurs)}
                      }
                    }
                  }
                }
              ]
            }`
            : 
            ``
          }
      ]
    }
    `;
  }

  getAllRisques() {
    this.dataService.getAllRisques().subscribe(
      (data: any) => {
        this.allRisques = data?.data?.risqueBranches;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllBranches() {
    this.dataService.getAllBranches().subscribe(
      (data: any) => {
        this.branches = data?.data?.branches;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllCompagnies() {
    this.dataService.getAllCompagnies().subscribe(
      (data: any) => {
        this.compagnies = data?.data?.compagnies;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllAvenants() {
    this.dataService.getAllCategorieAvenants().subscribe(
      (data: any) => {
        this.avenants = data?.data?.categorieAvenants;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllApporteurs() {
    this.dataService.getAllApporteurs().subscribe(
      (data: any) => {
        this.apporteurs = data?.data?.apporteurs;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllClientBySearch(searchText: string) {
    if (searchText && searchText?.length >= 2) {
      this.dataService.getAllClientBySearch(searchText).subscribe(
        (data: any) => {
          this.clients = data?.data?.clients;
        },
        (err: any) => {
          console.log(err);
        },
      )
    }
  }

  getHighestNettePrime() {
    this.dataService.getHighestNettePrime().subscribe(
      (data: any) => {
        const primeNette = data?.data?.quittances?.[0]?.primeNette;
        this.bordereauParam.primeNette[1] = primeNette;
        this.marksNettePrime[primeNette] = {
            style: {
              color: '#f50'
            },
            label: `<strong>${primeNette} FCFA</strong>`
        }
        setTimeout(() => {
          this.high.primeNette = primeNette;
        }, 500);
      },
      (err: any) => {
        console.log(err);
      },
    )
    this.dataService.getLowestNettePrime().subscribe(
      (data: any) => {
        const primeNette = data?.data?.quittances?.[0]?.primeNette;
        this.bordereauParam.primeNette[0] = primeNette;
        this.marksNettePrime[primeNette] = {
            style: {
              color: '#f50'
            },
            label: `<strong>${primeNette} FCFA</strong>`
        }
        setTimeout(() => {
          this.low.primeNette = primeNette;
        }, 500);
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getHighestAccessoire() {
    this.dataService.getHighestAccessoire().subscribe(
      (data: any) => {
        const accessoire = data?.data?.quittances?.[0]?.accessoire;
        this.bordereauParam.accessoire[1] = accessoire;
        this.marksAccessoire[accessoire] = {
            style: {
              color: '#f50'
            },
            label: `<strong>${accessoire} FCFA</strong>`
        }
        setTimeout(() => {
          this.high.accessoire = accessoire;
        }, 500);
      },
      (err: any) => {
        console.log(err);
      },
    );
    
    this.dataService.getLowestAccessoire().subscribe(
      (data: any) => {
        const accessoire = data?.data?.quittances?.[0]?.accessoire;
        this.bordereauParam.accessoire[0] = accessoire;
        this.marksAccessoire[accessoire] = {
            style: {
              color: '#f50'
            },
            label: `<strong>${accessoire} FCFA</strong>`
        }
        setTimeout(() => {
          this.low.accessoire = accessoire;
        }, 500);
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getHighestTaxe() {
    this.dataService.getHighestTaxe().subscribe(
      (data: any) => {
        const taxe = data?.data?.quittances?.[0]?.taxe;
        this.bordereauParam.taxe[1] = taxe;
        this.marksTaxe[taxe] = {
            style: {
              color: '#f50'
            },
            label: `<strong>${taxe} FCFA</strong>`
        }
        setTimeout(() => {
          this.high.taxe = taxe;
        }, 500);
      },
      (err: any) => {
        console.log(err);
      },
    );

    this.dataService.getLowestTaxe().subscribe(
      (data: any) => {
        const taxe = data?.data?.quittances?.[0]?.taxe;
        this.bordereauParam.taxe[0] = taxe;
        this.marksTaxe[taxe] = {
            style: {
              color: '#f50'
            },
            label: `<strong>${taxe} FCFA</strong>`
        }
        setTimeout(() => {
          this.low.taxe = taxe;
        }, 500);
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  handleColumnChanged() {
    this.getQuittances();
  }

  getDateFormated(_date: any, heure=true) {
    const date = new Date(_date);
    const yyyy = date.getUTCFullYear();
    let mm: any = date.getMonth() + 1 < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1); // Months start at 0!
    let dd: any = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate();
    let hh: any = date.getHours();
    let min: any = date.getMinutes();

    if (heure) {
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
      if (hh < 10) hh = '0' + hh;
      if (min < 10) min = '0' + min;
    }

    const formattedToday = `${dd}-${mm}-${yyyy}${heure ?` à ${hh}:${min}` : ``}`;
    return formattedToday
  }

  calculateDiff(date1: Date, date2: Date) {
    return Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate()) ) /(1000 * 60 * 60 * 24)) + ' jour(s)';
  }

  getBordereauHead() {
    var head: any = [];
    var tmp: any = {};
    const bordereau = this.getBordereauFields(this.options?.[this.currentBordereau]);
    tmp[bordereau?.[0]] = 'PERIODE DU';
    tmp[bordereau?.[1]] = '21-10-2023';
    head.push(tmp); 
    tmp[bordereau?.[0]] = 'AU';
    tmp[bordereau?.[1]] = '21-12-2023';
    head.push(tmp);
    return head;
  }

  getAccessoires() {  
    this.dataService.getAllAccessoires()
    .subscribe(
      (data: any) => {
        this.accessoires = data?.data?.accessoires;
        this.handleFilter();
        this.getQuittances();
      },
      (err: any) => {
        console.log(err);
      }
    ); 
  }

  handleCloture() {
    if (this.chequeForm?.valid) {
      if (this.chequeImage) {
        if (this.listOfDataChecked?.length) {
          this.dataService.createCloture({
            ...this.chequeForm?.value,
            debut: (new Date(this.bordereauParam?.dateCreation?.[0]))?.toISOString()?.split('T')?.[0]?.split(',')?.[0],
            fin: (new Date(this.bordereauParam?.dateCreation?.[1]))?.toISOString()?.split('T')?.[0]?.split(',')?.[0],
            chequeImage: this.chequeImage?.originFileObj,
            compagnie: this.currentCompagnie?.id,
            quittances: this.listOfDataChecked
          }).subscribe((data: any) => {
            this.handleCancel('preview');
            this.handleCancel('cloture');
            this.handleFilter();
            this.getQuittances();
            this.getCompagnieClotures();
          },
          err => {
            console.log(err);
          })
        }
        else {
          this.message.error("Aucune quittance n'a été séléctionnée")
        }
      }
      else {
        this.message.error("Veuillez charger l'image du chéque")
      }
    }
    else {
      this.message.error('Veuillez remplir tous les champs')
    }
  }

  getQuittanceOfCompagnie(compagnieIndex: number, brancheIndex: number, step = 1) {
    console.log(compagnieIndex, brancheIndex, step)
    var compagnies = [this.compagnieBorderaux?.[compagnieIndex]?.id];
    var branches = this.compagnieBorderaux?.[compagnieIndex]?.branches?.[brancheIndex]?.branche == 'ALL' ? [] : (this.compagnieBorderaux?.[compagnieIndex]?.branches?.[brancheIndex]?.branche == 'ATTESTATION' ? [this.branches?.find((branche: any) => branche?.nom?.toUpperCase() == 'AUTOMOBILE')?.id] : [this.compagnieBorderaux?.[compagnieIndex]?.branches?.[brancheIndex]?.branche]) ;
    console.log(branches?.[0])
    this.handleFilter('', null, compagnies?.[0], branches?.[0]);
    setTimeout(() => {
      this.dataService.getQuittanceForBordereauByCompagnie(compagnies?.[0], branches?.[0], this.filter, step, PAGE_LENGTH)
      .subscribe(
        (data: any) => {
          this.currentCompagnie = data?.data?.compagnie;
          this.currentBranche = this.compagnieBorderaux?.[compagnieIndex]?.branches?.[brancheIndex]?.branche == 'ATTESTATION' ? {...data?.data?.branche, nom: 'Attestations auto délivrées'} : data?.data?.branche;
          this.limiteDeGarantie = data?.data?.parametrages?.[0];
          this.datasetBordereau = this.handleList(data?.data?.quittances, this.compagnieBorderaux?.[compagnieIndex]?.branches?.[brancheIndex]?.branche == 'ATTESTATION')
          this.totalBordereauCount = data?.data?.quittancesCount;
          console.log(this.currentBranche)

          setTimeout(() => {
            const cols = Array.from(document.getElementsByClassName('htDimmed') as HTMLCollectionOf<HTMLElement>)
            for(let i = 0; i < cols.length; i++) {
                cols[i].style.color = 'black';
                // cols[i].style.fontSize = '15px';
            }

          }, 500)
        },
        (err: any) => {
          console.log(err);
        },
      );


      this.dataService.getQuittanceTotauxForBordereauByCompagnie(this.filter)
      .subscribe(
        (data: any) => {
          this.getStatisticsBordereau(data?.data?.quittances);
          var sum = 0;
          data?.data?.quittances?.map((quittance: any) => {
            sum += quittance?.primeTotale;
            return quittance?.primeTotale;
          })
          this.primeTotalBordereau = sum
        },
        (err: any) => {
          console.log(err);
        },
      );
    }, 200)
  }

  getQuittances(all: boolean=false) { 
    this.isTableLoading = true;
    this.handleFilter();
    this.dataService.getQuittanceForBordereau(this.currentPage, all ? 999999999 : this.pageCount, this.filter)
    .subscribe(
      (data: any) => {
        var count = 0;

        this.compagnieBorderaux = data?.data?.compagnies?.map((compagnie: any) => {
          var concernBranches: any = [];
          compagnie?.polices?.forEach((police: any) => {
            if (!concernBranches?.find((cb: any) => police?.risque?.branche?.id == cb?.branche )) {
              concernBranches.push({
                branche: police?.risque?.branche?.id,
                steps: Math.ceil(compagnie?.polices?.reduce((acc: number, pol: any) => acc + (pol?.risque?.branche?.id == police?.risque?.branche?.id ? police?.quittancesCount / PAGE_LENGTH : 0), 0))
              })
            }
            return })
          var branches = Array.from(new Set([...concernBranches, {branche: 'ALL', steps: 1}, {branche: 'ATTESTATION', steps: 1}]));
          count = count + (branches?.reduce((acc: number, branche: any) => acc + (branche?.steps ?? 0) , 0));
          return {
            ...compagnie,
            branches
          }
        });

        this.compagnieBorderauxTotalPagination = count;
        this.limiteDeGarantie = data?.data?.parametrages?.[0];
        this.listOfData = data?.data?.quittances;
        this.dataset = this.handleList(data?.data?.quittances)
        this.totalCount = data?.data?.quittancesCount;
      },
      (err: any) => {
        console.log(err);
      },
    )

    this.dataService.getQuittanceTotauxForBordereau(this.filter)
    .subscribe(
      (data: any) => {
        this.getStatistics(data?.data?.quittances);
        var sum = 0;
        data?.data?.quittances?.map((quittance: any) => {
          sum += quittance?.primeTotale;
          return quittance?.primeTotale;
        })
        this.primeTotal = sum
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getStatistics(_quittances: any) {
    var quittances = _quittances;
    switch (this.options[this.currentBordereau]) {
      case 'Production':
      case 'Client':
      case 'Annulation':
        this.statistics = [
          {
            titre: 'Prime nette',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeNette)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Accessoires',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.accessoire)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Taxes',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.taxe)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Prime totale',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Commissions',
            valeur: quittances?.length ? quittances?.map((quittance: any) => this.calculateCommissions(quittance))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
        ];
      break;
      case 'Encaissement':
        quittances = _quittances?.filter((quittance: any) => !!quittance?.paiements?.find((paiement: any) => !(paiement?.type == 'IMPAYE' && !(paiement?.accompte > 0))));
        this.statistics = [
          {
            titre: 'Prime nette',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeNette)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Accessoires',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.accessoire)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Taxes',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.taxe)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Prime totale',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Commissions',
            valeur: quittances?.length ? quittances?.map((quittance: any) => this.calculateCommissions(quittance))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Encaissé',
            // valeur: quittances?.length ? quittances?.map((quittance: any) => quittance?.paiements?.reduce((accumulator: number, paiement: any) => accumulator + (paiement?.accompte ? paiement?.accompte : 0), 0) > 0 ? quittance?.paiements?.reduce((accumulator: number, paiement: any) => accumulator + (paiement?.accompte ? paiement?.accompte : 0), 0) : quittance.primeTotale) : [0]
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance?.paiements?.reduce((accumulator: number, paiement: any) => accumulator + (paiement?.type == 'IMPAYE' ? (paiement?.accompte ?? 0) : (paiement?.montant ?? 0)) , 0))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
        ];
      break;
      case 'Arriéré':
        this.statistics = [
          {
            titre: 'Prime nette',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeNette)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Accessoires',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.accessoire)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Taxes',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.taxe)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Prime totale',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Commissions',
            valeur: quittances?.length ? quittances?.map((quittance: any) => this.calculateCommissions(quittance))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Accompte',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance?.paiements?.reduce((accumulator: number, currentValue: any) => accumulator + (currentValue?.accompte ? currentValue?.accompte : 0), 0))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Arriérés',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale - quittance?.paiements?.reduce((accumulator: number, currentValue: any) => accumulator + (currentValue?.accompte ? currentValue?.accompte : 0), 0))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
        ];
      break;
      case 'Reversement':
        this.statistics = [
          {
            titre: 'Prime nette',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeNette)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Accessoires',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.accessoire)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Taxes',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.taxe)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Prime totale',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Commissions',
            valeur: quittances?.length ? quittances?.map((quittance: any) => this.calculateCommissions(quittance))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Acompte',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'A reverser',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          }
        ];
      break;
      default: 
        this.statistics = [];
      break;
    }
  }

  getStatisticsBordereau(_quittances: any) {
    var quittances = _quittances;
    switch (this.options[this.currentBordereau]) {
      case 'Production':
      case 'Client':
      case 'Annulation':
        this.statisticsBorderaux = [
          {
            titre: 'Prime nette',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeNette)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Accessoires',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.accessoire)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Taxes',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.taxe)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Prime totale',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Commissions',
            valeur: quittances?.length ? quittances?.map((quittance: any) => this.calculateCommissions(quittance))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
        ];
      break;
      case 'Encaissement':
        quittances = _quittances?.filter((quittance: any) => !!quittance?.paiements?.find((paiement: any) => !(paiement?.type == 'IMPAYE' && !(paiement?.accompte > 0))));
        this.statisticsBorderaux = [
          {
            titre: 'Prime nette',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeNette)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Accessoires',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.accessoire)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Taxes',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.taxe)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Prime totale',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Commissions',
            valeur: quittances?.length ? quittances?.map((quittance: any) => this.calculateCommissions(quittance))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Encaissé',
            // valeur: quittances?.length ? quittances?.map((quittance: any) => quittance?.paiements?.reduce((accumulator: number, paiement: any) => accumulator + (paiement?.accompte ? paiement?.accompte : 0), 0) > 0 ? quittance?.paiements?.reduce((accumulator: number, paiement: any) => accumulator + (paiement?.accompte ? paiement?.accompte : 0), 0) : quittance.primeTotale) : [0]
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance?.paiements?.reduce((accumulator: number, paiement: any) => accumulator + (paiement?.type == 'IMPAYE' ? (paiement?.accompte ?? 0) : (paiement?.montant ?? 0)) , 0))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
        ];
      break;
      case 'Arriéré':
        this.statisticsBorderaux = [
          {
            titre: 'Prime nette',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeNette)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Accessoires',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.accessoire)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Taxes',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.taxe)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Prime totale',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Commissions',
            valeur: quittances?.length ? quittances?.map((quittance: any) => this.calculateCommissions(quittance))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Accompte',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance?.paiements?.reduce((accumulator: number, currentValue: any) => accumulator + (currentValue?.accompte ? currentValue?.accompte : 0), 0))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Arriérés',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale - quittance?.paiements?.reduce((accumulator: number, currentValue: any) => accumulator + (currentValue?.accompte ? currentValue?.accompte : 0), 0))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
        ];
      break;
      case 'Reversement':
        this.statisticsBorderaux = [
          {
            titre: 'Prime nette',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeNette)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Accessoires',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.accessoire)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Taxes',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.taxe)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Prime totale',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Commissions',
            valeur: quittances?.length ? quittances?.map((quittance: any) => this.calculateCommissions(quittance))?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'Acompte',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          },
          {
            titre: 'A reverser',
            valeur: quittances?.length ? quittances?.map((quittance: any) => quittance.primeTotale)?.reduce((acc: number, item: any) => acc + item, 0) : 0
          }
        ];
      break;
      default: 
        this.statisticsBorderaux = [];
      break;
    }
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length;
  }

  export() {
    this.download()
  }

  setTableUpdate(value: number) {
    this.currentPage = value;
    this.getQuittances();
  }

  getNumber(valeur: any) : number {
    return 20000000
  }

  getBordereauFields(bordereau: string) {
    switch(bordereau) {
      case 'Production':
        // return ['quittance', 'numeroClient', 'client', 'dateEffet', 'dateEcheance', 'police', 'primeNette', 'accessoire', 'taxe', 'primeTotale']
        return ['police', 'avenant', 'numeroClient', 'dateCreation', 'dureeContrat', 'client', 'dateEffet', 'branche', 'risque', 'limitesGarantie', 'primeNette', 'accessoire', 'taxe', 'primeTotale', 'commissions', 'etatPaiement']
      case 'Encaissement':
        return ['police', 'avenant', 'numeroClient', 'dateCreation', 'dureeContrat', 'client', 'dateEffet', 'branche', 'risque', 'limitesGarantie', 'primeNette', 'accessoire', 'taxe', 'primeTotale', 'commissions', 'etatPaiement']
      case 'Annulation':
        return ['police', 'avenant', 'numeroClient', 'dateCreation', 'dureeContrat', 'client', 'dateEffet', 'branche', 'risque', 'limitesGarantie', 'primeNette', 'accessoire', 'taxe', 'primeTotale', 'commissions', 'etatPaiement']
      case 'Reversement':
        return ['police', 'avenant', 'numeroClient', 'dateCreation', 'dureeContrat', 'client', 'dateEffet', 'branche', 'risque', 'limitesGarantie', 'primeNette', 'accessoire', 'taxe', 'primeTotale', 'commissions', 'acompte', 'soldeAreverser']
      case 'Arriéré':
        return ['police', 'avenant', 'numeroClient', 'dateCreation', 'dureeContrat', 'client', 'dateEffet', 'branche', 'risque', 'limitesGarantie', 'primeNette', 'accessoire', 'taxe', 'primeTotale', 'acompte', 'arrieres', 'commissions', 'etatPaiement', 'etatDesArrieres']
      case 'Sinistre':
        return ['numeroSinistre', 'police', 'client', 'dateDeSurvenance', 'dateDeDeclaration', 'victimeBeneficiaireAdversaire', 'evaluationInitiale', 'evaluationActuelle', 'montantDesPaiements', 'evaluationDesSommesRestantApayer', 'observations']
      case 'Recours':
        return ['numeroSinistre', 'police', 'client', 'dateDeSurvenance', 'dateDeDeclaration', 'victimeBeneficiaireAdversaire', 'evaluationInitiale', 'evaluationActuelle', 'montantDesPaiements', 'evaluationDesSommesRestantApayer', 'observations']
      case 'Client':
        return ['police', 'avenant', 'dateCreation', 'dureeContrat', 'client', 'dateEffet', 'branche', 'risque', 'limitesGarantie', 'primeNette', 'accessoire', 'taxe', 'primeTotale', 'commissions', 'etatPaiement', 'sinitreEnCours']
      default: 
        return ['dateCreation']
    }
    return [];
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  handleList(data: any, attestation=false) {
    this.isAttestation = attestation;
    let list = attestation ?
    data.map((row: any) => ({
      numeroPolice: parseInt(row?.police?.police?.split('/')?.[1]),
      client: `${row?.police?.client?.prenom} ${row?.police?.client?.nom} `,
      police: row?.police?.police,
      immatriculation: row?.police?.infoPolices?.find((info: any) => info?.cle?.toUpperCase() == 'IMMATRICULE' || info?.cle?.toUpperCase() == 'MATRICULE' || info?.cle?.toUpperCase() == 'IMMATRICULATION' || info?.cle?.toUpperCase() == 'N°IMMATRICULATIONCHASSIS')?.valeur,
      dateEffet: this.getDateFormated(row?.dateEffet, false), 
      dateEcheance: this.getDateFormated(row?.dateEcheance, false), 
    }))
    : data.map((row: any) => ({
      quittance: row?.numero,
      avenant: row?.avenantPolice?.numero,
      numeroClient: row?.police?.client?.code,
      dateCreation: row?.dateCreation,
      dureeContrat: this.calculateDiff(new Date(row?.dateEffet), new Date(row?.dateEcheance)),
      client: `${row?.police?.client?.prenom} ${row?.police?.client?.nom} `,
      dateEffet: this.getDateFormated(row?.dateEffet, false), 
      dateEcheance: this.getDateFormated(row?.dateEcheance, false), 
      police: row?.police?.police,
      branche: row?.police?.risque?.branche?.nom,
      risque: row?.police?.risque?.nom,
      limitesGarantie: this.limiteDeGarantie?.valeur ? this.limiteDeGarantie?.valeur : '--',
      primeNette: row?.primeNette,
      accessoire: row?.accessoire,
      taxe: row?.taxe,
      primeTotale: row?.primeTotale,
      etatPaiement: row?.paiements?.reduce((accumulator: number, paiement: any) => accumulator + (paiement?.accompte ? paiement?.accompte : 0), 0) > 0 ? row?.paiements?.reduce((accumulator: number, paiement: any) => accumulator + (paiement?.accompte ? paiement?.accompte : 0), 0) : row.primeTotale, // row?.paiements?.[0]?.type == 'IMPAYE' ? 'Arrieré' : (row?.paiements?.[0]?.type == 'ANNULATION' ? 'annulé' : 'Payé'),
      commissions: this.calculateCommissions(row),
      acompte: row?.paiements?.[0]?.accompte,
      arrieres: row?.paiements?.[0]?.restantApayer,
      etatDesArrieres: row?.primeTotale - (row?.paiements?.reduce((accumulator: number, paiement: any) => accumulator + (paiement?.accompte ? paiement?.accompte : 0), 0) > 0 ? row?.paiements?.reduce((accumulator: number, paiement: any) => accumulator + (paiement?.accompte ? paiement?.accompte : 0), 0) : row.primeTotale),
      soldeAreverser: row?.primeTotale, // row?.primeTotale - (row?.paiements?.reduce((accumulator: number, paiement: any) => accumulator + (paiement?.accompte ? paiement?.accompte : 0), 0) > 0 ? row?.paiements?.reduce((accumulator: number, paiement: any) => accumulator + (paiement?.accompte ? paiement?.accompte : 0), 0) : row.primeTotale),
      numeroSinistre: '', // TODO
      dateDeSurvenance: '', // TODO
      dateDeDeclaration: '', // TODO
      victimeBeneficiaireAdversaire: '', // TODO
      evaluationInitiale: '', // TODO
      evaluationActuelle: '', // TODO
      montantDesPaiements: '', // TODO
      evaluationDesSommesRestantApayer: '', // TODO
      sinitreEnCours: '---', // TODO
      observations: '', // TODO
    }));
    this.isTableLoading = false
    return list;
  }

  TREE_DATA: any[] = [
    {
      name: 'Fruit',
      children: [{ name: 'Apple' }, { name: 'Banana', disabled: true }, { name: 'Fruit loops' }]
    },
    {
      name: 'Vegetables',
      children: [
        {
          name: 'Green',
          children: [{ name: 'Broccoli' }, { name: 'Brussels sprouts' }]
        },
        {
          name: 'Orange',
          children: [{ name: 'Pumpkins' }, { name: 'Carrots' }]
        }
      ]
    }
  ];

  handleListBordereau(data: any) {
    const firstYear = 2021;
    var thisYear = (new Date()).getFullYear();
    var allYears: any = []
    while(thisYear >= firstYear) {
      allYears?.push(thisYear--);
    }
    let list = data.map((row: any) => ({
        name: `${row?.raisonSociale} (${row?.cloturesCount} clôture${row?.cloturesCount > 1 ? 's' : ''})`,
        children: allYears?.filter((_year: any) => row.clotures?.find((clot: any) => {
          const year = (new Date(clot.date)).getFullYear()
          return year == _year;
        }))?.map((onYear: any) => {
          return {
            name: onYear,
            children: row.clotures?.filter((clot: any) => {
              const year = (new Date(clot.date)).getFullYear()
              return year == onYear;
            })?.map((cloture: any) => {
              return {
                name: `Clôture du ${this.getDateFormated(cloture?.date)} / ref : ${cloture?.reference}`,
                id: cloture?.id
              }
            })
          }
        })
      }
    ));
    // this.dataSource?.setData(list);
    return list;
  }

  calculateCommissions(quittance: any) {
    const accessoire = this.accessoires.find((access: any) => access?.compagnie?.id == quittance?.police?.compagnie?.id);
    if (!accessoire) {
      console.log(quittance)
      alert('Attention! les calcules de commissions sont incorrectes. Merci de reseigner toutes les commissions des compagnies')
    }
    return Math.floor(((quittance?.primeNette * accessoire?.tauxCommissionPrime / 100) + (quittance?.accessoire * accessoire?.tauxCommissionAccessoire / 100)))
  }

  initializeHeaders(headers: any = []) {
    this.bordereauParam.titre = `Bordereau ${(this.options[this.currentBordereau] == 'Encaissement' || this.options[this.currentBordereau] == 'Annulation') ? ("d'" + this.options[this.currentBordereau]?.toLowerCase()) : ("de " + this.options[this.currentBordereau]?.toLowerCase()) }`
    this.bordereauParam.fileName = `Bordereau ${(this.options[this.currentBordereau] == 'Encaissement' || this.options[this.currentBordereau] == 'Annulation') ? ("d'" + this.options[this.currentBordereau]?.toLowerCase()) : ("de " + this.options[this.currentBordereau]?.toLowerCase()) }`?.toLowerCase()
    this.headers = [
      {
        name: 'N°',
        attestation: true,
        code: 'numeroPolice',
      },
      {
        name: 'N° sinistre',
        code: 'numeroSinistre',
        actif: headers?.includes('numeroSinistre')
      },
      {
        name: 'N° qtce',
        code: 'quittance',
        actif: headers?.includes('quittance')
      },
      {
        name: "N° l'avenant",
        code: 'avenant',
        actif: headers?.includes('avenant')
      },
      {
        name: "N° Client",
        code: 'numeroClient',
        actif: headers?.includes('numeroClient')
      },
      {
        name: 'Souscription',
        code: 'dateCreation',
        actif: headers?.includes('dateCreation')
      },
      {
        name: 'Durée du contrat',
        code: 'dureeContrat',
        actif: headers?.includes('dureeContrat')
      },
      {
        name: (this.options[this.currentBordereau] == 'Sinistre') ? "Nom de l'assuré" : "ASSURES",
        code: 'client',
        attestation: true,
        actif: headers?.includes('client')
      },
      {
        name: 'N° police',
        code: 'police',
        attestation: true,
        actif: headers?.includes('police')
      },
      {
        name: 'Immatriculation',
        code: 'immatriculation',
        attestation: true,
      },
      {
        name: "Effet",
        code: 'dateEffet',
        attestation: true,
        actif: headers?.includes('dateEffet')
      },
      {
        name: "Echéance",
        code: 'dateEcheance',
        attestation: true,
        actif: headers?.includes('dateEcheance')
      },
      {
        name: "Date de survenance",
        code: 'dateDeSurvenance',
        actif: headers?.includes('dateDeSurvenance')
      },
      {
        name: "Date de déclaration",
        code: 'dateDeDeclaration',
        actif: headers?.includes('dateDeDeclaration')
      },
      {
        name: "Victime / bénéficiaire / adversaire",
        code: 'victimeBeneficiaireAdversaire',
        actif: headers?.includes('victimeBeneficiaireAdversaire')
      },
      {
        name: "Evaluation initiale",
        code: 'evaluationInitiale',
        actif: headers?.includes('evaluationInitiale')
      },
      {
        name: "Evaluation actuelle",
        code: 'evaluationActuelle',
        actif: headers?.includes('evaluationActuelle')
      },
      {
        name: 'Branche',
        code: 'branche',
        actif: headers?.includes('branche')
      },
      {
        name: this.options?.[this.currentBordereau] == 'Client' ? 'Risque assuré' : 'Risque',
        code: 'risque',
        actif: headers?.includes('risque')
      },
      {
        name: 'Limites de garantie',
        code: 'limitesGarantie',
        actif: headers?.includes('limitesGarantie')
      },
      {
        name: 'Prime nette',
        code: 'primeNette',
        actif: headers?.includes('primeNette')
      },
      {
        name: 'Accessoires',
        code: 'accessoire',
        actif: headers?.includes('accessoire')
      },
      {
        name: 'Taxes',
        code: 'taxe',
        actif: headers?.includes('taxe')
      },
      {
        name: 'Prime totale',
        code: 'primeTotale',
        actif: headers?.includes('primeTotale')
      },
      {
        name: this.options[this.currentBordereau] == 'Sinistre' ? 'Montant des paiements' : 'Montant des recours reçus',
        code: 'montantDesPaiements',
        actif: headers?.includes('montantDesPaiements')
      },
      {
        name: this.options[this.currentBordereau] == 'Sinistre' ? 'Evaluation des sommes restant à payer' : 'Evaluation du recours restant à recevoir',
        code: 'evaluationDesSommesRestantApayer',
        actif: headers?.includes('evaluationDesSommesRestantApayer')
      },
      {
        name: 'Commissions',
        code: 'commissions',
        actif: headers?.includes('commissions')
      },
      {
        name: 'Acompte',
        code: 'acompte',
        actif: headers?.includes('acompte')
      },
      {
        name: 'Arriérés',
        code: 'arrieres',
        actif: headers?.includes('arrieres')
      },
      {
        name: 'Solde à reverser',
        code: 'soldeAreverser',
        actif: headers?.includes('soldeAreverser')
      },
      {
        name: 'Etat du paiement',
        code: 'etatPaiement',
        actif: headers?.includes('etatPaiement')
      },
      {
        name: 'Sinistres en cours',
        code: 'sinitreEnCours',
        actif: headers?.includes('sinitreEnCours')
      },
      {
        name: 'Etat des arriérés',
        code: 'etatDesArrieres',
        actif: headers?.includes('etatDesArrieres')
      },
      {
        name: 'Observations',
        code: 'observations',
        actif: headers?.includes('observations')
      },
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  listOfSelection = [
    {
      text: 'Sélectionner toutes les lignes',
      onSelect: () => {
        this.onAllChecked(true);
      }
    },
    {
      text: 'Sélectionner les lignes impaires',
      onSelect: () => {
        this.listOfCurrentPageData.forEach((data, index) => this.updateCheckedSet(data.id, index % 2 !== 0));
        this.refreshCheckedStatus();
      }
    },
    {
      text: 'Sélectionner les lignes paires',
      onSelect: () => {
        this.listOfCurrentPageData.forEach((data, index) => this.updateCheckedSet(data.id, index % 2 === 0));
        this.refreshCheckedStatus();
      }
    }
  ];

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
      this.listOfDataChecked = [
        ...this.listOfDataChecked,
        id
      ];
    } else {
      this.setOfCheckedId.delete(id);
      this.listOfDataChecked = this.listOfDataChecked?.filter((checkedId: any) => checkedId != id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly any[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  expandSet = new Set<number>();

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

}
