<!-- row -->
<div class="container-fluid">
    <div class="form-head d-flex mb-sm-4 mb-3 margin-left-right-35">
        <div class="mr-auto">
            <h1 class="text-black font-w600">{{isListBordereauxActived ? 'Clôture' : 'Bordereau'}}</h1>
        </div>
        <div *ngIf="!isListBordereauxActived">
            <a *ngIf="dataService.enableForRoles(['SUPER_ADMIN', 'ADMIN', 'SUPERVISEUR'])" (click)="isListBordereauxActived = true" class="btn btn-primary mr-2"><i class="fa fa-list scale5 mr-3"></i>Mes clôtures</a>
            <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzDropdownMenu]="colonne" class="btn btn-outline-primary mr-2"><i class="fa fa-plus scale5 mr-3"></i>{{headersActif()}} {{headersActif() <= 1 ? 'colonne' : 'colonnes'}}</a>

            <!-- <a (click)="export()" href="javascript:void(0)" class="btn btn-primary mr-3">
                Exporter
                <span class="btn-icon-right"><i class="fa fa-download"></i></span>
            </a> -->
            <!-- <a nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzDropdownMenu]="bordereau" class="btn btn-primary ml-2"><i class="fa fa-download scale5 mr-3 ml-2"></i>Bordereau</a> -->
        </div>
        <div *ngIf="isListBordereauxActived">
            <a (click)="isListBordereauxActived = false" class="btn btn-outline-primary mr-2"><i class="fa fa-plus scale5 mr-3"></i>Ajouter un bordereau</a>
        </div>
    </div>
    
    <div *ngIf="!isListBordereauxActived">
        <div class="filter-container">
            <nz-card style="margin-left: 1.875; margin-right: 1.875;" nzTitle="Filtres" [nzExtra]="extraTemplate">
                <nz-card-tab>
                  <nz-tabset nzSize="large" [(nzSelectedIndex)]="filterIndex">
                    <nz-tab nzTitle="Dates"></nz-tab>
                    <nz-tab nzTitle="Compagnie"></nz-tab>
                    <nz-tab nzTitle="Branche & Risque"></nz-tab>
                    <nz-tab nzTitle="Client"></nz-tab>
                    <nz-tab nzTitle="Apporteur"></nz-tab>
                    <nz-tab nzTitle="Prime Nette, Accessoires & Taxe"></nz-tab>
                  </nz-tabset>
                </nz-card-tab>
                <div class="filter-body">
                    <div *ngIf="filterIndex == 0">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Date de création / Période</label>
                                <br>
                                <nz-range-picker (nzOnCalendarChange)="handleFilter()" [(ngModel)]="bordereauParam.dateCreation" [nzFormat]="'dd-MM-yyyy'" [nzSize]="'large'"></nz-range-picker>
                            </div>
                            <div class="col-md-4">
                                <label>Date d'effet</label>
                                <br>
                                <nz-range-picker (nzOnCalendarChange)="handleFilter()" [(ngModel)]="bordereauParam.dateEffet" [nzFormat]="'dd-MM-yyyy'" [nzSize]="'large'"></nz-range-picker>
                            </div>
                            <div class="col-md-4">
                                <label>Date d'echéance</label>
                                <br>
                                <nz-range-picker (nzOnCalendarChange)="handleFilter()" [(ngModel)]="bordereauParam.dateEcheance" [nzFormat]="'dd-MM-yyyy'" [nzSize]="'large'"></nz-range-picker>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="filterIndex == 1">
                        <label>Liste des compagnies concernées</label>
                        <nz-select (ngModelChange)="handleFilter()" [nzAllowClear]="true" class="form-control select-multiple" nzMode="tags" nzPlaceHolder="Par défaut c'est toutes les compagnies" [(ngModel)]="bordereauParam.compagnies">
                            <!-- <nz-option [nzLabel]="'Toutes les compagnies'" [nzValue]="'*'"></nz-option> -->
                            <nz-option *ngFor="let option of compagnies" [nzDisabled]="bordereauParam?.compagnies?.includes('*')" [nzLabel]="option.raisonSociale" [nzValue]="option.id"></nz-option>
                        </nz-select>
                    </div>
                    <div *ngIf="filterIndex == 2">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Liste des branches concernées</label>
                                <nz-select (ngModelChange)="brancheChanged($event)" [nzAllowClear]="true" class="form-control select-multiple" nzMode="tags" nzPlaceHolder="Par défaut c'est toutes les branches" [(ngModel)]="bordereauParam.branches">
                                    <!-- <nz-option [nzLabel]="'Toutes les branches'" [nzValue]="'*'"></nz-option> -->
                                    <nz-option *ngFor="let option of branches" [nzDisabled]="bordereauParam?.branches?.includes('*')" [nzLabel]="option.nom" [nzValue]="option.id"></nz-option>
                                </nz-select>
                            </div>
                            <div class="col-md-6">
                                <label>Liste des risques concernés</label>
                                <nz-select (ngModelChange)="handleFilter()" [nzAllowClear]="true" class="form-control select-multiple" nzMode="tags" nzPlaceHolder="Par défaut c'est tous les risques" [(ngModel)]="bordereauParam.risques">
                                    <!-- <nz-option [nzLabel]="'Toutes les risques'" [nzValue]="'*'"></nz-option> -->
                                    <nz-option *ngFor="let option of risques" [nzDisabled]="bordereauParam?.risques?.includes('*')" [nzLabel]="option.nom" [nzValue]="option.id"></nz-option>
                                </nz-select>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="filterIndex == 3">
                        <label>Liste des clients concernés</label>
                        <nz-select (ngModelChange)="handleFilter('client', $event)" [nzAllowClear]="true" [nzLoading]="true" (nzOnSearch)="getAllClientBySearch($event)" class="form-control select-multiple" nzMode="tags" nzPlaceHolder="Par défaut c'est tous les clients" [(ngModel)]="bordereauParam.clients">
                            <!-- <nz-option [nzLabel]="'Toutes les clients'" [nzValue]="'*'"></nz-option> -->
                            <nz-option *ngFor="let option of clients" [nzDisabled]="bordereauParam?.clients?.includes('*')" [nzLabel]="option.code + ' - ' + option.prenom + ' ' + option.nom + ' - ' + (option.contact?.[0] ? option.contact?.[0] : '')" [nzValue]="option.id"></nz-option>
                        </nz-select>
                    </div>
                    <div *ngIf="filterIndex == 4">
                        <label>Liste des apporteurs concernés</label>
                        <nz-select (ngModelChange)="handleFilter()" [nzAllowClear]="true" class="form-control select-multiple" nzMode="tags" nzPlaceHolder="Par défaut c'est tous les apporteurs" [(ngModel)]="bordereauParam.apporteurs">
                            <!-- <nz-option [nzLabel]="'Toutes les compagnies'" [nzValue]="'*'"></nz-option> -->
                            <nz-option *ngFor="let option of apporteurs" [nzDisabled]="bordereauParam?.apporteurs?.includes('*')" [nzLabel]="option?.prenom + ' ' + option?.nom" [nzValue]="option.id"></nz-option>
                        </nz-select>
                    </div>
                    <div *ngIf="filterIndex == 5">
                        <div class="row">
                            <div class="col-md-3 margin-left-right-4-percent" *ngIf="high.primeNette"> 
                                <label>Prime nette</label>
                                <nz-slider (ngModelChange)="handleFilter()" [nzStep]="5000" [nzMin]="low.primeNette" [nzMax]="high.primeNette" [nzMarks]="marksNettePrime" nzIncluded nzRange [(ngModel)]="bordereauParam.primeNette"></nz-slider>
                            </div>
                            <div class="col-md-3 margin-left-right-4-percent" *ngIf="high.accessoire"> 
                                <label>Accessoire</label>
                                <nz-slider (ngModelChange)="handleFilter()" [nzStep]="1000" [nzMin]="low.accessoire" [nzMax]="high.accessoire" [nzMarks]="marksAccessoire" nzIncluded nzRange [(ngModel)]="bordereauParam.accessoire"></nz-slider>
                            </div>
                            <div class="col-md-3 margin-left-right-4-percent" *ngIf="high.taxe"> 
                                <label>Taxe</label>
                                <nz-slider (ngModelChange)="handleFilter()" [nzStep]="1000" [nzMin]="low.taxe" [nzMax]="high.taxe" [nzMarks]="marksTaxe" nzIncluded nzRange [(ngModel)]="bordereauParam.taxe"></nz-slider>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
    
        <ng-template #extraTemplate>
            <a nz-dropdown (click)="getQuittances()" class="btn btn-primar mr-3" style="color: #016b88;"><i class="fa fa-check scale5"></i> Valider filtre</a>
            <a nz-dropdown (click)="showModal()" class="btn btn-primar mr-3" style="color: #016b88;"><i class="fa fa-search scale5"></i> Eddition</a>
            <a (click)="reinitFilters()" style="color: #016b88;">Tout Réinitialiser</a>
        </ng-template>
    
        <!-- Add Order -->
        <div >
            <nz-card id="dataExport" [nzBordered]="false" nzTitle="Prévisualisation" [nzExtra]="extraBordereau">
                <div class="eliminate-padding" style="max-height: 340px; overflow-y: scroll;">
                    <hot-table
                    [data]="dataset"
                    [settings]="hotSettings"
                    licenseKey="non-commercial-and-evaluation">
                        <ng-container *ngFor="let header of headers">
                            <hot-column *ngIf="header?.actif" [data]="header?.code" [readOnly]="true" [title]="header.name"></hot-column>
                        </ng-container>
                    </hot-table>
                </div>
                <nz-ribbon [nzText]="totalCount + (totalCount > 1 ? ' résultats' : ' résultat')">
                    <nz-card nzTitle="Total" nzSize="small"> 
                        <div nz-row [nzGutter]="16" nzJustify="end">
                            <div class="gutter-row" nz-col [nzSpan]="19">
                                <div nz-row [nzGutter]="16" nzJustify="start">
                                    <div *ngFor="let statistic of statistics" nz-col class="gutter-row pt-2" [nzSpan]="3">
                                        <!-- <nz-statistic [nzValue]="statistic?.valeur | number:'':'fr-FR'" [nzTitle]="statistic?.titre"></nz-statistic> -->
                                        <h6 class="stat-title">{{statistic?.titre}}</h6>
                                        <h4 class="stat-value">{{statistic?.valeur | number:'':'fr-FR'}}</h4>
                                    </div>
                                </div>
                            </div>
                            <div nz-col class="gutter-row" [nzSpan]="5">
                                <div class="ant-pagination ant-table-pagination ant-table-pagination-right ng-star-inserted">
                                    <nz-pagination 
                                        class="mr-2" 
                                        [nzPageIndex]="currentPage" 
                                        [nzPageSize]="pageCount"
                                        [nzTotal]="totalCount"
                                        (nzPageIndexChange)="setTableUpdate($event)"
                                    ></nz-pagination>
                                </div>
                            </div>
                        </div>
                    </nz-card>
                </nz-ribbon>
            </nz-card>
        </div>
    
        <ng-template #extraBordereau>
            <nz-segmented [(ngModel)]="currentBordereau" [nzOptions]="options" (nzValueChange)="handleIndexChange($event)"></nz-segmented>
        </ng-template>
    </div>

    
    <nz-dropdown-menu #colonne="nzDropdownMenu">
        <ul nz-menu class="column">
            <div class="margin-auto width-90 mt-2 mb-3">
                <input (keyup)="searchColumn()" [(ngModel)]="searchColumnText" type="text" class="form-control" nz-input placeholder="Rechercher une colonne" />
            </div>
          <li *ngFor="let header of headers; let i = index" nz-menu-item>
            <ng-container *ngIf="header?.type != 'action'">
                <label [disabled]="headersActif() <= 1 && header?.actif" nz-checkbox (ngModelChange)="handleColumnChanged()" [(ngModel)]="headers[i].actif">{{header?.name}}</label>
            </ng-container>
          </li>
        </ul>
    </nz-dropdown-menu>

    

    <nz-dropdown-menu #bordereau="nzDropdownMenu">
        <nz-card style="width: 100%; max-height: 80vh;">
            <nz-card-tab>
              <nz-tabset nzSize="large" [(nzSelectedIndex)]="indexBordereau">
                <nz-tab nzTitle="Parametrage" ></nz-tab>
                <nz-tab [nzDisabled]="true" nzTitle="Résumé"></nz-tab>
                <nz-tab [nzDisabled]="true" *ngIf="bordereauParam.cloturer" nzTitle="Cloturer le bordereau"></nz-tab>
              </nz-tabset>
            </nz-card-tab>
            <div *ngIf="indexBordereau == 0" style="width: 512px;" class="bordereau-form">
                <h5>Paramétrons votre bordereau</h5>
                <form style="overflow-y: scroll; max-height: 54vh;">
                    <div class="form-group">
                        <label for="bordereau">Titre</label>
                        <input name="titre" [(ngModel)]="bordereauParam.titre" type="text" class="form-control" placeholder="Titre du bordereau"> 
                    </div>
                    <div class="form-group">
                        <label for="bordereau">Nom du fichier</label>
                        <input name="fileName" [(ngModel)]="bordereauParam.fileName" type="text" class="form-control" placeholder="Titre du bordereau"> 
                    </div>
                    <div class="form-group">
                        <nz-switch name="sendInfoactive" [(ngModel)]="bordereauParam.emvoyerEmail"></nz-switch> Envoyer par email
                    </div>
                    <div *ngIf="bordereauParam.emvoyerEmail">
                        <div class="form-group">
                            <label for="bordereau">Objet du mail</label>
                            <input [(ngModel)]="bordereauParam.objet" name="mailobject" type="text" class="form-control" placeholder="Objet"> 
                        </div>
                        <div class="form-group">
                            <label for="bordereau">Contenu du mail</label>
                            <textarea [(ngModel)]="bordereauParam.contenu" rows="4" nz-input name="mailcontain" class="form-control" placeholder="Contenu"></textarea>
                        </div>
                        <app-table-type
                            [table]="destinataires" 
                            (onTableEvent)="getTableValues($event)"
                        ></app-table-type>
                    </div>
                </form>
            </div>

            <div *ngIf="indexBordereau == 1" style="width: 512px; max-height: 59vh; overflow-y: scroll;" class="bordereau-form">
                <h6>Résumé du bordereau</h6>
                <nz-divider nzText="Info bordereau"></nz-divider>
                <nz-timeline>
                    <nz-timeline-item>Titre: <strong>{{bordereauParam?.titre}}</strong></nz-timeline-item>
                    <nz-timeline-item>Nom du fichier : <strong>{{bordereauParam?.fileName}}.xlsx</strong></nz-timeline-item>
                    <nz-timeline-item><strong>{{bordereauParam?.emvoyerEmail ? 'Le borderau sera envoyé par email' : 'Le borderau sera juste téléchargé'}}</strong></nz-timeline-item>
                </nz-timeline>
                 
                <nz-divider nzText="Liste des compagnies"></nz-divider>
                <nz-timeline>
                    <nz-timeline-item *ngIf="!bordereauParam?.compagnies?.length">Toutes les compagnies sont concernées</nz-timeline-item>
                    <nz-timeline-item *ngFor="let compagnie of getLabelByIds(compagnies, bordereauParam?.compagnies)">{{compagnie?.raisonSociale}}</nz-timeline-item>
                </nz-timeline>
                 
                <nz-divider nzText="Liste des branches"></nz-divider>
                <nz-timeline>
                    <nz-timeline-item *ngIf="!bordereauParam?.branches?.length">Toutes les branches sont concernées</nz-timeline-item>
                    <nz-timeline-item *ngFor="let branche of getLabelByIds(branches, bordereauParam?.branches)">{{branche?.nom}}</nz-timeline-item>
                </nz-timeline>
                 
                <nz-divider nzText="Liste des risques"></nz-divider>
                <nz-timeline>
                    <nz-timeline-item *ngIf="!bordereauParam?.risques?.length">Tous les risques sont concernés</nz-timeline-item>
                    <nz-timeline-item *ngFor="let risque of getLabelByIds(risques, bordereauParam?.risques)">{{risque?.nom}}</nz-timeline-item>
                </nz-timeline>
                 
                <nz-divider nzText="Liste des clients"></nz-divider>
                <nz-timeline>
                    <nz-timeline-item *ngIf="!bordereauParam?.clients?.length">Tous les clients sont concernés</nz-timeline-item>
                    <nz-timeline-item *ngFor="let client of getLabelByIds(clientSelected, bordereauParam?.clients)">{{client?.code}} - {{client?.prenom}} {{client?.nom}}{{client?.contact?.[0] ? (' - ' + client?.contact?.[0]) : ''}}</nz-timeline-item>
                </nz-timeline>
                 
                <nz-divider nzText="Fitre des dates"></nz-divider>
                <nz-timeline>
                    <nz-timeline-item *ngIf="!(bordereauParam?.derniereDateModif?.length || bordereauParam?.dateCreation?.length || bordereauParam?.derniereEffet?.length || bordereauParam?.dateEcheance?.length)">Toutes les dates sont concernées</nz-timeline-item>
                    <nz-timeline-item *ngIf="bordereauParam?.dateCreation?.length == 2">Date création: {{bordereauParam?.dateCreation?.[0] | date: 'dd/MM/yyyy'}} - {{bordereauParam?.dateCreation?.[1] | date: 'dd/MM/yyyy'}}</nz-timeline-item>
                    <nz-timeline-item *ngIf="bordereauParam?.dateEffet?.length == 2">Date création: {{bordereauParam?.dateEffet?.[0] | date: 'dd/MM/yyyy'}} - {{bordereauParam?.dateEffet?.[1] | date: 'dd/MM/yyyy'}}</nz-timeline-item>
                    <nz-timeline-item *ngIf="bordereauParam?.dateEcheance?.length == 2">Date création: {{bordereauParam?.dateEcheance?.[0] | date: 'dd/MM/yyyy'}} - {{bordereauParam?.dateEcheance?.[1] | date: 'dd/MM/yyyy'}}</nz-timeline-item>
                    <nz-timeline-item *ngIf="bordereauParam?.derniereDateModif?.length == 2">Derniére modif: {{bordereauParam?.derniereDateModif?.[0] | date: 'dd/MM/yyyy'}} - {{bordereauParam?.derniereDateModif?.[1] | date: 'dd/MM/yyyy'}}</nz-timeline-item>
                </nz-timeline>

                <nz-divider *ngIf="bordereauParam?.emvoyerEmail" nzText="Résumé du mail"></nz-divider>
                <div *ngIf="bordereauParam?.emvoyerEmail" class="media mail-container">
                    <div class="media-body"><span class="pull-right">{{getDate() | date: 'dd/MM/yyyy, à HH:mm'}}</span>
                        <h5 class="my-1 text-primary">{{bordereauParam?.objet}}</h5>
                        <p class="read-content-email">
                            À: <span *ngFor="let destinataire of bordereauParam?.destinataires; let i=index">{{i != 0 ? (', ' + destinataire) : destinataire }}</span></p>
                    </div>
                </div>
                <div *ngIf="bordereauParam?.emvoyerEmail" class="read-content-body">
                    <p>
                        {{bordereauParam?.contenu}}
                    </p>
                    <hr>
                </div>
                <div *ngIf="bordereauParam?.emvoyerEmail" class="read-content-attachment">
                    <h6><i class="fa fa-download mb-2"></i> Piéce jointe
                        <span>(1)</span></h6>
                    <div class="row attachment">
                        <div class="col-auto">
                            <a href="javascript:void()" class="text-muted">{{bordereauParam?.fileName}}.xlsx</a>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <form style="overflow-y: scroll; max-height: 58vh;">
                    <div class="form-group">
                        <nz-switch name="isToClose" [(ngModel)]="bordereauParam.cloturer"></nz-switch> Cloturer les quittances
                    </div>
                </form>
            </div>

            <div *ngIf="indexBordereau == 2" style="width: 512px;" class="bordereau-form">
                <h5>Clôturer le bordereau</h5>
                <form style="overflow-y: scroll; max-height: 58vh;">
                    <app-payment (onPayementFinished)="onPayementFinished($event)" (onPaymentStatus)="onPaymentStatus($event)" [totalApayer]="primeTotal" *ngIf="bordereauParam.cloturer"></app-payment>
                </form>
            </div>

            <div class="card-header chat-list-header text-center">
                <div class="margin-auto float-right__ d-flex bd-highlight center-block items-center">
                    <button *ngIf="indexBordereau != 0" (click)="indexBordereau = indexBordereau - 1" type="button" class="btn btn-light mr-3">
                        Retour
                        <span class="btn-icon-left"><i class="fa fa-arrow-left"></i></span>
                    </button>

                    <button [disabled]="!(bordereauParam.titre && bordereauParam.fileName && ((!bordereauParam.emvoyerEmail) || (bordereauParam.emvoyerEmail && bordereauParam.objet && bordereauParam.contenu && bordereauParam?.destinataires?.length)))" *ngIf="indexBordereau == 0" (click)="indexBordereau = 1" nz-button nzType="primary"  type="submit" class="btn btn-primary">
                        Voir le résumé
                        <span class="btn-icon-right"><i class="fa fa-arrow-right"></i></span>
                    </button>

                    <button *ngIf="indexBordereau == 1 && bordereauParam.cloturer" (click)="indexBordereau = 2" nz-button nzType="primary"  type="submit" class="btn btn-outline-primary mr-3">
                        Clôturer
                        <i class="fa fa-arrow-right"></i>
                    </button>

                    <button (click)="generateBordereau()" *ngIf="(indexBordereau == 1 && !bordereauParam.cloturer) || (indexBordereau == 2)" nz-button nzType="primary"  type="submit" class="btn btn-primary">
                        Valider
                        <span class="btn-icon-right"><i class="fa fa-download"></i></span>
                    </button>
                </div>
            </div>
          </nz-card>
    </nz-dropdown-menu>

    <div *ngIf="isListBordereauxActived">
        <app-table 
        [headers]="bordereauHeader" 
        [isTree]="true"
        [listOfData]="compagnieClotures"  
        [totalCount]="totalCountTable"
        [currentPage]="currentPageTable"
        (onTableUpdate)="onTableUpdate($event)"
        (onSearchTextChanged)="onSearchTextChanged($event)"
        [pageCount]="pageCountTable"
        [modelName]="modelName"
        title="Liste des clôtures"
        (onAction)="onAction($event)"
    >
    </app-table>
    </div>

    <nz-modal [nzWidth]="'98%'" [nzKeyboard]="false" [nzCloseOnNavigation]="false" [nzMaskClosable]="false" [(nzVisible)]="isPreviewVisibled" nzCloseIcon="">
      <ng-container *nzModalContent >
        <div id="invoice" #invoice>
            <div #screen class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="profile card pb-0">
                            <div class="profile-head">
                                <div class="profile-info">
									<!-- <div class="profile-photo">
										<img src="assets/images/compagnie.jpeg" class="img-fluid rounded-circle" alt="">
									</div> -->
                                    <h3>{{bordereauParam?.titre}}</h3>
                                    <br/>
									<div class="profile-details">
										<!-- <div class="profile-name pt-2">
											<h4 class="text-primary mb-0">{{currentCompagnie?.raisonSociale}}</h4>
											<p>{{currentCompagnie?.code}}</p>
										</div> -->
                                        <div class="profile-name pt-2" style="margin-right: 50px;">
											<h4 class="text-primary mb-0">{{currentCompagnie?.raisonSociale}}</h4>
											<p>{{currentCompagnie?.code}}</p>
										</div>
                                        <div class="profile-name pt-2" style="margin-right: 50px;">
											<h4 class="text-primary mb-0">Courtier</h4>
											<p>PRO ASSUR</p>
										</div>
										<div class="profile-email px-2 pt-2">
											<h4 class="text-muted mb-0">Période</h4>
											<p>Du {{getDateFormated(bordereauParam?.dateCreation?.[0], false)}} au {{getDateFormated(bordereauParam?.dateCreation?.[1], false)}}</p>
										</div>
										
									</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3>{{currentBranche?.nom ? currentBranche?.nom : 'TOUTES LES BRANCHES'}}</h3>
                <hot-table
                    *ngIf="!isAttestation"
                    [data]="datasetBordereau"
                    [settings]="hotSettings"
                    licenseKey="non-commercial-and-evaluation">
                    <ng-container *ngFor="let header of headers">
                        <hot-column *ngIf="header?.actif" [data]="header?.code" [readOnly]="true" [title]="header.name"></hot-column>
                    </ng-container>
                </hot-table>
                <hot-table
                    *ngIf="isAttestation"
                    [data]="datasetBordereau"
                    [settings]="hotSettings"
                    licenseKey="non-commercial-and-evaluation">
                    <ng-container *ngFor="let header of headers">
                        <hot-column *ngIf="header?.attestation" [data]="header?.code" [readOnly]="true" [title]="header.name"></hot-column>
                    </ng-container>
                </hot-table>
                <nz-ribbon *ngIf="!isAttestation" [nzText]="totalBordereauCount + (totalBordereauCount > 1 ? ' résultats' : ' résultat')">
                    <nz-card nzTitle="Total" nzSize="small"> 
                        <div nz-row [nzGutter]="16" nzJustify="end">
                            <div class="gutter-row" nz-col [nzSpan]="24">
                                <div nz-row [nzGutter]="16" nzJustify="start">
                                    <div *ngFor="let statistic of statisticsBorderaux" nz-col class="gutter-row pt-2" [nzSpan]="3">
                                        <!-- <nz-statistic [nzValue]="statistic?.valeur | number:'':'fr-FR'" [nzTitle]="statistic?.titre"></nz-statistic> -->
                                        <h5 class="stat-title">{{statistic?.titre}}</h5>
                                        <h4 class="stat-value">{{statistic?.valeur | number:'':'fr-FR'}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nz-card>
                </nz-ribbon>
                <div style="margin-top: -50px; margin-right: 20px; float: right; font-weight: 600;" class="dropdown ml-auto">
                    {{currentPageBordereaux}} / {{compagnieBorderauxTotalPagination - (compagnieBorderaux?.length * 2)}} pages
                </div>
            </div>
        </div>
      </ng-container>

      <div style="height: 30px;" *nzModalFooter>
        <div style="float: left;" class="dropdown ml-auto">
            <nz-pagination (nzPageIndexChange)="compagnieBordereauIndexChanged($event)" [nzPageIndex]="compagnieBordereauIndex" [nzPageSize]="1" [nzTotal]="compagnieBorderauxTotalPagination"></nz-pagination>
        </div>
        <div style="float: right;">
            <button class="ant-btn ng-star-inserted" nz-button nzType="default" (click)="handleCancel('preview')">Retour</button>
            <button class="ant-btn ant-btn-primary ng-star-inserted" nz-button nzType="primary" (click)="generatePDF()" >Télécharger</button>
            <button *ngIf="dataService.enableForRoles(['SUPER_ADMIN', 'ADMIN', 'SUPERVISEUR'])" class="ant-btn ant-btn-primary ng-star-inserted" nz-button nzType="primary" (click)="handleOk()" >Clôturer</button>
        </div>
      </div>
    </nz-modal>

    <nz-modal [nzWidth]="'98%'" [nzKeyboard]="false" [nzCloseOnNavigation]="false" [nzMaskClosable]="false" [(nzVisible)]="isClotureVisibled" [nzCancelText]="isClotureViewed ? 'Fermer' : 'Annuler la clôture'" [nzOkText]="isClotureViewed ? null : 'Valider la clôture'" (nzOnCancel)="handleCancel('cloture')" (nzOnOk)="handleCloture()">
        <ng-container *nzModalContent>
            <div>
                <div #screen class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="profile card pb-0">
                                <div class="profile-head">
                                    <div class="profile-info">
                                        <div class="profile-details row">
                                           <div class="col-md-12">
                                                <div class="profile-name pt-2">
                                                    <h4 class="text-primary mb-0">{{isClotureViewed ? currentCloture?.compagnie?.raisonSociale : currentCompagnie?.raisonSociale}}</h4>
                                                    <p class="p-cloture">{{isClotureViewed ? currentCloture?.compagnie?.code : currentCompagnie?.code}}</p>
                                                </div>
                                                <div class="profile-email pt-2">
                                                    <h4 class="text-muted mb-0">Période</h4>
                                                    <p class="p-cloture">Du {{getDateFormated(isClotureViewed ? currentCloture?.debut : bordereauParam?.dateCreation?.[0], false)}} au {{getDateFormated(isClotureViewed ? currentCloture?.fin : bordereauParam?.dateCreation?.[1], false)}}</p>
                                                    <p class="p-cloture" *ngIf="isClotureViewed">Ref : {{currentCloture?.reference}}</p>
                                                </div>
                                           </div>
                                            <div class="basic-form col-md-12">
                                                <form [formGroup]="chequeForm">
                                                    <div class="form-row">
                                                        <div class="form-group col-lg-4">
                                                            <label>Banque</label>
                                                            <input class="form-control" [readOnly]="isClotureViewed" formControlName="banque" name="banque" type="text" placeholder="Banque" [required]="true">
                                                        </div>
                                                        <div class="form-group col-lg-4">
                                                            <label>Numéro du chéque</label>
                                                            <input class="form-control" [readOnly]="isClotureViewed" formControlName="numeroCheque" name="numeroCheque" type="text" placeholder="Numéro du chéque" [required]="true">
                                                        </div>
                                                        <div class="form-group col-lg-4">
                                                            <label>Montant</label>
                                                            <input class="form-control" [readOnly]="isClotureViewed" formControlName="montantCheque" name="montantCheque" type="number" placeholder="Montant du chéque" [required]="true">
                                                        </div>
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                        <div>
                                            <nz-upload
                                                *ngIf="!isClotureViewed"
                                                nzType="drag"
                                                [nzMultiple]="false"
                                                (nzChange)="handlePreview($event)"
                                                >
                                                <div [ngStyle]="{'background-size': 'cover', backgroundImage: 'url(' + previewImage + ')'}">
                                                    <p class="ant-upload-drag-icon">
                                                        <span nz-icon nzType="inbox"></span>
                                                    </p>
                                                    <p class="ant-upload-text">Cliquer ou importer pour joindre votre chéque ici </p>
                                                    
                                                </div>
                                            </nz-upload>
                                            <!-- <img *ngIf="isClotureViewed" style="max-width: 100%;" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Ch%C3%A8que_postal_des_ann%C3%A9es_70.jpg/800px-Ch%C3%A8que_postal_des_ann%C3%A9es_70.jpg"  alt="Image du chéque"> -->
                                            <img *ngIf="isClotureViewed" style="max-width: 100%;" [src]="currentCloture?.chequeImage?.url"  alt="Image du chéque">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <nz-table
                    #rowSelectionTable
                    [nzData]="isClotureViewed ? currentCloture.quittances : listOfData"
                    [nzFrontPagination]="false"
                    (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
                  >
                    <thead>
                      <tr>
                        <th
                          *ngIf="!isClotureViewed"
                          [nzSelections]="listOfSelection"
                          [(nzChecked)]="checked"
                          [nzIndeterminate]="indeterminate"
                          (nzCheckedChange)="onAllChecked($event)"
                        ></th>
                        <th
                          *ngIf="isClotureViewed"
                        ></th>
                        <th>Quittance</th>
                        <th>Police</th>
                        <th>Echcéance</th>
                        <th>Client</th>
                        <!-- <th>Compagnie</th> -->
                        <th>Branche</th>
                        <th>Risque</th>
                        <th>Prime totale</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let data of rowSelectionTable.data">
                        <tr>
                            <td *ngIf="!isClotureViewed" [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)" [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)"></td>
                            <td *ngIf="isClotureViewed" [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)"></td>
                            <td>{{ data?.numero }}</td>
                            <td>{{ data?.police?.police }}</td>
                            <td>{{ data?.dateEcheance }}</td>
                            <td>{{ data?.police?.client?.code }} - {{ data?.police?.client?.prenom }} {{ data?.police?.client?.nom }}</td>
                            <!-- <td>{{ data?.police?.compagnie?.raisonSociale }}</td> -->
                            <td>{{ data?.police?.risque?.branche?.nom }}</td>
                            <td>{{ data?.police?.risque?.nom }}</td>
                            <td>{{ data?.primeTotale }} Fcfa</td>
                          </tr>
                          <tr [nzExpand]="expandSet.has(data.id)">
                            <div class="row">
                                <div *ngFor="let info of data?.police?.infoPolices" class="col-md-3">
                                    <i class="fa fa-circle text-success mr-1"></i>  <span >{{info?.libelle}} : {{ info?.valeur }}, <br></span>
                                </div>
                            </div>
                          </tr>
                      </ng-container>
                    </tbody>
                  </nz-table>
                </div>
            </div>
        </ng-container>
    </nz-modal>

    <div *ngIf="false"  class="pdf-margin">
        <div class="logo">
          <img src="assets/images/logo.png" class="img-fluid img-130" alt="">
        </div>
        <div class="destinataire">
          <span class="title-dordereau">Dakar, le 31 Octobre 2023</span> <br>
          Destinataire: <br>
          <h3>ALLIANZ Sénégal Assurances</h3>
          <span>Avenue Abdoulaye Fadiga X rue de Thann <br>
          BP. 2610 | Dakar</span>
        </div>

        <div class="emmetteur">
          N/Réf.: nnd 7440 - ALLIANZ - CC69 <br>
          Objet : transmission n°69 <br>
          AOUT - 2023 <br>
          Dossier sulvi par Yaye Diogop NDIAYE <br>
          <i class="fa fa-phone"></i> 33 825 50 50 - proassur@orange.sn
        </div>

        <div class="head">
          <h3>
              À L'ATTENTION DE MME CISSÉ AICHA DIÈYE, <br>
              RESPONSABLE COURTAGE
          </h3>
        </div>

        <div class="corps-text">
          Messieurs, <br>
          Nous vous transmettons ci-joint, pour la période du <strong>1er août 2023</strong> au <strong>31 août 2023</strong>:

          <div class="bordereau-resume">
              <div class="resume-item">
                  <i class="fa fa-check"></i> Le bordereau des règlements directs à l'ordre d'ALLIANZ;
              </div>
              <div class="resume-item">
                  <i class="fa fa-check"></i> Le bordereau et les pièces de production;
              </div>
              <div class="resume-item">
                  <i class="fa fa-check"></i> Le bordereau des attestations auto délivrées / annulées;
              </div>
              <div class="resume-item">
                  <i class="fa fa-check"></i> Le bordereau des sinistres et retour de pièces;
              </div>
              <div class="resume-item">
                  <i class="fa fa-check"></i> Le bordereau de retour de pièces de production signées (NÉANT);
              </div>
              <div class="resume-item">
                  <i class="fa fa-check"></i> Un chèque BGFIBANK N°0591669 d'un montant de F CFA 1.500.284, soldant le présent
                  arrêté :
              </div>

              <div class="">
                  <div class="recap-cheque">
                      <table class="table">
                          <tbody>
                              <tr>
                                  <td class="center">production PROASSUR sur AOUT 2023</td>
                                  <td class="right">14 487 938</td>
                              </tr>
                              <tr>
                                  <td class="center">règlements directs à l'ordre d'ALLIANZ</td>
                                  <td class="right">-12 987 654</td>
                              </tr>
                              <tr>
                                  <td class="center">A REVERSER</td>
                                  <td class="right">1 500 284</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>

              <div class="cheque-scan">
                  Nous vous prions d'agréer Messieurs, l'expression de nos sentiments distingués.
                  <br><br>
                  <img width="100%" src="https://fr-academic.com/pictures/frwiki/67/CanadianChequeSample.png" alt="">
              </div>
          </div>
        </div>
    </div>

</div>