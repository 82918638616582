<!-- row -->
<div class="container-fluid">
    <div class="form-head d-flex mb-sm-4 mb-3">
        <div class="mr-auto">
            <h1 class="text-black font-w600">Distributeur</h1>
        </div>
        <div>
            <a href="javascript:void(0)" class="btn btn-primary mr-3" data-toggle="modal" data-target="#addOrderModal">Ajouter distributeur</a>
            <a href="index.html" class="btn btn-outline-primary"><i class="las la-calendar-plus scale5 mr-3"></i>Filter Date</a>
        </div>
    </div>
    <!-- Add Order -->
    <app-table title="Liste des distributeurs"></app-table>
</div>