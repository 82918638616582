import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PoliceSanteComponent } from './police-sante.component';

const routes: Routes = [
  {
    path:'',
    component: PoliceSanteComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PoliceSanteRoutingModule { }
