<div class="row">
    <div class="col-xl-12">
        <div class="card details-card">
            <img *ngIf="selectedTypeFinancement?.domaine != 'SANTE'" src="assets/images/money-banner.png" alt="" class="bg-img">
            <div class="card-body">

                <label class="label-section">1 - Choisissez client/entreprise</label>

                <nz-segmented (nzValueChange)="segmentChaged($event)" [nzOptions]="tableTypes" [nzBlock]="true"></nz-segmented>

                <label *ngIf="!isParticulier" class="label-section">Choisissez l'entreprise</label>
                <div *ngIf="!isParticulier" nz-row style="border: 1px solid #31a0be;">
                    <div nz-col nzFlex="auto">
                        <nz-select [(ngModel)]="entrepriseId" (ngModelChange)="handleTypeClient(false)" nzShowSearch nzAllowClear nzPlaceHolder="Choississez l'entreprise" class="form-control default-select">
                            <nz-option *ngFor="let entreprise of entreprises" [nzLabel]="entreprise?.raisonSociale" [nzValue]="entreprise?.id"></nz-option>
                        </nz-select>
                    </div>
                    <!-- <div nz-col>
                        <button [ngClass]="{'btn': true, 'shadow': true, 'btn-outline-black': true}" style="height: 56px; width: 100%;">
                            Client particulier <nz-switch [(ngModel)]="isParticulier" (ngModelChange)="handleTypeClient($event)"></nz-switch>
                        </button>
                    </div> -->
                </div>

                <label class="label-section">2 - Choisissez un plan</label>

                <div class="flex mb-3 row no-wrap">
                    <div *ngFor="let planSante of planSantes" [ngStyle]="{'border-color': selectedPlanSante?.id == planSante?.id ? '#006b88' : '#006b8838'}" (click)="selectPlanSante(planSante)" class="img-card mb-sm-0 mb-3 col-md-4">	
                        <img [src]="planSante?.image?.url" alt="">
                        <span class="plan-taux">{{planSante?.tauxCouverture}}%</span>
                        <div [ngClass]="{'info': true, 'd-flex': true, 'align-items-center': true, 'p-md-3': true, 'p-2': true, 'bg-primary': (selectedPlanSante?.id == planSante?.id), 'bg-primary-opacity': (selectedPlanSante?.id != planSante?.id)}">
                            <svg class="mr-3 d-sm-inline-block d-none" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M28.75 12.5C28.7538 11.8116 28.568 11.1355 28.213 10.5458C27.8581 9.95597 27.3476 9.47527 26.7376 9.15632C26.1276 8.83737 25.4415 8.69248 24.7547 8.73752C24.0678 8.78257 23.4065 9.01581 22.8434 9.4117C22.2803 9.80758 21.837 10.3508 21.5621 10.9819C21.2872 11.613 21.1913 12.3076 21.2849 12.9896C21.3785 13.6715 21.6581 14.3146 22.0929 14.8482C22.5277 15.3819 23.101 15.7855 23.75 16.015V20C23.75 21.6576 23.0915 23.2473 21.9194 24.4194C20.7473 25.5915 19.1576 26.25 17.5 26.25C15.8424 26.25 14.2527 25.5915 13.0806 24.4194C11.9085 23.2473 11.25 21.6576 11.25 20V18.65C13.3301 18.3482 15.2323 17.3083 16.6092 15.7203C17.9861 14.1322 18.746 12.1019 18.75 10V2.5C18.75 2.16848 18.6183 1.85054 18.3839 1.61612C18.1495 1.3817 17.8315 1.25 17.5 1.25H13.75C13.4185 1.25 13.1005 1.3817 12.8661 1.61612C12.6317 1.85054 12.5 2.16848 12.5 2.5C12.5 2.83152 12.6317 3.14946 12.8661 3.38388C13.1005 3.6183 13.4185 3.75 13.75 3.75H16.25V10C16.25 11.6576 15.5915 13.2473 14.4194 14.4194C13.2473 15.5915 11.6576 16.25 10 16.25C8.34239 16.25 6.75268 15.5915 5.58058 14.4194C4.40848 13.2473 3.75 11.6576 3.75 10V3.75H6.25C6.58152 3.75 6.89946 3.6183 7.13388 3.38388C7.3683 3.14946 7.5 2.83152 7.5 2.5C7.5 2.16848 7.3683 1.85054 7.13388 1.61612C6.89946 1.3817 6.58152 1.25 6.25 1.25H2.5C2.16848 1.25 1.85054 1.3817 1.61612 1.61612C1.3817 1.85054 1.25 2.16848 1.25 2.5V10C1.25402 12.1019 2.01386 14.1322 3.3908 15.7203C4.76773 17.3083 6.6699 18.3482 8.75 18.65V20C8.75 22.3206 9.67187 24.5462 11.3128 26.1872C12.9538 27.8281 15.1794 28.75 17.5 28.75C19.8206 28.75 22.0462 27.8281 23.6872 26.1872C25.3281 24.5462 26.25 22.3206 26.25 20V16.015C26.9792 15.7599 27.6114 15.2848 28.0591 14.6552C28.5069 14.0256 28.7483 13.2726 28.75 12.5Z" fill="white"/>
                            </svg>
                            <div>
                                <p [ngClass]="{'fs-14': true, 'text-black': (selectedPlanSante?.id != planSante?.id), 'text-white': (selectedPlanSante?.id == planSante?.id) ,'op5': true, 'mb-1': true}">{{planSante?.nom}}</p>
                                <span [ngClass]="{'fs-18': true, 'text-black': (selectedPlanSante?.id != planSante?.id), 'text-white': (selectedPlanSante?.id == planSante?.id)}">{{planSante?.primeNette}} {{planSante.prorata ? '(' + planSante.prorataDays + 'j)' : ''}}</span> <br/>
                            </div>
                        </div>
                    </div>

                    <ng-lottie *ngIf="!planSantes?.length" class="margin-auto" [styles]="{height: '314px', margin: 'auto'}" [options]="optionsEmpty"></ng-lottie>

                    <!-- <nz-tabset>
                        <nz-tab *ngFor="let tab of outputs" [nzTitle]="tab?.cle">
                            <div class="card-info d-flex align-items-start">
                                <div class="mr-auto pr-3">
                                    <h2 class="font-w600 mb-2 text-black price-bottom">
                                        {{tab?.valeur ? tab?.valeur : (price ? price : '---')}} FCFA
                                    </h2>
                                    
                                </div>
                            </div>
                        </nz-tab>
                      </nz-tabset> -->
                      <div *ngIf="selectedTypeFinancement?.domaine != 'SANTE'" class="row no-wrap col-md-8">
                        <div *ngFor="let tab of outputs" class="col-xl-5 col-xxl-5 col-lg-5 col-md-5 col-sm-3">
                            <div class="card" (click)="selectedCompagnie = tab">
                                <div class="card-body" [ngStyle]="{'cursor': 'pointer', 'border-radius': '15px','border': selectedCompagnie?.cle == tab?.cle ? '2px solid rgb(1 107 136)' : '2px solid #f5f7ff', 'background':  '#f5f7ff'}">
                                    <div class="new-arrival-product">
                                        <div class="new-arrival-content text-center mt-3">
                                            <h4><a>{{tab?.cle}}</a></h4>
                                            <span class="price">{{tab?.valeur ? tab?.valeur : (price ? price : '---')}} F</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <h4 class="fs-20 text-black font-w600">Story About Disease</h4> -->


                <label class="label-section"> 3 - ajustez les bénéficiaires</label>

                <h5 style="float: right; margin-top: 31px;">Je charge un fichier de bénéficiaires <nz-switch [(ngModel)]="chargementEnMasse"></nz-switch></h5>
                 
                <input *ngIf="!isUploading" id="file" [hidden]="true" type="file" (change)="onFileChange($event)" multiple="false" />

                <div *ngIf="chargementEnMasse" class="svg-primary row" style="padding-top: 20px;">
                    <!-- <nz-upload
                        (click)="beginUpload()"
                        nzType="drag"
                        [nzMultiple]="false"
                        >
                        <p class="ant-upload-drag-icon">
                            <span nz-icon nzType="inbox"></span>
                        </p>
                        <p class="ant-upload-text">Charger votre fichier de bénéficiaires</p>
                        <p class="ant-upload-hint">
                            <img class="template" src="assets/images/template-famille.png" alt="">
                        </p>
                    </nz-upload> -->
                    <img (click)="beginUpload()" class="template" style="width: 100%; cursor: pointer;" src="assets/images/upload-beneficiaire.png" alt="">
                </div>

                <div *ngIf="!chargementEnMasse" class="row" style="padding-top: 20px;">
                    <div *ngFor="let qualite of qualites" class="col-lg-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div class="media pt-3 pb-3 qualite-container">
                            <ng-lottie [styles]="{height: '75px', borderRadius: '25px'}" [options]="qualite?.image"></ng-lottie>
                            <!-- <img src="https://static.vecteezy.com/system/resources/previews/016/721/625/original/cartoon-kids-boy-02-free-png.png" alt="image" class="mr-3 rounded" width="75"> -->
                            <div class="media-body">
                                <h5 class="m-b-5"><a class="text-black qualite-title">{{qualite?.nom}}</a></h5>
                                <div class="d-flex mb-20">
                                    <a (click)="handleQuantite(qualite, '-')" *ngIf="!qualite?.obligatoire" [ngStyle]="{'cursor': 'pointer'}" [ngClass]="{'btn': true, 'shadow': true, 'btn-xs': true, 'sharp': true, 'mr-1': true, 'btn-primary': true}">
                                        -
                                    </a>
                                    <a [ngStyle]="{fontWeight: 'bolder', color: 'black'}" [ngClass]="{'btn': true, 'shadow': true, 'btn-xs': true, 'sharp': true, 'mr-1': true}">
                                        {{qualite?.quantite}}
                                    </a>
                                    <a (click)="handleQuantite(qualite, '+')" *ngIf="!qualite?.obligatoire" [ngStyle]="{'cursor': 'pointer'}" [ngClass]="{'btn': true, 'shadow': true, 'btn-xs': true, 'sharp': true, 'mr-1': true, 'btn-primary': true}">
                                        <i [ngClass]="{'fa': true, 'fa-plus': true}"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>











                <div class="card" *ngIf="false">
                    <div class="card-header row">
                        <div class="col-md-6">
                            <h4 class="card-title">Critére de tarification</h4>
                        </div>
                        <div class="col-md-6">
                            <div class="align-right">
                                <button (click)="submitFormulaire()" class="btn btn-primary">Simuler</button>
                                <button nz-dropdown nzTrigger="click" [nzClickHide]="false" nzTrigger="click" [nzDropdownMenu]="colonne" class="btn btn-outline-primary">Garanties</button>
                            </div>
                        </div>
                    </div>
                    <nz-dropdown-menu #colonne="nzDropdownMenu">
                        <ul nz-menu>
                            <!-- <div class="margin-auto width-90 mt-2 mb-3">
                                <input (keyup)="searchColumn()" [(ngModel)]="searchColumnText" type="text" class="form-control" nz-input placeholder="Rechercher une colonne" />
                            </div> -->
                            <ng-container *ngFor="let garantie of selectedTypeFinancement?.risques?.[0]?.garanties; let i = index">
                                <li nz-menu-item *ngIf="garantie?.obligatoire">
                                    <label nz-checkbox [ngModel]="true" [nzDisabled]="garantie?.obligatoire" >{{garantie?.nom}}</label>
                                </li>
                            </ng-container>
                            <ng-container *ngFor="let garantie of selectedTypeFinancement?.risques?.[0]?.garanties; let i = index">
                                <li nz-menu-item *ngIf="!garantie?.obligatoire">
                                    <label nz-checkbox [(ngModel)]="garantie.checked">{{garantie?.nom}}</label>
                                </li>
                            </ng-container>
                        </ul>
                    </nz-dropdown-menu>
                    <div class="card-body">
                        <div class="basic-form">
                            <form [formGroup]="formulaireSimulation" (ngSubmit)="submitFormulaire()">
                                <div class="form-row">
                                    <div *ngFor="let champ of selectedTypeFinancement?.risques[0]?.champs; let i=index"  class="form-group col-md-4">
                                        <!-- Champ TEXT -->
                                        <label *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'TEXT' || champ?.typeChamp == 'NUMBER' || champ?.typeChamp == 'EMAIL' || champ?.typeChamp == 'TELEPHONE')">{{champ?.placeholder}}</label>
                                        <input *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'TEXT' || champ?.typeChamp == 'NUMBER' || champ?.typeChamp == 'EMAIL' || champ?.typeChamp == 'TELEPHONE')" class="form-control" [formControlName]="champ?.code" [name]="champ?.code" [type]="getAdequateType(champ?.typeChamp)" [placeholder]="champ?.placeholder" [required]="champ?.required">
                                        
                                        <!-- Champ Date -->
                                        <label *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'DATE')">{{champ?.placeholder}}</label>
                                        <input *ngIf="(champ?.typeReponse == 'WRITE') && (champ?.typeChamp == 'DATE')" [formControlName]="champ?.code" [name]="champ?.code" [type]="getAdequateType(champ?.typeChamp)" class="form-control" [placeholder]="champ?.placeholder" [required]="champ?.required">
    
                                        <!-- Champ Select -->
                                        <label *ngIf="champ?.typeReponse == 'OPTION'">{{champ?.placeholder}}</label>
                                        <select *ngIf="champ?.typeReponse == 'OPTION'" [formControlName]="champ?.code" [name]="champ?.code" id="inputState" class="form-control default-select">
                                            <option selected>Choisissez...</option>
                                            <option *ngFor="let option of champ?.options" [value]="option?.cle">{{option?.valeur}}</option>
                                        </select> 
                                        
                                        <!-- Champ Time Picker -->
                                        <nz-time-picker *ngIf="champ?.typeReponse == 'WRITE' && champ?.typeChamp == 'TIME'" [formControlName]="champ?.code" class="right-datepicker border-datepicker" [nzAutoFocus]="true" [nzDefaultOpenValue]="defaultOpenValue"></nz-time-picker> 

                                        <!-- champ Slider -->
                                        <nz-slider *ngIf="champ?.typeReponse == 'WRITE' && champ?.typeChamp == 'RANGE' && !champ?.errorAnswer && !champ?.answered" [formControlName]="champ?.code" class="slider-rigth" nzRange ></nz-slider>

                                        <div *ngIf="champ?.typeReponse == 'WRITE' && (champ?.typeChamp == 'IMAGE' || champ?.typeChamp == 'FICHIER') && !champ?.errorAnswer" class="clearfix">
                                            <nz-upload
                                            nzListType="picture"
                                            [formControlName]="champ?.code"
                                            (nzChange)="fileUploaded($event, champ)"
                                            [(nzFileList)]="fileList"
                                            >
                                            <button nz-button>
                                                <span nz-icon nzType="upload"></span>
                                                Upload
                                            </button>
                                            </nz-upload>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- <div class="form-row">
                                    <div class="col-md-4">
                                        <button type="submit" class="btn btn-primary">Simuler</button>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="mr-auto pr-3">
                                            <h2 class="font-w600 mb-2 text-black price-bottom">
                                                {{price ? price : '---'}} FCFA
                                            </h2>
                                        </div>
                                    </div>
                                </div> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>