
import { environment } from '../environments/environment';

export default {
    // baseUrl: "http://localhost:3000",
    baseUrl: environment?.baseUrl,
    // baseUrl: "https://cors-anywhere.herokuapp.com/https://api-dev.assurtech.org",
    apiUrl: "/api/graphql",
    imageUrl: "/images/",
    fileUrl: "/files/"
}