<div class="row" style="margin-left: 10px;">
    <div *ngFor="let typeFinancement of typeFinancements" class="col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-12" (click)="setTypeFinancementChanged(typeFinancement)">
        <div class="card" [ngStyle]="{border: '1px solid black', borderRadius: '25px', background: (selectedTypeFinancement?.id===typeFinancement?.id) ? '#6fd7d9' : '#f3f5f9'}">
            <div class="card-body">
                <div class="new-arrival-product" >
                    <div class="new-arrivals-img-contnent">
                        <ng-lottie [styles]="{height: '230px', borderRadius: '25px', background: (selectedTypeFinancement?.id===typeFinancement?.id) ? '#6fd7d9' : '#f3f5f9'}" [options]="typeFinancement?.image"></ng-lottie>
                    </div>
                    <div class="new-arrival-content text-center mt-3">
                        <h4><a>{{typeFinancement.nom}}</a></h4>
                        <!-- <span class="price"><i class="{{typeFinancement?.icon}}"></i></span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>