import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-employe',
  templateUrl: './employe.component.html',
  styleUrls: ['./employe.component.css']
})
export class EmployeComponent implements OnInit {

  emplacements: any =  ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  employes: any = [];
  entreprises: any = [];
  currentClient: any = {};

  headerInitialed : boolean = false;

  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'Client';
  isAddEmploye : boolean = true;

  searchText = '';

  constructor(
    private dataService: DataService,
    private message: NzMessageService
  ) {}

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter une assurance santé",
      titre: 'Assurance santé',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    },
  ]



  formulaires: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  currentEntrepId : string = '';



  initializeHeaders() {
    this.headers = [
      {
        name: 'Code',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Prénom',
        actif: true,
        position: 'left'
      },
      {
        name: 'Nom',
        actif: true,
        position: 'left',
      },
      {
        name: 'Email'
      },
      {
        name: 'Contact',
        actif: true
      },
      {
        name: 'Connexe'
      },
      {
        name: 'Adresse',
      },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        actif: true,
        position: 'right',
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  handleColumnChanged() {
    this.employes = this.getClients();
  }

  handleFilter() {
    // {
    //   entreprise: {
    //     id: {
    //       equals : "${this.currentEntrepId}"
    //     }
    //   }
    // }
    var _filter = `
    AND: [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    ]
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getClients();
  }

  ngOnInit(): void {
    // this.getEntreprises();
    // console.log(this.dataService.getUser()?.entreprise?.id);
    this.currentEntrepId = this.dataService.getUser()?.entreprise?.id;
    console.log(this.currentEntrepId);
    this.handleFilter();
    // this.getClients();


  }

  formulaireEvent(client: any) {

    const newContacts = client?.contact?.filter((contact: any) => !contact?.id);

    if (this.formType == 'ADD') {
      this.dataService.createClient(
        {
          ...client,
          newContacts
        },
        this.isAddEmploye

      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getClients();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    else {
      const removedContacts = this.currentClient?.contacts?.filter((contact: any) => !client?.contact?.map((cont: any) => cont?.id).includes(contact?.id));
      this.dataService.updateClient(
        this.currentId,
        {
          ...client,
          newContacts,
          removedContacts
        },
        this.isAddEmploye
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getClients();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getClients();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getClients();
  }

  getClients() {  

    // console.log(this.currentEntrepId);
    this.dataService.getClients(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        console.log(data?.data?.clients);
        this.employes = this.handleList(data?.data?.clients)
        this.totalCount = data?.data?.clientsCount;
        if (!this.headerInitialed) {
          this.initializeHeaders();
          this.headerInitialed = true;

        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getClients()
    })
  }

  handleList(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.code,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.prenom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.email
      },
     
      {
        type: 'LIST',
        value: row?.contacts?.map((contact: any) => contact?.contact)
      },
      {
        type: 'LIST',
        value: row?.connexe
      },
      {
        type: 'TEXT',
        value: row?.adresse
      },
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  

  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getClientById(action?.value);
      break;
      case 'delete':
        this.getClients();
      break;
    }
  }

  getClientById(id: string) {
    this.dataService.getClientById(id)
    .subscribe(
      (data: any) => {
        this.currentClient = data?.data?.client;
        this.initForm(data?.data?.client);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.initForm();
  }

  initForm(client: any = {}) {

    if (client?.code) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      {
        code: "plan",
        placeholder: "Plan",
        typeChamp: "PLAN",
        etape: 1,
        typeReponse: "PLAN",
        // defaultValue: client?.prenom,
        // obligatoire: !!(this.formType == 'ADD' || client?.prenom),
        size: 'col-md-12'
      },





      {
        code: "prenom",
        placeholder: "Prénom",
        typeChamp: "TEXT",
        etape: 2,
        typeReponse: "WRITE",
        defaultValue: client?.prenom,
        obligatoire: !!(this.formType == 'ADD' || client?.prenom),
        size: 'col-md-6'
      },
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        etape: 2,
        defaultValue: client?.nom,
        obligatoire: !!(this.formType == 'ADD' || client?.nom),
        size: 'col-md-6'
      },
      {
        code: "email",
        placeholder: "Adresse email",
        typeChamp: "EMAIL",
        typeReponse: "WRITE",
        etape: 2,
        defaultValue: client?.email,
        size: 'col-md-12'
      },
     
      {
        code: "adresse",
        placeholder: "Adresse",
        typeChamp: "TEXT",
        etape: 2,
        typeReponse: "WRITE",
        defaultValue: client?.adresse,
        size: 'col-md-12'
      },
      {
        code: "contact",
        placeholder: "Contact",
        inputPlaceholder: 'Téléphone..',
        typeChamp: "TABLE",
        etape: 2,
        typeReponse: "TABLE",
        values: [],
        columns: [
          {
            name: 'id',
            placeholder: 'Id',
            type: 'TEXT',
            hide: true,
          },
          {
            name: 'contact',
            placeholder: 'contact',
            type: 'TEXT'
          }
        ],
        defaultValue: client?.contacts,
        size: 'col-md-6'
      },
      
    ]
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
  }


}
