import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  emplacements: any =  ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  clients: any = [];
  entreprises: any = [];
  currentClient: any = {};

  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'Client';

  searchText = '';

  constructor(
    private dataService: DataService,
    private message: NzMessageService
  ) { }

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter un client",
      titre: 'Client',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    },
    {
      code: 'MANY',
      nom: 'Chargement en masse',
      titre: 'Client',
      soustitre: 'Chargement en masse des clients',
      type: 'CHARGEMENT',
      templateImage: 'assets/images/template-client.png'
    }
  ]

  formulaires: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  initializeHeaders() {
    this.headers = [
      {
        name: 'Code',
        position: 'left',
        actif: true,
        // sortOrder: null,
        // sortFn: (a: any, b: any) => a.name.localeCompare(b.name),
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Prénom',
        actif: true,
        position: 'left'
      },
      {
        name: 'Nom',
        actif: true,
        position: 'left',
      },
      {
        name: 'Email'
      },
      {
        name: 'Empla..',
        actif: false,
        // filterFn: (list: string[], item: any) => list.some(name => item.name.indexOf(name) !== -1)
      },
      {
        name: 'Entrep',
        filterMultiple: true,
        actif: true,
        dataFilter: [
          ...this.entreprises.map((entreprise: any) => {
            return { text: entreprise?.raisonSociale, value: entreprise?.id }
          }),
          { text: 'Particulier', value: 'Particulier' }
        ],
        // sortDirections: ['ascend', 'descend', null],
        filterFn: (list: string[], item: any) => {
          var oldFilter: string = '';
          if(this.filterArray?.map((fil: any) => fil.key)?.indexOf('entreprise') == -1) {
            this.filterArray.push({
              key: 'entreprise',
              value: ''
            })
          }
          else {
            const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('entreprise');
            oldFilter = this.filterArray[index].value;
          }

          const index = this.filterArray?.map((fil: any) => fil.key)?.indexOf('entreprise');


          if (!list.length) {
            
            this.filterArray = this.filterArray?.filter((fil: any) => fil.key != 'entreprise');
            this.handleFilter();
          }
          else {
            
            this.filterArray[index].value = `
            {
              OR: [
                {
                  entreprise: {
                    id: {
                      in: [${
                        list.filter((temp: any) => temp != "Particulier" )?.map((entreprise: any) => `"${entreprise}"` )
                      }]
                    }
                  }
                }
                ${
                  list?.includes('Particulier') ? 
                  `
                    {
                      entreprise: {
                        NOT: {
                          id: {
                            in: [${
                              this.entreprises.map((entreprise: any) => `"${entreprise?.id}"` )
                            }]
                          }
                        }
                      }
                    }
                  `
                  : 
                  ``
                }
              ]
            }
          `;
          if (oldFilter != this.filterArray[index].value) {
            this.handleFilter();
          }
          }
        }
      },
      {
        name: 'Contact',
        actif: true
      },
      {
        name: 'Connexe'
      },
      {
        name: 'Adresse',
      },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        actif: true,
        position: 'right',
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  handleColumnChanged() {
    this.clients = this.getClients();
  }

  handleFilter() {
    var _filter = `
    AND: [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    ]
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getClients();
  }

  ngOnInit(): void {
    this.getClients();
    this.getEntreprises();
  }

  formulaireEvent(client: any) {

    const newContacts = client?.contact?.filter((contact: any) => !contact?.id);

    if (this.formType == 'ADD') {
      this.dataService.createClient(
        {
          ...client,
          newContacts
        }
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getClients();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    else {
      const removedContacts = this.currentClient?.contacts?.filter((contact: any) => !client?.contact?.map((cont: any) => cont?.id).includes(contact?.id));
      this.dataService.updateClient(
        this.currentId,
        {
          ...client,
          newContacts,
          removedContacts
        }
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getClients();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getClients();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getClients();
  }

  getClients() {  
    this.dataService.getClients(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.clients = this.handleList(data?.data?.clients)
        this.totalCount = data?.data?.clientsCount;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getClients()
    })
  }

  handleList(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'TEXT',
        value: row?.code,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.prenom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.nom,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.email
      },
      {
        type: 'BOX',
        value: row?.emplacement
      },
      {
        type: 'TEXT',
        value: row?.entreprise?.raisonSociale ? row?.entreprise?.raisonSociale : 'Particulier'
      },
      {
        type: 'LIST',
        value: row?.contacts?.map((contact: any) => contact?.contact)
      },
      {
        type: 'LIST',
        value: row?.connexe
      },
      {
        type: 'TEXT',
        value: row?.adresse
      },
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  getEntreprises() {  
    this.dataService.getAllEntreprises()
    .subscribe(
      (data: any) => {
        this.entreprises = data?.data?.entreprises;
        this.initializeHeaders();
        let entreprise: any = this.formulaires.find((form: any) => form?.code == 'entreprise');
        entreprise.options = this.entreprises?.map((entrep: any) => {
          return {
            cle: entrep?.id,
            valeur: entrep?.raisonSociale
          }
        })
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getClientById(action?.value);
      break;
      case 'delete':
        this.getClients();
      break;
    }
  }

  getClientById(id: string) {
    this.dataService.getClientById(id)
    .subscribe(
      (data: any) => {
        this.currentClient = data?.data?.client;
        this.initForm(data?.data?.client);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.initForm();
  }

  initForm(client: any = {}) {
    if (client?.code) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      {
        code: "prenom",
        placeholder: "Prénom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        defaultValue: client?.prenom,
        obligatoire: !!(this.formType == 'ADD' || client?.prenom),
        size: 'col-md-6'
      },
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        etape: 1,
        defaultValue: client?.nom,
        obligatoire: !!(this.formType == 'ADD' || client?.nom),
        size: 'col-md-6'
      },
      {
        code: "email",
        placeholder: "Adresse email",
        typeChamp: "EMAIL",
        typeReponse: "WRITE",
        etape: 1,
        defaultValue: client?.email,
        size: 'col-md-12'
      },
      {
        code: "emplacement",
        placeholder: "Emplacement du dossier",
        typeChamp: "TEXT",
        typeReponse: "WRITE",
        etape: 1,
        defaultValue: client?.emplacement,
        size: 'col-md-6'
      },
      {
        code: "entreprise",
        placeholder: "Entreprise",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        options: this.entreprises?.map((entrep: any) => {
          return {
            cle: entrep?.id,
            valeur: entrep?.raisonSociale
          }
        }),
        defaultValue: client?.entreprise?.id,
        size: 'col-md-6'
      },
      {
        code: "adresse",
        placeholder: "Adresse",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        defaultValue: client?.adresse,
        size: 'col-md-12'
      },
      {
        code: "contact",
        placeholder: "Contact",
        inputPlaceholder: 'Téléphone..',
        typeChamp: "TABLE",
        etape: 2,
        typeReponse: "TABLE",
        values: [],
        columns: [
          {
            name: 'id',
            placeholder: 'Id',
            type: 'TEXT',
            hide: true,
          },
          {
            name: 'contact',
            placeholder: 'contact',
            type: 'TEXT'
          }
        ],
        defaultValue: client?.contacts,
        size: 'col-md-6'
      },
      {
        code: "connexe",
        placeholder: "Connexe",
        inputPlaceholder: 'Connexe..',
        typeChamp: "TABLE",
        etape: 2,
        typeReponse: "TABLE",
        values: [],
        defaultValue: client?.connexe,
        size: 'col-md-6'
      }
    ]
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
  }

}
