import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-forgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: ['./password-forgotten.component.css']
})
export class PasswordForgottenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
