import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-plan-sante',
  templateUrl: './plan-sante.component.html',
  styleUrls: ['./plan-sante.component.css']
})
export class PlanSanteComponent  implements OnInit {

  isFormOpened = false;
  currentPage: number = 1;
  totalCount: number = 1;
  pageCount: number = 5;
  filterArray: any = [];
  filter: string = '';
  planSantes: any = [];
  qualites: any = [];
  categorieActes: any = [];
  specialites: any = [];
  compagnies: any = [];
  entreprises: any = [];
  branches: any = [];
  risques: any = [];
  delaisCarences: any = [];
  previousData: any = {};
  headerInitialed = false;
  newImage = false;

  searchColumnText: string = '';

  currentId: string = '';

  formType: string = 'ADD';

  modelName: string = 'PlanSante';

  searchText = '';

  constructor(
    private dataService: DataService,
    private message: NzMessageService
  ) { }

  ngOnInit(): void {
    this.getPlanSantes();
    this.getAllQualites();
    this.getAllCategorieActes();
    this.getAllSpecialites();
    this.getCompagnies();
    this.getAllEntreprises();
    this.getAllBranches();
    this.getAllDelaisCarances();
  }
  

  formTab = [
    {
      code: 'ONE',
      nom: "Ajouter un plan santé",
      titre: 'Aporteur',
      soustitre: 'Veuillez remplir les champs ci dessous',
      type: 'AJOUT'
    }
  ]

  formulaires: any = [];

  headers: any = [];
  allHeaders: any = [];
  filteredHeader: any = [];

  initializeHeaders() {
    this.headers = [
      {
        name: 'Image',
        position: 'left',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Nom',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Taux',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Territorialité',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Forfaitaire',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Entreprises',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Branche',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Risque',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Garanties',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Bénéficiaires',
        actif: true,
        sortDirections: ['ascend', 'descend', null]
      },
      {
        name: 'Action',
        type: 'action',
        width: '10%',
        position: 'right',
        actif: true,
        actions: [
          {
            type: 'edit'
          },
          {
            type: 'delete'
          }
        ]
      }
    ];
    this.filteredHeader = this.headers;
    this.allHeaders = this.headers;
  }

  headersActif() {
    return this.filteredHeader?.filter((header: any) => header?.actif)?.length - 1;
  }

  handleFilter() {
    var _filter = `
    AND: [
    `;
    this.filterArray?.forEach((filter: any) => {
      _filter += filter?.value;
    });
    _filter += `
    ]
    `;
    this.filter = _filter;
    this.currentPage = 1;
    this.getPlanSantes();
  }
  
  getAllEntreprises() {
    this.dataService.getAllEntreprises().subscribe((data: any) => {
      this.entreprises = data?.data?.entreprises;
    });
  }

  getAllBranches() {
    this.dataService.getAllBranches('SANTE').subscribe(
      (data: any) => {
        this.branches = data?.data?.branches;
        // this.cdr.detectChanges();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllDelaisCarances() {
    this.dataService.getAllDelaisCarances().subscribe(
      (data: any) => {
        this.delaisCarences = data?.data?.delaisCarences;
        // this.cdr.detectChanges();
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  searchColumn() {
    this.headers = this.allHeaders?.filter((header: any) => header.name?.toLowerCase()?.includes(this.searchColumnText?.toLowerCase()));
  }

  handleColumnChanged() {
    this.planSantes = this.getPlanSantes();
  }

  formulaireEvent(data: any) {
    if (this.formType == 'ADD') {
      this.dataService.createPlanSante(
        data
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getPlanSantes();
          this.message.success(`Ajout ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de l'ajout`);
        },
      )
    }
    else {
      console.log(this.previousData?.entreprises, data?.entreprises, this.previousData?.entreprises?.filter((entrepriseId: any) => !data?.entreprises?.includes(entrepriseId)))
      this.dataService.updatePlanSante(
        this.currentId,
        {
          ...data,
          newImage: this.newImage,
          removedOptions: this.previousData?.options?.filter((option: any) => !data?.options?.find((op: any) => option?.id == op?.id) ),
          removedEntreprises: this.previousData?.entreprises?.filter((entreprise: any) => !data?.entreprises?.includes(entreprise?.id))?.map((entreprise: any) => entreprise?.id)
        }
      ).subscribe(
        (data: any) => {
          this.onCloseForm();
          this.currentPage = 1;
          this.getPlanSantes();
          this.message.success(`Modification ${this.modelName}`);
        },
        (err: any) => {
          console.log(err);
          this.message.error(`Une erreur est survenue lors de la modification`);
        },
      )
    }
  }

  getCompagnies() {  
    this.dataService.getAllCompagnies()
    .subscribe(
      (data: any) => {
        this.compagnies = data?.data?.compagnies;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onTableUpdate(value: any) {
    this.currentPage = value?.currentPage;
    this.pageCount = value?.pageCount;
    this.getPlanSantes();
  }

  onSearchTextChanged(value: string) {
    this.searchText = value;
    this.getPlanSantes();
  }

  getPlanSantes() {  
    this.dataService.getPlanSantes(this.currentPage, this.pageCount, this.filter, this.searchText)
    .subscribe(
      (data: any) => {
        this.planSantes = this.handleList(data?.data?.planSantes)
        this.totalCount = data?.data?.planSantesCount;
        if (!this.headerInitialed) {
          this.initializeHeaders();
          this.headerInitialed = true;
        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllQualites() {  
    this.dataService.getAllQualites()
    .subscribe(
      (data: any) => {
        this.qualites = data?.data?.qualites
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  onUploadEvent(upload: string) {
    setTimeout(() => {
      this.currentPage = 1;
      this.getPlanSantes()
    })
  }

  handleList(data: any) {
    let list = data.map((row: any) => ([
      {
        type: 'HTML',
        value: `<img width="80px" src="${row?.image?.url}"/>`,
        position: 'left',
      },
      {
        type: 'TEXT',
        value: row?.nom,
      },
      {
        type: 'TEXT',
        value: `${row?.tauxCouverture} %`
      },
      {
        type: 'TEXT',
        value: row?.territorialite,
      },
      {
        type: 'TEXT',
        value: row?.forfaitaire ? `${row?.prime} FCFA` : `---`,
      },
      {
        type: 'LIST',
        value: row?.entreprises?.map((entreprise: any) => entreprise?.raisonSociale)
      },
      {
        type: 'TEXT',
        value: row?.risque?.branche?.nom,
      },
      {
        type: 'TEXT',
        value: row?.risque?.nom,
      },
      {
        type: 'LIST',
        value: row?.options?.length ? row?.options?.map((option: any) => option?.nom) : ['---']
      },
      {
        type: 'LIST',
        value: row?.qualitePlans?.length ? row?.qualitePlans?.map((qualitePlan: any) => qualitePlan?.qualite?.nom) : ['---']
      },
      {
        type: 'ACTION',
        position: 'right',
        value: [
          {
            type: 'edit',
            value: row?.id
          },
          {
            type: 'delete',
            value: row?.id
          }
        ]
      }
    ]));
    return list;

  }

  onAction(action: any) {
    switch(action?.type) {
      case 'edit':
        this.currentId = action?.value;
        this.getPlanSanteById(action?.value);
      break;
      case 'delete':
        this.getPlanSantes();
      break;
    }
  }

  getPlanSanteById(id: string) {
    this.dataService.getPlanSanteById(id)
    .subscribe(
      (data: any) => {
        this.initForm(data?.data?.planSante);
        this.getAllRisqueOfBranche(data?.data?.planSante?.risque?.branche?.id);
        this.isFormOpened = true;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllCategorieActes() {
    this.dataService.getAllCategorieActes()
    .subscribe(
      (data: any) => {
        this.categorieActes = data?.data?.categorieActes;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllRisqueOfBranche(id: string, risqueId: string = '') {
    this.dataService.getAllRisqueOfBranche(id).subscribe(
      (data: any) => {
        this.risques = data?.data?.risqueBranches;
        var risqueForm: any = this.formulaires?.find((form: any) => form?.code == 'risque');
        if (risqueForm) {
          risqueForm.options = this.risques?.map((data: any) => {
            return {
              cle: data?.id,
              valeur: data?.nom
            }
          })
        }
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  getAllSpecialites() {
    this.dataService.getAllSpecialites()
    .subscribe(
      (data: any) => {
        this.specialites = data?.data?.specialites;
      },
      (err: any) => {
        console.log(err);
      },
    )
  }

  openAddForm() {
    this.isFormOpened = true;
    this.initForm();
  }

  initForm(data: any = {}) {
    this.previousData = {
      ...data
    };
    this.newImage = false;
    if (data?.id) {
      this.formType = 'EDIT';
    }
    else {
      this.formType = 'ADD';
    }
    this.formulaires = [
      {
        code: "image",
        placeholder: "Image",
        typeChamp: "IMAGE",
        etape: 1,
        max: 1,
        typeReponse: "WRITE",
        fileList: [],
        change: (event: any, formulaire: any) => {
          if (event?.type == "removed") {
            formulaire.patchValue({
              image: null
            })
          }
          else {
            this.newImage = true;
            formulaire.patchValue({
              image: event?.file?.originFileObj
            })
          }
          setTimeout(() => {
            console.log(formulaire)
          }, 2000);
        },
        obligatoire: !!(this.formType == 'ADD' || data?.image?.url),
        defaultValue: data?.image?.url ? [
          {
            uid: '-1',
            name: `${data?.nom}.png`,
            status: 'done',
            url: data?.image?.url
          },
        ]
        :
        [],
        size: 'col-md-12'
      },
      {
        code: "nom",
        placeholder: "Nom",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "WRITE",
        obligatoire: !!(this.formType == 'ADD' || data?.nom),
        defaultValue: data?.nom,
        size: 'col-md-8'
      },
      {
        code: "tauxCouverture",
        placeholder: "Taux de couverture",
        typeChamp: "NUMBER",
        etape: 1,
        keyup: (formulaire: any) => {
          if (this.formType == 'ADD') {
            let values = formulaire.value;
            let acteMedicaux: any = this.formulaires?.find((formulaire: any) => formulaire?.code == 'acteMedicaux');
            if (acteMedicaux) {
              acteMedicaux.values = this.categorieActes?.map((categorie: any) => {
                return {
                  id: categorie.id,
                  selectionId: categorie.specialite?.id,
                  items: [categorie?.nom, values?.tauxCouverture]
                }
              });
            }
          }
        },
        typeReponse: "WRITE",
        obligatoire: true,
        defaultValue: data?.tauxCouverture,
        size: 'col-md-4'
      },
      {
        code: "territorialite",
        placeholder: "Territorialité",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        options: [
          {
            cle: "SENEGAL",
            valeur: "SENEGAL"
          },
          {
            cle: "AFRIQUE_ZONE_CIMA",
            valeur: "AFRIQUE ZONE CIMA"
          },
          {
            cle: "MONDE_ENTIER",
            valeur: "MONDE ENTIER"
          }
        ],
        defaultValue: data?.territorialite ?? 'SENEGAL',
        forceDefaultValue: true,
        size: 'col-md-6'
      },
      {
        code: "compagnie",
        placeholder: "Compagnie",
        typeChamp: "TEXT",
        etape: 1,
        typeReponse: "OPTION",
        options: this.compagnies?.map((compagnie: any) => {
          return {
            cle: compagnie?.id,
            valeur: compagnie?.raisonSociale
          }
        }),
        obligatoire: true,
        defaultValue: data?.compagnie?.id,
        size: 'col-md-6'
      },
      {
        code: "entreprises",
        placeholder: "Entreprise",
        typeChamp: "TEXT",
        multiple: true,
        etape: 1,
        typeReponse: "OPTION",
        options: this.entreprises?.map((entreprise: any) => {
          return {
            cle: entreprise?.id,
            valeur: entreprise?.raisonSociale
          }
        }),
        defaultValue: data?.entreprises?.map((entreprise: any) => entreprise?.id),
        size: 'col-md-12'
      },
      {
        code: "forfaitaire",
        placeholder: "Le plan est forfaitaire",
        typeChamp: "CHECKBOX",
        etape: 1,
        typeReponse: "WRITE",
        onChange: (selected:  any, formulaire: any) => {
          console.log(formulaire, selected)
          let primeForm : any = this.formulaires?.find((formulaire: any) => formulaire?.code == 'prime');
          primeForm.obligatoire = selected ? true : false;
        },
        defaultValue: data?.forfaitaire,
        size: 'col-md-6'
      },
      {
        code: "particulier",
        placeholder: "Disponible pour les particuliers",
        typeChamp: "CHECKBOX",
        etape: 1,
        typeReponse: "WRITE",
        defaultValue: data?.particulier,
        size: 'col-md-6'
      },
      {
        code: "prime",
        placeholder: "Prime forfaitaire",
        typeChamp: "NUMBER",
        etape: 1,
        typeReponse: "WRITE",
        visilityDependsOn: 'forfaitaire',
        obligatoire: false,
        defaultValue: data?.prime,
        size: 'col-md-12'
      },
      {
        code: "branche",
        placeholder: "Branche",
        typeChamp: "TEXT",
        etape: 2,
        obligatoire: !!(this.formType == 'ADD' || data?.risque?.branche?.id),
        typeReponse: "OPTION",
        options: this.branches?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.nom
          }
        }),
        onChange: (id: string, formulaire: any) => {
          this.getAllRisqueOfBranche(id);
        },
        defaultValue: data?.risque?.branche?.id,
        size: 'col-md-6'
      },
      {
        code: "risque",
        placeholder: "Risque",
        typeChamp: "TEXT",
        etape: 2,
        obligatoire: !!(this.formType == 'ADD' || data?.risque?.id),
        typeReponse: "OPTION",
        // visilityDependsOn: 'branche',
        options: this.risques?.map((data: any) => {
          return {
            cle: data?.id,
            valeur: data?.nom
          }
        }),
        defaultValue: data?.risque?.id,
        size: 'col-md-6'
      },
      {
        code: "plafond1erAnnee",
        placeholder: "Plafond 1er année",
        typeChamp: "NUMBER",
        etape: 2,
        typeReponse: "WRITE",
        obligatoire: true,
        defaultValue: data?.plafond1erAnnee,
        size: 'col-md-6'
      },
      {
        code: "plafond2emeAnnee",
        placeholder: "Plafond 2eme Année",
        typeChamp: "NUMBER",
        etape: 2,
        typeReponse: "WRITE",
        obligatoire: true,
        defaultValue: data?.plafond2emeAnnee,
        size: 'col-md-6'
      },
      // {
      //   code: "description",
      //   placeholder: "description",
      //   typeChamp: "TINYMICE",
      //   etape: 1,
      //   typeReponse: "WRITE",
      //   defaultValue: data?.description,
      //   size: 'col-md-12'
      // },
      {
        code: "qualites",
        placeholder: "Qualités",
        typeChamp: "TABLE_EDIT_SELECT",
        sizeTable: 6,
        listOfSelection: [
          {
            text: 'Tout séléctionner',
            id: 'ALL'
          }
        ],
        columns: [
          {
            name: 'qualite',
            placeholder: "Qualité",
            type: 'TEXT',
            editable: false,
            size: '40%',
            defaultValue: data?.qualite
          },
          {
            name: 'min',
            placeholder: "Min",
            editable: true,
            size: '15%',
            type: 'NUMBER'
          },
          {
            name: 'max',
            placeholder: "Max",
            editable: true,
            size: '15%',
            type: 'NUMBER'
          },
          {
            name: 'prime',
            placeholder: "Prime",
            editable: true,
            size: '30%',
            type: 'NUMBER'
          }
        ],
        values: this.qualites?.map((qualite: any) => {
          let foundQualite = data?.qualitePlans?.find((qualitePlan: any) => qualitePlan?.qualite?.id == qualite?.id);
          return {
            id: qualite.id,
            saveId: foundQualite?.id,
            selected: foundQualite ? true : false,
            items: [foundQualite?.qualite?.nom ?? qualite?.nom, ((foundQualite?.min || qualite?.obligatoire) ? (foundQualite?.min ?? qualite?.value) : 0), ((foundQualite?.max || qualite?.obligatoire) ? (foundQualite?.max ?? qualite?.value) : 0), foundQualite?.prime ?? 0]
          }
        }),
        etape: 3,
        typeReponse: "TABLE_EDIT_SELECT",
        obligatoire: !!(this.formType == 'ADD' || data?.police?.id),
        // defaultValue: data?.qualitePlans,
        size: 'col-md-12'
      },
      {
        code: "options",
        placeholder: "Options",
        typeChamp: "TABLE",
        sizeTable: 6,
        columns: [
          {
            name: 'id',
            placeholder: 'Id',
            type: 'TEXT',
            hide: true,
          },
          {
            name: 'nom',
            placeholder: 'Nom',
            type: 'TEXT'
          },
          {
            name: 'prime',
            placeholder: 'Prime',
            type: 'NUMBER'
          },
          {
            name: 'forfaitaire',
            placeholder: 'Forfaitaire',
            type: 'CHECKBOX'
          }
        ],
        values: data?.options ? [...data?.options] : [],
        etape: 4,
        typeReponse: "TABLE",
        // obligatoire: !!(this.formType == 'ADD' || data?.qualites),
        defaultValue: data?.options ? [...data?.options] : [],
        size: 'col-md-12'
      },
      {
        code: "acteMedicaux",
        placeholder: "Actes medicaux",
        typeChamp: "TABLE_EDIT_SELECT",
        sizeTable: 6,
        listOfSelection: [
          {
            text: 'Tout séléctionner',
            id: 'ALL'
          },
          ...this.specialites?.map((specialite: any) => ({
            text: specialite?.nom,
            id: specialite?.id
          }))
        ],
        columns: [
          {
            name: 'categorieActe',
            placeholder: "Groupe d'acte",
            type: 'TEXT',
            editable: false,
            size: '63%',
            defaultValue: data?.qualite
          },
          {
            name: 'taux',
            placeholder: "Taux",
            editable: true,
            size: '12%',
            type: 'NUMBER'
          }
        ],
        values: this.categorieActes?.map((categorie: any) => {
          let foundCategorie = data?.planCategorieActes?.find((ca: any) => ca?.categorieActe?.id == categorie?.id);
          console.log(foundCategorie);
          return {
            id: categorie.id,
            saveId: foundCategorie?.id,
            selected: foundCategorie ? true : false,
            items: [foundCategorie?.categorieActe?.nom ?? categorie?.nom, foundCategorie?.tauxCouverture ?? data?.tauxCouverture]
          }
        }),
        etape: 5,
        typeReponse: "TABLE_EDIT_SELECT",
        obligatoire: !!(this.formType == 'ADD' || data?.planCategorieActes),
        defaultValue: this.categorieActes?.map((categorie: any) => {
          let foundCategorie = data?.planCategorieActes?.find((ca: any) => ca?.categorieActe?.id == categorie?.id);
          console.log(foundCategorie);
          return {
            id: categorie.id,
            saveId: foundCategorie?.id,
            selected: foundCategorie ? true : false,
            items: [foundCategorie?.categorieActe?.nom ?? categorie?.nom, foundCategorie?.tauxCouverture ?? data?.tauxCouverture]
          }
        }),
        size: 'col-md-12'
      },
      {
        code: "delaisCarence",
        placeholder: "Delais de carence",
        typeChamp: "TABLE_EDIT_SELECT",
        sizeTable: 6,
        listOfSelection: [
          {
            text: 'Tout séléctionner',
            id: 'ALL'
          }
        ],
        columns: [
          {
            name: 'delaisCarance',
            placeholder: "Acte",
            type: 'TEXT',
            editable: false,
            size: '55%',
            defaultValue: data?.delaisCarance
          },
          {
            name: 'duree',
            placeholder: "Durée",
            editable: true,
            size: '15%',
            type: 'NUMBER'
          },
          {
            name: 'libelleDuree',
            placeholder: "Libellé durée",
            editable: true,
            size: '30%',
            type: 'TEXT'
          }
        ],
        values: this.delaisCarences?.map((delaisCarence: any) => {
          let foundDelaisCarence = data?.planSanteDelaisCarences?.find((psDC: any) => psDC?.delaisCarence?.id == delaisCarence?.id);
          return {
            id: delaisCarence.id,
            saveId: foundDelaisCarence?.id,
            selected: foundDelaisCarence ? true : false,
            items: [foundDelaisCarence?.delaisCarence?.nom ?? delaisCarence?.nom, foundDelaisCarence?.duree ?? delaisCarence?.delaisParDefaut, foundDelaisCarence?.libelleDuree ?? delaisCarence?.libelleDelaisParDefaut]
          }
        }),
        etape: 6,
        typeReponse: "TABLE_EDIT_SELECT",
        obligatoire: !!(this.formType == 'ADD' || data?.planSanteDelaisCarences),
        defaultValue: this.delaisCarences?.map((delaisCarence: any) => {
          let foundDelaisCarence = data?.planSanteDelaisCarences?.find((psDC: any) => psDC?.delaisCarence?.id == delaisCarence?.id);
          return {
            id: delaisCarence.id,
            saveId: foundDelaisCarence?.id,
            selected: foundDelaisCarence ? true : false,
            items: [foundDelaisCarence?.delaisCarence?.nom ?? delaisCarence?.nom, foundDelaisCarence?.duree ?? delaisCarence?.delaisParDefaut, foundDelaisCarence?.libelleDuree ?? delaisCarence?.libelleDelaisParDefaut]
          }
        }),
        size: 'col-md-12'
      },
    ]
  }

  onCloseForm() {
    this.isFormOpened = !this.isFormOpened;
  }

}