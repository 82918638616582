import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreateSouscriptionRoutingModule } from './create-souscription-routing.module';


@NgModule({
  imports: [
    CommonModule,
    CreateSouscriptionRoutingModule
  ],
  declarations: [
  
  ]
})
export class CreateSouscriptionModule { }
