<div class="container-fluid">
    <div class="form-head d-flex align-items-center mb-sm-4 mb-3">
        <div class="mr-auto">
            <h1 class="text-black font-w600">Dashboard</h1>
            <p class="mb-0 sub-title">AWA, une assurance sans limite</p>
        </div>
        <nz-range-picker (nzOnCalendarChange)="calendarChanged()" [(ngModel)]="dateRange" [nzFormat]="dateFormat"></nz-range-picker>
        <!-- <a *ngIf="false" href="javascript:void(0)" class="btn btn-outline-primary"><i class="las la-person-booth scale5 mr-3"></i>Espace personnel</a> -->
    </div>
    <div class="row">
        <div class="col-xl-3  col-sm-6">
            <div class="card">
                <div class="card-body">
                    <div class="media align-items-center">
                        <div class="media-body mr-3">
                            <h2 class="fs-34 text-black font-w600">
                                <nz-statistic [nzValue]="statistics?.quittanceSome | nzAggregate: 'sum'"></nz-statistic>
                            </h2>
                            <span>Production</span>
                        </div>
                        <i class="fa fa-money fa-3x fa-primary" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="progress  rounded-0" style="height:4px;">
                    <div class="progress-bar rounded-0 bg-secondary progress-animated" style="width: 100%; height:4px;" role="progressbar">
                        <span class="sr-only">100% Complete</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3  col-sm-6">
            <div class="card">
                <div class="card-body">
                    <div class="media align-items-center">
                        <div class="media-body mr-3">
                            <h2 class="fs-34 text-black font-w600">
                                <nz-statistic [nzValue]="statistics?.quittanceCommission | nzAggregate: 'sum'"></nz-statistic>
                            </h2>
                            <span>Commissions</span>
                        </div>
                        <i class="fa fa-money fa-3x fa-primary" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="progress  rounded-0" style="height:4px;">
                    <div class="progress-bar rounded-0 bg-secondary progress-animated" style="width: 100%; height:4px;" role="progressbar">
                        <span class="sr-only">100% Complete</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-sm-6">
            <div class="card">
                <div class="card-body">
                    <div class="media align-items-center">
                        <div class="media-body mr-3">
                            <h2 class="fs-34 text-black font-w600">
                                <nz-statistic [nzValue]="statistics?.commissionPrime | nzAggregate: 'sum'"></nz-statistic>
                            </h2>
                            <span>Commissions sur la prime</span>
                        </div>
                        <i class="fa fa-money fa-3x fa-primary" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="progress  rounded-0" style="height:4px;">
                    <div class="progress-bar rounded-0 bg-secondary progress-animated" style="width: 100%; height:4px;" role="progressbar">
                        <span class="sr-only">100% Complete</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3  col-sm-6">
            <div class="card">
                <div class="card-body">
                    <div class="media align-items-center">
                        <div class="media-body mr-3">
                            <h2 class="fs-34 text-black font-w600">
                                <nz-statistic [nzValue]="statistics?.commissionAccessoire | nzAggregate: 'sum'"></nz-statistic>
                            </h2>
                            <span>Commiss. sur les accessoires</span>
                        </div>
                        <i class="fa fa-money fa-3x fa-primary" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="progress  rounded-0" style="height:4px;">
                    <div class="progress-bar rounded-0 bg-secondary progress-animated" style="width: 100%; height:4px;" role="progressbar">
                        <span class="sr-only">100% Complete</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">	
                    <div class="card">
                        <div class="card-header d-sm-flex d-block pb-0 border-0">
                            <div class="card-action card-tabs mt-3 mt-sm-0 mt-3 mb-sm-0 mb-3 mt-sm-0">
                                <ul class="nav nav-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a (click)="compagnieTabChanged('production')" class="nav-link" data-toggle="tab" role="tab">
                                            Production
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a (click)="compagnieTabChanged('commissionTotale')" class="nav-link active" data-toggle="tab" role="tab">
                                            Commis..
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a (click)="compagnieTabChanged('commissionPrime')" class="nav-link" data-toggle="tab" role="tab">
                                            Com. prime
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a (click)="compagnieTabChanged('commissionAccessoire')" class="nav-link" data-toggle="tab" role="tab">
                                            Com. accessoires
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="Daily" role="tabpanel">
                                    <div class="d-flex flex-wrap align-items-center px-4 bg-light">
                                        <div class="mr-auto d-flex align-items-center py-3">
                                            <span class="heart-ai bg-primary mr-3">
                                                <i class="fa fa-file-archive-o" aria-hidden="true"></i>
                                            </span>
                                            <div>
                                                <p class="fs-18 mb-2">{{currentTabCompagnieLibelle[currentTabCompagnie]}}</p>
                                                <span class="fs-26 text-primary font-w600">
                                                    {{quittanceFilters?.totale}} Fcfa
                                                </span>
                                            </div>
                                        </div>
                                        <ul class="users pl-3 py-2">
                                            <!-- <li><img src="assets/images/users/1.png" alt=""></li>
                                            <li><img src="assets/images/users/2.png" alt=""></li>
                                            <li><img src="assets/images/users/3.png" alt=""></li>
                                            <li><img src="assets/images/users/4.png" alt=""></li>
                                            <li><img src="assets/images/users/5.png" alt=""></li> -->
                                        </ul>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-xl-12 col-xxl-12 col-md-12">
                                            <div id="compagniequittance"></div>
                                        </div>
                                        <div class="compagnie-details">
                                            <div *ngFor="let compagnie of quittanceFilters?.compagnies" class="col-xl-12 col-xxl-12 col-md-12">
                                                <div class="d-flex mb-4 align-items-center">
                                                    <span class="mr-auto pr-3 font-w500 fs-30 text-black">
                                                        <svg class="mr-3" width="8" height="30" viewBox="0 0 8 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="7.65957" height="30" fill="#BDA25C"/>
                                                        </svg>
                                                        {{compagnie?.valeur}} Fcfa
                                                    </span>
                                                    <span>{{compagnie?.nom}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-md-6">
            <div class="row">
                <div class="col-md-12">	
                    <div class="card appointment-schedule">
                        <div class="card-header pb-0 border-0">
                            <h3 class="fs-20 text-black mb-0">Production</h3>
                            <div class="dropdown ml-auto">
                                <div class="btn-link p-2 bg-light" data-toggle="dropdown">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a *ngFor="let compagnie of compagnies" class="dropdown-item text-black" href="javascript:;">
                                        <label (ngModelChange)="compagnieFilterChanged()" [(ngModel)]="compagnie.actif" nz-checkbox >{{compagnie?.raisonSociale}}</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- <div *ngFor="let color of colors; let i=index" [ngStyle]="{'background': color}">
                            col {{i}}
                        </div> -->
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-12 col-xxl-12 col-md-12">
                                    <div class="appointment-calender">
                                        <div id="branchequittance"></div>
                                        <!-- <input type='text' class="form-control d-none" id='datetimepicker1' /> -->
                                        <!-- <nz-range-picker [nzSize]="'small'" nzInline [nzFormat]="dateFormat"></nz-range-picker> -->
                                    </div>
                                </div>
                                <div class="col-xl-12 col-xxl-12  col-md-12 dz-scroll" id="appointment-schedule" style="max-height: 298px">
                                    <div *ngFor="let branche of branches" class="d-flex pb-3 border-bottom mb-3 align-items-end">
                                        <div class="mr-auto">
                                            <p class="text-black font-w600 mb-2 t-25">{{branche?.nom}}</p>
                                            <ul>
                                                <li><i class="las la-building"></i>Production <strong> : {{branche?.production}} Fcfa</strong></li>
                                                <li><i class="las la-building"></i>Commission <strong> :  {{branche?.commission}} Fcfa</strong></li>
                                                <li><i class="las la-building"></i>Commission prime <strong> :  {{branche?.comPrime}} Fcfa</strong></li>
                                                <li><i class="las la-building"></i>Commission accessoires<strong> :  {{branche?.comAccessoire}} Fcfa</strong></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">	
                    <div class="card appointment-schedule">
                        <div class="card-header pb-0 border-0">
                            <h3 class="fs-20 text-black mb-0">Arbre quittance</h3>
                            <div class="dropdown ml-auto">
                                <div class="btn-link p-2 bg-light" data-toggle="dropdown">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#2E2E2E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a *ngFor="let compagnie of compagnieTrees" class="dropdown-item text-black" href="javascript:;">
                                        <label (ngModelChange)="arbreFilterChanged()" [(ngModel)]="compagnie.actifTree" nz-checkbox >{{compagnie?.raisonSociale}}</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-12 col-xxl-12 col-md-12">
                                    <div class="appointment-calender">
                                        <div id="arbrequittance"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="false" class="col-md-12">	
                    <div class="card">
                        <div class="card-header border-0 pb-0">
                            <h3 class="fs-20 text-black mb-0 mr-2">Revenue Summary</h3>
                            <div class="dropdown mt-sm-0 mt-3">
                                <button type="button" class="btn bg-light text-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                    2020
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item" href="javascript:void(0);">2020</a>
                                    <a class="dropdown-item" href="javascript:void(0);">2021</a>
                                    <a class="dropdown-item" href="javascript:void(0);">2022</a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-0">
                            <div id="chartBar"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>